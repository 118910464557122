import { EntryFieldTypes, EntryLink } from 'contentful'
import { CmsModel } from './cms-models'
import { LinkModel, LinkSkeleton } from './link-model'

export enum CtaField {
  link = 'link',
  name = 'name',
  style = 'style',
  text = 'displayText'
}

export enum CtaStyle {
  primary = 'Primary',
  secondary = 'Secondary'
}

export const styleIsCtaStyle = (style: any): style is CtaStyle =>
  Object.values(CtaStyle).includes(style)

export type CtaSkeleton = {
  contentTypeId: CmsModel.cta
  fields: {
    [CtaField.link]: EntryFieldTypes.EntryLink<LinkSkeleton>
    [CtaField.name]: EntryFieldTypes.Symbol
    [CtaField.text]: EntryFieldTypes.Symbol
    [CtaField.style]: EntryFieldTypes.Symbol<CtaStyle>
  }
}

export type CtaModelPublish = {
  link: {
    sys: EntryLink
  }
  name: string
  displayText: string
  style: CtaStyle
}

export type CtaModel = {
  displayText: string
  style: CtaStyle
  link: LinkModel
}

export const CtaFields = Object.values(CtaField)
