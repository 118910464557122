import * as React from 'react'
import { DrawGame as DrawGameResponse } from '@interaction-gaming/game-data-service-browser-client'
import { Page } from '../page/page-ui'
import { WinningNumbersCardContainer } from '../winning-numbers-card/winning-numbers-card-container'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { getBlockClass } from '../component-helpers'
import { RapidGameCard } from '../winning-numbers-card/rapid-game-card-ui'
import { RapidOrDrawGame } from '@Cms/model-types/game-model'
import './styles.scss'

export type WinningNumbersPageProps = {
  drawGames: DrawGameResponse[]
  rapidGames: RapidOrDrawGame[]
}

const ROOT_CLASS = 'winning-numbers-page'

export const WinningNumbersPage = (props: WinningNumbersPageProps) => {
  return (
    <Page className={ROOT_CLASS} title='Winning Numbers'>
      <section className={getBlockClass(ROOT_CLASS, 'content')}>
        {props.drawGames.length ? (
          props.drawGames.map((game) => (
            <WinningNumbersCardContainer
              drawGameMetadata={game}
              key={`${game.id}-draw-games`}
            />
          ))
        ) : (
          <LoadingSpinner />
        )}
        {props.rapidGames.map((game) => (
          <RapidGameCard game={game} key={game.identifier} />
        ))}
      </section>
    </Page>
  )
}
