import * as React from 'react'
import { JackpotCard } from './jackpot-card-ui'
import { useAppDispatch, useAppSelector } from '@Client/hooks/redux-hooks'
import { iLotteryActions } from '@Client/reducers/ilottery/ilottery-reducer'

export type OnlineGameJackpotCardContainerProps = {
  iLotteryGameId: number
}

export const OnlineGameJackpotCardContainer = (
  props: OnlineGameJackpotCardContainerProps
) => {
  const dispatch = useAppDispatch()
  const iLotteryGame = useAppSelector(
    (state) => state.iLottery.instantGamesById[props.iLotteryGameId]
  )
  const {
    isFrameworkReady,
    progressiveJackpotsById,
    isPlayerAuthenticated,
    progressiveJackpotLastUpdateEpoch
  } = useAppSelector((state) => state.iLottery)

  const cmsGame = useAppSelector((state) => {
    const gameIdentifier =
      state.cmsGames.gameIdentifiersByiLotteryId[props.iLotteryGameId]
    const gameDataServiceId =
      state.cmsGames.dataServiceIdByGameIdentifier[gameIdentifier]
    return state.cmsGames.gamesByDataServiceId[gameDataServiceId]
  })

  const gamePJPIds = iLotteryGame?.PJPs
  if (!iLotteryGame || !gamePJPIds || !gamePJPIds.length) {
    return null
  }
  const progressiveId = gamePJPIds[0]
  const progressiveValueInDollarsStr =
    progressiveJackpotsById[progressiveId]?.PJPBalanceCurrent

  if (!progressiveValueInDollarsStr) {
    return null
  }

  const playGame = () =>
    dispatch(
      iLotteryActions.requestLoadInstantGame({
        gameId: props.iLotteryGameId,
        playMode: isPlayerAuthenticated ? 'M' : 'D'
      })
    )

  const float = parseFloat(progressiveValueInDollarsStr.replace(/,/g, ''))

  return (
    <JackpotCard
      isLoading={false}
      gameIconSrc={cmsGame?.imageUrl ?? iLotteryGame.images.default}
      gameName={iLotteryGame.name}
      gameType={'online'}
      promoText={'Must be 18+ to play'}
      estimatedJackpotCents={float * 100}
      jackpotDisplay={`$${progressiveValueInDollarsStr}`} // need cents for these games
      status={'ACTIVE'}
      isILotteryFrameworkReady={isFrameworkReady}
      openGameWidget={playGame}
      jackpotType='current'
      asOf={
        progressiveJackpotLastUpdateEpoch
          ? new Date(progressiveJackpotLastUpdateEpoch)
          : new Date()
      }
    />
  )
}
