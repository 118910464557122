import * as React from 'react'
import { Winners } from './winners-ui'
import { WinnerApi, WinnersPageResponse } from '@Client/api/winners'
import { Winner } from '../winners-table/winners-cell'
import { WinnersFormPayload } from '../winners-form/winners-form-ui'
import {
  SortByDirection,
  WinnersSortByDirection,
  WinnersSortBy
} from '../winners-table/winners-table-header'
import { InputMultiSelectOption } from '../input-multi-select/input-multi-select-ui'
import { useDeepLink } from '@Client/hooks/use-deep-link/use-deep-link'
import { useFetchFunction } from '@Client/hooks/use-fetch/use-fetch-function'
import { dateUtil } from '@Util/date-util'
import { Nullable, Optional } from '@Util/utility-types'
import { WinnerFilterValues } from '@interaction-gaming/game-data-service-browser-client'

export enum WinnersParamKey {
  startDate = 'startDate',
  endDate = 'endDate',
  selectedGames = 'selectedGames',
  page = 'page'
}

const RESULTS_PER_PAGE = 100

export const WinnersContainer = () => {
  const { updateParam, getParam, stripParam } = useDeepLink()
  const { fetchData: fetchWinners, isLoading: isWinnersLoading } =
    useFetchFunction<Optional<WinnersPageResponse>>()
  const {
    fetchData: fetchFilters,
    isLoading: isFiltersLoading,
    data: filtersData
  } = useFetchFunction<Optional<WinnerFilterValues>>()

  const [winners, setWinners] = React.useState<Optional<Winner[]>>(undefined)
  const [totalResults, setTotalResults] = React.useState<number>(-1)
  const [selectedGames, setSelectedGames] = React.useState<Optional<string[]>>(
    getParam(WinnersParamKey.selectedGames) !== null
      ? JSON.parse(getParam(WinnersParamKey.selectedGames) as string)
      : undefined
  )
  const [startDate, setStartDate] = React.useState<Optional<string>>(
    getParam(WinnersParamKey.startDate) ?? undefined
  )
  const [endDate, setEndDate] = React.useState<Optional<string>>(
    getParam(WinnersParamKey.endDate) ?? undefined
  )
  const [sortByDirection, setSortByDirection] =
    React.useState<WinnersSortByDirection>(SortByDirection.descending)
  const [sortBy, setSortBy] = React.useState<WinnersSortBy>(WinnersSortBy.date)
  const [page, setPage] = React.useState<Nullable<string>>(
    getParam(WinnersParamKey.page) ?? '1'
  )

  const toggleSortDirection = (sortBy: WinnersSortBy) => {
    const newSortByDirection =
      sortByDirection === SortByDirection.ascending
        ? SortByDirection.descending
        : SortByDirection.ascending
    setSortByDirection(newSortByDirection)
    onWinnersFormSubmit({
      selectedGames: selectedGames,
      startDate: startDate,
      endDate: endDate,
      sortBy: sortBy,
      sortByDirection: newSortByDirection
    })
  }

  const onPageChange = (page: number) => {
    onWinnersFormSubmit({
      selectedGames: selectedGames,
      startDate: startDate,
      endDate: endDate,
      page: page
    })
  }

  const onWinnersFormSubmit = async (payload: WinnersFormPayload) => {
    setSelectedGames(payload.selectedGames)
    setStartDate(payload.startDate)
    setEndDate(payload.endDate)
    if (payload.sortBy) setSortBy(payload.sortBy)
    if (payload.sortByDirection) setSortByDirection(payload.sortByDirection)
    if (payload.page) {
      setPage(payload.page.toString())
      if (payload.page === 1) stripParam('page')
      else updateParam('page', payload.page.toString())
    } else {
      setPage('1')
      stripParam('page')
    }

    const data = await fetchWinners(async () => {
      const response = await WinnerApi.getWinners({
        resultStart: payload.page ? (payload.page - 1) * RESULTS_PER_PAGE : 0,
        resultEnd: payload.page
          ? payload.page * RESULTS_PER_PAGE
          : RESULTS_PER_PAGE,
        startDate: payload.startDate ? new Date(payload.startDate) : undefined,
        endDate: payload.endDate ? new Date(payload.endDate) : undefined,
        gameIds: payload.selectedGames ?? undefined,
        sortField: payload.sortBy ?? sortBy,
        sortDirection: payload.sortByDirection ?? sortByDirection
      })
      return response
    })

    if (data) {
      const winners = data.results.map((winner) => ({
        date: dateUtil(winner.sortDate).toDate(),
        gameName: winner.game.name,
        name: winner.name,
        city: winner.city,
        prize: winner.winAmountInCents
      }))
      setWinners(winners)
      setTotalResults(data.totalResults)
    }
  }

  React.useEffect(() => {
    fetchFilters(async () => await WinnerApi.getFilters())
  }, [])

  // Init submit
  React.useEffect(() => {
    onWinnersFormSubmit({
      selectedGames: selectedGames,
      startDate: startDate,
      endDate: endDate,
      sortBy: sortBy,
      sortByDirection: sortByDirection,
      page: Number(page)
    })
  }, [])

  const gameOptions: InputMultiSelectOption[] = React.useMemo(
    () =>
      filtersData?.games
        .sort((a, b) => a.value.name.localeCompare(b.value.name))
        .map((game) => ({
          label: game.value.name,
          value: game.value.id
        })) ?? [],
    [filtersData]
  )

  return (
    <Winners
      isLoading={isWinnersLoading || isFiltersLoading}
      winners={winners}
      gameOptions={gameOptions}
      totalResults={totalResults}
      payload={{
        selectedGames: selectedGames,
        startDate: startDate,
        endDate: endDate
      }}
      page={Number(page)}
      totalPages={Math.ceil(totalResults / RESULTS_PER_PAGE)}
      onPageChange={onPageChange}
      onWinnersFormSubmit={onWinnersFormSubmit}
      toggleSortDirection={toggleSortDirection}
    />
  )
}
