import * as React from 'react'
import { Nullable } from '@Util/utility-types'

export type UseSwipeProps = {
  xDeltaMin: number
  yDeltaMin: number
}

export type UseSwipeHandlers = {
  onSwipeLeft?: () => void
  onSwipeRight?: () => void
  onSwipeUp?: () => void
  onSwipeDown?: () => void
}

export const useSwipe = (props: UseSwipeProps) => {
  const [startX, setStartX] = React.useState<Nullable<number>>(null)
  const [startY, setStartY] = React.useState<Nullable<number>>(null)
  const [endX, setEndX] = React.useState<Nullable<number>>(null)
  const [endY, setEndY] = React.useState<Nullable<number>>(null)

  const reset = () => {
    setStartX(null)
    setStartY(null)
    setEndX(null)
    setEndY(null)
  }

  const handleTouchStart = (event: React.TouchEvent) => {
    setStartX(event.touches[0].clientX)
    setStartY(event.touches[0].clientY)
    setEndX(null)
    setEndY(null)
  }

  const handleTouchMove = (event: React.TouchEvent) => {
    setEndX(event.touches[0].clientX)
    setEndY(event.touches[0].clientY)
    event.stopPropagation()
  }

  const directionalTouchEnd = (
    start: Nullable<number>,
    end: Nullable<number>,
    delta: number,
    negativeHandler?: () => void,
    positiveHandler?: () => void
  ) => {
    if (start && end) {
      if (start - end >= delta) {
        negativeHandler?.()
      } else if (end - start >= delta) {
        positiveHandler?.()
      }
    }
  }

  const handleTouchEnd = (handlers: UseSwipeHandlers) => {
    directionalTouchEnd(
      startX,
      endX,
      props.xDeltaMin,
      handlers.onSwipeLeft,
      handlers.onSwipeRight
    )
    directionalTouchEnd(
      startY,
      endY,
      props.yDeltaMin,
      handlers.onSwipeUp,
      handlers.onSwipeDown
    )
    reset()
  }

  return {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    xDelta: startX && endX ? startX - endX : 0,
    yDelta: startY && endY ? startY - endY : 0
  }
}
