import * as React from 'react'
import {
  LinkGroupShowcase,
  LinkGroupShowcaseProps
} from '../link-group-showcase/link-group-showcase-ui'
import {
  DynamicUrl,
  GameCollection,
  GameCollectionFilters,
  GameCollectionParam,
  RoutePath
} from '@Client/route-paths'
import { EInstantsIcon } from '@Client/icons/e-instants/e-instants'
import { DrawGamesIcon } from '@Client/icons/draw-games/draw-games'
import { ProgressiveJackpotsIcon } from '@Client/icons/progressive-jackpots/progressive-jackpots'
import { ScratchGamesIcon } from '@Client/icons/scratch-games/scratch-games'
import { KenoIcon } from '@Client/icons/keno/keno'
import { FastPlayIcon } from '@Client/icons/fast-play/fast-play'
import { DraftKingsIcon } from '@Client/icons/draft-kings/draft-kings'
import { DraftKingsLocationsIcon } from '@Client/icons/draft-kings-locations/draft-kings-locations'
import './styles.scss'

export type NavigationGamesStackedProps = {
  onLinkClick: () => void
}

const gamesNavBlocks: Omit<LinkGroupShowcaseProps, 'onLinkClick'>[] = [
  {
    titleDisplayText: 'ILOTTERY GAMES',
    titlePath: DynamicUrl.getGameCollectionUrl(GameCollection.iLottery),
    titleColor: 'white',
    background: 'blue',
    links: [
      {
        displayText: 'e-Instants',
        textColor: 'white',
        icon: <EInstantsIcon />,
        iconBackgroundColor: 'white',
        iconShadow: true,
        path: DynamicUrl.getGameCollectionUrl(GameCollection.iLottery, {
          [GameCollectionParam.filters]: [GameCollectionFilters.eInstant]
        })
      },
      {
        displayText: 'Draw',
        textColor: 'white',
        icon: <DrawGamesIcon />,
        iconBackgroundColor: 'white',
        iconShadow: true,
        path: DynamicUrl.getGameCollectionUrl(GameCollection.iLottery, {
          [GameCollectionParam.filters]: [GameCollectionFilters.draw]
        })
      },
      {
        displayText: 'Progressive',
        textColor: 'white',
        icon: <ProgressiveJackpotsIcon />,
        iconBackgroundColor: 'white',
        iconShadow: true,
        path: DynamicUrl.getGameCollectionUrl(GameCollection.iLottery, {
          [GameCollectionParam.filters]: [GameCollectionFilters.progressive]
        })
      }
    ]
  },
  {
    titleDisplayText: 'IN-STORE GAMES',
    titlePath: DynamicUrl.getGameCollectionUrl(GameCollection.inStore),
    titleColor: 'black',
    background: 'yellow',
    links: [
      {
        displayText: 'Scratch',
        textColor: 'black',
        icon: <ScratchGamesIcon />,
        iconBackgroundColor: 'red',
        iconShadow: true,
        path: DynamicUrl.getGameCollectionUrl(GameCollection.inStore, {
          [GameCollectionParam.filters]: [GameCollectionFilters.scratch]
        })
      },
      {
        displayText: 'Draw & Keno 603',
        textColor: 'black',
        icon: <KenoIcon />,
        iconBackgroundColor: 'red',
        iconShadow: true,
        path: DynamicUrl.getGameCollectionUrl(GameCollection.inStore, {
          [GameCollectionParam.filters]: [GameCollectionFilters.draw]
        })
      },
      {
        displayText: 'Fast Play',
        textColor: 'black',
        icon: <FastPlayIcon />,
        iconBackgroundColor: 'red',
        iconShadow: true,
        path: DynamicUrl.getGameCollectionUrl(GameCollection.inStore, {
          [GameCollectionParam.filters]: [GameCollectionFilters.fastPlay]
        })
      }
    ]
  },
  {
    titleDisplayText: 'SPORTS BETTING',
    titlePath: RoutePath.sports,
    titleColor: 'black',
    background: 'white',
    links: [
      {
        displayText: 'DraftKings Mobile App',
        textColor: 'black',
        icon: <DraftKingsIcon />,
        iconBackgroundColor: 'black',
        iconShadow: true,
        path: '/Sports/DraftKings-Mobile-Sportsbook'
      },
      {
        displayText: 'DraftKings Locations',
        textColor: 'black',
        icon: <DraftKingsLocationsIcon />,
        iconBackgroundColor: 'black',
        iconShadow: true,
        path: '/Sports/DraftKings-Sportsbook-Locations'
      }
    ]
  }
]

const ROOT_CLASS = 'game-nav-stacked'

export const NavigationGamesStacked = (props: NavigationGamesStackedProps) => {
  return (
    <div className={ROOT_CLASS}>
      {gamesNavBlocks.map((previewBlock) => (
        <LinkGroupShowcase
          onLinkClick={props.onLinkClick}
          key={previewBlock.titleDisplayText}
          {...previewBlock}
        />
      ))}
    </div>
  )
}
