/* eslint-disable no-console */

export type LogDriver = {
  debug: (message: string, ...params: any[]) => void
  info: (message: string, ...params: any[]) => void
  warn: (message: string, ...params: any[]) => void
  error: (message: string, ...params: any[]) => void
}

export const registeredLogDrivers: LogDriver[] = []

export function registerLogger(driver: LogDriver) {
  registeredLogDrivers.push(driver)
}

export function unregisterLogger(driver: LogDriver) {
  const driverIndex = registeredLogDrivers.indexOf(driver)
  if (driverIndex > 0) {
    registeredLogDrivers.splice(driverIndex, 1)
  } else {
    console.warn('Could not unregister log driver, no matching driver found')
  }
}

export const Logger: LogDriver = {
  debug: (message: string, ...params: any[]) => {
    registeredLogDrivers.forEach((driver) => driver.debug(message, ...params))
  },
  info: (message: string, ...params: any[]) => {
    registeredLogDrivers.forEach((driver) => driver.info(message, ...params))
  },
  warn: (message: string, ...params: any[]) => {
    registeredLogDrivers.forEach((driver) => driver.warn(message, ...params))
  },
  error: (message: string, ...params: any[]) => {
    registeredLogDrivers.forEach((driver) => driver.error(message, ...params))
  }
}
