import * as React from 'react'
import { getBlockClass } from '../component-helpers'
import { FooterIconGroup } from './footer-icon-group'
import { FooterLinkGroup, FooterStandaloneLinks } from './footer-link-group'
import { footerCategoriesByName } from './constants'
import { FooterContactBlock } from './footer-contact-block'

const ROOT_CLASS = 'footer-mobile'

export type FooterMobileProps = {
  onILotteryHelpClick: () => void
}

export const FooterMobile = (props: FooterMobileProps) => (
  <div className={ROOT_CLASS}>
    <FooterIconGroup />
    <div className={getBlockClass(ROOT_CLASS, 'columns')}>
      <div className={getBlockClass(ROOT_CLASS, 'column')}>
        <FooterStandaloneLinks />
        <FooterContactBlock onILotteryHelpClick={props.onILotteryHelpClick} />
        <FooterLinkGroup title='Games' links={footerCategoriesByName.Games} />
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'column')}>
        <FooterLinkGroup
          title='Partners'
          links={footerCategoriesByName.Partners}
        />
        <FooterLinkGroup title='Legal' links={footerCategoriesByName.Legal} />
      </div>
    </div>
  </div>
)
