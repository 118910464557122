import { PayloadAction } from '@reduxjs/toolkit'
import { put, select, takeEvery } from 'redux-saga/effects'
import { Api } from '@Client/api'
import { DrawGameApi } from '@Client/api/draw-games'
import { drawTimesActions } from '@Client/reducers/draw-times/draw-times-reducer'
import { FetchDrawTimesPayload } from '@Client/reducers/draw-times/draw-times-reducer-types'
import { DrawTimeResponse } from '@interaction-gaming/game-data-service-browser-client'
import {
  fetchDrawTimesWindow,
  getDrawDateStateFromDrawTimes
} from '@Client/reducers/draw-times/helpers'
import { GameField, GameModel, GameType } from '@Cms/model-types/game-model'
import { RootState } from '@Client/reducers'

export function* setupDrawTimesSaga() {
  yield takeEvery(drawTimesActions.fetchDrawTimes.type, fetchDrawTimes)
  yield takeEvery(drawTimesActions.fetchAllDrawTimes.type, fetchAllDrawTimes)
}

function* fetchDrawTimes(action: PayloadAction<FetchDrawTimesPayload>) {
  try {
    const drawTimeResponse: DrawTimeResponse[] =
      yield DrawGameApi.getGameDrawTimes(
        action.payload.gameId,
        new Date(action.payload.startDateEpoch),
        new Date(action.payload.endDateEpoch)
      )
    const drawDates = getDrawDateStateFromDrawTimes(drawTimeResponse)
    yield put(
      drawTimesActions.fetchDrawTimesSuccess({
        gameId: action.payload.gameId,
        drawDates
      })
    )
  } catch (error) {
    yield put(
      drawTimesActions.fetchDrawTimesFailure({
        gameId: action.payload.gameId,
        error: (error as Error)?.message ?? Api.DEFAULT_ERROR
      })
    )
  }
}

function* fetchAllDrawTimes() {
  const games: Record<string, GameModel> = yield select(
    (state: RootState) => state.cmsGames.gamesByDataServiceId
  )

  for (const game of Object.values(games)) {
    if (
      game[GameField.type] === GameType.draw &&
      game.configuration.dataServices.gameDataServiceId !== 'foo' // TODO remove; test data only
    ) {
      yield put(
        fetchDrawTimesWindow(game.configuration.dataServices.gameDataServiceId)
      )
    }
  }
}
