import { GameField, GameModel } from '@Cms/model-types/game-model'
import {
  SortOptionField,
  SortOptionIdentifier,
  SortOptionModel
} from '@Cms/model-types/sort-option-model'
import { dateUtil } from '@Util/date-util'
import { deepCopy } from '@Util/object-utilities'

export type SortCallback = (a: any, b: any) => -1 | 1 | 0

export const sortGamesByName: SortCallback = (a: GameModel, b: GameModel) => {
  const lowerNameA = a[GameField.name].toLowerCase()
  const lowerNameB = b[GameField.name].toLowerCase()
  return lowerNameA < lowerNameB ? -1 : lowerNameA > lowerNameB ? 1 : 0
}

export const sortByGameDateNewest: SortCallback = (
  a: GameModel,
  b: GameModel
) => {
  const dateObjA = dateUtil(a[GameField.startDate])
  const dateObjB = dateUtil(b[GameField.startDate])
  return dateObjA.isBefore(dateObjB) ? 1 : dateObjB.isBefore(dateObjA) ? -1 : 0
}

export const sortByGamePriceLowToHigh: SortCallback = (
  a: GameModel,
  b: GameModel
) => {
  const priceA = a[GameField.price].priceInCents
  const priceB = b[GameField.price].priceInCents
  if (!priceA && !priceB) {
    return 0
  } else if (!priceA) {
    return 1
  } else if (!priceB) {
    return -1
  }
  return priceA < priceB ? -1 : priceA > priceB ? 1 : 0
}

export const sortByGamePriceHighToLow: SortCallback = (
  a: GameModel,
  b: GameModel
) => {
  const priceA = a[GameField.price].priceInCents
  const priceB = b[GameField.price].priceInCents
  if (!priceA && !priceB) {
    return 0
  } else if (!priceA) {
    return -1
  } else if (!priceB) {
    return 1
  }
  return priceA > priceB ? -1 : priceA < priceB ? 1 : 0
}

const sortGamesByPredefinedSort = (
  games: GameModel[],
  sortOption: SortOptionModel
): GameModel[] => {
  const gamesToSort = deepCopy(games)
  switch (sortOption[SortOptionField.identifier]) {
    case SortOptionIdentifier.gameName:
      return gamesToSort.sort(sortGamesByName)
    case SortOptionIdentifier.newest:
      return gamesToSort.sort(sortByGameDateNewest)
    case SortOptionIdentifier.priceLowToHigh:
      return gamesToSort.sort(sortByGamePriceLowToHigh)
    case SortOptionIdentifier.priceHighToLow:
      return gamesToSort.sort(sortByGamePriceHighToLow)
    default:
      return games
  }
}

export const sortGamesByOrder = (
  games: GameModel[],
  sortOption: SortOptionModel
): GameModel[] => {
  const gamesToSort = deepCopy(games)
  const manualOrder = sortOption.gameOrder
  if (manualOrder) {
    const gameIdentifiersInOrder = manualOrder.gameIdentifiers
    const orderedGameIdentifiersSet = new Set(gameIdentifiersInOrder)
    const manualGames: GameModel[] = []
    const remainingGames: GameModel[] = []

    gamesToSort.forEach((game) =>
      orderedGameIdentifiersSet.has(game.identifier)
        ? manualGames.push(game)
        : remainingGames.push(game)
    )
    const indicesByIdentifier: Record<string, number> = {}

    const getGameSortIndex = (identifier: string) => {
      let gameIndex = indicesByIdentifier[identifier]
      if (gameIndex === undefined) {
        gameIndex = gameIdentifiersInOrder.findIndex(
          (gameIdentifier) => gameIdentifier === identifier
        )
        gameIndex = gameIndex !== -1 ? gameIndex : Infinity
        indicesByIdentifier[identifier] = gameIndex
      }
      return gameIndex
    }
    manualGames.sort((a, b) => {
      const firstIndex = getGameSortIndex(a[GameField.identifier])
      const secondIndex = getGameSortIndex(b[GameField.identifier])
      return firstIndex - secondIndex
    })

    const sortedRemainingGames = sortGamesByPredefinedSort(
      remainingGames,
      sortOption
    )
    return manualGames.concat(sortedRemainingGames)
  }
  return sortGamesByPredefinedSort(games, sortOption)
}
