import * as React from 'react'
import { AsyncImage } from '../async-image/async-image-ui'
import { getBlockClass } from '../component-helpers'
import { GeneralLink } from '../link/link-ui'
import myRewardsImg from '@Client/assets/my-rewards.png'
import { AppStore } from '@Client/constants'
import './styles.scss'

const ROOT_CLASS = 'mobile-app-card'

export const MobileAppCard = () => {
  return (
    <div className={ROOT_CLASS}>
      <AsyncImage
        src={myRewardsImg}
        alt={
          'Illustration of a phone. Phone states that retail rewards are here in the New Hampshire Lottery mobile app.'
        }
        className={getBlockClass(ROOT_CLASS, 'image')}
      />
      <div className={getBlockClass(ROOT_CLASS, 'content')}>
        <h2 className={getBlockClass(ROOT_CLASS, 'title')}>
          NH LOTTERY MOBILE APP
        </h2>
        <span className={getBlockClass(ROOT_CLASS, 'description')}>
          <span>
            Redeem rewards and play iLottery games with the NH Mobile App
          </span>
        </span>
        <div className={getBlockClass(ROOT_CLASS, 'links')}>
          <GeneralLink
            dest={AppStore.APP_STORE_LINK}
            className={getBlockClass(ROOT_CLASS, 'app-store-link')}
            ariaLabel='App Store Link'
          >
            <AsyncImage
              src={AppStore.APP_STORE_BADGE}
              className={getBlockClass(ROOT_CLASS, 'badge')}
              alt={'Download on the App Store'}
            />
          </GeneralLink>
          <GeneralLink
            dest={AppStore.PLAY_STORE_LINK}
            className={getBlockClass(ROOT_CLASS, 'play-store-link')}
            ariaLabel='Play Store Link'
          >
            <AsyncImage
              src={AppStore.PLAY_STORE_BADGE}
              className={getBlockClass(ROOT_CLASS, 'badge')}
              alt={'Get it on Google Play'}
            />
          </GeneralLink>
        </div>
      </div>
    </div>
  )
}
