import * as React from 'react'
import Select, { components } from 'react-select'
import { isServerRender } from '@Util/ssr-util'
import { getInputLabelId, InputLabel } from '../input-label/input-label-ui'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { InputError } from '../input-error/input-error-ui'
import './styles.scss'

export type InputMultiSelectOption = {
  label: string
  value: string
}

export type InputMultiSelectProps = {
  className?: string
  options: InputMultiSelectOption[]
  onChange: (value: Readonly<InputMultiSelectOption[]>) => void
  id: string
  ariaLabel: string
  label?: string
  error?: string
  placeholder?: string
  values?: InputMultiSelectOption[]
}

export const getMultiSelectOptionsFromValues = (
  options: InputMultiSelectOption[],
  values?: string[]
) => (!values ? [] : options.filter((option) => values.includes(option.value)))

const ROOT_CLASS = 'input-multi-select'
const SELECT_CLASS = getBlockClass(ROOT_CLASS, 'select')

const baseCustomComponents = {
  Control: (props: any) => (
    <components.Control
      {...props}
      className={getBlockClass(ROOT_CLASS, 'control')}
    />
  )
}

export const InputMultiSelect = (props: InputMultiSelectProps) => {
  return (
    <div className={joinClasses([ROOT_CLASS, props.className])}>
      {props.label && (
        <InputLabel for={props.id} error={props.error}>
          {props.label}
        </InputLabel>
      )}
      <Select
        components={
          isServerRender()
            ? {
                ...baseCustomComponents,
                Input: (props) => (
                  // Open issue:
                  // https://github.com/JedWatson/react-select/issues/5459
                  <components.Input
                    {...props}
                    aria-activedescendant={undefined}
                  />
                )
              }
            : baseCustomComponents
        }
        isMulti
        id={`${props.id}-select`}
        inputId={props.id}
        instanceId={props.id}
        aria-label={props.ariaLabel}
        aria-errormessage={props.error}
        placeholder={props.placeholder}
        aria-labelledby={getInputLabelId(props.id)}
        className={joinClasses([
          SELECT_CLASS,
          props.className ? getBlockClass(props.className, 'select') : '',
          getModifierClass(SELECT_CLASS, 'error', !!props.error)
        ])}
        options={props.options}
        onChange={props.onChange}
        value={props.values}
        styles={{
          control: (provided) => ({
            ...provided,
            borderColor: '#b2b5b9'
          }),
          placeholder: (provided) => ({
            ...provided,
            color: '#757575'
          })
        }}
      />
      <InputError id={props.id} error={props.error} />
    </div>
  )
}
