import * as React from 'react'
import { useRevalidator } from 'react-router-dom'
import { useAppSelector } from '../redux-hooks'

export const useCmsPreview = () => {
  const revalidator = useRevalidator()
  const { cmsPreviewActive } = useAppSelector((state) => state.operations)

  React.useEffect(() => {
    revalidator.revalidate()
  }, [cmsPreviewActive])
}
