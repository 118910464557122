import { ApiConfig } from './config'
import {
  Configuration,
  GeoSearchUnit,
  LocationResponse,
  LocationsApi
} from '@interaction-gaming/game-data-service-browser-client'
import { Optional } from '@Util/utility-types'

let LOCATION_API: Optional<LocationsApi> = undefined

export type LocationsRequest = {
  latitude: number
  longitude: number
  radius: number
  unit?: GeoSearchUnit
  tags?: Array<string>
  limit?: number
}

export type LocationsApiResponse = LocationResponse[]

const configureLocationApi = (): LocationsApi => {
  if (!LOCATION_API) {
    const config = new Configuration({
      basePath: ApiConfig.gameDataRoot,
      apiKey: ApiConfig.gameDataApiKey
    })
    LOCATION_API = new LocationsApi(config)
  }
  if (!LOCATION_API) {
    throw new Error('Error configuring Location API')
  }
  return LOCATION_API
}

const getLocations = async (
  locationsRequest: LocationsRequest
): Promise<Optional<LocationsApiResponse>> => {
  try {
    const api = configureLocationApi()
    return await api.geoSearchLocations({
      ...locationsRequest,
      lat: locationsRequest.latitude,
      _long: locationsRequest.longitude,
      radius: locationsRequest.radius,
      xClientID: ApiConfig.CLIENT_ID
    })
  } catch (e) {
    console.error('Error fetching locations', e)
    throw e
  }
}

export const LocationApi = {
  getLocations
}
