import { EntryFieldTypes } from 'contentful'
import { CmsModel } from './cms-models'
import { ResponsiveImageModel } from './responsive-image-model'

export enum TextField {
  name = 'name',
  content = 'content',
  textColor = 'textColor'
}

export type TextSkeleton = {
  contentTypeId: CmsModel.text
  fields: {
    [TextField.name]: EntryFieldTypes.Symbol
    [TextField.content]: EntryFieldTypes.RichText
    [TextField.textColor]?: EntryFieldTypes.Symbol
  }
}

export enum TextInlineContentType {
  text = 'text',
  entryLink = 'link',
  embeddedImage = 'embeddedImage'
}

export enum TextBlockContentType {
  embeddedEntry = 'embedded-entry-block',
  paragraph = 'paragraph',
  orderedList = 'ordered-list',
  unorderedList = 'unordered-list',
  listItem = 'list-item',
  headingOne = 'heading-1',
  headingTwo = 'heading-2',
  headingThree = 'heading-3',
  headingFour = 'heading-4'
}

export enum TextMark {
  bold = 'bold',
  italic = 'italic',
  underline = 'underline'
}

export const isValidCmsTextMark = (value: string): value is TextMark =>
  Object.values(TextMark).includes(value as TextMark)

export type TextInlineTextData = { text: string }
export type TextInlineHyperlinkData = {
  route: string
  text: TextInlineTextContent
  isAbsolute: boolean
}

export type TextInlineData = TextInlineTextData | TextInlineHyperlinkData

export type TextInlineTextContent = {
  type: TextInlineContentType.text
  data: TextInlineTextData
  marks?: TextMark[]
}

export type TextInlineHyperlinkContent = {
  type: TextInlineContentType.entryLink
  data: TextInlineHyperlinkData
}

export type TextEmbeddedImageContent = {
  type: TextInlineContentType.embeddedImage
  data: ResponsiveImageModel
}

export type TextEmbeddedEntryContent = TextEmbeddedImageContent

export type TextInlineContent =
  | TextInlineTextContent
  | TextInlineHyperlinkContent
  | TextEmbeddedImageContent

export type TextBlockNode = {
  type: TextBlockContentType
  content: TextInlineContent[] | TextBlockNode[]
}

export type TextDocumentNode = TextBlockNode | TextInlineContent

export type TextModel = {
  name: string
  document: TextDocumentNode[]
  textColor?: string
}

export const TextFields = Object.values(TextField)
