import * as React from 'react'
import { useIsDesktop } from '@Client/hooks/use-media-query/use-media-query'
import { CompositeBannerModel } from '@Cms/model-types/composite-banner-model'
import { ImageBanner } from '../banner-image/banner-image-ui'
import { getBlockClass } from '../component-helpers'
import { CTA } from '../cms-cta/cta'
import { useResizeObserver } from '@Client/hooks/use-resize-observer/use-resize-observer'
import './styles.scss'

export type CompositeBannerProps = {
  banner: CompositeBannerModel
}

const ROOT_CLASS = 'composite-banner'

export const CompositeBanner = (props: CompositeBannerProps) => {
  const isDesktop = useIsDesktop()
  const imageRef = React.useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = React.useState(0)
  useResizeObserver(() => {
    setContentHeight(imageRef.current?.offsetHeight || 0)
  }, imageRef)

  const popOutOffset = contentHeight * props.banner.popOutRatio
  const computedHeight =
    isDesktop && props.banner.popOutRatio
      ? `${contentHeight - popOutOffset}px`
      : undefined
  return (
    <div className={ROOT_CLASS}>
      <ImageBanner
        ref={imageRef}
        imageBanner={props.banner.imageBanner}
        className={getBlockClass(ROOT_CLASS, 'image-banner')}
      />
      <div
        className={getBlockClass(ROOT_CLASS, 'content-container')}
        style={{
          height: computedHeight
        }}
      >
        <div className={getBlockClass(ROOT_CLASS, 'content')}>
          <h2 className={getBlockClass(ROOT_CLASS, 'header')}>
            {props.banner.header}
          </h2>
          <span className={getBlockClass(ROOT_CLASS, 'subheader')}>
            {props.banner.subheader}
          </span>
          <CTA
            cta={props.banner.cta}
            className={getBlockClass(ROOT_CLASS, 'cta')}
          />
        </div>
      </div>
    </div>
  )
}
