import * as React from 'react'
import { DialogClose } from '@radix-ui/react-dialog'

export type ModalCloseButtonProps = {
  className?: string
}

export const ModalCloseButton = React.forwardRef(
  (
    props: React.PropsWithChildren<ModalCloseButtonProps>,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <DialogClose asChild>
        <button {...props} ref={ref}>
          {props.children}
        </button>
      </DialogClose>
    )
  }
)
