import * as React from 'react'
import {
  getBlockClass,
  getCustomCssProperties,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { GameActionMenu } from '../game-action-menu/game-action-menu-ui'
import { GameModel, GameType } from '@Cms/model-types/game-model'
import { GeneralLink } from '../link/link-ui'
import { DynamicUrl } from '@Client/route-paths'
import { GameRibbon } from '../game-ribbon/game-ribbon'
import { Button } from '../button/button'
import './styles.scss'

export type GameTileProps = {
  game: GameModel
  tagLine?: string
  loadGame: () => void
  unsafeDevPlaceholderImageOverride?: true
  isILotteryFrameworkReady: boolean
  nextDrawingDate?: Date
  isILotteryGame: boolean
}

type GameTileImageProps = GameTileProps

const ROOT_CLASS = 'game-tile'
const BOTTOM_INFO_CLASS = getBlockClass(ROOT_CLASS, 'bottom-info')
const CONTAINER_CLASS = getBlockClass(ROOT_CLASS, 'container')
const GLOW_CONTAINER_CLASS = getBlockClass(ROOT_CLASS, 'glow-container')
const IMAGE_CONTAINER_CLASS = getBlockClass(ROOT_CLASS, 'image-container')
const TAGLINE_CLASS = getBlockClass(ROOT_CLASS, 'tagline')

const GameTileImage = (props: GameTileImageProps) => {
  const iLotteryGameId = Number(props.game.configuration.iLottery?.iLotteryId)
  if (iLotteryGameId && props.game.type === GameType.online) {
    return (
      <Button
        ariaLabel={`Launch ${props.game.name} in iLottery`}
        onClick={() => props.loadGame()}
        className={getBlockClass(ROOT_CLASS, 'image-button')}
      />
    )
  }
  return (
    <GeneralLink
      dest={DynamicUrl.getGameByIdentifierUrl(props.game.identifier)}
      className={getBlockClass(ROOT_CLASS, 'image-link')}
      ariaLabel={`View game details for ${props.game.name}`}
    />
  )
}

export const GameTile = (props: GameTileProps) => {
  const rootRef = React.useRef<HTMLDivElement>(null)
  const actionMenuRef = React.useRef<HTMLButtonElement>(null)
  const [mousePosX, setMousePosX] = React.useState(0)

  const seriesBkgColor = props.game.meta.series?.backgroundColor
  const hasILottery = Boolean(props.game.configuration.iLottery?.iLotteryId)

  const updateMousePosition = (e: MouseEvent) => {
    if (actionMenuRef.current?.contains(e.target as Node)) {
      return
    }
    const tileOffsetX = e.offsetX
    setMousePosX(tileOffsetX)
  }

  const resetMousePosition = () => {
    setMousePosX(0)
  }

  React.useEffect(() => {
    if (rootRef.current) {
      rootRef.current.addEventListener('mousemove', updateMousePosition)
      rootRef.current.addEventListener('mouseleave', resetMousePosition)
    }
    return () => {
      rootRef.current?.removeEventListener('mousemove', updateMousePosition)
      rootRef.current?.removeEventListener('mouseleave', resetMousePosition)
    }
  }, [rootRef, actionMenuRef])

  return (
    <div
      ref={rootRef}
      className={joinClasses([
        CONTAINER_CLASS,
        getModifierClass(CONTAINER_CLASS, 'is-ilottery', props.isILotteryGame)
      ])}
      style={
        mousePosX
          ? getCustomCssProperties({
              '--mouse-left': mousePosX ? `${mousePosX}px` : ''
            })
          : undefined
      }
    >
      {props.game.meta.ribbon && <GameRibbon {...props.game.meta.ribbon} />}
      <div
        className={joinClasses([
          ROOT_CLASS,
          getModifierClass(ROOT_CLASS, 'is-ilottery', props.isILotteryGame)
        ])}
        style={
          seriesBkgColor
            ? getCustomCssProperties({
                '--series-bkg-color': seriesBkgColor,
                '--series-text-color':
                  props.game.meta.series?.textColor ?? 'white'
              })
            : undefined
        }
      >
        <div
          className={joinClasses([
            IMAGE_CONTAINER_CLASS,
            getModifierClass(IMAGE_CONTAINER_CLASS, props.game.type, true)
          ])}
          style={{
            backgroundImage: `url(${props.game.previewImageUrl ?? props.game.imageUrl})`
          }}
        >
          <GameTileImage {...props} />
        </div>
        <div
          className={joinClasses([
            BOTTOM_INFO_CLASS,
            getModifierClass(
              BOTTOM_INFO_CLASS,
              'is-ilottery',
              props.isILotteryGame
            )
          ])}
        >
          <span
            className={joinClasses([
              TAGLINE_CLASS,
              getModifierClass(
                TAGLINE_CLASS,
                'is-ilottery',
                props.isILotteryGame
              )
            ])}
          >
            {props.tagLine}
          </span>
          <GameActionMenu
            ref={actionMenuRef}
            hasILottery={hasILottery}
            game={props.game}
            loadGame={props.loadGame}
            priceMinInCents={props.game.price.priceInCents ?? 0}
            priceMaxInCents={props.game.price.priceInCents ?? 0}
            isILotteryFrameworkReady={props.isILotteryFrameworkReady}
            nextDrawingDate={props.nextDrawingDate}
            buttonHexColor={!props.isILotteryGame ? '#000000' : undefined}
          />
        </div>
      </div>
      <span
        className={joinClasses([
          GLOW_CONTAINER_CLASS,
          getModifierClass(
            GLOW_CONTAINER_CLASS,
            'is-ilottery',
            props.isILotteryGame
          )
        ])}
      />
    </div>
  )
}
