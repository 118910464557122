import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IwgJackpotState,
  UpdateJackpotValueAction
} from './iwg-jackpots-reducer-types'
import { MqttApiInfo } from '@Server/adapters/iwg-adapter'

export const defaultIwgJackpotsState: IwgJackpotState = {
  isLoading: false,
  mqttByIwgIdentifier: {},
  jackpotValuesByIwgIdentifier: {}
}

const iwgJackpotsSlice = createSlice({
  name: 'iwgJackpots',
  initialState: defaultIwgJackpotsState,
  reducers: {
    fetchAllIwgJackpotsRequest: (state) => {
      state.isLoading = true
      state.error = undefined
    },
    fetchAllIwgJackpotsSuccess: (
      state,
      action: PayloadAction<Record<string, MqttApiInfo>>
    ) => {
      state.isLoading = false
      state.mqttByIwgIdentifier = action.payload
    },
    fetchAllIwgJackpotsFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    updateIwgJackpotValue: (
      state,
      action: PayloadAction<UpdateJackpotValueAction>
    ) => {
      state.jackpotValuesByIwgIdentifier[action.payload.iwgIdentifier] = {
        ...state.jackpotValuesByIwgIdentifier[action.payload.iwgIdentifier],
        [action.payload.jackpot.id]: action.payload.jackpot.a
      }
    }
  }
})

export const iwgJackpotsReducer = iwgJackpotsSlice.reducer
export const iwgJackpotsActions = iwgJackpotsSlice.actions
