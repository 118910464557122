import * as React from 'react'
import { DialogTrigger } from '@radix-ui/react-dialog'
import { joinClasses } from '../component-helpers'
import './styles.scss'

export type ModalTriggerButtonProps = {
  className?: string
  ariaLabel?: string
  id?: string
}

const ROOT_CLASS = 'modal-trigger-button'

export const ModalTriggerButton = React.forwardRef(
  (
    props: React.PropsWithChildren<ModalTriggerButtonProps>,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const { ariaLabel, ...rest } = props
    return (
      <DialogTrigger asChild>
        <button
          {...rest}
          id={props.id}
          aria-label={ariaLabel}
          className={joinClasses([ROOT_CLASS, props.className])}
          ref={ref}
        >
          {props.children}
        </button>
      </DialogTrigger>
    )
  }
)
