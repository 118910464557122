import * as React from 'react'
import * as Popover from '@radix-ui/react-popover'
import { Button } from '../button/button'
import { getBlockClass } from '@Components/component-helpers'
import './styles.scss'

export type ControlledActionMenuProps = {
  onClose?: () => void
  onOpen?: () => void
  buttonFillColorHex?: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const ROOT_CLASS = 'action-menu'
const NUM_DOTS = 3

const ActionMenuDot = (
  props: Pick<ControlledActionMenuProps, 'buttonFillColorHex'>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='8'
    height='8'
    viewBox='0 0 8 8'
    fill='none'
    className={getBlockClass(ROOT_CLASS, 'dot')}
  >
    <circle cx='4' cy='4' r='3' fill={props.buttonFillColorHex ?? '#FFFFFF'} />
  </svg>
)

export const ControlledActionMenu = React.forwardRef(
  (
    props: React.PropsWithChildren<ControlledActionMenuProps>,
    triggerRef?: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const onOpenChange = (open: boolean) => {
      open ? props.onOpen?.() : props.onClose?.()
      props.setIsOpen(open)
    }

    const toggleActionMenu = () => onOpenChange(!props.isOpen)

    return (
      <Popover.Root open={props.isOpen} onOpenChange={onOpenChange}>
        <Popover.Trigger asChild ref={triggerRef}>
          <Button
            ariaLabel='Toggle action menu open state'
            className={getBlockClass(ROOT_CLASS, 'trigger')}
            onClick={toggleActionMenu}
          >
            <span
              role='presentation'
              className={getBlockClass(ROOT_CLASS, 'dots')}
            >
              {Array(NUM_DOTS)
                .fill(0)
                .map((_, i) => (
                  <ActionMenuDot
                    key={i}
                    buttonFillColorHex={props.buttonFillColorHex}
                  />
                ))}
            </span>
          </Button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            align='end'
            sideOffset={6}
            alignOffset={-4}
            className={getBlockClass(ROOT_CLASS, 'content')}
          >
            {props.children}
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    )
  }
)
