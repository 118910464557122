import {
  CurrentAndPreviousDrawings,
  DrawGame,
  DrawGamesApi,
  Drawing,
  DrawSchedule,
  DrawTimeResponse
} from '@interaction-gaming/game-data-service-browser-client'
import { Optional } from '@Util/utility-types'
import { ApiConfig, gameDataConfig } from './config'

let GAME_DATA_DRAW_GAME_API: Optional<DrawGamesApi> = undefined
const GAME_DRAWINGS_MAX_DAYS = 90
const gamePriority = [
  'Powerball',
  'Mega Millions',
  'Megabucks',
  'Lucky for Life',
  'Gimme 5',
  'Pick 3',
  'Pick 4'
]

export type GetAllDrawGamesResponse = DrawGame[]
export type DrawingDetailsResponse = CurrentAndPreviousDrawings
export type ListDrawingsResponse = Drawing[]
export type DrawGameResponse = DrawGame

const configureGameDataDrawGameApi = (): DrawGamesApi => {
  if (!GAME_DATA_DRAW_GAME_API) {
    GAME_DATA_DRAW_GAME_API = new DrawGamesApi(gameDataConfig)
  }
  if (!GAME_DATA_DRAW_GAME_API) {
    throw new Error('Error configuring Draw Games API')
  }
  return GAME_DATA_DRAW_GAME_API
}

const getDrawGames = async (): Promise<GetAllDrawGamesResponse> => {
  try {
    const api = configureGameDataDrawGameApi()
    const games = await api.getAllDrawGames({
      xClientID: ApiConfig.CLIENT_ID
    })
    return games.sort((a, b) => {
      const indexA = gamePriority.indexOf(a.name)
      const indexB = gamePriority.indexOf(b.name)
      if (indexA !== -1 && indexB !== -1) return indexA - indexB
      if (indexA !== -1) return -1
      if (indexB !== -1) return 1
      return 0
    })
  } catch (e) {
    console.error('Error fetching draw games', e)
    throw e
  }
}

const getDrawingDetails = async (
  drawGameId: string,
  asOf?: Date
): Promise<DrawingDetailsResponse> => {
  try {
    const api = configureGameDataDrawGameApi()
    return await api.getDrawingDetails({
      xClientID: ApiConfig.CLIENT_ID,
      drawGameId,
      asOf
    })
  } catch (e) {
    console.error('Error fetching drawing details', e)
    throw e
  }
}

const getGameDrawTimes = async (
  drawGameId: string,
  startDate: Date,
  endDate: Date
): Promise<DrawTimeResponse[]> => {
  try {
    const api = configureGameDataDrawGameApi()
    return await api.listDrawTimes({
      xClientID: ApiConfig.CLIENT_ID,
      drawGameId,
      startDate,
      endDate
    })
  } catch (e) {
    console.error('Error fetching game drawings', e)
    throw e
  }
}

const getGameDrawings = async (
  drawGameId: string,
  startDate: Date,
  endDate: Date
): Promise<ListDrawingsResponse> => {
  try {
    const api = configureGameDataDrawGameApi()
    return await api.listDrawings({
      xClientID: ApiConfig.CLIENT_ID,
      drawGameId,
      startDate,
      endDate
    })
  } catch (e) {
    console.error('Error fetching game drawings', e)
    throw e
  }
}

const getGameSchedule = async (
  drawGameId: string,
  asOf?: Date
): Promise<DrawSchedule> => {
  try {
    const api = configureGameDataDrawGameApi()
    return await api.getDrawSchedule({
      xClientID: ApiConfig.CLIENT_ID,
      drawGameId,
      asOf
    })
  } catch (e) {
    console.error('Error fetching game schedule', e)
    throw e
  }
}

export const DrawGameApi = {
  getDrawGames,
  getDrawingDetails,
  getGameDrawTimes,
  getGameDrawings,
  getGameSchedule,
  GAME_DRAWINGS_MAX_DAYS
}
