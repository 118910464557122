import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@Client/hooks/redux-hooks'
import { ILotteryGameWindow } from './ilottery-game-window-ui'
import { iLotteryActions } from '@Client/reducers/ilottery/ilottery-reducer'
import { Optional } from '@Util/utility-types'
import { NeoGamesService } from '@Client/services/pam/ng-service'

export const ILotteryGameWindowContainer = () => {
  const activeGame = useAppSelector((state) => state.iLottery.activeGame)
  const instantGamesById = useAppSelector(
    (state) => state.iLottery.instantGamesById
  )
  const dispatch = useAppDispatch()
  const { isGameLoadRequested, isGameLoaded, gameId, playMode } = activeGame
  const closeGame = () => dispatch(iLotteryActions.closeGame())
  const depositFunds = () => NeoGamesService.showDeposit()
  let gameBackgroundImageUrl: Optional<string> = undefined

  if (gameId) {
    const game = instantGamesById[gameId]
    gameBackgroundImageUrl = game?.backgroundImageUrl
  }

  const launchInMoneyMode = () => {
    closeGame()
    dispatch(
      iLotteryActions.requestLoadInstantGame({
        gameId: gameId!,
        playMode: 'M'
      })
    )
  }

  return (
    <ILotteryGameWindow
      isGameLoaded={isGameLoaded}
      isGameLoadRequested={isGameLoadRequested}
      playMode={playMode}
      gameBackgroundImageUrl={gameBackgroundImageUrl}
      closeGame={closeGame}
      launchInMoneyMode={launchInMoneyMode}
      depositFunds={depositFunds}
    />
  )
}
