import * as React from 'react'
import { Page } from '../page/page-ui'
import { PastResultsContainer } from '../past-results/past-results-container'
import './styles.scss'

export const PastResultsPage = () => {
  return (
    <Page title='Past Winning Numbers' className='past-results-page'>
      <PastResultsContainer />
    </Page>
  )
}
