import { ConstantContentName } from '@Cms/model-types/constant-content-model'
import { Api } from '.'
import {
  AllMarketingModalsResponse,
  ConstantContentResponse
} from '@Server/controllers/content/content-controller'
import { NavigationCategoriesResponse } from '@Server/controllers/navigation/navigation-controller'

const fetchAllModals = async (): Promise<AllMarketingModalsResponse> => {
  try {
    return await Api.callServer('/api/v1/content/modal/all')
  } catch (error) {
    console.error('Error fetching all marketing modals', error)
    throw error
  }
}

const fetchConstantContent = async (
  name: ConstantContentName
): Promise<ConstantContentResponse> => {
  try {
    return await Api.callServer('/api/v1/content/constant', {
      name
    })
  } catch (error) {
    console.error('Error fetching constant content', error)
    throw error
  }
}

const fetchNavigationCategories =
  async (): Promise<NavigationCategoriesResponse> => {
    try {
      return await Api.callServer('/api/v1/navigation/categories')
    } catch (error) {
      console.error('Error fetching navigation categories', error)
      throw error
    }
  }

export const ContentApi = {
  fetchAllModals,
  fetchConstantContent,
  fetchNavigationCategories
}
