import createSagaMiddleware from 'redux-saga'
import { spawn } from 'redux-saga/effects'
import { Dispatch } from '@reduxjs/toolkit'
import { setupCmsGamesSaga } from './cms-game-saga'
import { setupAppSaga } from './app-setup-saga'
import { setupJackpotsSaga } from './jackpots-saga'
import { setupDrawTimesSaga } from './draw-times-saga'
import { setupAlertsSaga } from './alerts-saga'
import { setupInstantGameSaga } from './instant-game-saga'
import { setupDrawResultsSaga } from './draw-results-saga'
import { setupILotterySaga } from './ilottery-saga'
import { setupIwgJackpotSaga } from './iwg-jackpot-saga'
import { setupUiSaga } from './ui-saga'

export const sagaMiddleware = createSagaMiddleware()

export function* spawnAllSagas(dispatch: Dispatch) {
  yield spawn(setupAppSaga)
  yield spawn(setupAlertsSaga)
  yield spawn(setupDrawResultsSaga)
  yield spawn(setupDrawTimesSaga)
  yield spawn(setupInstantGameSaga)
  yield spawn(setupJackpotsSaga)
  yield spawn(setupCmsGamesSaga)
  yield spawn(setupILotterySaga, dispatch)
  yield spawn(setupIwgJackpotSaga, dispatch)
  yield spawn(setupUiSaga)
}
