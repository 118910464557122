import * as React from 'react'

export const ImagePlaceholder = () => (
  <svg
    aria-hidden='true'
    xmlns='http://www.w3.org/2000/svg'
    width='34'
    height='25'
    viewBox='0 0 34 25'
    fill='none'
  >
    <g opacity='0.2'>
      <path
        d='M3.50803 0.245117C1.66457 0.245117 0.13501 1.63442 0.13501 3.30884V21.6912C0.13501 23.3656 1.66457 24.7549 3.50803 24.7549H30.4922C32.3356 24.7549 33.8652 23.3656 33.8652 21.6912V3.30884C33.8652 1.63442 32.3356 0.245117 30.4922 0.245117H3.50803ZM3.50803 3.30884H30.4922V21.6912H3.50803V3.30884ZM13.6271 6.37257C13.1798 6.37257 12.7508 6.53396 12.4345 6.82124C12.1182 7.10852 11.9406 7.49816 11.9406 7.90443C11.9406 8.31071 12.1182 8.70034 12.4345 8.98762C12.7508 9.2749 13.1798 9.43629 13.6271 9.43629C14.0744 9.43629 14.5033 9.2749 14.8196 8.98762C15.1359 8.70034 15.3136 8.31071 15.3136 7.90443C15.3136 7.49816 15.1359 7.10852 14.8196 6.82124C14.5033 6.53396 14.0744 6.37257 13.6271 6.37257ZM21.2164 10.9682L15.3136 17.0956L11.0973 13.266L6.50553 18.6275H27.5408L21.2164 10.9682Z'
        fill='#2B3037'
      />
    </g>
  </svg>
)
