import * as React from 'react'
import {
  ResponsiveImageField,
  ResponsiveImageModel
} from '@Cms/model-types/responsive-image-model'
import { AsyncImage } from '../async-image/async-image-ui'
import { GeneralLink } from '../link/link-ui'
import { LinkField } from '@Cms/model-types/link-model'
import { useIsDesktop } from '@Client/hooks/use-media-query/use-media-query'
import { getBlockClass, joinClasses } from '../component-helpers'

export type ResponsiveImageProps = {
  image: ResponsiveImageModel
  className?: string
}

const ROOT_CLASS = 'responsive-image'

export const ResponsiveImage = (props: ResponsiveImageProps) => {
  const isDesktop = useIsDesktop()

  const image = isDesktop
    ? props.image[ResponsiveImageField.desktopImage]
    : props.image[ResponsiveImageField.mobileImage]
  const imageElement = (
    <AsyncImage
      src={image.imageUrl}
      alt={props.image[ResponsiveImageField.description]}
      className={joinClasses([
        getBlockClass(ROOT_CLASS, 'image'),
        props.className
      ])}
    />
  )
  return props.image.link ? (
    <GeneralLink
      dest={props.image.link[LinkField.route]}
      className={getBlockClass(ROOT_CLASS, 'link')}
    >
      {imageElement}
    </GeneralLink>
  ) : (
    imageElement
  )
}
