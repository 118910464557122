import * as React from 'react'

export type ChevronLeftProps = {
  useLightColor?: boolean
}

export const ChevronLeftSmall = (props: ChevronLeftProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
  >
    <title>Left Chevron</title>
    <path
      d='M8.4037 9.99247C8.4012 9.88696 8.35712 9.78671 8.28104 9.71356L4.56385 5.99637L8.28104 2.27918C8.31936 2.24191 8.34982 2.19733 8.37062 2.14808C8.39141 2.09884 8.40213 2.04592 8.40213 1.99246C8.40211 1.91289 8.37836 1.83513 8.33392 1.76913C8.28947 1.70312 8.22635 1.65187 8.15263 1.62193C8.0789 1.59199 7.99792 1.58471 7.92004 1.60104C7.84216 1.61736 7.77091 1.65653 7.71542 1.71356L3.71542 5.71356C3.64043 5.78858 3.5983 5.8903 3.5983 5.99637C3.5983 6.10244 3.64043 6.20417 3.71542 6.27918L7.71542 10.2792C7.77128 10.3373 7.84338 10.3771 7.92228 10.3936C8.00118 10.41 8.08321 10.4022 8.1576 10.3712C8.232 10.3402 8.2953 10.2875 8.33921 10.2199C8.38312 10.1523 8.4056 10.073 8.4037 9.99247Z'
      fill={props.useLightColor ? '#dddfe1' : '#2B3037'}
    />
  </svg>
)

export const ChevronLeft = (props: ChevronLeftProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
  >
    <title>Left Chevron</title>
    <path
      d='M14.0057 16.6539C14.0015 16.4781 13.928 16.311 13.8012 16.1891L7.60593 9.99379L13.8012 3.79848C13.8651 3.73635 13.9159 3.66205 13.9505 3.57998C13.9852 3.4979 14.0031 3.40971 14.0031 3.32061C14.003 3.18799 13.9634 3.05839 13.8894 2.94838C13.8153 2.83838 13.7101 2.75296 13.5872 2.70306C13.4643 2.65315 13.3294 2.64103 13.1996 2.66823C13.0698 2.69543 12.951 2.76073 12.8585 2.85577L6.19187 9.52243C6.06689 9.64746 5.99669 9.81701 5.99669 9.99379C5.99669 10.1706 6.06689 10.3401 6.19187 10.4651L12.8585 17.1318C12.9516 17.2286 13.0718 17.2951 13.2033 17.3224C13.3348 17.3498 13.4715 17.3368 13.5955 17.2851C13.7195 17.2335 13.825 17.1456 13.8982 17.033C13.9714 16.9203 14.0088 16.7882 14.0057 16.6539Z'
      fill={props.useLightColor ? '#dddfe1' : '#2B3037'}
    />
  </svg>
)
