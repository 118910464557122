import * as React from 'react'
import { AccordionModel } from '@Cms/model-types/accordion-model'
import { Collapsible } from '../collapsible/collapsible-ui'
import { getBlockClass, joinClasses } from '../component-helpers'
import { CmsGenericContent } from '../cms-generic-content/cms-generic-content-ui'
import './styles.scss'

export type CmsAccordionProps = {
  className: string
  accordion: AccordionModel
}

const ROOT_CLASS = 'cms-accordion'
const ACCORDION_HEADER_BLOCK = 'accordion-header'
const ACCORDION_CONTENT_BLOCK = 'accordion-content'

export const CmsAccordion = (props: CmsAccordionProps) => {
  return (
    <Collapsible
      className={ROOT_CLASS}
      header={
        <span
          className={joinClasses([
            getBlockClass(ROOT_CLASS, ACCORDION_HEADER_BLOCK),
            getBlockClass(props.className, ACCORDION_HEADER_BLOCK)
          ])}
        >
          {props.accordion.title}
        </span>
      }
      content={
        <div
          className={joinClasses([
            getBlockClass(ROOT_CLASS, ACCORDION_CONTENT_BLOCK),
            getBlockClass(props.className, ACCORDION_CONTENT_BLOCK)
          ])}
        >
          {props.accordion.content.map((content) => (
            <CmsGenericContent
              key={`accordion-${props.accordion.title}-${content.contentType}-${JSON.stringify(content.data)}`}
              content={content}
              rootClassName={props.className}
            />
          ))}
        </div>
      }
    />
  )
}
