import * as React from 'react'
import { GameModel, GameType } from '@Cms/model-types/game-model'
import { GameTile } from './game-tile-ui'
import { useAppDispatch, useAppSelector } from '@Client/hooks/redux-hooks'
import {
  NeoGamesGamePlayModeShort,
  NeoGamesInstantGame
} from '@Client/services/pam/ng-types'
import { iLotteryActions } from '@Client/reducers/ilottery/ilottery-reducer'
import { CurrencyUtilities } from '@Util/currency-utilities'
import { Optional } from '@Util/utility-types'
import { CurrentJackpotState } from '@Client/reducers/jackpots/jackpot-reducer-types'
import { selectNextDrawTime } from '@Client/reducers/draw-results/draw-results-selectors'
import { selectLargestIwgJackpot } from '@Client/reducers/iwg-jackpots/iwg-jackpots-selectors'
import { selectJackpotForFastPlayGame } from '@Client/reducers/jackpots/jackpot-selectors'

type GameTileContainerProps = {
  game: GameModel
  isILotteryGame: boolean
}

export const GameTileContainer = (props: GameTileContainerProps) => {
  const dispatch = useAppDispatch()
  const {
    isPlayerAuthenticated,
    isFrameworkReady,
    progressiveJackpotsById,
    instantGamesById
  } = useAppSelector((state) => state.iLottery)

  const fastPlayJackpotDisplay = useAppSelector((state) =>
    props.game.type === GameType.fastPlay
      ? selectJackpotForFastPlayGame(state, props.game)
      : undefined
  )

  const currentJackpot: Optional<CurrentJackpotState> = useAppSelector(
    (state) =>
      state.jackpots.jackpotsByGameId[
        props.game.configuration.dataServices.gameDataServiceId
      ]
  )
  const nextDrawingStr = useAppSelector((state) =>
    selectNextDrawTime(
      state,
      props.game.configuration.dataServices.gameDataServiceId
    )
  )
  const defaultTopPrizeText = props.game.topPrizeDisplay
    ? `Win up to ${props.game.topPrizeDisplay}`
    : undefined
  const iwgIdentifier = props.game.configuration.iLottery?.iwgIdentifier
  const isIwgGame = iwgIdentifier !== undefined
  const iwgJackpot = useAppSelector((state) =>
    !isIwgGame ? undefined : selectLargestIwgJackpot(state, iwgIdentifier)
  )
  const nextDrawingDate = nextDrawingStr ? new Date(nextDrawingStr) : undefined

  const iLotteryId = props.game.configuration.iLottery?.iLotteryId
  const numericILotteryId = iLotteryId ? Number(iLotteryId) : undefined

  const getTopPrize = () => {
    if (props.game.type === GameType.draw) {
      if (currentJackpot?.type === 'draw') {
        const jackpotInCents =
          currentJackpot.progressiveJackpot.jackpot?.jackpotAmountInCents
        return jackpotInCents
          ? `Jackpot: ${CurrencyUtilities.numericFormat(CurrencyUtilities.getDollarsFromCents(jackpotInCents))}*`
          : 'Jackpot: Pending'
      } else {
        return defaultTopPrizeText
      }
    } else if (props.game.type === GameType.scratch) {
      return defaultTopPrizeText
    } else if (props.game.type === GameType.fastPlay) {
      return fastPlayJackpotDisplay !== props.game.topPrizeDisplay
        ? `Jackpot: ${fastPlayJackpotDisplay}*`
        : fastPlayJackpotDisplay
    }
    if (props.game.type !== GameType.online || !numericILotteryId) {
      return
    }
    if (isIwgGame) {
      return iwgJackpot
        ? `Jackpot: ${CurrencyUtilities.numericFormat(iwgJackpot)}*`
        : 'Jackpot: Pending'
    }
    // should not reach here in lobbies, due to filtering at lobby level
    const iLotteryGame: Optional<NeoGamesInstantGame> =
      instantGamesById[numericILotteryId]
    if (!iLotteryGame) {
      return defaultTopPrizeText
    }
    const gameProgressiveJackpotsIds = iLotteryGame.PJPs
    if (
      !gameProgressiveJackpotsIds ||
      gameProgressiveJackpotsIds.length === 0
    ) {
      return iLotteryGame.maxPrize
        ? `Win up to ${CurrencyUtilities.numericFormat(iLotteryGame.maxPrize)}`
        : defaultTopPrizeText
    }
    const progressiveId = gameProgressiveJackpotsIds[0]
    if (!progressiveId || !progressiveJackpotsById[progressiveId]) {
      return
    }
    const progressiveValueInDollars =
      progressiveJackpotsById[progressiveId]?.PJPBalanceCurrent
    return progressiveValueInDollars
      ? `Jackpot: $${progressiveValueInDollars}*`
      : undefined
  }

  const loadGame = (playModeOverride?: NeoGamesGamePlayModeShort) => {
    if (!numericILotteryId) {
      return
    }
    if (props.game.type === GameType.online) {
      const playMode: NeoGamesGamePlayModeShort = playModeOverride
        ? playModeOverride
        : isPlayerAuthenticated
          ? 'M'
          : 'D'
      dispatch(
        iLotteryActions.requestLoadInstantGame({
          gameId: Number(iLotteryId),
          playMode
        })
      )
    } else if (props.game.type === GameType.draw) {
      dispatch(iLotteryActions.openDrawGameWidget(numericILotteryId))
    }
  }
  return (
    <GameTile
      game={props.game}
      loadGame={loadGame}
      isILotteryFrameworkReady={isFrameworkReady}
      tagLine={getTopPrize()}
      nextDrawingDate={nextDrawingDate}
      isILotteryGame={props.isILotteryGame}
    />
  )
}
