import * as React from 'react'
import { useLocation } from 'react-router-dom'
import {
  NavCategoryModel,
  NavCategoryName
} from '@Cms/model-types/nav-category-model'
import { HamburgerMenu } from '@Client/icons/hamburger-menu'
import { Button } from '@Components/button/button'
import { AsyncImage } from '@Components/async-image/async-image-ui'
import { getBlockClass, joinClasses } from '@Components/component-helpers'
import { MobileNavigation } from '@Components/navigation/mobile-navigation-ui'
import { GeneralLink } from '@Components/link/link-ui'
import { DesktopNavigation } from '@Components/navigation/desktop-navigation-ui'
import { HorizontalNavigationCategory } from '../navigation-category-horizontal/navigation-category-horizontal-ui'
import { RoutePath } from '@Client/route-paths'
import { CartIcon } from '@Client/icons/cart-icon'
import { useIsDesktop } from '@Client/hooks/use-media-query/use-media-query'
import { AlertBannerModel } from '@Cms/model-types/alert-banner-model'
import { AlertBannerContainer } from '../alert-banner/alert-banner-container'
import { DotNotification } from '../dot-notification/dot-notification'
import { AuthButtons } from '../auth-buttons/auth-buttons-ui'
import { AccountMenuContainer } from '../account-menu/account-menu-container'
import logoSrc from '@Client/assets/nhlottery-logo.png'
import { useAppSelector } from '@Client/hooks/redux-hooks'
import { NeoGamesService } from '@Client/services/pam/ng-service'
import './styles.scss'

export type MenuBarProps = {
  navigationCategories: NavCategoryModel[]
  alertBanner?: AlertBannerModel
  isAuthenticated: boolean
  isILotteryFrameworkReady: boolean
  isILotteryGameActive: boolean
}

const ROOT_CLASS = 'menu-bar'

export const MenuBar = (props: MenuBarProps) => {
  const isDesktop = useIsDesktop()
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false)
  const isHomePage = useLocation().pathname === RoutePath.home
  const shoppingCartCount = useAppSelector(
    (state) => state.iLottery.shoppingCartCount
  )

  if (isDesktop && isMobileNavOpen) {
    setIsMobileNavOpen(false)
  }

  const getNavItemsByName = (
    categoryName: NavCategoryName,
    navigationCategories: NavCategoryModel[]
  ) => {
    const category = navigationCategories.find(
      (item) => item.name === categoryName
    )
    return category?.navItems ?? []
  }

  return props.isILotteryGameActive ? null : (
    <>
      <header className={joinClasses([ROOT_CLASS])}>
        <div className={getBlockClass(ROOT_CLASS, 'content')}>
          <div className={getBlockClass(ROOT_CLASS, 'general')}>
            <Button
              onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
              ariaLabel='Toggle Navigation Menu Open State'
              className={getBlockClass(ROOT_CLASS, 'mobile-nav-button')}
            >
              <HamburgerMenu />
            </Button>
            {props.navigationCategories && (
              <MobileNavigation
                navCategories={props.navigationCategories}
                isOpen={isMobileNavOpen}
                setIsOpen={setIsMobileNavOpen}
              />
            )}

            <GeneralLink
              className={getBlockClass(ROOT_CLASS, 'logo')}
              dest={RoutePath.home}
            >
              <AsyncImage src={logoSrc} alt={'New Hampshire Lottery Logo'} />
            </GeneralLink>
            {props.navigationCategories && (
              <DesktopNavigation navCategories={props.navigationCategories} />
            )}
          </div>
          <div className={getBlockClass(ROOT_CLASS, 'player')}>
            {isDesktop ? (
              props.isAuthenticated ? (
                <AccountMenuContainer />
              ) : (
                <AuthButtons />
              )
            ) : (
              <AccountMenuContainer />
            )}
            <Button
              className={getBlockClass(ROOT_CLASS, 'cart-container')}
              ariaLabel='Go to shopping cart'
              onClick={() => {
                NeoGamesService.showShoppingCart()
              }}
              disabled={!props.isILotteryFrameworkReady}
            >
              <div className={getBlockClass(ROOT_CLASS, 'cart-notification')}>
                <DotNotification
                  isActive={shoppingCartCount !== undefined}
                  count={shoppingCartCount}
                />
              </div>
              <CartIcon />
            </Button>
          </div>
        </div>
      </header>
      {props.alertBanner && (
        <AlertBannerContainer alertBanner={props.alertBanner} />
      )}
      {isHomePage && (
        <HorizontalNavigationCategory
          name={NavCategoryName.homeSpotlight}
          navItems={getNavItemsByName(
            NavCategoryName.homeSpotlight,
            props.navigationCategories
          )}
        />
      )}
    </>
  )
}
