import * as React from 'react'
import { Winner, WinnerCell } from './winners-cell'
import { WinnersTableHeader, WinnersSortBy } from './winners-table-header'
import { Button } from '../button/button'
import { Pagination } from '../pagination/pagination-ui'
import { ErrorMessage } from '../error-message/error-message-ui'
import { jsonToCsv, downloadCSV } from '@Util/object-utilities'
import { DateFormat, dateUtil } from '@Util/date-util'
import { CurrencyUtilities } from '@Util/currency-utilities'
import { getBlockClass } from '../component-helpers'
import './styles.scss'

export type WinnersTableProps = {
  isDesktop: boolean
  winners?: Winner[]
  page: number
  totalPages: number
  onPageChange: (page: number) => void
  toggleSortDirection: (sortBy: WinnersSortBy) => void
}

export const ROOT_CLASS = 'winners-table'

export const WinnersTable = (props: WinnersTableProps) => {
  const downloadTable = () => {
    if (!props.winners) return
    const csvData = jsonToCsv(
      props.winners.map((winner) => ({
        Date: dateUtil(winner.date).format(DateFormat.monthDayYear),
        Game: winner.gameName,
        Name: winner.name,
        City: winner.city,
        Prize: CurrencyUtilities.getDollarsFromCents(winner.prize)
      }))
    )
    downloadCSV(csvData, 'winners')
  }

  if (props.winners === undefined) return null
  return props.winners.length > 0 ? (
    <>
      {props.isDesktop && (
        <Button
          className={getBlockClass(ROOT_CLASS, 'download-button')}
          ariaLabel='Download Results as a CSV Button'
          onClick={downloadTable}
          disabled={props.winners.length === 0}
        >
          <span>Download Results as a CSV</span>
        </Button>
      )}
      <table className={ROOT_CLASS}>
        <thead className={getBlockClass(ROOT_CLASS, 'header')}>
          <tr className={getBlockClass(ROOT_CLASS, 'header-row')}>
            <WinnersTableHeader
              isDesktop={props.isDesktop}
              toggleSortDirection={props.toggleSortDirection}
            />
          </tr>
        </thead>
        <tbody>
          {props.winners.map((winner, i) => (
            <WinnerCell
              key={`${winner.date.toLocaleString()}-${winner.gameName}-${winner.name}-${winner.city}-${winner.prize}-${i}`}
              isDesktop={props.isDesktop}
              {...winner}
            />
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={props.page}
        totalPages={props.totalPages}
        onPageChange={props.onPageChange}
      />
    </>
  ) : (
    <ErrorMessage
      className={getBlockClass(ROOT_CLASS, 'no-results')}
      message='No results. Please try a different query'
    />
  )
}
