import * as React from 'react'
import { useAppSelector } from '@Client/hooks/redux-hooks'
import { useLocation } from 'react-router-dom'
import { MarketingModal } from './marketing-modal-ui'
import { useLocalStorage } from '@Client/hooks/use-local-storage/use-local-storage'

const MODAL_LOCAL_STORAGE_KEY = 'marketingModals'
export type ModalStateByUuid = Record<string, boolean>

export const MarketingModalContainer = () => {
  const { write, value: localStorageState } = useLocalStorage<ModalStateByUuid>(
    {
      key: MODAL_LOCAL_STORAGE_KEY
    }
  )
  const modalsByPath = useAppSelector((state) => state.alerts.modals.byRoute)
  const location = useLocation()
  const relativeUrl = `${location.pathname}${location.search}`
  const pathModal = modalsByPath[relativeUrl]
  const globalModal = modalsByPath['*']

  const modalHasBeenDismissed = (modalUuid: string) =>
    localStorageState?.[modalUuid]

  const getModal = () => {
    if (globalModal && !modalHasBeenDismissed(globalModal.uuid)) {
      return globalModal
    } else if (pathModal && !modalHasBeenDismissed(pathModal.uuid)) {
      return pathModal
    }
  }

  const getCloseModalState = (modalUuid: string) => ({
    ...localStorageState,
    [modalUuid]: true
  })

  const modal = getModal()

  return modal ? (
    <MarketingModal
      onClose={() => write(getCloseModalState(modal.uuid))}
      modal={modal}
      isOpen
    />
  ) : null
}
