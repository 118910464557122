import { openInNewTab } from '@Client/components/game-lobby/helpers/url-helpers'
import { store } from '@Client/reducers/store'
import { DynamicUrl } from '@Client/route-paths'
import {
  OpenPageIdentifier,
  RoutePathByExternalOpenPageId,
  InternalHandlerByOpenPageId
} from '@Client/services/pam/open-page/ng-open-page-map'

export const isNgOpenPageRoute = (
  varToCheck: any
): varToCheck is OpenPageIdentifier =>
  Object.values(OpenPageIdentifier).includes(varToCheck)

const isInternalRoute = (key: OpenPageIdentifier) => {
  return Object.keys(InternalHandlerByOpenPageId).includes(key)
}

const isExternalRoute = (key: OpenPageIdentifier) => {
  return Object.keys(RoutePathByExternalOpenPageId).includes(key)
}

const openInternalRoute = (key: OpenPageIdentifier) => {
  try {
    const eventHandler = InternalHandlerByOpenPageId[key]
    if (!eventHandler) {
      throw new Error(`Invalid internal route key: ${key}`)
    }
    eventHandler.newTab
      ? openInNewTab(eventHandler.path)
      : window.navigateReact?.(eventHandler.path)
  } catch (error) {
    console.error('Error opening internal route:', error)
  }
}

const openExternalRoute = (key: OpenPageIdentifier) => {
  try {
    const route = RoutePathByExternalOpenPageId[key]
    if (!route) {
      throw new Error(`Invalid internal route key: ${key}`)
    }
    openInNewTab(route)
  } catch (error) {
    console.error('Error opening internal route:', error)
  }
}

const openGameRulesRoute = (iLotteryId: string | null, newTab: boolean) => {
  try {
    if (!iLotteryId) {
      throw new Error(`No iLotteryId provided for opening game rules`)
    }
    const identifier =
      store.getState().cmsGames.gameIdentifiersByiLotteryId[iLotteryId]
    const route = DynamicUrl.getGameByIdentifierUrl(identifier)
    if (newTab) {
      openInNewTab(route)
    } else {
      window.navigateReact?.(route)
      window.ng_fw?.closeAllWidgets()
    }
  } catch (error) {
    console.error(
      `Error opening game rules for iLotteryId ${iLotteryId}`,
      error
    )
  }
}

export const OpenPageRoute = {
  isNgOpenPageRoute,
  isInternalRoute,
  isExternalRoute,
  openInternalRoute,
  openExternalRoute,
  openGameRulesRoute
}
