import { RouteObject } from 'react-router-dom'
import { AppContainer, appLoader } from './components/app/app-container'
import { RoutePath } from './route-paths'
import { PageNotFound } from './components/page-not-found/page-not-found-ui'
import { store } from './reducers/store'

export const routes: RouteObject[] = [
  {
    loader: appLoader,
    Component: AppContainer,
    ErrorBoundary: PageNotFound,
    shouldRevalidate: () => {
      // by default, any URL changes would revalidate the app
      // we don't want to perform these fetches on every URL change by default
      // but we must stay updated if CMS preview is active, which is never allowed in prod
      const state = store.getState()
      return state.operations.cmsPreviewActive
    },
    children: [
      {
        path: RoutePath.home,
        lazy: async () => {
          const { HomePageContainer, homePageLoader } = await import(
            './components/home-page/home-page-container'
          )
          return {
            loader: homePageLoader,
            Component: HomePageContainer,
            ErrorBoundary: PageNotFound
          }
        }
      },
      {
        path: RoutePath.gameCollectionByIdentifier,
        lazy: async () => {
          const { gameCollectionLoader, GameCollectionContainer } =
            await import(
              './components/game-collection/game-collection-container'
            )
          return {
            loader: gameCollectionLoader,
            Component: GameCollectionContainer,
            ErrorBoundary: PageNotFound
          }
        }
      },
      {
        path: RoutePath.gameByIdentifier,
        lazy: async () => {
          const { GameDetailsPageContainer } = await import(
            './components/game-details-page/game-details-page-container'
          )
          return {
            Component: GameDetailsPageContainer,
            ErrorBoundary: PageNotFound
          }
        }
      },
      {
        path: RoutePath.winningNumbers,
        lazy: async () => {
          const { WinningNumbersPageLoader, WinningNumbersPageContainer } =
            await import(
              './components/winning-numbers-page/winning-numbers-page-container'
            )
          return {
            loader: WinningNumbersPageLoader,
            Component: WinningNumbersPageContainer,
            ErrorBoundary: PageNotFound
          }
        }
      },
      {
        path: RoutePath.pastResults,
        lazy: async () => {
          const { PastResultsPage } = await import(
            './components/past-results-page/past-results-page'
          )
          return {
            Component: PastResultsPage,
            ErrorBoundary: PageNotFound
          }
        }
      },
      {
        path: RoutePath.prizesRemaining,
        lazy: async () => {
          const { PrizesRemainingPage } = await import(
            './components/prizes-remaining-page/prizes-remaining-page'
          )
          return {
            Component: PrizesRemainingPage,
            ErrorBoundary: PageNotFound
          }
        }
      },
      {
        path: RoutePath.winners,
        lazy: async () => {
          const { WinnersPage } = await import(
            './components/winners-page/winners-page'
          )
          return {
            Component: WinnersPage,
            ErrorBoundary: PageNotFound
          }
        }
      },
      {
        path: RoutePath.findARetailer,
        lazy: async () => {
          const { RetailFinderPage } = await import(
            './components/retail-finder-page/retail-finder-page-ui'
          )
          return {
            Component: RetailFinderPage,
            ErrorBoundary: PageNotFound
          }
        }
      },
      {
        path: RoutePath.genericPage,
        lazy: async () => {
          const { cmsPageLoader, CmsPageContainer } = await import(
            './components/page-cms/cms-page-container'
          )
          return {
            loader: cmsPageLoader,
            Component: CmsPageContainer,
            ErrorBoundary: PageNotFound
          }
        }
      }
    ]
  }
]
