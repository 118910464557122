import { InstantGame } from '@interaction-gaming/game-data-service-browser-client'
import { InstantGameApi } from '@Client/api/instant-games'
import { instantGameActions } from '@Client/reducers/instant-games/instant-games-reducer'
import { put, takeEvery } from 'redux-saga/effects'
import { InstantGamesReducerHelpers } from '@Client/reducers/instant-games/helpers'

export function* setupInstantGameSaga() {
  yield takeEvery(
    instantGameActions.fetchInstantGamesRequest.type,
    fetchInstantGames
  )
}

function* fetchInstantGames() {
  try {
    const response: InstantGame[] = yield InstantGameApi.getAll()
    const serializedByDataServiceId =
      InstantGamesReducerHelpers.getInstantGamesById(response)
    yield put(
      instantGameActions.fetchInstantGamesSuccess(serializedByDataServiceId)
    )
  } catch (error) {
    yield put(
      instantGameActions.fetchInstantGamesFailure({
        error: 'Something went wrong'
      })
    )
  }
}
