import { EntryFieldTypes } from 'contentful'
import { CmsModel } from './cms-models'
import { FilterTagModel, FilterTagSkeleton } from './filter-tag-model'
import { SortOptionModel, SortOptionSkeleton } from './sort-option-model'
import {
  FilterCategoryModel,
  FilterCategorySkeleton
} from './filter-category-model'
import { GameModel, GamePrice } from './game-model'

export enum GameLobbyField {
  name = 'name',
  identifier = 'identifier',
  title = 'title',
  spotlightFilters = 'spotlightFilters',
  defaultSortOrder = 'defaultSortOrder',
  lobbyFilters = 'lobbyFilters',
  supportedFilterCategories = 'supportedFilterCategories',
  supportedGameSorts = 'supportedGameSorts'
}

export type GameLobbySkeleton = {
  contentTypeId: CmsModel.gameLobby
  fields: {
    [GameLobbyField.name]: EntryFieldTypes.Symbol
    [GameLobbyField.identifier]: EntryFieldTypes.Symbol
    [GameLobbyField.title]: EntryFieldTypes.Symbol
    [GameLobbyField.spotlightFilters]?: EntryFieldTypes.Array<
      EntryFieldTypes.EntryLink<FilterTagSkeleton>
    >
    [GameLobbyField.defaultSortOrder]: EntryFieldTypes.EntryLink<SortOptionSkeleton>
    [GameLobbyField.lobbyFilters]?: EntryFieldTypes.Array<
      EntryFieldTypes.EntryLink<FilterTagSkeleton>
    >
    [GameLobbyField.supportedFilterCategories]: EntryFieldTypes.Array<
      EntryFieldTypes.EntryLink<FilterCategorySkeleton>
    >
    [GameLobbyField.supportedGameSorts]?: EntryFieldTypes.Array<
      EntryFieldTypes.EntryLink<SortOptionSkeleton>
    >
  }
}

export type GameLobbyModel = {
  identifier: string
  title: string
  spotlightFilters: FilterTagModel[]
  defaultSortOrder: SortOptionModel
  supportedFilterCategories: FilterCategoryModel[]
  supportedGameSorts: SortOptionModel[]
  games: GameModel[]
  priceOptions: GamePrice[]
}

export const GameLobbyFields = Object.values(GameLobbyField)
