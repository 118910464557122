import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SetModalOpenPayload, UiState } from './ui-reducer-types'

export const defaultUiState: UiState = {
  isModalOpen: false,
  isFullScreenModal: false,
  isMobileViewport: true,
  isiLotteryWidgetOpen: false
}

const uiSlice = createSlice({
  name: 'ui',
  initialState: defaultUiState,
  reducers: {
    setILotteryWidgetOpen: (state, action: PayloadAction<boolean>) => {
      state.isiLotteryWidgetOpen = action.payload
    },
    setMobileViewport: (state, action: PayloadAction<boolean>) => {
      state.isMobileViewport = action.payload
    },
    setModalOpen: (state, action: PayloadAction<SetModalOpenPayload>) => {
      state.isModalOpen = action.payload.isModalOpen
      state.isFullScreenModal = action.payload.isFullScreenModal
    },
    closeModal: (state) => {
      state.isModalOpen = false
      state.isFullScreenModal = false
    }
  }
})

export const uiActions = uiSlice.actions
export const uiReducer = uiSlice.reducer
