import * as React from 'react'
import { InputSelect } from '../input-select/input-select-ui'
import { InputDate } from '../input-date/input-date-ui'
import { InputNumber } from '../input-number/input-number'
import {
  DRAWINGS_OPTIONS,
  PastResultsFieldId,
  PastResultsFormProps,
  ROOT_CLASS
} from './constants'
import { Optional } from '@Util/utility-types'
import { dateUtil } from '@Util/date-util'
import { getBlockClass } from '../component-helpers'

export type PastResultsGameFormProps = PastResultsFormProps & {
  onFieldChange: (
    fieldId: PastResultsFieldId,
    fieldValue?: string | number
  ) => void
  onDateChange?: (date?: Date) => void
  drawDate?: Date
  errors: Record<string, Optional<string>>
}

export const PastResultsDrawGameForm = (props: PastResultsGameFormProps) => {
  return (
    <InputSelect
      label='Drawings'
      options={DRAWINGS_OPTIONS}
      onChange={(value) =>
        props.onFieldChange(PastResultsFieldId.TIME_SPAN, value)
      }
      id={PastResultsFieldId.TIME_SPAN}
      placeholder='Select drawing range'
      defaultValue={props.defaultValues?.[PastResultsFieldId.TIME_SPAN]}
      error={props.errors[PastResultsFieldId.TIME_SPAN]}
      className={getBlockClass(ROOT_CLASS, 'drawings-select')}
    />
  )
}

export const PastResultsRapidGameForm = (props: PastResultsGameFormProps) => {
  const today = dateUtil()
  return (
    <>
      <InputDate
        onChange={(value) => props.onDateChange?.(value)}
        selectedDate={props.drawDate}
        id={PastResultsFieldId.DRAW_DATE}
        labelText='Draw date'
        isDateDisabled={(date) => today.isBefore(date, 'date')}
      />
      <InputNumber
        id={PastResultsFieldId.DRAW_NUMBER_START}
        onChange={(value?: number) =>
          props.onFieldChange(PastResultsFieldId.DRAW_NUMBER_START, value)
        }
        ariaLabel={'Draw number start (optional)'}
        labelText='Draw number start (optional)'
        defaultValue={
          props.defaultValues?.[PastResultsFieldId.DRAW_NUMBER_START]
        }
        error={props.errors[PastResultsFieldId.DRAW_NUMBER_START]}
      />
      <InputNumber
        id={PastResultsFieldId.DRAW_NUMBER_END}
        onChange={(value?: number) =>
          props.onFieldChange(PastResultsFieldId.DRAW_NUMBER_END, value)
        }
        ariaLabel={'Draw number end (optional)'}
        labelText='Draw Number end (optional)'
        defaultValue={props.defaultValues?.[PastResultsFieldId.DRAW_NUMBER_END]}
        error={props.errors[PastResultsFieldId.DRAW_NUMBER_END]}
      />
    </>
  )
}
