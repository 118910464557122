import * as React from 'react'
import { getBlockClass, joinClasses } from '../component-helpers'
import { TabIndex } from '@Client/constants'
import './styles.scss'

export type PageProps = {
  id?: string
  title?: string
  className?: string
}

const ROOT_CLASS = 'page'

export const Page = (props: React.PropsWithChildren<PageProps>) => {
  return (
    <main
      className={joinClasses([ROOT_CLASS, props.className])}
      id={props.id}
      tabIndex={TabIndex.accessible}
    >
      {props.title ? (
        <h1
          className={joinClasses([
            getBlockClass(ROOT_CLASS, 'title'),
            props.className ? getBlockClass(props.className, 'title') : ''
          ])}
        >
          {props.title}
        </h1>
      ) : null}
      {props.children}
    </main>
  )
}
