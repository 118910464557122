import * as React from 'react'
import { LobbyFilterTag } from '@Components/game-lobby/helpers/filter-helpers'
import { getBlockClass } from '../component-helpers'
import { FilterButton } from '../button-filter/filter-button'
import { FilterTagField } from '@Cms/model-types/filter-tag-model'
import './styles.scss'

export type FilterCategoryProps = {
  identifier: string
  displayText: string
  filters: LobbyFilterTag[]
  setFilterActive: (
    categoryIdentifier: string,
    filterIdentifier: string,
    isActive: boolean
  ) => void
  isTransparent?: boolean
}

const ROOT_CLASS = 'filter-category'

export const FilterCategory = (props: FilterCategoryProps) => {
  return (
    <div className={ROOT_CLASS}>
      <p className={getBlockClass(ROOT_CLASS, 'header')}>{props.displayText}</p>
      <div className={getBlockClass(ROOT_CLASS, 'filters')}>
        {props.filters.map((filter) => (
          <FilterButton
            ariaLabel={`Toggle ${props.displayText} filter`}
            key={filter[FilterTagField.identifier]}
            {...filter}
            onToggle={props.setFilterActive}
            isTransparent={props.isTransparent}
          >
            {filter[FilterTagField.displayText]}
          </FilterButton>
        ))}
      </div>
    </div>
  )
}
