import * as React from 'react'
import { WinnersResponse } from '@Client/api/winners'
import { WinnersContainer } from '../winners/winners-container'
import { Page } from '../page/page-ui'
import './styles.scss'

export type WinnersPageProps = {
  winners: WinnersResponse
}

const ROOT_CLASS = 'winners-page'

export const WinnersPage = () => {
  return (
    <Page title='Game Winners' className={ROOT_CLASS}>
      <WinnersContainer />
    </Page>
  )
}
