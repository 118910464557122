import * as React from 'react'
import { Page } from '../page/page-ui'
import { RetailFinderContainer } from '../retail-finder/retail-finder-container'
import './styles.scss'

const ROOT_CLASS = 'retail-finder-page'

export const RetailFinderPage = () => {
  return (
    <Page title='Find a Retailer' className={ROOT_CLASS}>
      <RetailFinderContainer />
    </Page>
  )
}
