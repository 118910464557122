import * as React from 'react'
import { GameModel, GameType } from '@Cms/model-types/game-model'
import { Page } from '../page/page-ui'
import { DrawGameDetailsPageContainer } from '../game-details-page-draw/draw-game-details-container'
import { RapidGameDetailsPageContainer } from '../game-details-page-rapid/rapid-game-details-page-container'
import { ScratchGameDetailsPageContainer } from '../game-details-page-scratch/scratch-game-details-page-container'
import { FastPlayGameDetailsPageContainer } from '../game-details-page-fast-play/fast-play-game-details-page-container'

export type GameDetailsPageProps = {
  game: GameModel
}

const ROOT_CLASS = 'game-details-page'

export const GameDetailsPage = (props: GameDetailsPageProps) => {
  const getGamePage = () => {
    switch (props.game.type) {
      case GameType.draw:
        return <DrawGameDetailsPageContainer game={props.game} />
      case GameType.scratch:
        return <ScratchGameDetailsPageContainer game={props.game} />
      case GameType.rapid:
        return <RapidGameDetailsPageContainer game={props.game} />
      case GameType.fastPlay:
        return <FastPlayGameDetailsPageContainer game={props.game} />
      default:
        return null
    }
  }
  return <Page className={ROOT_CLASS}>{getGamePage()}</Page>
}
