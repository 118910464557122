import { combineReducers } from '@reduxjs/toolkit'
import { cmsGamesReducer } from './cms-games/cms-games-reducer'
import { jackpotReducer } from './jackpots/jackpots-reducer'
import { drawTimesReducer } from './draw-times/draw-times-reducer'
import { alertsReducer } from './alerts/alerts-reducer'
import { instantGameReducer } from './instant-games/instant-games-reducer'
import { drawResultsReducer } from './draw-results/draw-results-reducer'
import { iLotteryReducer } from './ilottery/ilottery-reducer'
import { iwgJackpotsReducer } from './iwg-jackpots/iwg-jackpots-reducer'
import { uiReducer } from './ui/ui-reducer'
import { operationsReducer } from './operations/operations-reducer'

export const RootReducer = combineReducers({
  alerts: alertsReducer,
  cmsGames: cmsGamesReducer,
  drawResults: drawResultsReducer,
  drawTimes: drawTimesReducer,
  iLottery: iLotteryReducer,
  instantGames: instantGameReducer,
  jackpots: jackpotReducer,
  iwgJackpots: iwgJackpotsReducer,
  ui: uiReducer,
  operations: operationsReducer
})

export type RootState = ReturnType<typeof RootReducer>
