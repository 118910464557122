import * as React from 'react'
import { GameModel } from '@Cms/model-types/game-model'
import { FastPlayGameDetailsPage } from './fast-play-game-details-page-ui'
import { useAppDispatch, useAppSelector } from '@Client/hooks/redux-hooks'
import {
  selectJackpotForFastPlayGame,
  selectUpdateTimeForFastPlayGame
} from '@Client/reducers/jackpots/jackpot-selectors'
import { useRecurringAction } from '@Client/hooks/use-recurring-action/use-recurring-action'
import { jackpotActions } from '@Client/reducers/jackpots/jackpots-reducer'
import { GamePrizeTableResponseData } from '@Server/controllers/game/game-controller'
import { useFetchServer } from '@Client/hooks/use-fetch/use-fetch'

const NINETY_SECONDS_MS = 90 * 1000

export type FastPlayGameDetailsPageContainerProps = {
  game: GameModel
}

export const FastPlayGameDetailsPageContainer = (
  props: FastPlayGameDetailsPageContainerProps
) => {
  const {
    isLoading: isPrizeTableLoading,
    error: prizeTableError,
    data: prizeTableData,
    fetchData: fetchPrizeTable
  } = useFetchServer<GamePrizeTableResponseData>('/api/v1/game/prize-table', {
    identifier: props.game.identifier
  })
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    fetchPrizeTable()
    return () => {}
  }, [])

  useRecurringAction(() => {
    dispatch(jackpotActions.fetchJackpotsRequest())
  }, NINETY_SECONDS_MS)

  const jackpotDisplay = useAppSelector((state) =>
    selectJackpotForFastPlayGame(state, props.game)
  )
  const updateTime = useAppSelector((state) =>
    selectUpdateTimeForFastPlayGame(state, props.game)
  )
  const isProgressive =
    jackpotDisplay &&
    props.game.topPrizeDisplay &&
    !jackpotDisplay.includes(props.game.topPrizeDisplay)

  return isProgressive && jackpotDisplay ? (
    <FastPlayGameDetailsPage
      game={props.game}
      topPrizeDisplay={props.game.topPrizeDisplay}
      topPrizeType='PROGRESSIVE'
      jackpotUpdateTimeFormatted={updateTime}
      jackpotValueFormatted={jackpotDisplay}
      isPrizeTableLoading={isPrizeTableLoading}
      prizeTableError={prizeTableError}
      prizeTableData={prizeTableData?.prizeTable}
    />
  ) : (
    <FastPlayGameDetailsPage
      game={props.game}
      topPrizeDisplay={props.game.topPrizeDisplay}
      topPrizeType='FIXED'
      isPrizeTableLoading={isPrizeTableLoading}
      prizeTableError={prizeTableError}
      prizeTableData={prizeTableData?.prizeTable}
    />
  )
}
