import { UrlUtil } from '@Util/url-utilities'
import { Nullable } from '@Util/utility-types'

export type RetailerInfoWindowProps = {
  name: string
  address1: string
  address2?: Nullable<string>
  city: string
  region?: Nullable<string>
  postalCode?: Nullable<string>
  phoneNumber?: Nullable<string>
  latitude: number
  longitude: number
}

export enum FindARetailerFilterIdentifier {
  ADA = 'ADA',
  KENO = 'KENO'
}

export const getRetailerAddress = (props: RetailerInfoWindowProps) =>
  `${props.address1}${props.address2 ? ` ${props.address2}` : ''}, ${props.city}${props.region ? `, ${props.region}` : ''} ${props.postalCode ?? ''}`

export const getMapsUrl = (address: string) => {
  const queryStr = UrlUtil.getQueryParamStr({ daddr: address })
  const googleMapsUrl = `https://maps.google.com/?${queryStr}`
  const appleMapsUrl = `https://maps.apple.com/?${queryStr}`
  const isApple = /iPhone|iPad|iPod/i.test(navigator.userAgent)
  return isApple ? appleMapsUrl : googleMapsUrl
}
