import * as React from 'react'
import { AccountMenu } from './account-menu-ui'
import { useAppDispatch, useAppSelector } from '@Client/hooks/redux-hooks'
import { NeoGamesService } from '@Client/services/pam/ng-service'
import {
  iLotteryActions,
  iLotterySelectors
} from '@Client/reducers/ilottery/ilottery-reducer'

export const AccountMenuContainer = () => {
  const isAuthenticated = useAppSelector(
    (state) => state.iLottery.isPlayerAuthenticated
  )
  const session = useAppSelector((state) => state.iLottery.session)
  const playerBalanceDisplay = useAppSelector((state) =>
    iLotterySelectors.selectPlayerBalanceDisplay(state.iLottery)
  )
  const hasUnviewedBonuses = useAppSelector(
    (state) => state.iLottery.hasUnviewedBonuses
  )
  const dispatch = useAppDispatch()
  const onMyAccountClick = () => {
    NeoGamesService.showMyAccount()
  }

  const onMyBonusesClick = () => {
    NeoGamesService.showMyBonuses()
  }

  const onDepositClick = () => {
    NeoGamesService.showDeposit()
  }

  const onWithdrawClick = () => {
    NeoGamesService.showWithdraw()
  }

  const onSignOutClick = () => {
    dispatch(iLotteryActions.logout())
  }

  const commonProps = {
    onMyAccountClick,
    onMyBonusesClick,
    onDepositClick,
    onWithdrawClick,
    onSignOutClick,
    hasUnviewedBonuses
  }

  return isAuthenticated && session ? (
    <AccountMenu
      {...commonProps}
      isAuthenticated={true}
      playerFirstName={session.PlayerName}
      accountBalance={playerBalanceDisplay}
    />
  ) : (
    <AccountMenu {...commonProps} isAuthenticated={false} />
  )
}
