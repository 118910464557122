import {
  InstantGame,
  InstantGamesApi
} from '@interaction-gaming/game-data-service-browser-client'
import { Optional } from '@Util/utility-types'
import { gameDataConfig } from './config'

let INSTANT_GAMES_API: Optional<InstantGamesApi> = undefined

const configureInstantGamesApi = (): InstantGamesApi => {
  if (!INSTANT_GAMES_API) {
    INSTANT_GAMES_API = new InstantGamesApi(gameDataConfig)
  }
  if (!INSTANT_GAMES_API) {
    throw new Error('Error configuring Instant Games API')
  }
  return INSTANT_GAMES_API
}

const getAll = async (): Promise<InstantGame[]> => {
  try {
    const api = configureInstantGamesApi()
    return await api.getAllInstantGames()
  } catch (e) {
    console.error('Error fetching instant games', e)
    throw e
  }
}

export const InstantGameApi = {
  getAll
}
