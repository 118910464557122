import * as React from 'react'
import {
  TextBlockContentType,
  TextBlockNode,
  TextDocumentNode,
  TextInlineContent,
  TextInlineContentType,
  TextModel
} from '@Cms/model-types/text-model'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { compact } from '@Util/array-utilities'
import { GeneralLink } from '../link/link-ui'
import { ResponsiveImage } from '../cms-responsive-image/responsive-image-ui'
import { getCustomCssProperties } from '../component-helpers'
import './styles.scss'

export type CmsTextProps = {
  textModel: TextModel
}

type TextContentProps = {
  node: TextDocumentNode
  color?: string
}

const ROOT_CLASS = 'cms-text'
const BLOCK_CLASS = getBlockClass(ROOT_CLASS, 'block')
const INLINE_CONTENT_CLASS = getBlockClass(ROOT_CLASS, 'inline-content')

const TextContent = (props: TextContentProps) => {
  const mapChildNodes = (
    inlineContent: TextInlineContent | TextBlockNode,
    i: number
  ) => <TextContent node={inlineContent} key={`${inlineContent.type}-${i}`} />
  const customStyles = props.color
    ? getCustomCssProperties({
        '--text-color': props.color
      })
    : undefined

  switch (props.node.type) {
    case TextBlockContentType.paragraph:
      return (
        <div
          style={customStyles}
          className={joinClasses([
            BLOCK_CLASS,
            getBlockClass(ROOT_CLASS, 'paragraph')
          ])}
        >
          {props.node.content.map(mapChildNodes)}
        </div>
      )
    case TextBlockContentType.orderedList:
      return (
        <ol
          style={customStyles}
          className={joinClasses([
            BLOCK_CLASS,
            getBlockClass(ROOT_CLASS, 'ordered-list')
          ])}
        >
          {props.node.content.map(mapChildNodes)}
        </ol>
      )
    case TextBlockContentType.unorderedList:
      return (
        <ul
          style={customStyles}
          className={joinClasses([
            BLOCK_CLASS,
            getBlockClass(ROOT_CLASS, 'unordered-list')
          ])}
        >
          {props.node.content.map(mapChildNodes)}
        </ul>
      )
    case TextBlockContentType.listItem:
      return (
        <li
          style={customStyles}
          className={joinClasses([
            BLOCK_CLASS,
            getBlockClass(ROOT_CLASS, 'list-item')
          ])}
        >
          {props.node.content.map(mapChildNodes)}
        </li>
      )
    case TextBlockContentType.headingOne:
      return (
        <h1
          style={customStyles}
          className={joinClasses([
            BLOCK_CLASS,
            getBlockClass(ROOT_CLASS, 'heading')
          ])}
        >
          {props.node.content.map(mapChildNodes)}
        </h1>
      )
    case TextBlockContentType.headingTwo:
      return (
        <h2
          style={customStyles}
          className={joinClasses([
            BLOCK_CLASS,
            getBlockClass(ROOT_CLASS, 'heading')
          ])}
        >
          {props.node.content.map(mapChildNodes)}
        </h2>
      )
    case TextBlockContentType.headingThree:
      return (
        <h3
          style={customStyles}
          className={joinClasses([
            BLOCK_CLASS,
            getBlockClass(ROOT_CLASS, 'heading')
          ])}
        >
          {props.node.content.map(mapChildNodes)}
        </h3>
      )
    case TextBlockContentType.headingFour:
      return (
        <h4
          style={customStyles}
          className={joinClasses([
            BLOCK_CLASS,
            getBlockClass(ROOT_CLASS, 'heading')
          ])}
        >
          {props.node.content.map(mapChildNodes)}
        </h4>
      )
    case TextBlockContentType.embeddedEntry:
      return <>{props.node.content.map(mapChildNodes)}</>
    case TextInlineContentType.embeddedImage:
      return (
        <ResponsiveImage
          image={props.node.data}
          className={getBlockClass(ROOT_CLASS, 'embedded-image')}
        />
      )
    case TextInlineContentType.entryLink:
      return (
        <GeneralLink
          dest={props.node.data.route}
          className={getBlockClass(ROOT_CLASS, 'inline-link')}
        >
          <TextContent node={props.node.data.text} />
        </GeneralLink>
      )
    case TextInlineContentType.text: {
      const marksClasses = compact(
        props.node.marks?.map((mark) =>
          getModifierClass(INLINE_CONTENT_CLASS, mark)
        ) ?? []
      )
      return (
        <p className={joinClasses([INLINE_CONTENT_CLASS, ...marksClasses])}>
          {props.node.data.text}
        </p>
      )
    }
    default:
      return null
  }
}

export const CmsText = (props: CmsTextProps) => {
  return (
    <div
      className={ROOT_CLASS}
      style={
        props.textModel.textColor
          ? getCustomCssProperties({
              '--text-color': props.textModel.textColor
            })
          : undefined
      }
    >
      {props.textModel.document.map((node, index) => (
        <TextContent
          node={node}
          key={`${node.type}-${index}`}
          color={props.textModel.textColor}
        />
      ))}
    </div>
  )
}
