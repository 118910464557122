import * as React from 'react'
import { getBlockClass } from '../component-helpers'
import { Page } from '../page/page-ui'
import './styles.scss'

export type MaintenancePageProps = {
  title?: string
  message?: string
}

const ROOT_CLASS = 'maintenance-page'

export const MaintenancePage = (props: MaintenancePageProps) => (
  <Page className={ROOT_CLASS}>
    <div className={getBlockClass(ROOT_CLASS, 'info')}>
      <h1>{props.message ?? `Site Under Maintenance`}</h1>
      <span>{props.message ?? `Come back soon!`}</span>
    </div>
  </Page>
)
