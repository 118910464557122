import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OperationsReducerState } from './operations-reducer-types'

export const InitialOperationsState: OperationsReducerState = {
  cmsPreviewActive: false
}

const operationsSlice = createSlice({
  name: 'operations',
  initialState: InitialOperationsState,
  reducers: {
    setCmsPreviewActive: (state, action: PayloadAction<boolean>) => {
      state.cmsPreviewActive = action.payload
    }
  }
})

export const operationsActions = operationsSlice.actions
export const operationsReducer = operationsSlice.reducer
