import * as React from 'react'
import { SortingArrowheads } from '@Client/icons/sorting-arrowheads'
import { AccessibleKey, TabIndex } from '@Client/constants'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import './styles.scss'

export type WinnersTableHeaderProps = {
  isDesktop: boolean
  toggleSortDirection: (sortBy: WinnersSortBy) => void
}

export enum SortByDirection {
  ascending = 'ASC',
  descending = 'DESC'
}
export type WinnersSortByDirection =
  (typeof SortByDirection)[keyof typeof SortByDirection]

export enum WinnersSortBy {
  date = 'sort_date',
  prize = 'win_amount_in_cents'
}

export const ROOT_CLASS = 'winners-table__header-row'
export const COLUMN_CLASS = getBlockClass(ROOT_CLASS, 'col')

export const WinnersTableHeader = (props: WinnersTableHeaderProps) => {
  const handleSortToggle = (
    e: React.KeyboardEvent<HTMLTableCellElement>,
    sortBy: WinnersSortBy
  ) => {
    if (e.key === AccessibleKey.enter || e.key === AccessibleKey.space) {
      e.preventDefault()
      props.toggleSortDirection(sortBy)
    }
  }

  return (
    <>
      {props.isDesktop ? (
        <>
          <td
            className={COLUMN_CLASS}
            onClick={() => props.toggleSortDirection(WinnersSortBy.date)}
            aria-label='Toggle Sort Direction by Date'
            role={'button'}
            tabIndex={TabIndex.accessible}
            onKeyDown={(e) => handleSortToggle(e, WinnersSortBy.date)}
          >
            <div
              className={joinClasses([
                getBlockClass(ROOT_CLASS, 'container'),
                getModifierClass(
                  getBlockClass(ROOT_CLASS, 'container'),
                  'clickable'
                )
              ])}
            >
              <span>Date</span>
              <SortingArrowheads />
            </div>
          </td>
          <td className={COLUMN_CLASS}>
            <div className={getBlockClass(ROOT_CLASS, 'container')}>
              <span>Game</span>
            </div>
          </td>
          <td className={COLUMN_CLASS}>
            <div className={getBlockClass(ROOT_CLASS, 'container')}>
              <span>Winner</span>
            </div>
          </td>
          <td
            className={COLUMN_CLASS}
            onClick={() => props.toggleSortDirection(WinnersSortBy.prize)}
            aria-label='Toggle Sort Direction by prize amount'
            role={'button'}
            tabIndex={TabIndex.accessible}
            onKeyDown={(e) => handleSortToggle(e, WinnersSortBy.prize)}
          >
            <div
              className={joinClasses([
                getBlockClass(ROOT_CLASS, 'container'),
                getModifierClass(
                  getBlockClass(ROOT_CLASS, 'container'),
                  'clickable'
                ),
                getModifierClass(getBlockClass(ROOT_CLASS, 'container'), 'last')
              ])}
            >
              <span>Prize Won</span>
              <SortingArrowheads />
            </div>
          </td>
        </>
      ) : (
        <>
          <td className={COLUMN_CLASS} aria-label='Date Column Header'>
            <div className={getBlockClass(ROOT_CLASS, 'container')}>
              <span>Date</span>
            </div>
          </td>
          <td className={COLUMN_CLASS} aria-label='Prize Won Column Header'>
            <div
              className={joinClasses([
                getBlockClass(ROOT_CLASS, 'container'),
                getModifierClass(getBlockClass(ROOT_CLASS, 'container'), 'last')
              ])}
            >
              <span>Prize Won</span>
            </div>
          </td>
        </>
      )}
    </>
  )
}
