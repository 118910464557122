import * as React from 'react'
import { CtaField, CtaModel } from '@Cms/model-types/cta-model'
import { GeneralLink } from '../link/link-ui'
import { LinkField } from '@Cms/model-types/link-model'
import { getModifierClass, joinClasses } from '../component-helpers'
import './styles.scss'

export type CtaProps = {
  cta: CtaModel
  onClick?: () => void
  className?: string
}

const ROOT_CLASS = 'cta'

export const CTA = (props: CtaProps) => (
  <GeneralLink
    onClick={props.onClick}
    dest={props.cta[CtaField.link][LinkField.route]}
    className={joinClasses([
      ROOT_CLASS,
      getModifierClass(
        ROOT_CLASS,
        props.cta[CtaField.style].toLowerCase(),
        true
      ),
      props.className
    ])}
  >
    {props.cta[CtaField.text]}
  </GeneralLink>
)
