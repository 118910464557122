import { v4 } from 'uuid'
import { ClientConfig } from '@Client/config'
import {
  Configuration,
  ErrorContext,
  FetchParams,
  Middleware,
  RequestContext,
  ResponseContext
} from '@interaction-gaming/game-data-service-browser-client'
import { StagingEnvironment } from '@Server/constants'
import { isServerRender } from '@Util/ssr-util'
import { Logger } from '@Server/monitoring/logger'

enum ProgressiveJackpotUrl {
  QA = 'https://newhampshirewebapi.qa-npi.nhlottery.com/api/pjp/getprogressivejackpots',
  UAT = 'https://newhampshirewebapi.uat.nhlottery.com/api/pjp/getprogressivejackpots',
  PROD = 'https://newhampshirewebapi.nhlottery.com/api/pjp/getprogressivejackpots'
}

const getProgressiveJackpotUrl = () => {
  switch (ClientConfig.STAGING_ENV) {
    case StagingEnvironment.develop:
    case StagingEnvironment.qa:
      return ProgressiveJackpotUrl.QA
    case StagingEnvironment.uat:
    case StagingEnvironment.uatAi:
      return ProgressiveJackpotUrl.UAT
    case StagingEnvironment.prod:
    default:
      return ProgressiveJackpotUrl.PROD
  }
}

export const ApiConfig = {
  apiRoot: ClientConfig.API_ROOT,
  gameDataRoot: ClientConfig.GAME_DATA_BASE_URL,
  gameDataApiKey: ClientConfig.GAME_DATA_API_KEY,
  progressiveJackpotUrl: getProgressiveJackpotUrl(),
  CLIENT_ID: v4()
}

const gdsRequestMiddleware: Middleware = {
  async pre(context: RequestContext): Promise<FetchParams | void> {
    const gdsRequestId = isServerRender() ? `S-${v4()}` : `C-${v4()}`

    Logger.info(
      `Sending outbound GDS request to ${context.url} with ID ${gdsRequestId}`
    )
    const headers = context.init?.headers
      ? new Headers(context.init.headers)
      : new Headers()

    if (!headers.has('X-Request-ID')) {
      headers.set('X-Request-ID', gdsRequestId)
    }

    context.init.headers = headers
  },

  async post(context: ResponseContext): Promise<Response | void> {
    const headers = context.init?.headers
      ? new Headers(context.init.headers)
      : new Headers()
    const gdsRequestId = headers.get('X-Request-ID')

    Logger.info(
      `Received response for outbound GDS request to url ${context.url} with ID ${gdsRequestId}. Got status code ${context.response.status}.`
    )
  },

  async onError(context: ErrorContext): Promise<Response | void> {
    const headers = context.init?.headers
      ? new Headers(context.init.headers)
      : new Headers()
    const gdsRequestId = headers.get('X-Request-ID')

    Logger.error(
      `Got error response on GDS request to url ${context.url} with ID ${gdsRequestId}. Got error status ${context.response?.status}`,
      context.error
    )
  }
}

export const gameDataConfig = new Configuration({
  basePath: ApiConfig.gameDataRoot,
  apiKey: ApiConfig.gameDataApiKey,
  middleware: [gdsRequestMiddleware]
})
