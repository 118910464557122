import { GameType, RapidOrDrawGame } from '@Cms/model-types/game-model'
import { Nullable, Optional } from '@Util/utility-types'
import { WinningNumbersProps } from '../winning-numbers/winning-numbers-ui'

type PastResultCommon = {
  winningNumbers: WinningNumbersProps
  drawDate: Date
  gameId: string
  gameIdentifier: string
}

export type PastResultRapid = {
  type: GameType.rapid
  bonus: number
  drawNumber: number
} & PastResultCommon

export type PastResultDraw = {
  type: GameType.draw
  jackpotInCents: number
  jackpotWinLocation?: string
} & PastResultCommon

export type PastResult = PastResultRapid | PastResultDraw

export const isRapidResult = (result: PastResult): result is PastResultRapid =>
  result.type === GameType.rapid

export const isDrawResult = (result: PastResult): result is PastResultDraw =>
  result.type === GameType.draw

export const areDrawResults = (
  results: PastResult[]
): results is PastResultDraw[] => results.every(isDrawResult)

export const areRapidResults = (
  results: PastResult[]
): results is PastResultRapid[] => results.every(isRapidResult)

export type PastResultsTableProps = {
  results: Optional<PastResult[]>
  activeGame: RapidOrDrawGame
  maxDate?: Date
  minDate?: Date
  hasYourNumbers: boolean
  yourNumbers: Nullable<number>[]
  yourNumbersSpecialBall: Nullable<number>
}

export type PastResultsTableHeaderProps = {
  isDesktop: boolean
  hasJackpot: boolean
  toggleSortDirection: () => void
} & PastResultsTableProps

export const ROOT_CLASS = 'past-results-table'
