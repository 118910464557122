import { EntryFieldTypes } from 'contentful'
import { CmsModel } from './cms-models'

export enum FilterTagField {
  displayText = 'displayText',
  identifier = 'identifier'
}

export type FilterTagSkeleton = {
  contentTypeId: CmsModel.filterTag
  fields: {
    [FilterTagField.displayText]: EntryFieldTypes.Symbol
    [FilterTagField.identifier]: EntryFieldTypes.Symbol
  }
}

export type FilterTagModel = {
  [FilterTagField.displayText]: string
  [FilterTagField.identifier]: string
}

export const FilterTagFields = Object.values(FilterTagField)
