import * as React from 'react'
import { Dayjs } from 'dayjs'
import { DateFormat, dateUtil } from '@Util/date-util'
import { InputSelect, InputSelectOption } from '../input-select/input-select-ui'
import { getBlockClass } from '../component-helpers'
import { useKeyDown } from '@Client/hooks/use-keydown/use-keydown'
import { AccessibleKey } from '@Client/constants'
import {
  CalendarMonth,
  CalendarMonthProps
} from '../calendar/calendar-month-ui'
import { getArrayOfLength } from '@Util/array-utilities'
import { ChevronLeftSmall } from '@Client/icons/chevron-left'
import { Button } from '../button/button'
import { ChevronRightSmall } from '@Client/icons/chevron-right'
import { Optional } from '@Util/utility-types'
import './styles.scss'

export type DateRangeCalendarProps = {
  isDateDisabled: (date: Date) => boolean
  startDate?: Date
  endDate?: Date
  onDateChange: (startDate?: Date, endDate?: Date) => void
  onNextMonth?: (date: Date) => void
  onPreviousMonth?: (date: Date) => void
  headerText?: string
  inputName: string
}

const ROOT_CLASS = 'date-range-calendar'
const MONTH_BUTTON_NAV_CLASS = getBlockClass(ROOT_CLASS, 'month-button')

const MIN_YEAR = 1970

const getYearOptions = (): InputSelectOption[] => {
  const currentYear = dateUtil().year()
  const validYears = getArrayOfLength(currentYear - MIN_YEAR + 1).map(
    (_, i) => currentYear - i
  )
  return validYears.map((year) => ({
    value: String(year),
    displayText: String(year)
  }))
}

const getMonthKey = (month: Dayjs) =>
  `${month.format(DateFormat.fullMonthAndYear)}-month`

export const DateRangeCalendar = (props: DateRangeCalendarProps) => {
  const initialViewDate = dateUtil(props.startDate)
  const [visibleYear, setVisibleYear] = React.useState<number>(
    initialViewDate.year()
  )
  const [visibleMonth, setVisibleMonth] = React.useState<number>(
    initialViewDate.month()
  )
  const [hoveredEndDate, setHoveredEndDate] = React.useState<Optional<Date>>()
  const navigateToPreviousMonth = () => {
    if (visibleMonth === 0) {
      setVisibleYear(visibleYear - 1)
      setVisibleMonth(11)
    } else {
      setVisibleMonth(visibleMonth - 1)
    }
    props.onPreviousMonth?.(lastMonth.toDate())
  }
  const navigateToNextMonth = () => {
    if (visibleMonth === 11) {
      setVisibleYear(visibleYear + 1)
      setVisibleMonth(0)
    } else {
      setVisibleMonth(visibleMonth + 1)
    }
    props.onNextMonth?.(nextMonth.toDate())
  }

  useKeyDown({
    key: AccessibleKey.rightArrow,
    action: navigateToNextMonth
  })

  useKeyDown({
    key: AccessibleKey.leftArrow,
    action: navigateToPreviousMonth
  })

  const currentMonth = dateUtil()
    .month(visibleMonth)
    .year(visibleYear)
    .startOf('month')

  const isDateInRange = (date: Date) => {
    const dateObj = dateUtil(date)
    if (props.endDate) {
      return (
        dateObj.isAfter(props.startDate, 'day') &&
        dateObj.isBefore(props.endDate, 'day')
      )
    } else if (!props.startDate || !hoveredEndDate) {
      return false
    }

    return (
      (dateObj.isAfter(props.startDate, 'day') &&
        dateObj.isSameOrBefore(hoveredEndDate, 'day')) ||
      dateObj.isSame(dateUtil(hoveredEndDate), 'day')
    )
  }

  const onDateHover = (date?: Date) => {
    if (!props.startDate) {
      return
    } else if (props.endDate && hoveredEndDate) {
      setHoveredEndDate(undefined)
    }
    setHoveredEndDate(date)
  }

  const onDateChange = (date?: Date) => {
    if (props.startDate && !props.endDate) {
      if (date && dateUtil(date).isBefore(props.startDate, 'day')) {
        props.onDateChange(date, undefined)
      } else {
        props.onDateChange(props.startDate, date)
      }
    } else {
      props.onDateChange(date)
    }
  }

  const lastMonth = currentMonth.subtract(1, 'month')
  const nextMonth = currentMonth.add(1, 'month')
  const monthsToRender: (CalendarMonthProps & {
    key: string
  })[] = [
    {
      ...props,
      selectedDate: props.startDate,
      selectedEndDate: props.endDate,
      year: lastMonth.year(),
      month: lastMonth.month(),
      key: getMonthKey(lastMonth),
      monthPosition: 'prev'
    },
    {
      ...props,
      selectedDate: props.startDate,
      selectedEndDate: props.endDate,
      year: visibleYear,
      month: visibleMonth,
      key: getMonthKey(currentMonth),
      monthPosition: 'current'
    },
    {
      ...props,
      selectedDate: props.startDate,
      selectedEndDate: props.endDate,
      year: nextMonth.year(),
      month: nextMonth.month(),
      key: getMonthKey(nextMonth),
      monthPosition: 'next'
    }
  ]

  return (
    <div className={ROOT_CLASS}>
      {props.headerText ? (
        <span className={getBlockClass(ROOT_CLASS, 'header-text')}>
          {props.headerText}
        </span>
      ) : null}
      <div className={getBlockClass(ROOT_CLASS, 'nav-controls')}>
        <div className={getBlockClass(ROOT_CLASS, 'month-and-year')}>
          <span className={getBlockClass(ROOT_CLASS, 'month-text')}>
            {dateUtil().month(visibleMonth).format(DateFormat.longMonth)}
          </span>
          <InputSelect
            options={getYearOptions()}
            onChange={(value: string) => setVisibleYear(Number(value))}
            id={`${props.inputName}-year`}
            value={visibleYear.toString()}
            isBorderless
          />
        </div>
        <div className={getBlockClass(ROOT_CLASS, 'month-buttons')}>
          <Button
            onClick={navigateToPreviousMonth}
            ariaLabel={`Navigate to previous month: ${currentMonth.subtract(1, 'month').format(DateFormat.fullMonthAndYear)}`}
            className={MONTH_BUTTON_NAV_CLASS}
          >
            <ChevronLeftSmall />
          </Button>
          <Button
            onClick={navigateToNextMonth}
            ariaLabel={`Navigate to next month: ${currentMonth.add(1, 'month').format(DateFormat.fullMonthAndYear)}`}
            className={MONTH_BUTTON_NAV_CLASS}
          >
            <ChevronRightSmall />
          </Button>
        </div>
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'months-container')}>
        {monthsToRender.map((monthProps) => (
          <CalendarMonth
            {...monthProps}
            isDateInRange={isDateInRange}
            onDateHover={onDateHover}
            onDateChange={onDateChange}
            onMouseLeave={() => setHoveredEndDate(undefined)}
            key={monthProps.key}
          />
        ))}
      </div>
    </div>
  )
}
