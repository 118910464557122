import { RootState } from '@Client/reducers'
import { uiActions } from '@Client/reducers/ui/ui-reducer'
import {
  SetModalOpenPayload,
  UiState
} from '@Client/reducers/ui/ui-reducer-types'
import { FreshDesk } from '@Client/util/freshdesk'
import { PayloadAction } from '@reduxjs/toolkit'
import { select, takeEvery } from 'redux-saga/effects'

export function* setupUiSaga() {
  yield takeEvery(uiActions.setModalOpen, handleOpenModal)
  yield takeEvery(uiActions.closeModal, handleCloseModal)
  yield takeEvery(uiActions.setMobileViewport, handleMobileViewportChange)
  yield takeEvery(uiActions.setILotteryWidgetOpen, handleILotteryWidgetOpen)
}

function handleILotteryWidgetOpen(action: PayloadAction<boolean>) {
  action.payload ? FreshDesk.hideWidget() : FreshDesk.showWidget()
}

function* handleOpenModal(action: PayloadAction<SetModalOpenPayload>) {
  const isMobileViewport: boolean = yield select(
    (state: RootState) => state.ui.isMobileViewport
  )
  if (
    action.payload.isFullScreenModal &&
    action.payload.isModalOpen &&
    isMobileViewport
  ) {
    FreshDesk.hideWidget()
  } else if (!action.payload.isModalOpen) {
    FreshDesk.showWidget()
  }
}

function handleCloseModal() {
  FreshDesk.showWidget()
}

function* handleMobileViewportChange(action: PayloadAction<boolean>) {
  const uiState: UiState = yield select((state: RootState) => state.ui)
  const isGameWindowOpen: boolean = yield select(
    (state: RootState) => state.iLottery.activeGame?.isGameLoadRequested
  )
  const isMobileViewport = action.payload
  if (uiState.isModalOpen && uiState.isFullScreenModal && isMobileViewport) {
    FreshDesk.hideWidget()
  } else if (!isGameWindowOpen) {
    FreshDesk.showWidget()
  }
}
