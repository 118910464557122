import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FetchInstantGamesFailurePayload,
  FetchInstantGamesSuccessPayload,
  InstantGameState
} from './instant-game-reducer-types'

export const initialInstantGameState: InstantGameState = {
  instantGamesByDataServiceId: {},
  isLoading: false
}

const instantGameSlice = createSlice({
  name: 'instantGames',
  initialState: initialInstantGameState,
  reducers: {
    fetchInstantGamesRequest: (state) => {
      state.isLoading = true
    },
    fetchInstantGamesSuccess: (
      state,
      action: PayloadAction<FetchInstantGamesSuccessPayload>
    ) => {
      state.instantGamesByDataServiceId = action.payload
      state.isLoading = false
    },
    fetchInstantGamesFailure: (
      state,
      action: PayloadAction<FetchInstantGamesFailurePayload>
    ) => {
      state.isLoading = false
      state.error = action.payload.error
    }
  }
})

export const instantGameActions = instantGameSlice.actions
export const instantGameReducer = instantGameSlice.reducer
