import * as React from 'react'

export const CalendarIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
  >
    <title>Calendar Icon</title>
    <path
      d='M5.83333 0.833252C5.37333 0.833252 5 1.20659 5 1.66659V2.49992H4.16667C3.25 2.49992 2.5 3.24992 2.5 4.16658V15.8332C2.5 16.7499 3.25 17.4999 4.16667 17.4999H15.8333C16.75 17.4999 17.5 16.7499 17.5 15.8332V4.16658C17.5 3.24992 16.75 2.49992 15.8333 2.49992H15V1.66659C15 1.20659 14.6267 0.833252 14.1667 0.833252C13.7067 0.833252 13.3333 1.20659 13.3333 1.66659V2.49992H6.66667V1.66659C6.66667 1.20659 6.29333 0.833252 5.83333 0.833252ZM4.16667 6.66658H15.8333V14.9999C15.8333 15.4599 15.46 15.8332 15 15.8332H5C4.54 15.8332 4.16667 15.4599 4.16667 14.9999V6.66658Z'
      fill='#2B3037'
    />
  </svg>
)
