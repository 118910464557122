import * as React from 'react'
import { DrawGameApi, GetAllDrawGamesResponse } from '@Client/api/draw-games'
import { Optional } from '@Util/utility-types'
import { LoaderFunction, useLoaderData } from 'react-router-dom'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { WinningNumbersPage } from './winning-numbers-page-ui'
import { useAppSelector } from '@Client/hooks/redux-hooks'
import { isRapidGame } from '@Cms/model-types/game-model'

export const WinningNumbersPageLoader: LoaderFunction = async () =>
  await DrawGameApi.getDrawGames()

export const WinningNumbersPageContainer = () => {
  const loaderData = useLoaderData() as Optional<GetAllDrawGamesResponse>
  const cmsGames = useAppSelector(
    (state) => state.cmsGames.gamesByDataServiceId
  )

  const rapidGames = React.useMemo(
    () => Object.values(cmsGames).filter(isRapidGame),
    [cmsGames]
  )

  const validDrawGames =
    loaderData?.filter((drawGame) => !!cmsGames[drawGame.id]) ?? []

  return validDrawGames.length ? (
    <WinningNumbersPage drawGames={validDrawGames} rapidGames={rapidGames} />
  ) : (
    <LoadingSpinner />
  )
}
