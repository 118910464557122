import { keyBy } from 'lodash'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GameLoadedAction,
  ILotteryState,
  LaunchGameAction,
  SetProgressiveJackpotsActions,
  UpdateGamesAction,
  UpdateILotterySessionAction
} from './ilottery-reducer-types'
import { NeoGamesBalance } from '@Client/services/pam/ng-types'
import { compact } from '@Util/array-utilities'

const initialILotteryState: ILotteryState = {
  activeGame: {
    gameId: null,
    isGameLoaded: false,
    isGameLoadRequested: false,
    subscription: false
  },
  hasUnviewedBonuses: false,
  isFrameworkReady: false,
  isReadyToLoadLibrary: false,
  isPlayerAuthenticated: false,
  shoppingCartCount: 0,
  session: null,
  instantGamesById: {},
  drawGamesById: {},
  progressiveJackpotsById: {},
  progressiveJackpotGameIds: []
}

export const iLotterySlice = createSlice({
  name: 'iLottery',
  initialState: initialILotteryState,
  reducers: {
    closeGame: (state) => {
      state.activeGame.isGameLoaded = false
      state.activeGame.isGameLoadRequested = false
      state.activeGame.gameId = null
      state.activeGame.playMode = undefined
    },
    loginAborted: () => {},
    logout: (state) => {
      state.isPlayerAuthenticated = false
      state.session = null
    },
    openDrawGameWidget: (state, action: PayloadAction<number>) => {
      state.activeGame.gameId = action.payload
    },
    openSubscriptionGameWidget: (state, action: PayloadAction<number>) => {
      state.activeGame.gameId = action.payload
      state.activeGame.subscription = true
    },

    registrationAborted: () => {},
    requestBalance: () => {},
    requestShoppingCartStatus: () => {},
    requestLoadInstantGame: (
      state,
      action: PayloadAction<LaunchGameAction>
    ) => {
      state.activeGame.isGameLoaded = false
      state.activeGame.isGameLoadRequested = true
      state.activeGame.gameId = action.payload.gameId
      state.activeGame.playMode = action.payload.playMode
    },
    requestProgressiveJackpots: () => {},
    requestSessionDetails: () => {},
    requestUpdatedGames: () => {},
    setIsReadyToLoadLibrary: (state, action: PayloadAction<boolean>) => {
      state.isReadyToLoadLibrary = action.payload
    },
    setInstantGameLoaded: (state, action: PayloadAction<GameLoadedAction>) => {
      state.activeGame.isGameLoaded = action.payload.isLoaded
      state.activeGame.isGameLoadRequested = action.payload.isLoaded
      state.activeGame.gameId = action.payload.gameId
      state.activeGame.playMode = action.payload.playMode
    },
    setBalance: (state, action: PayloadAction<NeoGamesBalance>) => {
      state.balance = action.payload
    },
    setShoppingCartCount: (state, action: PayloadAction<number>) => {
      state.shoppingCartCount = action.payload
    },
    setHasUnviewedBonuses: (state, action: PayloadAction<boolean>) => {
      state.hasUnviewedBonuses = action.payload
    },
    setIsFrameworkReady: (state, action: PayloadAction<boolean>) => {
      state.isFrameworkReady = action.payload
    },
    setProgressiveJackpots: (
      state,
      action: PayloadAction<SetProgressiveJackpotsActions>
    ) => {
      state.progressiveJackpotsById = keyBy(
        action.payload.progressiveJackpots,
        'GlobalIdentifier'
      )
      state.progressiveJackpotLastUpdateEpoch = new Date().getTime()
    },
    setUpdatedGames: (state, action: PayloadAction<UpdateGamesAction>) => {
      state.instantGamesById = keyBy(action.payload.instantGames, 'id')
      state.drawGamesById = keyBy(action.payload.drawGames, 'id')
      state.progressiveJackpotGameIds = Array.from(
        new Set(
          compact(
            action.payload.instantGames.map((game) =>
              game.PJPs?.length ? game.id : null
            )
          )
        )
      )
    },
    setSession: (state, action: PayloadAction<UpdateILotterySessionAction>) => {
      state.session = action.payload
      state.isPlayerAuthenticated = action.payload !== null
    },
    showChat: () => {},
    showContactUs: () => {}
  }
})

export const iLotterySelectors = {
  selectPlayerBalanceDisplay: (state: ILotteryState) => {
    if (state.balance?.DisplayBalance) {
      return state.balance.DisplayBalance
    }
    return state.session?.Balance ? `$${state.session.Balance}` : '-'
  }
}

export const iLotteryActions = iLotterySlice.actions
export const iLotteryReducer = iLotterySlice.reducer
