import { EntryFieldTypes } from 'contentful'
import { CmsModel } from './cms-models'
import { FilterTagModel, FilterTagSkeleton } from './filter-tag-model'

export enum FilterCategoryField {
  displayText = 'displayText',
  identifier = 'identifier',
  filterTags = 'filterTags'
}

export type FilterCategorySkeleton = {
  contentTypeId: CmsModel.filterCategory
  fields: {
    [FilterCategoryField.displayText]: EntryFieldTypes.Symbol
    [FilterCategoryField.identifier]: EntryFieldTypes.Symbol
    [FilterCategoryField.filterTags]: EntryFieldTypes.Array<
      EntryFieldTypes.EntryLink<FilterTagSkeleton>
    >
  }
}

export type FilterCategoryModel = {
  [FilterCategoryField.displayText]: string
  [FilterCategoryField.identifier]: string
  [FilterCategoryField.filterTags]: FilterTagModel[]
}

export const FilterCategoryFields = Object.values(FilterCategoryField)
