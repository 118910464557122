import * as React from 'react'
import {
  ColumnLayoutModel,
  ColumnRatio
} from '@Cms/model-types/column-layout-model'
import {
  getBlockClass,
  getCustomCssProperties,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { CmsGenericContent } from '../cms-generic-content/cms-generic-content-ui'
import './styles.scss'

export type ColumnLayoutProps = {
  columnLayout: ColumnLayoutModel
}

const ROOT_CLASS = 'column-layout'

const ratioClassMap: Record<ColumnRatio, string> = {
  [ColumnRatio.equal]: 'equal',
  [ColumnRatio.oneThirdTwoThirds]: 'one-third-two-thirds',
  [ColumnRatio.twoThirdsOneThird]: 'two-thirds-one-third'
}

export const ColumnLayout = (props: ColumnLayoutProps) => {
  const ratioClassModifier = ratioClassMap[props.columnLayout.columnRatioString]
  return (
    <section
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, ratioClassModifier, true)
      ])}
      style={getCustomCssProperties({
        '--num-columns': `${props.columnLayout.columns.length}`
      })}
    >
      {props.columnLayout.columns.map((column, i) => (
        <div
          className={getBlockClass(ROOT_CLASS, 'column')}
          key={`column-${i}`}
        >
          {column.map((genericContent) => (
            <CmsGenericContent
              rootClassName={ROOT_CLASS}
              content={genericContent}
              key={JSON.stringify(genericContent.data)}
            />
          ))}
        </div>
      ))}
    </section>
  )
}
