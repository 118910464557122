import { Nullable } from '@Util/utility-types'
import { InputSelectOption } from '../input-select/input-select-ui'
import { GameModel } from '@Cms/model-types/game-model'
import { PastResultFormData } from './form-helpers'

export type RapidGameFormPayload = {
  gameIdentifier: string
  drawDate: Date
  drawNumberStart?: number
  drawNumberEnd?: number
}

export type DrawGameFormPayload = {
  gameIdentifier: string
  timeSpan: string
}

export type PastResultsFormProps = {
  gameOptions: InputSelectOption[]
  onYourNumbersChange: (yourNumbers: Nullable<number>, index: number) => void
  clearYourNumbers: () => void
  yourNumbers: Nullable<number>[]
  onBonusNumberChange: (value: Nullable<number>) => void
  yourNumbersSpecialBall: Nullable<number>
  specialBallColorHex?: string
  onDrawGameSubmit: (payload: DrawGameFormPayload) => Promise<void>
  onRapidGameSubmit: (payload: RapidGameFormPayload) => Promise<void>
  activeGame?: GameModel
  setActiveGameId: (gameId: string) => void
  defaultValues?: PastResultFormData
}

export const ROOT_CLASS = 'past-results-form'

export enum PastResultsFieldId {
  GAME_IDENTIFIER = 'game',
  TIME_SPAN = 'time-span',
  YOUR_NUMBERS = 'your-numbers',
  YOUR_NUMBERS_SPECIAL_BALL = 'your-numbers-special-ball',
  DRAW_DATE = 'draw-date',
  DRAW_NUMBER_START = 'draw-number-start',
  DRAW_NUMBER_END = 'draw-number-end'
}

export const DRAWINGS_OPTIONS: InputSelectOption[] = [
  {
    displayText: 'Last 30 Days',
    value: '30 days'
  },
  {
    displayText: 'Last 60 Days',
    value: '60 days'
  },
  {
    displayText: 'Last 90 Days',
    value: '90 days'
  },
  {
    displayText: 'Last 6 Months',
    value: '6 months'
  },
  {
    displayText: 'Last Year',
    value: '1 year'
  }
]
