import * as React from 'react'

export const useScrollLock = (predicate: boolean) => {
  React.useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    if (predicate) {
      body.style.overflow = 'hidden'
    } else {
      body.style.overflow = 'auto'
    }
  }, [predicate])
}
