import * as React from 'react'

export const PlayerIcon = () => (
  <svg
    aria-hidden={true}
    xmlns='http://www.w3.org/2000/svg'
    width='28'
    height='28'
    viewBox='0 0 28 28'
    fill='none'
  >
    <title>Player Account</title>
    <path
      d='M14.0003 2.33334C12.4532 2.33334 10.9695 2.94792 9.87554 4.04188C8.78157 5.13584 8.16699 6.61957 8.16699 8.16667C8.16699 9.71376 8.78157 11.1975 9.87554 12.2915C10.9695 13.3854 12.4532 14 14.0003 14C15.5474 14 17.0312 13.3854 18.1251 12.2915C19.2191 11.1975 19.8337 9.71376 19.8337 8.16667C19.8337 6.61957 19.2191 5.13584 18.1251 4.04188C17.0312 2.94792 15.5474 2.33334 14.0003 2.33334ZM21.0126 16.3333H6.98808C5.70824 16.3333 4.66699 17.3746 4.66699 18.6544V19.5417C4.66699 21.4737 5.76658 23.1443 7.76274 24.2451C9.42349 25.1621 11.639 25.6667 14.0003 25.6667C18.4955 25.6667 23.3337 23.7498 23.3337 19.5417V18.6544C23.3337 17.3746 22.2924 16.3333 21.0126 16.3333Z'
      fill='#186259'
    />
  </svg>
)
