import { Api } from '.'
import { ConfigResponse } from '@Server/controllers/operations/operations-controller'

const fetchConfig = async (): Promise<ConfigResponse> => {
  try {
    return await Api.callServer('/api/v1/operations/config')
  } catch (error) {
    console.error('Error fetching server configuration', error)
    throw error
  }
}

export const OperationsApi = {
  fetchConfig
}
