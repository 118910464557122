import * as React from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export const useResizeObserver = (
  action: () => any,
  ref: React.RefObject<HTMLElement>
) => {
  const resizeObserver = new ResizeObserver(action)

  React.useEffect(() => {
    if (ref?.current) {
      resizeObserver.observe(ref?.current)
    }
    return () => resizeObserver.disconnect()
  }, [ref?.current])
}
