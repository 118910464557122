import * as React from 'react'
import { LocalStorageAdapter } from '@Client/util/local-storage-adapter'
import { Optional } from '@Util/utility-types'

export type UseLocalStorageProps = {
  key: string
}

export const useLocalStorage = <T>(props: UseLocalStorageProps) => {
  const [localValue, setLocalValue] = React.useState<Optional<T>>()

  React.useEffect(() => {
    const existingValue = LocalStorageAdapter.read<T>(props.key)
    if (existingValue !== localValue) {
      setLocalValue(existingValue)
    }
  }, [])

  const write = (value: T) => {
    if (JSON.stringify(value) === JSON.stringify(localValue)) {
      return
    }
    LocalStorageAdapter.write<T>(props.key, value)
    setLocalValue(value)
  }

  const clear = () => {
    LocalStorageAdapter.clear(props.key)
    setLocalValue(undefined)
  }

  return {
    write,
    clear,
    value: localValue
  }
}
