import * as React from 'react'
import { debounce } from 'lodash'
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps'
import { LatLong, Map } from '@Components/map/map-ui'
import { RetailerInfoWindow } from './retailer-info-window'
import { InputSearch } from '../input-search/input-search-ui'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { FilterButton } from '../button-filter/filter-button'
import { useDeepLink } from '@Client/hooks/use-deep-link/use-deep-link'
import { getObjectKeys } from '@Util/object-utilities'
import { compact } from '@Util/array-utilities'
import { TextButton } from '../button-text/text-button'
import { RetailFinderList, RetailLocation } from './retail-finder-list-ui'
import { FindARetailerFilterIdentifier } from './constants'
import './styles.scss'

export type RetailFinderProps = {
  locations: RetailLocation[]
  onBoundsChanged: (center: LatLong) => Promise<void>
  positionRequestComplete: boolean
  defaultCenter: LatLong
  isLoading: boolean
}

type RetailerFilterState = {
  displayText: string
  isActive: boolean
}

const ROOT_CLASS = 'retail-finder'
const RESET_FILTERS_CLASS = getBlockClass(ROOT_CLASS, 'reset-filters')
const FILTERS_CATEGORY = 'tags'
const findARetailerFilter = {
  ADA: 'Accessible',
  KENO: 'Keno'
}

const DEFAULT_FILTER_STATE: Record<string, RetailerFilterState> = {
  [FindARetailerFilterIdentifier.KENO]: {
    displayText: findARetailerFilter.KENO,
    isActive: false
  },
  [FindARetailerFilterIdentifier.ADA]: {
    displayText: findARetailerFilter.ADA,
    isActive: false
  }
}
const LOCALITY = 'NH' // Localization

const getFiltersText = (numFiltersActive: number) => {
  const baseText = 'Remove Filters'
  return numFiltersActive > 0 ? `${baseText} (${numFiltersActive})` : baseText
}

/**
 * RetailFinder component. Must be wrapped in a Google Maps ApiProvider.
 * @param props RetailFinderProps
 * @returns React.ReactElement
 */
export const RetailFinder = (props: RetailFinderProps) => {
  const [filters, setFilters] =
    React.useState<Record<string, RetailerFilterState>>(DEFAULT_FILTER_STATE)
  const { updateParam, getParam, stripParam } = useDeepLink()
  const map = useMap()
  const geocodingLib = useMapsLibrary('geocoding')
  const geocoder = React.useMemo(
    () => geocodingLib && new geocodingLib.Geocoder(),
    [geocodingLib]
  )

  const handleSearchChange = (search: string) => {
    if (!search) {
      return
    }
    geocoder?.geocode(
      {
        address: search,
        componentRestrictions: { locality: LOCALITY }
      },
      (results, status) => {
        if (status === 'OK' && results) {
          const location = results[0].geometry.location
          map?.panTo({ lat: location.lat(), lng: location.lng() })
        }
      }
    )
  }

  const toggleFilter = (identifier: string) => {
    setFilters({
      ...filters,
      [identifier]: {
        ...filters[identifier],
        isActive: !filters[identifier].isActive
      }
    })
    if (!filters[identifier].isActive) {
      updateParam(identifier, (!filters[identifier].isActive).toString())
    } else {
      stripParam(identifier)
    }
  }

  const resetFilters = () => {
    setFilters(DEFAULT_FILTER_STATE)
    for (const identifier of getObjectKeys(filters)) {
      stripParam(identifier)
    }
  }

  React.useEffect(() => {
    for (const identifier of getObjectKeys(filters)) {
      if (getParam(identifier) === 'true') {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [identifier]: {
            ...prevFilters[identifier],
            isActive: !prevFilters[identifier].isActive
          }
        }))
      }
    }
  }, [])

  const activeFilters = compact(
    getObjectKeys(filters).map((tag) => (filters[tag].isActive ? tag : null))
  )
  const filteredLocations = props.locations.filter((location) => {
    for (const activeFilter of activeFilters) {
      if (!location.tags.includes(activeFilter)) {
        return false
      }
    }
    return true
  })

  return (
    <div className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'input')}>
        <div className={getBlockClass(ROOT_CLASS, 'controls')}>
          <InputSearch
            id={`${ROOT_CLASS}-search`}
            onChange={debounce(handleSearchChange, 500)}
            ariaLabel={'Search by city or ZIP Code'}
            placeholder='Search by city or ZIP Code'
          />
          <div className={getBlockClass(ROOT_CLASS, 'filters')}>
            <div className={getBlockClass(ROOT_CLASS, 'filter-buttons')}>
              {getObjectKeys(filters).map((tag) => (
                <FilterButton
                  key={tag}
                  isActive={filters[tag].isActive}
                  identifier={tag}
                  categoryIdentifier={FILTERS_CATEGORY}
                  onToggle={(
                    categoryIdentifier: string,
                    identifier: string
                  ) => {
                    toggleFilter(identifier)
                  }}
                  ariaLabel={'Toggle'}
                >
                  {filters[tag].displayText}
                </FilterButton>
              ))}
            </div>
            <TextButton
              disabled={activeFilters.length === 0}
              onClick={resetFilters}
              ariaLabel={'Remove Filters'}
              className={joinClasses([
                RESET_FILTERS_CLASS,
                getModifierClass(
                  RESET_FILTERS_CLASS,
                  'active',
                  activeFilters.length > 0
                )
              ])}
            >
              {getFiltersText(activeFilters.length)}
            </TextButton>
          </div>
        </div>
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'results')}>
        <Map
          markers={filteredLocations.map((location) => ({
            position: { lat: location.latitude, lng: location.longitude },
            infoWindowContent: <RetailerInfoWindow {...location} />,
            id: location.id
          }))}
          onBoundsChanged={props.onBoundsChanged}
          useUserPosition={true}
          positionRequestComplete={props.positionRequestComplete}
          defaultCenter={props.defaultCenter}
          useApiProvider={false}
        />
        <RetailFinderList
          locations={filteredLocations}
          isLoading={props.isLoading}
        />
      </div>
    </div>
  )
}
