import { RootState } from '..'

export const selectLargestIwgJackpot = (
  state: RootState,
  iwgIdentifier: string
) => {
  const gameJackpots =
    state.iwgJackpots.jackpotValuesByIwgIdentifier[iwgIdentifier]
  if (!gameJackpots) {
    return undefined
  }
  const largestJackpotValue = Math.max(...Object.values(gameJackpots))
  return largestJackpotValue
}
