import * as React from 'react'
import { useFetchServer } from '@Client/hooks/use-fetch/use-fetch'
import { GamePrizeTableResponseData } from '@Server/controllers/game/game-controller'
import { PageNotFound } from '../page-not-found/page-not-found-ui'
import {
  ScratchGameDetailsPage,
  ScratchGameDetailsPageLoadedProps
} from './scratch-game-details-page-ui'
import { useAppDispatch, useAppSelector } from '@Client/hooks/redux-hooks'
import { getObjectKeys } from '@Util/object-utilities'
import { instantGameActions } from '@Client/reducers/instant-games/instant-games-reducer'

export type ScratchGameDetailsPageContainerProps = Pick<
  ScratchGameDetailsPageLoadedProps,
  'game'
>

export const ScratchGameDetailsPageContainer = (
  props: ScratchGameDetailsPageContainerProps
) => {
  const dispatch = useAppDispatch()
  const {
    isLoading: isPrizeTableLoading,
    error: prizeTableError,
    data: prizeTableData,
    fetchData
  } = useFetchServer<GamePrizeTableResponseData>('/api/v1/game/prize-table', {
    identifier: props.game.identifier
  })
  const instantGamesState = useAppSelector((state) => state.instantGames)

  React.useEffect(() => {
    fetchData()
    return () => {}
  }, [])

  React.useEffect(() => {
    if (
      !getObjectKeys(instantGamesState.instantGamesByDataServiceId).length &&
      !instantGamesState.isLoading
    ) {
      dispatch(instantGameActions.fetchInstantGamesRequest())
    }
  }, [instantGamesState, dispatch])

  const gameId =
    instantGamesState.instantGamesByDataServiceId[
      props.game.configuration.dataServices.gameDataServiceId
    ]?.gameId

  return props.game ? (
    <ScratchGameDetailsPage
      game={props.game}
      topPrizeDisplay={props.game.topPrizeDisplay}
      prizeTableData={prizeTableData?.prizeTable}
      isPrizeTableLoading={isPrizeTableLoading}
      prizeTableError={prizeTableError}
      gameId={gameId}
    />
  ) : (
    <PageNotFound message={`We couldn't find that game /:`} />
  )
}
