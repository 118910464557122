import * as React from 'react'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { DrawResultStatus } from '@interaction-gaming/game-data-service-browser-client'
import { Nullable } from '@Util/utility-types'
import { PendingAnimation } from '../pending-animation/pending-animation'
import './styles.scss'

export enum SpecialBallType {
  luckyball = 'luckyball',
  megaball = 'megaball',
  powerball = 'powerball',
  megabucks = 'megabucks',
  default = 'default-special'
}

export type SpecialBall = {
  type: SpecialBallType
  value: number
}

export type WinningNumbersProps = {
  status: DrawResultStatus
  numbers: number[]
  specialBalls: Nullable<SpecialBall[]>
  multipliers: Nullable<number[]>
  multiplierText?: string
  multipleDraws?: boolean
  drawTime?: string
  drawTimeText?: string
  style?: 'primary' | 'secondary'
  label?: string
  align?: 'left' | 'center' | 'right'
  yourNumbers?: Nullable<number>[]
}

const ROOT_CLASS = 'winning-numbers'
const SINGLE_NUMBER_CLASS = getBlockClass(ROOT_CLASS, 'single-number')
const CONTENT_CLASS = getBlockClass(ROOT_CLASS, 'content')

export const getSpecialBallType = (gameName: string) => {
  switch (gameName.toLowerCase()) {
    case 'powerball':
      return SpecialBallType.powerball
    case 'mega millions':
      return SpecialBallType.megaball
    case 'lucky for life':
      return SpecialBallType.luckyball
    case 'megabucks':
      return SpecialBallType.megabucks
    default:
      return SpecialBallType.default
  }
}

export const WinningNumbers = (props: WinningNumbersProps) => {
  return (
    <span
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, 'secondary', props.style === 'secondary'),
        getModifierClass(ROOT_CLASS, 'with-label', !!props.label),
        getModifierClass(ROOT_CLASS, 'no-multiple-draws', !props.multipleDraws)
      ])}
    >
      <div
        className={joinClasses([
          CONTENT_CLASS,
          getModifierClass(CONTENT_CLASS, props.align ?? '', !!props.align)
        ])}
      >
        {props.label ? (
          <span className={getBlockClass(ROOT_CLASS, 'label')}>
            {props.label}
          </span>
        ) : null}
        <span className={getBlockClass(ROOT_CLASS, 'numbers')}>
          <span className={getBlockClass(ROOT_CLASS, 'base-numbers')}>
            {props.numbers?.length ? (
              props.numbers.map((number, i) => (
                <span
                  key={`${number}-${i}`}
                  className={joinClasses([
                    SINGLE_NUMBER_CLASS,
                    getModifierClass(
                      SINGLE_NUMBER_CLASS,
                      'with-label',
                      !!props.label
                    ),
                    getModifierClass(
                      SINGLE_NUMBER_CLASS,
                      'underline',
                      props.yourNumbers !== undefined &&
                        props.yourNumbers?.includes(number)
                    )
                  ])}
                >
                  {number}
                </span>
              ))
            ) : props.drawTime && new Date() < new Date(props.drawTime) ? (
              <span className={getBlockClass(ROOT_CLASS, 'next-draw')}>
                {props.drawTimeText}
              </span>
            ) : (
              <PendingAnimation useAsterisk={false} alignment='center' />
            )}
          </span>
          {props.specialBalls
            ? props.specialBalls.map((specialBall, i) => (
                <span
                  key={`${specialBall.value}-${i}`}
                  className={joinClasses([
                    SINGLE_NUMBER_CLASS,
                    getModifierClass(
                      SINGLE_NUMBER_CLASS,
                      specialBall.type,
                      true
                    ),
                    getModifierClass(
                      SINGLE_NUMBER_CLASS,
                      'with-label',
                      !!props.label
                    ),
                    getModifierClass(
                      SINGLE_NUMBER_CLASS,
                      'underline',
                      props.yourNumbers !== undefined &&
                        props.yourNumbers?.includes(specialBall.value)
                    )
                  ])}
                >
                  {specialBall.value}
                </span>
              ))
            : null}
          {props.multipliers
            ? props.multipliers.map((multiplier, i) => (
                <span
                  key={`${multiplier}-${i}`}
                  className={getBlockClass(ROOT_CLASS, 'multiplier')}
                >
                  {`${multiplier}x`}
                </span>
              ))
            : null}
        </span>
      </div>
      {props.multiplierText ? (
        <span className={getBlockClass(ROOT_CLASS, 'multiplier-text')}>
          {props.multiplierText}
        </span>
      ) : null}
    </span>
  )
}
