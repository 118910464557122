import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

export const EST_TimeZone = 'America/New_York'

dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)

dayjs.tz.setDefault(EST_TimeZone)

export enum DateFormat {
  yearMonthDay = 'YYYY-MM-DD',
  shortMonthAndYear = 'MMM, YYYY',
  fullMonthAndYear = 'MMMM, YYYY',
  fullDateAndTime = 'MM/DD/YYYY hh:mm A',
  longDay = 'dddd',
  longMonth = 'MMM',
  minDayOfWeek = 'dd',
  monthDayYear = 'MM/DD/YYYY',
  monthDayYearDashes = 'MM-DD-YYYYTHH-mm-ss',
  monthDayTime = 'MM/DD h:mm A',
  shortDay = 'ddd',
  shortDayMonthDayYear = 'ddd, MM/DD/YYYY',
  shortDayShortMonthDate = 'ddd, MMM D',
  shortDayShortMonthDateAndYear = 'ddd, MMM D, YYYY',
  shortDayDateAndTime = 'ddd, MMM D h:mm A',
  shortMonthDateAndTime = 'MMM D, h:mm A',
  time = 'h:mm A',
  localTime = 'h:mm A z'
}

export const dateUtil = (
  dateObject?: string | Date | number | dayjs.Dayjs,
  isUtc?: boolean,
  format?: string
): dayjs.Dayjs =>
  isUtc
    ? dayjs.utc(dateObject, format).tz(EST_TimeZone)
    : dayjs(dateObject, format)

export const extendedDayJs = dayjs
