import * as React from 'react'
import {
  APIProvider,
  Map as GoogleMap,
  MapCameraChangedEvent
} from '@vis.gl/react-google-maps'
import { debounce } from 'lodash'
import { ClientConfig } from '@Client/config'
import { MapMarker, MarkerProps } from './map-marker-ui'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import './styles.scss'

export type LatLong = google.maps.LatLngLiteral
export type MapMarkerProps = Omit<
  MarkerProps,
  'openMarkerId' | 'setOpenMarkerId'
>

export type UserPositionProps =
  | {
      useUserPosition: true
      positionRequestComplete: boolean
    }
  | {
      useUserPosition: false
    }

export type MapProps = {
  markers: MapMarkerProps[]
  defaultCenter: LatLong
  onBoundsChanged?: (center: LatLong) => void | Promise<void>
  useApiProvider: boolean
} & UserPositionProps

const ROOT_CLASS = 'map'

export const Map = (props: MapProps) => {
  const [windowAvailable, setWindowAvailable] = React.useState<boolean>(false)
  const [openMarkerId, setOpenMarkerId] = React.useState<string | null>(null)

  React.useEffect(() => {
    // Library not currently fully compatible with SSR
    setWindowAvailable(true)
  }, [])

  const renderMap =
    !props.useUserPosition ||
    (props.useUserPosition && props.positionRequestComplete)

  const map = (
    <GoogleMap
      defaultCenter={props.defaultCenter}
      defaultZoom={10}
      onClick={() => setOpenMarkerId(null)}
      mapId={ClientConfig.MAP_ID}
      onBoundsChanged={debounce((event: MapCameraChangedEvent) => {
        props.onBoundsChanged?.(event.detail.center)
      }, 300)}
    >
      {props.markers.map((marker) => (
        <MapMarker
          key={marker.id}
          {...marker}
          openMarkerId={openMarkerId}
          setOpenMarkerId={setOpenMarkerId}
        />
      ))}
    </GoogleMap>
  )
  return (
    <div className={ROOT_CLASS}>
      {renderMap && windowAvailable ? (
        props.useApiProvider ? (
          <APIProvider apiKey={ClientConfig.MAPS_API_KEY}>{map}</APIProvider>
        ) : (
          map
        )
      ) : (
        <LoadingSpinner />
      )}
    </div>
  )
}
