import * as React from 'react'
import {
  RapidGameDetailsPage,
  RapidGameDetailsPageLoadedProps
} from './rapid-game-details-page-ui'
import { PageNotFound } from '../page-not-found/page-not-found-ui'

export type RapidGameDetailsPageContainerProps = Pick<
  RapidGameDetailsPageLoadedProps,
  'game'
>

export const RapidGameDetailsPageContainer = (
  props: RapidGameDetailsPageContainerProps
) => {
  return props.game ? (
    <RapidGameDetailsPage game={props.game} isLoading={false} />
  ) : (
    <PageNotFound message={`We couldn't find that game /:`} />
  )
}
