import { UAParser } from '@ua-parser-js/pro-business'
import { isServerRender } from '@Util/ssr-util'

const isMobileOrTablet = () => {
  if (isServerRender()) {
    return false
  }
  const { userAgent: navigatorUserAgent } = navigator
  const userAgent = UAParser(navigatorUserAgent)
  const isIOs =
    userAgent.os.is('iOS') ||
    (navigatorUserAgent.toLowerCase().includes('macintosh') &&
      window.navigator.maxTouchPoints > 2)

  const isMobileOrTablet =
    userAgent.device.is('mobile') || userAgent.device.is('tablet') || isIOs
  return isMobileOrTablet
}

export const UserAgentUtil = {
  isMobileOrTablet
}
