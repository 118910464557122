export const getObjectKeys = <T extends Record<string, any>>(obj: T) =>
  Object.keys(obj) as Array<keyof T>

export const deepCopy = <T>(obj: T): T => JSON.parse(JSON.stringify(obj))

export type SerializedDates<T> = {
  [K in keyof T]: T[K] extends Date
    ? string
    : T[K] extends Record<string, any>
      ? SerializedDates<T[K]>
      : T[K]
}

export const serializeDates = <T extends Record<string, any>>(
  obj: T
): SerializedDates<T> => {
  const newObj = deepCopy(obj)
  for (const key of getObjectKeys(newObj)) {
    if ((newObj[key] as any) instanceof Date) {
      newObj[key] = newObj[key].toISOString()
    }
  }
  return newObj
}

export const jsonToCsv = (json: any[]) => {
  const allHeaders: string[] = json.reduce((acc, curr) => {
    Object.keys(curr).forEach((key) => {
      if (!acc.includes(key)) {
        acc.push(key)
      }
    })
    return acc
  }, [])
  const header = allHeaders.join(',')
  const rows = json
    .map((row) =>
      allHeaders.map((header) => JSON.stringify(row[header]) ?? '').join(',')
    )
    .join('\n')
  return `${header}\n${rows}`
}

export const downloadCSV = (csvData: string, filename: string) => {
  const blob = new Blob([csvData], { type: 'text/csv' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = filename + '.csv'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}

export const prettyPrint = (obj: any) => JSON.stringify(obj, undefined, 2)
