import * as React from 'react'
import { getBlockClass } from '../component-helpers'
import './styles.scss'

const ROOT_CLASS = 'game-ribbon-icon'

export const GameRibbonIcon = () => (
  <svg
    id='game-ribbon-icon'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 105.93 30.72'
  >
    <defs>
      <filter
        id='outer-glow-1'
        x='-2.22'
        y='-1.02'
        width='110.4'
        height='29.76'
        filterUnits='userSpaceOnUse'
      >
        <feOffset dx='0' dy='0' />
        <feGaussianBlur result='blur' stdDeviation='1' />
        <feFlood floodColor='#fff' floodOpacity='.75' />
        <feComposite in2='blur' operator='in' />
        <feComposite in='SourceGraphic' />
      </filter>
    </defs>
    <path
      className={getBlockClass(ROOT_CLASS, 'wrap-around')}
      d='M105.08,25.62s.66,2.32,0,2.98l-2.98-2.98h2.98Z'
    />
    <polygon
      className={getBlockClass(ROOT_CLASS, 'main')}
      points='105.08 25.62 81.58 2.12 24.36 2.12 .85 25.62 .85 25.62 105.08 25.62'
    />
    <path
      className={getBlockClass(ROOT_CLASS, 'wrap-around')}
      d='M.85,25.62s-.66,2.32,0,2.98l2.98-2.98H.85Z'
    />
  </svg>
)
