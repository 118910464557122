import * as React from 'react'

export const SearchIcon = () => (
  <svg
    aria-hidden='true'
    xmlns='http://www.w3.org/2000/svg'
    width='28'
    height='28'
    viewBox='0 0 28 28'
    fill='none'
  >
    <path
      d='M12.1331 2.80005C6.98953 2.80005 2.7998 6.98978 2.7998 12.1334C2.7998 17.277 6.98953 21.4667 12.1331 21.4667C14.3699 21.4667 16.424 20.6724 18.0339 19.354L23.6066 24.9266C23.6926 25.0162 23.7956 25.0877 23.9096 25.137C24.0236 25.1862 24.1462 25.2123 24.2704 25.2135C24.3946 25.2148 24.5177 25.1913 24.6327 25.1443C24.7477 25.0974 24.8521 25.028 24.9399 24.9402C25.0277 24.8524 25.0971 24.7479 25.1441 24.633C25.191 24.518 25.2145 24.3948 25.2133 24.2706C25.212 24.1465 25.186 24.0238 25.1367 23.9098C25.0875 23.7958 25.0159 23.6928 24.9264 23.6068L19.3537 18.0342C20.6722 16.4243 21.4665 14.3701 21.4665 12.1334C21.4665 6.98978 17.2767 2.80005 12.1331 2.80005ZM12.1331 4.66672C16.2679 4.66672 19.5998 7.9986 19.5998 12.1334C19.5998 16.2682 16.2679 19.6 12.1331 19.6C7.99836 19.6 4.66647 16.2682 4.66647 12.1334C4.66647 7.9986 7.99836 4.66672 12.1331 4.66672Z'
      fill='black'
    />
  </svg>
)
