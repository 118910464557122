import * as React from 'react'
import { useRedirectHandler } from '@Client/hooks/use-redirect-handler/use-redirect-handler'
import { getBlockClass } from '../component-helpers'
import { GeneralLink } from '../link/link-ui'
import { RoutePath } from '@Client/route-paths'
import { Page } from '../page/page-ui'
import './styles.scss'

export type PageNotFoundProps = {
  message?: string
}

const ROOT_CLASS = 'page-not-found'

export const PageNotFound = (props: PageNotFoundProps) => {
  useRedirectHandler()
  return (
    <Page className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'info')}>
        <h1>404</h1>
        <span>{props.message ?? `We couldn't find that page ):`}</span>
        <GeneralLink
          className={getBlockClass(ROOT_CLASS, 'home-link')}
          dest={RoutePath.home}
        >
          Go to Home
        </GeneralLink>
      </div>
    </Page>
  )
}
