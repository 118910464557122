import * as React from 'react'
import { getBlockClass, getCustomCssProperties } from '../component-helpers'
import { GameRibbonIcon } from './game-ribbon-icon'
import './styles.scss'

export type GameRibbonProps = {
  text: string
  backgroundColor?: string
  textColor?: string
}

const ROOT_CLASS = 'game-ribbon'

export const GameRibbon = (props: GameRibbonProps) => (
  <span
    className={ROOT_CLASS}
    style={getCustomCssProperties({
      '--ribbon-bkg-color': props.backgroundColor ?? '',
      '--ribbon-text-color': props.textColor ?? ''
    })}
  >
    <GameRibbonIcon />
    <span className={getBlockClass(ROOT_CLASS, 'text')}>{props.text}</span>
  </span>
)
