import { isServerRender } from '@Util/ssr-util'

export const downloadFileBlob = (blob: Blob, filename: string) => {
  if (isServerRender()) {
    return
  }
  const data = window?.URL?.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = data
  link.download = filename
  link.click()
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data)
    link.remove()
  }, 100)
}
