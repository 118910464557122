import * as React from 'react'
import { Button, ButtonProps } from '@Components/button/button'
import { joinClasses } from '../component-helpers'
import './styles.scss'

export type TextButtonProps = ButtonProps

const ROOT_CLASS = 'text-button'

export const TextButton = (props: React.PropsWithChildren<TextButtonProps>) => {
  return (
    <Button {...props} className={joinClasses([ROOT_CLASS, props.className])}>
      {props.children}
    </Button>
  )
}
