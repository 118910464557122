import * as React from 'react'
import {
  GameCollectionField,
  GameCollectionModel
} from '@Cms/model-types/game-collection-model'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { CmsModel } from '@Cms/model-types/cms-models'
import { GameCarousel } from '../game-carousel/game-carousel-ui'
import { GameCarouselField } from '@Cms/model-types/game-carousel-model'
import { GameLobbyContainer } from '../game-lobby/game-lobby-container'
import { GameLobbyField } from '@Cms/model-types/game-lobby-model'
import { ImageBannerField } from '@Cms/model-types/image-banner-model'
import { ImageBanner } from '../banner-image/banner-image-ui'
import { Page } from '../page/page-ui'
import { GameCollectionFooter } from '../game-collection-footer/game-collection-footer-ui'
import './styles.scss'

export type GameCollectionProps = {
  collection: GameCollectionModel
  isILottery: boolean
}

const ROOT_CLASS = 'game-collection'
const PAGE_CLASS = getBlockClass(ROOT_CLASS, 'page')

export const GameCollection = (props: GameCollectionProps) => {
  return (
    <Page
      title={props.collection[GameCollectionField.title]}
      id={PAGE_CLASS}
      className={joinClasses([
        PAGE_CLASS,
        getModifierClass(PAGE_CLASS, 'is-ilottery', props.isILottery)
      ])}
    >
      <section
        className={joinClasses([
          ROOT_CLASS,
          getModifierClass(ROOT_CLASS, 'is-ilottery', props.isILottery)
        ])}
      >
        {props.collection.subtitle && (
          <h2 className={getBlockClass(ROOT_CLASS, 'subtitle')}>
            {props.collection.subtitle}
          </h2>
        )}
        <div className={getBlockClass(ROOT_CLASS, 'content')}>
          {props.collection[GameCollectionField.content].map((gameContent) => {
            switch (gameContent.type) {
              case CmsModel.gameCarousel:
                return (
                  <div
                    className={getBlockClass(ROOT_CLASS, 'carousel-container')}
                    key={gameContent.data[GameCarouselField.title]}
                  >
                    <GameCarousel
                      carousel={gameContent.data}
                      isILottery={props.isILottery}
                    />
                  </div>
                )
              case CmsModel.imageBanner:
                return (
                  <ImageBanner
                    imageBanner={gameContent.data}
                    key={gameContent.data[ImageBannerField.name]}
                    className={getBlockClass(ROOT_CLASS, 'image-banner')}
                  />
                )
              case CmsModel.gameLobby:
                return (
                  <GameLobbyContainer
                    lobby={gameContent.data}
                    key={gameContent.data[GameLobbyField.identifier]}
                    isILottery={props.isILottery}
                  />
                )
              default:
                return null
            }
          })}
        </div>
      </section>
      <GameCollectionFooter identifier={props.collection.identifier} />
    </Page>
  )
}
