import * as React from 'react'
import { getBlockClass } from '../component-helpers'
import { ModalTriggerButton } from '../modal/modal-trigger-button'
import { DateFormat, dateUtil } from '@Util/date-util'
import { CalendarIcon } from '@Client/icons/calendar'
import { ModalRoot, Modal } from '../modal/modal'
import { DateSelectorCalendar } from './date-selector-calendar'
import './styles.scss'

export type DateSelectorProps = {
  selectedDate?: Date
  onDateChange: (date?: Date) => void
  isDateDisabled?: (date: Date) => boolean
  onNextMonth?: (date: Date) => void
  onPreviousMonth?: (date: Date) => void
  headerElement?: React.ReactNode
  headerText?: string
  inputName: string
}

export type DateInputButtonProps = {
  onClick: () => void
  selectedDate?: Date
}

const ROOT_CLASS = 'date-selector'

const DateInputButton = (props: DateInputButtonProps) => {
  return (
    <ModalTriggerButton
      className={getBlockClass(ROOT_CLASS, 'input')}
      ariaLabel='Open Date Selector'
    >
      {props.selectedDate
        ? dateUtil(props.selectedDate).format(DateFormat.shortDayMonthDayYear)
        : 'Select a date'}
      <CalendarIcon />
    </ModalTriggerButton>
  )
}

export const DateSelector = (props: DateSelectorProps) => {
  const [calendarOpen, setCalendarOpen] = React.useState<boolean>(false)
  const selectDate = (date?: Date) => {
    props.onDateChange(date)
    setTimeout(() => {
      setCalendarOpen(false)
    }, 300)
  }
  return (
    <ModalRoot isOpen={calendarOpen} onOpenChange={setCalendarOpen}>
      <DateInputButton
        selectedDate={props.selectedDate}
        onClick={() => setCalendarOpen(true)}
      />
      <Modal
        className={getBlockClass(ROOT_CLASS, 'modal')}
        ariaTitle={'Calendar Picker'}
        ariaDescription={'Select date'}
        mobileFullScreen={false}
      >
        {props.headerElement ?? null}
        <DateSelectorCalendar
          selectedDate={props.selectedDate}
          onDateChange={selectDate}
          headerText={props.headerText}
          inputName={props.inputName}
          isDateDisabled={props.isDateDisabled}
          onNextMonth={props.onNextMonth}
          onPreviousMonth={props.onPreviousMonth}
        />
      </Modal>
    </ModalRoot>
  )
}
