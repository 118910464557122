import * as React from 'react'
import { FeedItemField, FeedItemModel } from '@Cms/model-types/feed-item-model'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { CmsText } from '../cms-text/cms-text-ui'
import { CTA } from '../cms-cta/cta'
import { ResponsiveImage } from '../cms-responsive-image/responsive-image-ui'
import './styles.scss'

export type FeedItemProps = {
  item: FeedItemModel
  style: 'tile' | 'listItem'
}

const ROOT_CLASS = 'feed-item'
const CONTENT_CLASS = getBlockClass(ROOT_CLASS, 'content')
const PREVIEW_GROUP_CLASS = getBlockClass(ROOT_CLASS, 'preview-group')

export const FeedItem = (props: FeedItemProps) => {
  const previewGroupContent = (
    <>
      <CmsText textModel={props.item[FeedItemField.previewText]} />
      {props.item[FeedItemField.cta] ? (
        <CTA cta={props.item[FeedItemField.cta]} />
      ) : null}
    </>
  )
  return (
    <div
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, props.style, true)
      ])}
    >
      <h3 className={getBlockClass(ROOT_CLASS, 'header')}>
        {props.item[FeedItemField.header]}
      </h3>
      <span className={getBlockClass(ROOT_CLASS, 'date')}>
        {props.item.formattedDate}
      </span>
      <div
        className={joinClasses([
          CONTENT_CLASS,
          getModifierClass(CONTENT_CLASS, 'no-image', !props.item.previewImage)
        ])}
      >
        {props.item.previewImage && (
          <ResponsiveImage image={props.item.previewImage} />
        )}
        {props.item.previewImage ? (
          <div
            className={joinClasses([
              PREVIEW_GROUP_CLASS,
              getModifierClass(
                PREVIEW_GROUP_CLASS,
                'with-cta',
                !!props.item.cta
              )
            ])}
          >
            {previewGroupContent}
          </div>
        ) : (
          previewGroupContent
        )}
      </div>
    </div>
  )
}
