import * as React from 'react'
import {
  GameCarouselField,
  GameCarouselModel as GameCarouselModel
} from '@Cms/model-types/game-carousel-model'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '@Components/component-helpers'
import { GameTileContainer } from '../game-tile/game-tile-container'
import { GameField } from '@Cms/model-types/game-model'
import './styles.scss'

export type GameCarouselProps = {
  carousel: Omit<GameCarouselModel, 'type'>
  isILottery?: boolean
}

const ROOT_CLASS = 'game-carousel'
const GAMES_CLASS = getBlockClass(ROOT_CLASS, 'games')
const HEADER_CLASS = getBlockClass(ROOT_CLASS, 'header')

export const GameCarousel = (props: GameCarouselProps) => {
  return (
    <div className={ROOT_CLASS}>
      <h2
        className={joinClasses([
          HEADER_CLASS,
          getModifierClass(
            HEADER_CLASS,
            'is-ilottery',
            props.isILottery === true
          )
        ])}
      >
        {props.carousel[GameCarouselField.title]}
      </h2>
      <div className={joinClasses([GAMES_CLASS])}>
        {props.carousel.games.map((game, i) => (
          <GameTileContainer
            key={`${game[GameField.identifier]}-${i}`}
            game={game}
            isILotteryGame={props.isILottery === true}
          />
        ))}
      </div>
    </div>
  )
}
