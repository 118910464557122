import { call, put, takeEvery } from 'redux-saga/effects'
import { JackpotApi } from '@Client/api/jackpots'
import { jackpotActions } from '@Client/reducers/jackpots/jackpots-reducer'
import { JackpotSummary } from '@interaction-gaming/game-data-service-browser-client'
import { JackpotGroupsResponse } from '@Server/controllers/jackpot/jackpot-controller'
import { serializeDates } from '@Util/object-utilities'

export function* setupJackpotsSaga() {
  yield takeEvery(jackpotActions.fetchJackpotsRequest.type, fetchJackpots)
  yield takeEvery(
    jackpotActions.fetchJackpotGroupsRequest.type,
    fetchJackpotGroups
  )
}

function* fetchJackpots() {
  try {
    const response: JackpotSummary = yield JackpotApi.getAllCurrentJackpots()
    const serializedResponse = serializeDates(response)
    yield put(jackpotActions.fetchJackpotsSuccess(serializedResponse))
  } catch (error) {
    yield put(
      jackpotActions.fetchJackpotsFailure(
        error instanceof Error ? error.message : 'An error occurred'
      )
    )
  }
}

function* fetchJackpotGroups() {
  try {
    const response: JackpotGroupsResponse = yield call(
      JackpotApi.getJackpotGroupsById
    )
    if (response.status !== 'success') {
      throw new Error('Error fetching jackpot groups')
    }
    const serializedGroupsById = serializeDates(response.data.groupsById)
    yield put(jackpotActions.fetchJackpotGroupsSuccess(serializedGroupsById))
  } catch (error) {
    yield put(jackpotActions.fetchJackpotGroupsFailure('Something went wrong'))
  }
}
