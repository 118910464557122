import { OpenPageIdentifier } from '@Client/services/pam/open-page/ng-open-page-map'
import { isServerRender } from '@Util/ssr-util'

// https://developers.freshdesk.com/widget-api/
type FreshDeskArticleIdMap = Partial<Record<OpenPageIdentifier, string>>
export const FreshDeskSolutionArticleId: FreshDeskArticleIdMap = {
  [OpenPageIdentifier.FAQ_REGISTRATION]: '156000024202',
  [OpenPageIdentifier.HELP_DEPOSIT_METHODS]: '156000024615',
  [OpenPageIdentifier.HELP_DEPOSIT]: '156000024309',
  [OpenPageIdentifier.HELP_DEPOSIT_BY_CREDIT]: '156000024310',
  [OpenPageIdentifier.SUCCESSFUL_DEPOSIT]: '156000024312',
  [OpenPageIdentifier.UNSUCCESSFUL_DEPOSIT]: '156000024313',
  [OpenPageIdentifier.FAQ_FORGOT_PASSWORD]: '156000024314',
  [OpenPageIdentifier.HELP_CASHOUT]: '156000024315',
  [OpenPageIdentifier.HELP_CASHOUT_HOW_TO]: '156000024315',
  [OpenPageIdentifier.HELP_CASHOUT_POLICY_WITHDRAW_ERROR_WITHDRAW_NOT_ALLOWED_FAQ]:
    '156000024670',
  [OpenPageIdentifier.HELP_CASHOUT_POLICY_WITHDRAW_ERROR_WITHDRAW_FUNDS_WITHDRAW_PROCESS]:
    '156000024730',
  [OpenPageIdentifier.FAQ_MY_ACCOUNT]: '156000024318',
  [OpenPageIdentifier.FAQ_HISTORY_TRANSACTIONS_FAQ]: '156000024319',
  [OpenPageIdentifier.FAQ_UPLOAD_DOCUMENTS]: '156000024320',
  [OpenPageIdentifier.FAQ_PERSONAL_INFO]: '156000024321',
  [OpenPageIdentifier.FAQ_NOTIFICATIONS_PREFERENCES_FAQ]: '156000024322',
  [OpenPageIdentifier.BONUSES_NEW_BONUS_OFFERS_FAQ]: '156000024323',
  [OpenPageIdentifier.BONUSES_MY_BONUS_ACTIVITY_FAQ]: '156000024635',
  [OpenPageIdentifier.FAQ_GEOLOCATION]: '156000024324',
  [OpenPageIdentifier.BONUS_POLICY]: '156000024325',
  [OpenPageIdentifier.HELP_DEPOSIT_ONLINE_BANKING]: '156000024326',
  [OpenPageIdentifier.HELP_DEPOSIT_PAYPAL]: '156000024327',
  [OpenPageIdentifier.HELP_DEPOSIT_ACH_BANK_TRANSFER]: '156000024328',
  [OpenPageIdentifier.HELP_DEPOSIT_VENMO]: '156000024329',
  [OpenPageIdentifier.TERMS_CLAIMS_TAX_REPORTED_PRIZES_FAQ]: '156000161689',
  [OpenPageIdentifier.FAQ_REWARD_HISTORY_REWARDS_FAQ]: '156000024331',
  [OpenPageIdentifier.FAQ_SHOPPING_CART_FAQ]: '156000024565',
  [OpenPageIdentifier.FAQ_UPCOMING_PURCHASED_DRAWS_FAQ]: '156000024566',
  [OpenPageIdentifier.FAQ_FAVORITE_PURCHASE_FAQ]: '156000024567',
  [OpenPageIdentifier.FAQ_HISTORY_PREVIOUSLY_PLAYED_GAMES_FAQ]: '156000024568',
  [OpenPageIdentifier.FAQ_TRANSACTION_FAQ_HISTORY]: '156000024569',
  [OpenPageIdentifier.FAQ]: '156000024668',
  [OpenPageIdentifier.FAQ_PAST_SUBSCRIPTIONS]: '156000024570',
  [OpenPageIdentifier.FAQ_UPCOMING_SUBSCRIPTIONS]: '156000024571',
  [OpenPageIdentifier.RESPONSIBLE_GAMING_SELF_EXCLUSION]: '156000153083',
  [OpenPageIdentifier.RESPONSIBLE_GAMING_DEPOSIT_LIMIT_FAQ]: '156000180052'
}

const isFreshDesk = (key: OpenPageIdentifier) => {
  return Object.keys(FreshDeskSolutionArticleId).includes(key)
}

const ensureWidgetIsPresent = () => {
  if (isServerRender() || !window.FreshworksWidget) {
    throw new Error('Freshdesk widget is not available')
  }
}

const hideWidget = () => {
  try {
    ensureWidgetIsPresent()
    window.FreshworksWidget?.('hide', 'launcher')
  } catch (error) {
    console.error('Error hiding Freshdesk widget:', error)
  }
}

const showWidget = () => {
  try {
    ensureWidgetIsPresent()
    window.FreshworksWidget?.('show', 'launcher')
  } catch (error) {
    console.error('Error showing Freshdesk widget:', error)
  }
}

const openArticle = (key: OpenPageIdentifier) => {
  try {
    ensureWidgetIsPresent()
    window.FreshworksWidget?.('open', 'article', {
      id: FreshDeskSolutionArticleId[key]
    })
  } catch (error) {
    console.error('Error opening Freshdesk article:', error)
  }
}

export const FreshDesk = {
  isFreshDesk,
  hideWidget,
  openArticle,
  showWidget
}
