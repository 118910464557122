import * as React from 'react'

export const CloseIcon = () => (
  <svg
    aria-hidden='true'
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
  >
    <path
      d='M6.6536 5.32031C6.38835 5.32038 6.12915 5.39955 5.90914 5.54771C5.68913 5.69586 5.5183 5.90626 5.41849 6.15201C5.31868 6.39776 5.29443 6.66769 5.34883 6.92729C5.40324 7.1869 5.53383 7.42438 5.72391 7.60937L14.1145 16L5.72391 24.3906C5.59594 24.5135 5.49378 24.6606 5.4234 24.8235C5.35302 24.9863 5.31584 25.1616 5.31403 25.3389C5.31223 25.5163 5.34584 25.6923 5.41289 25.8565C5.47994 26.0208 5.57909 26.17 5.70453 26.2954C5.82997 26.4209 5.97917 26.52 6.14341 26.5871C6.30765 26.6541 6.48361 26.6877 6.661 26.6859C6.83839 26.6841 7.01363 26.6469 7.17647 26.5765C7.33931 26.5062 7.48646 26.404 7.60932 26.276L15.9999 17.8854L24.3906 26.276C24.5134 26.404 24.6606 26.5062 24.8234 26.5765C24.9863 26.6469 25.1615 26.6841 25.3389 26.6859C25.5163 26.6877 25.6923 26.6541 25.8565 26.5871C26.0207 26.52 26.1699 26.4209 26.2954 26.2954C26.4208 26.17 26.52 26.0208 26.587 25.8565C26.6541 25.6923 26.6877 25.5163 26.6859 25.339C26.6841 25.1616 26.6469 24.9863 26.5765 24.8235C26.5061 24.6606 26.404 24.5135 26.276 24.3906L17.8854 16L26.276 7.60937C26.4687 7.42209 26.6003 7.18097 26.6535 6.91761C26.7068 6.65425 26.6793 6.38094 26.5746 6.13349C26.4698 5.88604 26.2928 5.676 26.0667 5.5309C25.8405 5.38579 25.5758 5.31239 25.3072 5.32031C24.9608 5.33063 24.6321 5.47541 24.3906 5.72396L15.9999 14.1146L7.60932 5.72396C7.48507 5.59623 7.33648 5.49471 7.17233 5.42538C7.00817 5.35605 6.83179 5.32032 6.6536 5.32031Z'
      fill='#2B3037'
    />
  </svg>
)
