import { EntryFieldTypes } from 'contentful'
import { CmsModel } from './cms-models'
import {
  ResponsiveImageModel,
  ResponsiveImageSkeleton
} from './responsive-image-model'
import { LinkModel, LinkSkeleton } from './link-model'
import { ClientPlatform } from '@Server/controllers/controller-helpers'

export enum ImageBannerField {
  name = 'name',
  image = 'image',
  backgroundColor = 'backgroundColor',
  link = 'link',
  displayPlatform = 'displayPlatform'
}

export const ImageBannerFields = Object.values(ImageBannerField)

export type ImageBannerSkeleton = {
  contentTypeId: CmsModel.imageBanner
  fields: {
    [ImageBannerField.name]: EntryFieldTypes.Symbol
    [ImageBannerField.image]: EntryFieldTypes.EntryLink<ResponsiveImageSkeleton>
    [ImageBannerField.backgroundColor]: EntryFieldTypes.Symbol
    [ImageBannerField.displayPlatform]: EntryFieldTypes.Array<
      EntryFieldTypes.Symbol<ClientPlatform>
    >
    [ImageBannerField.link]?: EntryFieldTypes.EntryLink<LinkSkeleton>
  }
}

export type TypedImageBannerModel = {
  contentType: CmsModel.imageBanner
  data: ImageBannerModel
}

export type ImageBannerModel = {
  name: string
  image: ResponsiveImageModel
  backgroundColorHex: string
  displayPlatform: ClientPlatform[]
  link?: LinkModel
}
