import * as React from 'react'
import {
  NavCategoryField,
  NavCategoryModel
} from '@Cms/model-types/nav-category-model'
import { GeneralLink } from '../link/link-ui'
import { getBlockClass } from '../component-helpers'
import { NavItemField } from '@Cms/model-types/nav-item-model'
import './styles.scss'

const ROOT_CLASS = 'horizontal-navigation-category'

export const HorizontalNavigationCategory = (props: NavCategoryModel) => {
  return (
    <div className={ROOT_CLASS}>
      <ul className={getBlockClass(ROOT_CLASS, 'links')}>
        {props[NavCategoryField.navItems].map((navItem) => (
          <li
            key={navItem[NavItemField.displayText]}
            className={getBlockClass(ROOT_CLASS, 'link-li')}
          >
            <GeneralLink
              dest={navItem[NavItemField.route]}
              className={getBlockClass(ROOT_CLASS, 'link')}
            >
              {navItem[NavItemField.displayText]}
            </GeneralLink>
          </li>
        ))}
      </ul>
    </div>
  )
}
