import React from 'react'
import { useDispatch } from 'react-redux'
import { iLotteryActions } from '@Client/reducers/ilottery/ilottery-reducer'
import { useDeepLink } from '../use-deep-link/use-deep-link'
import { useAppSelector } from '../redux-hooks'

export enum ILotteryDeepLinkParams {
  loadDrawGameId = 'loadDrawGameId',
  loadInstantGameId = 'loadInstantGameId'
}

export const useILotteryDeepLink = () => {
  const dispatch = useDispatch()
  const isILotteryFrameworkReady = useAppSelector(
    (state) => state.iLottery.isFrameworkReady
  )
  const { getParam, stripParam } = useDeepLink()
  const drawGameId = getParam(ILotteryDeepLinkParams.loadDrawGameId)

  React.useEffect(() => {
    if (isILotteryFrameworkReady) {
      if (drawGameId) {
        dispatch(iLotteryActions.openDrawGameWidget(Number(drawGameId)))
        stripParam(ILotteryDeepLinkParams.loadDrawGameId)
      }
    }
  }, [isILotteryFrameworkReady, drawGameId])
}
