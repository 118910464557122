import * as React from 'react'
import { formatWinner } from '../winners/helpers'
import { DateFormat, dateUtil } from '@Util/date-util'
import { CurrencyUtilities } from '@Util/currency-utilities'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import './styles.scss'

export type WinnersCellProps = {
  isDesktop: boolean
} & Winner

export type Winner = {
  date: Date
  gameName: string
  name: string
  city: string
  prize: number
}

const ROOT_CLASS = 'winner-cell'
const COLUMN_CLASS = getBlockClass(ROOT_CLASS, 'col')

export const WinnerCell = (props: WinnersCellProps) => {
  return props.isDesktop ? (
    <tr className={ROOT_CLASS}>
      <td className={COLUMN_CLASS}>
        <span className={getBlockClass(ROOT_CLASS, 'container')}>
          {dateUtil(props.date).format(DateFormat.shortMonthAndYear)}
        </span>
      </td>
      <td className={COLUMN_CLASS}>
        <span className={getBlockClass(ROOT_CLASS, 'container')}>
          {props.gameName}
        </span>
      </td>
      <td className={COLUMN_CLASS}>
        <span className={getBlockClass(ROOT_CLASS, 'container')}>
          {formatWinner(props.name, props.city)}
        </span>
      </td>
      <td className={COLUMN_CLASS}>
        <span
          className={joinClasses([
            getBlockClass(ROOT_CLASS, 'container'),
            getModifierClass(getBlockClass(ROOT_CLASS, 'container'), 'last')
          ])}
        >
          {CurrencyUtilities.compactFormat(
            CurrencyUtilities.getDollarsFromCents(props.prize)
          )}
        </span>
      </td>
    </tr>
  ) : (
    <tr className={ROOT_CLASS}>
      <td className={getBlockClass(ROOT_CLASS, 'vertical')}>
        <span className={getBlockClass(ROOT_CLASS, 'date')}>
          {dateUtil(props.date).format(DateFormat.shortMonthAndYear)}
        </span>
        <span className={getBlockClass(ROOT_CLASS, 'game')}>
          {props.gameName}
        </span>
        <span className={getBlockClass(ROOT_CLASS, 'winner')}>
          {formatWinner(props.name, props.city)}
        </span>
      </td>
      <td>
        <span className={getBlockClass(ROOT_CLASS, 'prize')}>
          {CurrencyUtilities.compactFormat(
            CurrencyUtilities.getDollarsFromCents(props.prize)
          )}
        </span>
      </td>
    </tr>
  )
}
