import * as React from 'react'
import { Button } from '../button/button'
import { ChevronLeft } from '@Client/icons/chevron-left'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { ChevronRight } from '@Client/icons/chevron-right'
import './styles.scss'

export type DotNavigationProps = {
  activeIndex: number
  count: number
  navigateToIndex: (index: number) => void
  name: string
  showDotsInDesktop: boolean
  dotColor: 'light' | 'dark'
}

const MAX_MOBILE_DOTS = 8
const ROOT_CLASS = 'dot-navigation'
const DOT_CLASS = getBlockClass(ROOT_CLASS, 'dot')

export const DotNavigation = (props: DotNavigationProps) => (
  <div
    className={joinClasses([
      ROOT_CLASS,
      getModifierClass(ROOT_CLASS, 'no-desktop-dots', !props.showDotsInDesktop),
      getModifierClass(ROOT_CLASS, 'with-gap', props.count <= MAX_MOBILE_DOTS)
    ])}
  >
    <Button
      ariaLabel='Scroll to previous item'
      onClick={() => props.navigateToIndex(props.activeIndex - 1)}
    >
      <ChevronLeft useLightColor={props.dotColor === 'light'} />
    </Button>
    {new Array(props.count).fill(true).map((_, i) => (
      <Button
        key={`${props.name}-dot-${i}`}
        onClick={() => props.navigateToIndex(i)}
        ariaLabel={`Go to item ${i + 1}`}
      >
        <span
          className={joinClasses([
            DOT_CLASS,
            getModifierClass(DOT_CLASS, 'is-selected', props.activeIndex === i),
            getModifierClass(DOT_CLASS, 'is-light', props.dotColor === 'light'),
            getModifierClass(
              DOT_CLASS,
              'is-light-selected',
              props.dotColor === 'light' && props.activeIndex === i
            )
          ])}
        />
      </Button>
    ))}
    <Button
      ariaLabel='Scroll to next item'
      onClick={() => props.navigateToIndex(props.activeIndex + 1)}
    >
      <ChevronRight useLightColor={props.dotColor === 'light'} />
    </Button>
  </div>
)
