import * as React from 'react'
import { keyBy } from 'lodash'
import { NavigationCategory } from '../navigation-category/navigation-category-ui'
import {
  NavCategoryField,
  NavCategoryModel,
  NavCategoryName
} from '@Cms/model-types/nav-category-model'
import { NAV_ORDER } from './constants'
import { getBlockClass } from '../component-helpers'
import { GeneralLink } from '../link/link-ui'
import { TopLevelRoutesByName } from '@Client/constants'
import { NavigationGamesStacked } from '../navigation-games/navigation-games-stacked-ui'
import { OutsideClickHandler } from '../outside-click-handler/outside-click-handler'
import { ControlledCollapsible } from '../collapsible/controlled-collapsible-ui'
import './styles.scss'

export type DesktopNavigationProps = {
  navCategories: NavCategoryModel[]
}

const ROOT_CLASS = 'desktop-navigation'

type NavCollapsibleState = Record<string, boolean>

const getInitialCollapsibleState = (
  navCategories: NavCategoryModel[]
): NavCollapsibleState => {
  const initialState: NavCollapsibleState = {
    [NavCategoryName.games]: false
  }
  navCategories.forEach((category) => {
    if (category[NavCategoryField.navItems].length) {
      initialState[category[NavCategoryField.name]] = false
    }
  })
  return initialState
}

export const DesktopNavigation = (props: DesktopNavigationProps) => {
  const [navCollapsibleState, setNavCollapsibleState] =
    React.useState<NavCollapsibleState>(
      getInitialCollapsibleState(props.navCategories)
    )

  const closeAllMenus = () => {
    setNavCollapsibleState(getInitialCollapsibleState(props.navCategories))
  }

  const toggleMenu = (categoryName: string, isOpen: boolean) => {
    const newState = getInitialCollapsibleState(props.navCategories)
    newState[categoryName] = isOpen
    setNavCollapsibleState(newState)
  }

  const categoriesByName: Record<string, NavCategoryModel> = keyBy(
    props.navCategories,
    NavCategoryField.name
  )

  return (
    <OutsideClickHandler onOutsideClick={closeAllMenus}>
      <nav className={ROOT_CLASS}>
        <ControlledCollapsible
          isOpen={navCollapsibleState[NavCategoryName.games]}
          setIsOpen={(isOpen) => toggleMenu(NavCategoryName.games, isOpen)}
          header={<span>{NavCategoryName.games}</span>}
          className={getBlockClass(ROOT_CLASS, 'category')}
          content={<NavigationGamesStacked onLinkClick={closeAllMenus} />}
        />
        {NAV_ORDER.filter(
          (categoryName) => categoryName !== NavCategoryName.games
        ).map((categoryName) => {
          if (
            categoriesByName[categoryName]?.[NavCategoryField.navItems].length
          ) {
            return (
              <ControlledCollapsible
                key={categoryName}
                isOpen={navCollapsibleState[categoryName]}
                setIsOpen={(isOpen) => toggleMenu(categoryName, isOpen)}
                header={
                  <span role='heading' aria-level={3}>
                    {categoryName}
                  </span>
                }
                className={getBlockClass(ROOT_CLASS, 'category')}
                content={
                  <NavigationCategory
                    name={categoryName}
                    navItems={
                      categoriesByName[categoryName]?.[
                        NavCategoryField.navItems
                      ] ?? []
                    }
                    closeMenus={closeAllMenus}
                  />
                }
              />
            )
          }
          return (
            <GeneralLink
              key={categoryName}
              dest={TopLevelRoutesByName[categoryName]}
              className={getBlockClass(ROOT_CLASS, 'standalone-link')}
              onClick={closeAllMenus}
            >
              {categoryName}
            </GeneralLink>
          )
        })}
      </nav>
    </OutsideClickHandler>
  )
}
