import { delay, fork, put, select } from 'redux-saga/effects'
import { cmsGameActions } from '@Client/reducers/cms-games/cms-games-reducer'
import { jackpotActions } from '@Client/reducers/jackpots/jackpots-reducer'
import { alertsActions } from '@Client/reducers/alerts/alerts-reducer'
import {
  JackpotsByGameId,
  SerializedJackpotsGroupsById
} from '@Client/reducers/jackpots/jackpot-reducer-types'
import { RootState } from '@Client/reducers'
import { getObjectKeys } from '@Util/object-utilities'
import { iLotteryActions } from '@Client/reducers/ilottery/ilottery-reducer'

function* fetchAllMarketingModals() {
  yield delay(3000)
  yield put(alertsActions.fetchAllMarketingModalsRequest())
}

function* fetchJackpotGroupsIfNotFetched() {
  const existingGroupsById: SerializedJackpotsGroupsById = yield select(
    (state: RootState) => state.jackpots.groupsById
  )
  const jackpotsByGameId: JackpotsByGameId = yield select(
    (state: RootState) => state.jackpots.jackpotsByGameId
  )
  if (!getObjectKeys(existingGroupsById).length) {
    yield put(jackpotActions.fetchJackpotGroupsRequest())
  }
  if (!getObjectKeys(jackpotsByGameId).length) {
    yield put(jackpotActions.fetchJackpotsRequest())
  }
}

export function* setupAppSaga() {
  yield put(cmsGameActions.fetchGamesRequest({}))
  yield fork(fetchAllMarketingModals)
  yield fetchJackpotGroupsIfNotFetched()
  yield put(iLotteryActions.requestProgressiveJackpots())
}
