import * as React from 'react'
import { InputLabel } from '@Components/input-label/input-label-ui'
import { getInputErrorId, InputError } from '../input-error/input-error-ui'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import './styles.scss'

export type InputTextProps = {
  placeholder?: string
  id: string
  labelText?: string
  onChange: (value: string) => any
  fieldClassName?: string
  ariaLabel: string
  defaultValue?: string
  error?: string
  noBorder?: true
}

const ROOT_CLASS = 'input-text'
const FIELD_CLASS = getBlockClass(ROOT_CLASS, 'field')

export const InputText = React.forwardRef(
  (props: InputTextProps, searchRef: React.Ref<HTMLInputElement>) => {
    return (
      <div className={ROOT_CLASS}>
        {props.labelText && (
          <InputLabel for={props.id}>{props.labelText}</InputLabel>
        )}
        <input
          aria-label={props.ariaLabel}
          aria-invalid={!!props.error}
          aria-errormessage={getInputErrorId(props.id)}
          type='text'
          id={props.id}
          onChange={(e) => props.onChange(e.target.value)}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          className={joinClasses([
            FIELD_CLASS,
            props.fieldClassName,
            getModifierClass(FIELD_CLASS, 'error', !!props.error),
            getModifierClass(FIELD_CLASS, 'no-border', props.noBorder === true)
          ])}
          ref={searchRef}
        />
        <InputError error={props.error} id={props.id} />
      </div>
    )
  }
)
