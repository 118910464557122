import * as React from 'react'
import {
  DrawGameDetailsPage,
  DrawGameDetailsPageLoadedProps,
  GameDetailsJackpotProps
} from './draw-game-details-page-ui'
import { useWinningNumbers } from '@Client/hooks/use-winning-numbers/use-winning-numbers'
import { useAppSelector } from '@Client/hooks/redux-hooks'
import { Optional } from '@Util/utility-types'
import { CurrentJackpotState } from '@Client/reducers/jackpots/jackpot-reducer-types'
import { useDispatch } from 'react-redux'
import { iLotteryActions } from '@Client/reducers/ilottery/ilottery-reducer'
import { CurrencyUtilities } from '@Util/currency-utilities'
import { useFetchServer } from '@Client/hooks/use-fetch/use-fetch'
import { GamePrizeTableResponseData } from '@Server/controllers/game/game-controller'
import { useRecurringAction } from '@Client/hooks/use-recurring-action/use-recurring-action'
import { jackpotActions } from '@Client/reducers/jackpots/jackpots-reducer'

const NINETY_SECONDS_MS = 90 * 1000

export type DrawGameDetailsPageContainerProps = Pick<
  DrawGameDetailsPageLoadedProps,
  'game'
>

export const DrawGameDetailsPageContainer = (
  props: DrawGameDetailsPageContainerProps
) => {
  const dispatch = useDispatch()
  const {
    isLoading: prizeTableLoading,
    error: prizeTableError,
    data: prizeTableData,
    fetchData: fetchPrizeTableData
  } = useFetchServer<GamePrizeTableResponseData>('/api/v1/game/prize-table', {
    identifier: props.game.identifier
  })

  const gameDataServicesId =
    props.game.configuration.dataServices.gameDataServiceId

  const winningNumbersProps = useWinningNumbers({
    drawGameServicesId: gameDataServicesId
  })
  const jackpotState = useAppSelector((state) => state.jackpots)
  const gameDrawResults = useAppSelector(
    (state) => state.drawResults.drawResultsByDataServiceId[gameDataServicesId]
  )
  const isILotteryEnabled = useAppSelector(
    (state) => state.iLottery.isFrameworkReady
  )
  const hasILottery = useAppSelector((state) =>
    Boolean(
      props.game.configuration.iLottery?.iLotteryId &&
        state.iLottery.drawGamesById[
          Number(props.game.configuration.iLottery.iLotteryId)
        ]
    )
  )

  const latestDrawResultTimestamp = gameDrawResults?.nextDrawingTimestamp
  const latestDrawResult = latestDrawResultTimestamp
    ? gameDrawResults?.drawResults[latestDrawResultTimestamp]
    : undefined

  const drawBreakStamp = latestDrawResult?.when.drawBreak
  const drawTimeStamp = latestDrawResult?.when.drawTime

  const drawBreak = drawBreakStamp ? new Date(drawBreakStamp) : new Date()
  const drawTime = drawTimeStamp ? new Date(drawTimeStamp) : new Date()

  const openGameWidget = () => {
    const iLotteryId = props.game.configuration.iLottery?.iLotteryId
    if (!iLotteryId) {
      return
    }
    dispatch(iLotteryActions.openDrawGameWidget(Number(iLotteryId)))
  }

  const openSubscriptionGameWidget = () => {
    const iLotteryId = props.game.configuration.iLottery?.iLotteryId
    if (!iLotteryId) {
      return
    }
    dispatch(iLotteryActions.openSubscriptionGameWidget(Number(iLotteryId)))
  }

  const currentJackpot: Optional<CurrentJackpotState> =
    jackpotState.jackpotsByGameId[gameDataServicesId]

  React.useEffect(() => {
    fetchPrizeTableData()
  }, [])

  useRecurringAction(() => {
    if (jackpotState?.isLoading) {
      return
    }
    dispatch(jackpotActions.fetchJackpotsRequest())
  }, NINETY_SECONDS_MS)

  if (currentJackpot?.type !== 'draw') {
    return (
      <DrawGameDetailsPage
        jackpot={{
          status: 'ACTIVE',
          estimatedJackpotDisplay: props.game.topPrizeDisplay ?? ''
        }}
        winningNumbersProps={winningNumbersProps}
        type='FIXED'
        isLoading={false}
        game={props.game}
        nextDrawing={{
          drawBreak,
          drawTime
        }}
        isILotteryEnabled={isILotteryEnabled}
        onBuyNowClick={openGameWidget}
        onBuySubscriptionClick={openSubscriptionGameWidget}
        isPrizeTableLoading={prizeTableLoading}
        prizeTableData={prizeTableData?.prizeTable}
        prizeTableError={prizeTableError}
        overallOdds={props.game.odds}
        hasILottery={hasILottery}
      />
    )
  }
  const { status: stateJackpotStatus } = currentJackpot.progressiveJackpot

  const jackpotStatus: 'ACTIVE' | 'PENDING' | 'LOADING' =
    stateJackpotStatus === 'ACTIVE' || stateJackpotStatus === 'PENDING'
      ? stateJackpotStatus
      : 'LOADING'

  const cashValueInCents =
    currentJackpot?.progressiveJackpot.jackpot?.jackpotCashAmountInCents

  const jackpot: GameDetailsJackpotProps = {
    status: jackpotStatus,
    estimatedJackpotDisplay: CurrencyUtilities.numericFormat(
      CurrencyUtilities.getDollarsFromCents(
        currentJackpot?.progressiveJackpot.jackpot?.jackpotAmountInCents ?? 0
      )
    ),
    jackpotCashValueDisplay: cashValueInCents
      ? CurrencyUtilities.numericFormat(
          CurrencyUtilities.getDollarsFromCents(cashValueInCents)
        )
      : undefined
  }

  return (
    <DrawGameDetailsPage
      type='PROGRESSIVE'
      isLoading={false}
      game={props.game}
      winningNumbersProps={winningNumbersProps}
      jackpot={jackpot}
      nextDrawing={{
        drawBreak,
        drawTime
      }}
      isILotteryEnabled={isILotteryEnabled}
      onBuyNowClick={openGameWidget}
      onBuySubscriptionClick={openSubscriptionGameWidget}
      isPrizeTableLoading={prizeTableLoading}
      prizeTableData={prizeTableData?.prizeTable}
      prizeTableError={prizeTableError}
      overallOdds={props.game.odds}
      hasILottery={hasILottery}
    />
  )
}
