import { keyBy } from 'lodash'
import { InstantGame } from '@interaction-gaming/game-data-service-browser-client'
import { serializeDates } from '@Util/object-utilities'

const getInstantGamesById = (instantGames: InstantGame[]) => {
  const instantGamesByDataServiceId = keyBy(instantGames, 'id')
  const serializedByDataServiceId = serializeDates(instantGamesByDataServiceId)
  return serializedByDataServiceId
}

export const InstantGamesReducerHelpers = {
  getInstantGamesById
}
