import * as React from 'react'
import { useFetchFunction } from './use-fetch-function'

export type UsePaginatedFetchProps<ResponseData> = {
  paginatedDataKey?: keyof ResponseData
}

export const usePaginatedFetch = <
  ResponseData extends Record<string, any> | any[],
  PaginatedData extends any[]
>(
  props: UsePaginatedFetchProps<ResponseData>
) => {
  const {
    data: latestFetchData,
    error,
    isLoading,
    setError,
    resetData: resetLatestData,
    fetchData
  } = useFetchFunction<ResponseData>()
  const [paginatedData, setPaginatedData] = React.useState<PaginatedData[]>()

  const fetchPaginatedData = async (
    fetcher: () => Promise<ResponseData>,
    reset?: boolean
  ) => {
    try {
      const response = await fetchData(fetcher)
      if (!response) {
        console.error('No response')
        return
      }
      const existingData = reset ? [] : paginatedData || []
      const newData = props.paginatedDataKey
        ? (response[props.paginatedDataKey] as PaginatedData[])
        : (response as PaginatedData[])
      setPaginatedData([...existingData, ...newData])
    } catch (error) {
      setError('Error fetching paginated data')
    }
  }

  const resetData = () => {
    setPaginatedData(undefined)
    resetLatestData()
  }

  return {
    isLoading,
    error,
    paginatedData,
    latestFetchData,
    resetData,
    setError,
    fetchPaginatedData
  }
}
