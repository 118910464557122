import * as React from 'react'
import { Outlet } from 'react-router-dom'
import { NavCategoryModel } from '@Cms/model-types/nav-category-model'
import { SkipToMainButton } from '@Components/skip-to-main-content-button/skip-to-main-button'
import { MenuBarContainer } from '../menu-bar/menu-bar-container'
import { ConstantContentModel } from '@Cms/model-types/constant-content-model'
import { CmsModel } from '@Cms/model-types/cms-models'
import { useScrollToTop } from '@Client/hooks/use-scroll-to-top/use-scroll-to-top'
import { useMaintainAuth } from '@Client/hooks/use-maintain-auth/use-maintain-auth'
import { MaintenancePage } from '../page-maintenance/page-maintenance-ui'
import { useILotteryDeepLink } from '@Client/hooks/use-ilottery-deeplinks/use-ilottery-deeplinks'
import { MarketingModalContainer } from '../marketing-modal/marketing-modal-container'
import { FooterContainer } from '../footer/footer-container'
import { ILotteryGameWindowContainer } from '../ilottery-game-window/ilottery-game-window-container'
import { EmailSubscribeContainer } from '../email-subscribe/email-subscribe-container'
import { useExposeGlobals } from '@Client/hooks/use-expose-globals/use-expose-globals'
import { useUpdateIsMobileState } from '@Client/hooks/use-media-query/use-media-query'
import { useILotteryProvider } from '@Client/hooks/use-ilottery-provider/use-ilottery-provider'
import { CmsPreviewContainer } from '../cms-preview/cms-preview-container'
import './styles.scss'

export type AppProps = {
  navigationCategories?: NavCategoryModel[]
  alertBannerConstantContent?: ConstantContentModel
  isMaintenanceMode?: boolean
}

export const App = (props: AppProps) => {
  useScrollToTop()
  useMaintainAuth()
  useILotteryDeepLink()
  useILotteryProvider()
  useExposeGlobals()
  useUpdateIsMobileState()

  if (props.isMaintenanceMode) {
    return <MaintenancePage />
  }

  return (
    <>
      <SkipToMainButton />
      {props.navigationCategories && (
        <MenuBarContainer
          navigationCategories={props.navigationCategories}
          alertBanner={
            props.alertBannerConstantContent?.isEnabled &&
            props.alertBannerConstantContent?.contentType ===
              CmsModel.alertBanner
              ? props.alertBannerConstantContent.content
              : undefined
          }
        />
      )}
      <Outlet />
      <ILotteryGameWindowContainer />
      <EmailSubscribeContainer />
      <FooterContainer />
      <MarketingModalContainer />
      <CmsPreviewContainer />
    </>
  )
}
