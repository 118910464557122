import * as React from 'react'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { UserAgentUtil } from '@Client/util/user-agent'
import { Button } from '../button/button'
import { CloseIcon } from '@Client/icons/close-icon'
import { useScrollLock } from '@Client/hooks/use-scroll-lock/use-scroll-lock'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { useKeyDown } from '@Client/hooks/use-keydown/use-keydown'
import { AccessibleKey } from '@Client/constants'
import { GeneralLink } from '../link/link-ui'
import { RoutePath } from '@Client/route-paths'
import { AsyncImage } from '../async-image/async-image-ui'
import logoSrc from '@Client/assets/nhlottery-logo.png'
import { NeoGamesGamePlayModeShort } from '@Client/services/pam/ng-types'
import './styles.scss'

export type ILotteryGameWindowProps = {
  isGameLoadRequested: boolean
  isGameLoaded: boolean
  playMode?: NeoGamesGamePlayModeShort
  gameBackgroundImageUrl?: string
  closeGame: () => void
  launchInMoneyMode: () => void
  depositFunds: () => void
}

const ILOTTERY_CONTAINER_ID = 'ng-game-container'
const ROOT_CLASS = 'ilottery-game-window'
const CTA_CLASS = getBlockClass(ROOT_CLASS, 'cta')
const HEADER_CLASS = getBlockClass(ROOT_CLASS, 'header')

export const ILotteryGameWindow = (props: ILotteryGameWindowProps) => {
  useScrollLock(props.isGameLoadRequested)
  useKeyDown({
    key: AccessibleKey.escape,
    action: () => {
      if (props.isGameLoadRequested) {
        props.closeGame()
      }
    }
  })

  React.useEffect(() => {
    const handlePopState = () => {
      props.closeGame()
    }
    if (props.isGameLoadRequested) {
      window.history.pushState({ iLotteryGameWindow: true }, '')
      window.addEventListener('popstate', handlePopState)
      return
    }
    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [props.isGameLoadRequested])

  return (
    <div
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, 'is-visible', props.isGameLoadRequested)
      ])}
      style={{ backgroundImage: `url(${props.gameBackgroundImageUrl})` }}
    >
      {props.isGameLoadRequested && (
        <header
          className={joinClasses([
            HEADER_CLASS,
            getModifierClass(
              HEADER_CLASS,
              'is-mobile-or-tablet',
              UserAgentUtil.isMobileOrTablet()
            )
          ])}
        >
          <GeneralLink
            className={getBlockClass(ROOT_CLASS, 'logo')}
            dest={RoutePath.home}
            onClick={props.closeGame}
          >
            <AsyncImage src={logoSrc} alt={'New Hampshire Lottery Logo'} />
          </GeneralLink>
          {props.playMode === 'M' ? (
            <Button
              ariaLabel='Deposit funds to wallet'
              onClick={props.depositFunds}
              className={CTA_CLASS}
            >
              Deposit
            </Button>
          ) : (
            <Button
              ariaLabel='Launch game in money mode'
              onClick={props.launchInMoneyMode}
              className={CTA_CLASS}
            >
              Switch to Money Mode
            </Button>
          )}
          <Button
            ariaLabel='Close Game'
            onClick={props.closeGame}
            className={getBlockClass(ROOT_CLASS, 'close-button')}
          >
            <CloseIcon />
          </Button>
        </header>
      )}
      <div id={ILOTTERY_CONTAINER_ID} className={ILOTTERY_CONTAINER_ID} />
      {props.isGameLoadRequested && !props.isGameLoaded && (
        <span className={getBlockClass(ROOT_CLASS, 'loading-container')}>
          <LoadingSpinner />
        </span>
      )}
    </div>
  )
}
