export enum CmsModel {
  accordion = 'accordion',
  alertBanner = 'alertBanner',
  bannerCarousel = 'bannerCarousel',
  columnLayout = 'columnLayout',
  compositeBanner = 'compositeBanner',
  constantContent = 'constantContent',
  csvTable = 'csvTable',
  cta = 'cta',
  feed = 'feed',
  feedItem = 'feedItem',
  filterTag = 'filterTag',
  filterCategory = 'filterCategory',
  imageBanner = 'imageBanner',
  game = 'game',
  gameCarousel = 'gameCarousel',
  gameCollection = 'gameCollection',
  gameConfigDataServices = 'gameConfigurationDataServices',
  gameConfigILottery = 'gameConfigurationILottery',
  gameJackpotGroup = 'gameJackpotGroup',
  gameLobby = 'gameLobby',
  gameRibbon = 'gameRibbon',
  gameOrder = 'gameOrder',
  gameSeries = 'gameSeries',
  link = 'link',
  marketingModal = 'marketingModal',
  migration = 'migration',
  navCategory = 'navCategory',
  navItem = 'navItem',
  page = 'page',
  responsiveImage = 'responsiveImage',
  sortOption = 'sortOption',
  text = 'text'
}
