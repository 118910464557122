import * as React from 'react'
import { GeneralLink } from '../link/link-ui'
import { DynamicUrl } from '@Client/route-paths'
import { AsyncImage, AsyncImageProps } from '../async-image/async-image-ui'
import { getBlockClass, joinClasses } from '../component-helpers'
import './styles.scss'

export type GameImageLinkProps = {
  gameIdentifier: string
  gameName: string
  image: Omit<AsyncImageProps, 'unsafeDevPlaceholderOverride' | 'alt'>
}

const ROOT_CLASS = 'game-image-link'

export const GameImageLink = (props: GameImageLinkProps) => (
  <GeneralLink
    dest={DynamicUrl.getGameByIdentifierUrl(props.gameIdentifier)}
    className={ROOT_CLASS}
  >
    <AsyncImage
      {...props.image}
      className={joinClasses([
        props.image.className,
        getBlockClass(ROOT_CLASS, 'image')
      ])}
      alt={`${props.gameName} game icon`}
    />
  </GeneralLink>
)
