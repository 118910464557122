import * as React from 'react'
import { PageModel } from '@Cms/model-types/page-model'
import { Page } from '../page/page-ui'
import { CmsGenericContent } from '../cms-generic-content/cms-generic-content-ui'
import './styles.scss'

export type CmsPageProps = {
  page: PageModel
}

const ROOT_CLASS = 'cms-page'

export const CmsPage = (props: CmsPageProps) => {
  return (
    <Page className={ROOT_CLASS}>
      {props.page.content.map((content) => (
        <CmsGenericContent
          rootClassName={ROOT_CLASS}
          content={content}
          key={JSON.stringify(content.data)}
        />
      ))}
    </Page>
  )
}
