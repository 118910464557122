import * as React from 'react'
import { InputText, InputTextProps } from '@Components/input-text/input-text'
import { SearchIcon } from '@Client/icons/search'
import { getBlockClass } from '../component-helpers'
import './styles.scss'

export type InputSearchProps = InputTextProps

const ROOT_CLASS = 'input-search'

export const InputSearch = React.forwardRef(
  (props: InputSearchProps, searchRef?: React.Ref<HTMLInputElement>) => {
    return (
      <div className={ROOT_CLASS}>
        <InputText
          ariaLabel={props.ariaLabel}
          labelText={props.labelText}
          id={props.id}
          placeholder={props.placeholder}
          onChange={props.onChange}
          fieldClassName={getBlockClass(ROOT_CLASS, 'search-field')}
          ref={searchRef}
          noBorder
        />
        <span className={getBlockClass(ROOT_CLASS, 'icon')}>
          <SearchIcon />
        </span>
      </div>
    )
  }
)
