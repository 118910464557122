import { EntryFieldTypes } from 'contentful'
import { CmsModel } from './cms-models'
import { AlertBannerModel, AlertBannerSkeleton } from './alert-banner-model'
import {
  BannerCarouselModel,
  BannerCarouselSkeleton
} from './banner-carousel-model'

export enum ConstantContentField {
  name = 'name',
  isEnabled = 'isEnabled',
  content = 'content'
}

export enum ConstantContentName {
  generalAlertBanner = 'General Alert Banner',
  newEInstantsCarousel = 'New E-Instants Carousel',
  homepageBannerCarousel = 'Homepage Banner Carousel'
}

export const ConstantContentFields = Object.values(ConstantContentField)
export const ConstantContentNames = Object.values(ConstantContentName)

export type ConstantContentLinkedSkeleton =
  | AlertBannerSkeleton
  | BannerCarouselSkeleton

export type ConstantContentSkeleton = {
  contentTypeId: CmsModel.constantContent
  fields: {
    [ConstantContentField.name]: EntryFieldTypes.Symbol<ConstantContentName>
    [ConstantContentField.isEnabled]: EntryFieldTypes.Boolean
    [ConstantContentField.content]: EntryFieldTypes.EntryLink<ConstantContentLinkedSkeleton>
  }
}

export type ConstantContentLinkedModel = AlertBannerModel | BannerCarouselModel

type ConstantContentTypedAlertBanner = {
  contentType: CmsModel.alertBanner
  content: AlertBannerModel
}

type ConstantContentTypedBannerCarousel = {
  contentType: CmsModel.bannerCarousel
  content: BannerCarouselModel
}

export type TypedConstantContent =
  | ConstantContentTypedAlertBanner
  | ConstantContentTypedBannerCarousel

export type ConstantContentEnabledModel = {
  name: ConstantContentName
  isEnabled: true
} & TypedConstantContent

export type ConstantContentModel =
  | ConstantContentEnabledModel
  | {
      name: ConstantContentName
      isEnabled: false
      contentType: null
      content: null
    }
