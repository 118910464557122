import * as React from 'react'
import { getBlockClass } from '@Client/components/component-helpers'
import './styles.scss'

const ROOT_CLASS = 'progressive-jackpots-icon'

export const ProgressiveJackpotsIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='_ÎÓÈ_2'
    data-name='—ÎÓÈ_2'
    viewBox='0 0 578.01 578.01'
  >
    <defs></defs>
    <circle
      className={getBlockClass(ROOT_CLASS, 'cls-4')}
      cx='289'
      cy='289'
      r='289'
    />
    <g>
      <g>
        <polyline
          className={getBlockClass(ROOT_CLASS, 'cls-1')}
          points='222.81 312.21 248.54 288.27 268.76 305.43 311.92 262.53 314.08 279.4'
        />
        <line
          className={getBlockClass(ROOT_CLASS, 'cls-1')}
          x1='311.92'
          y1='262.53'
          x2='294.23'
          y2='261.81'
        />
      </g>
      <text
        className={getBlockClass(ROOT_CLASS, 'cls-3')}
        transform='translate(197.43 370.43)'
      >
        <tspan x='0' y='0'>
          $
        </tspan>
      </text>
      <text
        className={getBlockClass(ROOT_CLASS, 'cls-2')}
        transform='translate(325.41 280.37)'
      >
        <tspan x='0' y='0'>
          $
        </tspan>
      </text>
      <g>
        <g>
          <path
            className={getBlockClass(ROOT_CLASS, 'cls-1')}
            d='M374.23,493.09h-172.37c-16.48,0-29.84-13.36-29.84-29.84V114.75c0-16.48,13.36-29.84,29.84-29.84h174.3c16.48,0,29.84,13.36,29.84,29.84v347.82'
          />
          <line
            className={getBlockClass(ROOT_CLASS, 'cls-1')}
            x1='172.02'
            y1='440.45'
            x2='405.99'
            y2='440.45'
          />
          <path
            className={getBlockClass(ROOT_CLASS, 'cls-1')}
            d='M221.39,84.91v21.88c0,11.65,9.44,21.09,21.09,21.09h93.05c11.65,0,21.09-9.44,21.09-21.09v-21.88'
          />
        </g>
        <path
          className={getBlockClass(ROOT_CLASS, 'cls-1')}
          d='M376.15,493.09c16.48,0,29.84-13.36,29.84-29.84'
        />
      </g>
    </g>
  </svg>
)
