import {
  SalesChannel,
  SortDirection,
  Winner,
  WinnerPage,
  WinnerSortField,
  WinnersApi,
  WinnerFilterValues
} from '@interaction-gaming/game-data-service-browser-client'
import { gameDataConfig } from './config'
import { Optional } from '@Util/utility-types'

let WINNERS_API: Optional<WinnersApi> = undefined

export type WinnersResponse = Winner[]
export type WinnersPageResponse = WinnerPage
export type WinnerSortBy = WinnerSortField

export type WinnersRequest = {
  resultStart: number
  resultEnd: number
  sortField?: WinnerSortField
  sortDirection?: SortDirection
  salesChannel?: SalesChannel
  city?: string
  startDate?: Date
  endDate?: Date
  gameIds?: string[]
  xClientID?: string
}

export type WinnersFilterRequest = {
  xClientID?: string
}

const configureWinnersApi = (): WinnersApi => {
  if (!WINNERS_API) {
    WINNERS_API = new WinnersApi(gameDataConfig)
  }
  if (!WINNERS_API) {
    throw new Error('Error configuring Winners API')
  }
  return WINNERS_API
}

const getWinners = async (
  request: WinnersRequest
): Promise<WinnersPageResponse> => {
  try {
    const api = configureWinnersApi()

    return await api.getWinners(request)
  } catch (e) {
    console.error('Error fetching winners', e)
    throw e
  }
}

const getFilters = async (
  request?: WinnersFilterRequest
): Promise<WinnerFilterValues> => {
  try {
    const api = configureWinnersApi()

    return await api.getWinnerFilterValues(request)
  } catch (e) {
    console.error('Error fetching winner Filters', e)
    throw e
  }
}

export const WinnerApi = {
  getWinners,
  getFilters
}
