import * as React from 'react'
import { InputLabel } from '@Components/input-label/input-label-ui'
import { getInputErrorId, InputError } from '../input-error/input-error-ui'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import './styles.scss'

export type InputNumberProps = {
  placeholder?: string
  id: string
  labelText?: string
  onChange: (value?: number) => any
  fieldClassName?: string
  ariaLabel: string
  defaultValue?: number
  error?: string
  min?: number
  max?: number
  htmlProps?: React.InputHTMLAttributes<HTMLInputElement>
}

const ROOT_CLASS = 'input-number'
const FIELD_CLASS = getBlockClass(ROOT_CLASS, 'field')

export const InputNumber = React.forwardRef(
  (props: InputNumberProps, ref: React.Ref<HTMLInputElement>) => {
    const extraProps = props.htmlProps ?? {}
    return (
      <div className={ROOT_CLASS}>
        {props.labelText && (
          <InputLabel for={props.id} error={props.error}>
            {props.labelText}
          </InputLabel>
        )}
        <input
          aria-label={props.ariaLabel}
          aria-invalid={!!props.error}
          aria-errormessage={getInputErrorId(props.id)}
          type='number'
          id={props.id}
          onChange={(e) => {
            if (e.target.value === '') {
              props.onChange(undefined)
              return
            }
            props.onChange(Number(e.target.value))
          }}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          inputMode='numeric'
          min={props.min}
          max={props.max}
          className={joinClasses([
            FIELD_CLASS,
            props.fieldClassName,
            getModifierClass(FIELD_CLASS, 'error', !!props.error)
          ])}
          ref={ref}
          {...extraProps}
        />
        <InputError error={props.error} id={props.id} />
      </div>
    )
  }
)
