import * as React from 'react'
import {
  getBlockClass,
  getCustomCssProperties,
  getModifierClass,
  joinClasses
} from '@Components/component-helpers'
import { ImagePlaceholder } from '@Client/icons/image-placeholder'
import './styles.scss'

export type AsyncImageProps = {
  src: string
  alt: string
  unsafeDevPlaceholderOverride?: true
  className?: string
  minHeightPx?: number
  minWidthPx?: number
}

const ROOT_CLASS = 'async-image'
const IMG_CLASS = getBlockClass(ROOT_CLASS, 'img')

export const AsyncImage = (props: AsyncImageProps) => {
  const ref = React.useRef<HTMLImageElement>(null)
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false)
  const notLoaded = !isLoaded || props.unsafeDevPlaceholderOverride === true

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (ref.current?.complete) {
        setIsLoaded(true)
        clearInterval(interval)
      }
    }, 500)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className={joinClasses([ROOT_CLASS, props.className])}>
      <img
        ref={ref}
        alt={props.alt}
        onLoad={() => setIsLoaded(true)}
        // esbuild outputs with leading '.' in the path, causing issues on non-root paths
        src={props.src.startsWith('.') ? props.src.replace('.', '') : props.src}
        className={joinClasses([
          IMG_CLASS,
          getModifierClass(IMG_CLASS, 'not-loaded', notLoaded)
        ])}
      />
      {notLoaded && (
        <div
          className={getBlockClass(ROOT_CLASS, 'loading-container')}
          style={
            props.minHeightPx || props.minWidthPx
              ? getCustomCssProperties({
                  '--min-height': props.minHeightPx
                    ? `${props.minHeightPx}px`
                    : '',
                  '--min-width': props.minWidthPx ? `${props.minWidthPx}px` : ''
                })
              : undefined
          }
        >
          <ImagePlaceholder />
        </div>
      )}
    </div>
  )
}
