import { configureStore } from '@reduxjs/toolkit'
import { RootReducer, RootState } from '.'
import { sagaMiddleware } from '@Client/sagas'
import { isServerRender } from '@Util/ssr-util'

export const setupStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: RootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sagaMiddleware),
    preloadedState
  })

export const store = setupStore(
  isServerRender() ? undefined : window.__PRELOADED_STATE__
)

export type AppDispatch = typeof store.dispatch
export type AppStore = ReturnType<typeof setupStore>
