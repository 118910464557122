import { ContentApi } from '@Client/api/content'
import { alertsActions } from '@Client/reducers/alerts/alerts-reducer'
import { AllMarketingModalsResponse } from '@Server/controllers/content/content-controller'
import { put, takeEvery } from 'redux-saga/effects'

export function* setupAlertsSaga() {
  yield takeEvery(
    alertsActions.fetchAllMarketingModalsRequest.type,
    fetchAllMarketingModals
  )
}

function* fetchAllMarketingModals() {
  try {
    const response: AllMarketingModalsResponse =
      yield ContentApi.fetchAllModals()
    if (response.status === 'success') {
      yield put(
        alertsActions.fetchAllMarketingModalsSuccess(response.data.byRoute)
      )
    } else {
      throw new Error('Something went wrong')
    }
  } catch (error) {
    yield put(
      alertsActions.fetchAllMarketingModalsFailure(
        (error as any).message ?? 'Something went wrong'
      )
    )
  }
}
