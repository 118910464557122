import {
  SortOptionField,
  SortOptionModel
} from '@Cms/model-types/sort-option-model'
import { Nullable } from '@Util/utility-types'
import { GameLobbyParamKey } from './url-helpers'

export const getSelectOptionsFromSortOptions = (
  sortOptions: SortOptionModel[]
) =>
  sortOptions.map((sortOption) => ({
    displayText: sortOption[SortOptionField.name],
    value: sortOption[SortOptionField.identifier]
  }))

export const getMatchingSort = (
  value: string,
  supportedSorts: SortOptionModel[]
) =>
  supportedSorts.find(
    (sortOption) => sortOption[SortOptionField.identifier] === value
  )

export const setMatchingSort = (
  value: string,
  supportedSorts: SortOptionModel[],
  onSortChange: (sortOption: SortOptionModel) => void
) => {
  const matchingSort = getMatchingSort(value, supportedSorts)
  matchingSort && onSortChange(matchingSort)
}

export const getInitialSort = (
  defaultSort: SortOptionModel,
  supportedGameSorts: SortOptionModel[],
  getParam: (paramKey: string) => Nullable<string>,
  stripParam: (paramKey: string) => void
) => {
  const urlSortIdentifier = getParam(GameLobbyParamKey.sort)
  if (
    !urlSortIdentifier ||
    urlSortIdentifier === defaultSort[SortOptionField.identifier]
  ) {
    return defaultSort
  }
  const matchingSort = getMatchingSort(urlSortIdentifier, supportedGameSorts)
  if (!matchingSort) {
    stripParam(GameLobbyParamKey.sort)
    return defaultSort
  }
  return matchingSort
}
