import * as React from 'react'
import { AuthButtons } from '../auth-buttons/auth-buttons-ui'
import { getBlockClass } from '../component-helpers'
import { Button } from '../button/button'
import './styles.scss'

type AuthenticatedAccountDepositWidgetProps = {
  isAuthenticated: true
  firstName: string
  accountBalanceDisplay: string
  onDepositClick: () => void
}

type UnauthenticatedAccountDepositWidgetProps = {
  isAuthenticated: false
}

export type AccountDepositWidgetProps =
  | AuthenticatedAccountDepositWidgetProps
  | UnauthenticatedAccountDepositWidgetProps

const ROOT_CLASS = 'account-deposit-widget'

export const AccountDepositWidget = (props: AccountDepositWidgetProps) => {
  return (
    <div className={ROOT_CLASS}>
      {props.isAuthenticated ? (
        <div className={getBlockClass(ROOT_CLASS, 'authenticated')}>
          <div className={getBlockClass(ROOT_CLASS, 'account-info')}>
            <span
              className={getBlockClass(ROOT_CLASS, 'name')}
            >{`Hi, ${props.firstName}`}</span>
            <span
              className={getBlockClass(ROOT_CLASS, 'balance')}
            >{`Balance: ${props.accountBalanceDisplay}`}</span>
          </div>
          <Button
            ariaLabel='Make an account deposit'
            onClick={props.onDepositClick}
            className={getBlockClass(ROOT_CLASS, 'deposit-button')}
          >
            Make a Deposit
          </Button>
        </div>
      ) : (
        <AuthButtons />
      )}
    </div>
  )
}
