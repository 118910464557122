import {
  PrizesRemaining,
  PrizesRemainingApi,
  PrizesRemainingWithLastUpdated
} from '@interaction-gaming/game-data-service-browser-client'
import { Optional } from '@Util/utility-types'
import { ApiConfig, gameDataConfig } from './config'

let PRIZES_REMAINING_API: Optional<PrizesRemainingApi> = undefined

export type PrizesRemainingList = PrizesRemaining[]
export type PrizesRemainingWithLastUpdatedResponse =
  PrizesRemainingWithLastUpdated

const configurePrizesRemainingApi = (): PrizesRemainingApi => {
  if (!PRIZES_REMAINING_API) {
    PRIZES_REMAINING_API = new PrizesRemainingApi(gameDataConfig)
  }
  if (!PRIZES_REMAINING_API) {
    throw new Error('Error configuring Prizes Remaining API')
  }
  return PRIZES_REMAINING_API
}

const getPrizesRemaining = async (
  instantGameId?: string,
  ticketCostInCents?: number,
  prizeAmountInDollars?: number
): Promise<PrizesRemainingWithLastUpdatedResponse> => {
  try {
    const api = configurePrizesRemainingApi()

    return await api.listPrizesRemaining({
      xClientID: ApiConfig.CLIENT_ID,
      instantGameId: instantGameId ? [instantGameId] : undefined,
      ticketCostInCents: ticketCostInCents ? [ticketCostInCents] : undefined,
      minPrizeAmountInDollars: prizeAmountInDollars,
      maxPrizeAmountInDollars: prizeAmountInDollars
    })
  } catch (e) {
    console.error('Error fetching prizes remaining', e)
    throw e
  }
}

export const PrizeApi = {
  getPrizesRemaining
}
