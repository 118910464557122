export enum ClientPlatform {
  ios = 'ios',
  android = 'android',
  web = 'web'
}

export type CmsRequestParams<T = any> = T & {
  cmsPreview: boolean
}

export type ErrorResponse = {
  status: 'error'
  message: string
}

export type DataResponse<T> = {
  status: 'success'
  data: T
}

export type ApiResponse<T> = ErrorResponse | DataResponse<T>

export const ClientPlatformValues = Object.values(ClientPlatform)

const isValidClientPlatform = (platform: string): platform is ClientPlatform =>
  ClientPlatformValues.includes(platform as ClientPlatform)

const getErrorBody = (message: string): ErrorResponse => ({
  status: 'error',
  message
})

export const getDataBody = <T>(data: T): DataResponse<T> => ({
  status: 'success',
  data
})

export const ControllerHelper = {
  isValidClientPlatform,
  getErrorBody,
  getDataBody
}
