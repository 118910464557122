import * as React from 'react'
import { Button } from '../button/button'
import { NeoGamesService } from '@Client/services/pam/ng-service'
import { useAppSelector } from '@Client/hooks/redux-hooks'
import './styles.scss'

const ROOT_CLASS = 'register-button'

export const RegisterButton = () => {
  const isFrameworkReady = useAppSelector(
    (state) => state.iLottery.isFrameworkReady
  )
  return (
    <Button
      ariaLabel='Register for account'
      onClick={NeoGamesService.showRegistration}
      className={ROOT_CLASS}
      disabled={!isFrameworkReady}
    >
      Register
    </Button>
  )
}
