import { put, takeEvery } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { Api } from '@Client/api'
import { cmsGameActions } from '@Client/reducers/cms-games/cms-games-reducer'
import { GamesQueryResponse } from '@Server/controllers/game/game-controller'

import { FetchGamePayload } from '@Client/reducers/cms-games/cms-game-reducer-types'
import { CmsGamesApi } from '@Client/api/cms-games'
import { operationsActions } from '@Client/reducers/operations/operations-reducer'

export function* setupCmsGamesSaga() {
  yield takeEvery(cmsGameActions.fetchGamesRequest.type, fetchCmsGames)
  yield takeEvery(
    operationsActions.setCmsPreviewActive,
    function* refetchCmsGames() {
      yield put(cmsGameActions.fetchGamesRequest({}))
    }
  )
}

function* fetchCmsGames(action: PayloadAction<FetchGamePayload>) {
  try {
    const gameType = action.payload.type
    const response: GamesQueryResponse = gameType
      ? yield CmsGamesApi.fetchGamesByType(gameType)
      : yield CmsGamesApi.fetchAllGames()
    if (response.status === 'success') {
      yield put(cmsGameActions.fetchGamesSuccess(response.data.games))
    } else {
      throw new Error(response.message ?? Api.DEFAULT_ERROR)
    }
  } catch (error) {
    yield put(
      cmsGameActions.fetchGamesFailure(
        (error as Error)?.message ?? Api.DEFAULT_ERROR
      )
    )
  }
}
