import * as React from 'react'
import { getModifierClass, joinClasses } from '../component-helpers'
import './styles.scss'

export type InputLabelProps = {
  for: string
  error?: string
}

const ROOT_CLASS = 'input-label'

export const getInputLabelId = (id: string) => `${id}-label`

export const InputLabel = (props: React.PropsWithChildren<InputLabelProps>) => (
  <label
    htmlFor={props.for}
    id={getInputLabelId(props.for)}
    className={joinClasses([
      ROOT_CLASS,
      getModifierClass(ROOT_CLASS, 'error', !!props.error)
    ])}
  >
    {props.children}
  </label>
)
