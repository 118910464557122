import * as React from 'react'
import { PrizesRemainingWithLastUpdatedResponse } from '@Client/api/prizes-remaining'
import { PrizesRemainingContainer } from '../prizes-remaining/prizes-remaining-container'
import { Page } from '../page/page-ui'
import './styles.scss'

export type PrizesRemainingPageProps = {
  prizesRemaining: PrizesRemainingWithLastUpdatedResponse
}

const ROOT_CLASS = 'prizes-remaining-page'

export const PrizesRemainingPage = () => {
  return (
    <Page title='Prizes Remaining' className={ROOT_CLASS}>
      <PrizesRemainingContainer />
    </Page>
  )
}
