import * as React from 'react'

export const ChevronDown = () => (
  <svg
    aria-hidden='true'
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='none'
  >
    <path
      d='M16.66 6.49091C16.4842 6.49507 16.3171 6.56854 16.1952 6.69534L9.99989 12.8906L3.80458 6.69534C3.74245 6.63147 3.66816 6.58071 3.58608 6.54604C3.504 6.51138 3.41581 6.49352 3.32671 6.49351C3.19409 6.49355 3.06449 6.53313 2.95449 6.60721C2.84448 6.68128 2.75907 6.78648 2.70916 6.90936C2.65926 7.03223 2.64713 7.1672 2.67433 7.297C2.70154 7.4268 2.76683 7.54555 2.86187 7.63804L9.52854 14.3047C9.65357 14.4297 9.82311 14.4999 9.99989 14.4999C10.1767 14.4999 10.3462 14.4297 10.4712 14.3047L17.1379 7.63804C17.2347 7.54494 17.3012 7.42477 17.3285 7.29327C17.3559 7.16176 17.3429 7.02506 17.2912 6.90107C17.2396 6.77708 17.1517 6.67158 17.0391 6.59839C16.9264 6.5252 16.7943 6.48774 16.66 6.49091Z'
      fill='#2B3037'
    />
  </svg>
)
