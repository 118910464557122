import * as React from 'react'
import { getBlockClass } from '../component-helpers'
import { GeneralLink } from '../link/link-ui'
import { MapPinSmall } from '@Client/icons/map-pin-small'
import { formatPhoneNumber } from '@Util/string-utilities'
import { getMapsUrl, RetailerInfoWindowProps } from './constants'
import './styles.scss'

const ROOT_CLASS = 'retailer-info-window'

export const RetailerInfoWindow = (props: RetailerInfoWindowProps) => {
  const fullAddress = `${props.address1}${props.address2 ? ` ${props.address2}` : ''}, ${props.city}${props.region ? `, ${props.region}` : ''} ${props.postalCode ?? ''}`
  return (
    <div className={ROOT_CLASS}>
      <span className={getBlockClass(ROOT_CLASS, 'name')}>{props.name}</span>
      <span className={getBlockClass(ROOT_CLASS, 'address')}>
        {fullAddress}
      </span>
      {props.phoneNumber && (
        <span className={getBlockClass(ROOT_CLASS, 'phone')}>
          {formatPhoneNumber(props.phoneNumber)}
        </span>
      )}
      <GeneralLink
        className={getBlockClass(ROOT_CLASS, 'directions-link')}
        dest={getMapsUrl(fullAddress)}
      >
        <MapPinSmall />
        Get Directions
      </GeneralLink>
    </div>
  )
}
