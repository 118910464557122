import * as React from 'react'
import { useLocation } from 'react-router-dom'

export const useScrollToTop = () => {
  const { pathname } = useLocation()
  React.useEffect(() => {
    try {
      window?.scrollTo(0, 0)
    } catch (e) {
      console.error('Error scrolling to top:', e)
    }
  }, [pathname])
}
