import * as React from 'react'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { GeneralLink } from '../link/link-ui'
import './styles.scss'

type LinkPreview = {
  displayText: string
  textColor: 'white' | 'black'
  icon: React.ReactNode
  iconBackgroundColor: 'red' | 'black' | 'white'
  iconShadow: boolean
  path: string
}

export type LinkGroupShowcaseProps = {
  titleDisplayText: string
  titlePath: string
  links: LinkPreview[]
  titleColor: 'white' | 'black'
  background: 'white' | 'blue' | 'yellow'
  onLinkClick: () => void
}

const ROOT_CLASS = 'link-group-showcase'
const TITLE_DISPLAY_CLASS = getBlockClass(ROOT_CLASS, 'title-display')
const LINK_DISPLAY_CLASS = getBlockClass(ROOT_CLASS, 'link-display')
const LINK_ICON_CLASS = getBlockClass(ROOT_CLASS, 'link-icon')

export const LinkGroupShowcase = (props: LinkGroupShowcaseProps) => {
  return (
    <div
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, props.background, true)
      ])}
    >
      <GeneralLink
        dest={props.titlePath}
        className={getBlockClass(ROOT_CLASS, 'title-link')}
        onClick={props.onLinkClick}
      >
        <span
          className={joinClasses([
            TITLE_DISPLAY_CLASS,
            getModifierClass(TITLE_DISPLAY_CLASS, props.titleColor, true)
          ])}
        >
          {props.titleDisplayText}
        </span>
      </GeneralLink>
      <div className={getBlockClass(ROOT_CLASS, 'links')}>
        {props.links.map((link) => (
          <GeneralLink
            className={getBlockClass(ROOT_CLASS, 'link-group')}
            key={link.displayText}
            dest={link.path}
            onClick={props.onLinkClick}
          >
            <div
              className={joinClasses([
                LINK_ICON_CLASS,
                getModifierClass(LINK_ICON_CLASS, 'shadow', link.iconShadow),
                getModifierClass(
                  LINK_ICON_CLASS,
                  link.iconBackgroundColor,
                  true
                )
              ])}
              aria-label={`${link.displayText} link icon`}
            >
              {link.icon}
            </div>
            <span
              className={joinClasses([
                LINK_DISPLAY_CLASS,
                getModifierClass(LINK_DISPLAY_CLASS, link.textColor, true)
              ])}
            >
              {link.displayText}
            </span>
          </GeneralLink>
        ))}
      </div>
    </div>
  )
}
