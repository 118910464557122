import * as React from 'react'

export const SortingArrowheads = () => (
  <svg
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>Toggle Sort Direction</title>
    <g id='icon/Sorting arrowheads'>
      <path
        id='Vector'
        d='M10 2.5C9.82955 2.5 9.65903 2.56498 9.52869 2.69531L4.19536 8.02865C4.00469 8.21931 3.94749 8.50588 4.05083 8.75521C4.15349 9.00454 4.39738 9.16667 4.66671 9.16667H15.3334C15.6027 9.16667 15.8466 9.00454 15.9493 8.75521C16.0526 8.50588 15.9954 8.21931 15.8047 8.02865L10.4714 2.69531C10.3411 2.56498 10.1705 2.5 10 2.5ZM4.66671 11.8333C4.39738 11.8333 4.15349 11.9955 4.05083 12.2448C3.94749 12.4941 4.00469 12.7807 4.19536 12.9714L9.52869 18.3047C9.65869 18.4347 9.82938 18.5 10 18.5C10.1707 18.5 10.3414 18.4347 10.4714 18.3047L15.8047 12.9714C15.9954 12.7807 16.0526 12.4941 15.9493 12.2448C15.8466 11.9955 15.6027 11.8333 15.3334 11.8333H4.66671Z'
        fill='white'
      />
    </g>
  </svg>
)
