import { useSearchParams } from 'react-router-dom'
import { Nullable } from '@Util/utility-types'

export const useDeepLink = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const updateUrl = (searchParams: URLSearchParams) => {
    setSearchParams(searchParams, { replace: true })
  }

  const getParam = (paramKey: string): Nullable<string> => {
    const param = searchParams.get(paramKey)
    return param ? decodeURI(param) : param
  }

  const stripParam = (paramKey: string) => {
    searchParams.delete(paramKey)
    updateUrl(searchParams)
  }

  const updateParam = (paramKey: string, paramVal: string) => {
    if (paramVal) {
      searchParams.set(paramKey, paramVal)
    } else {
      searchParams.delete(paramKey)
    }
    updateUrl(searchParams)
  }

  return {
    getParam,
    stripParam,
    updateParam
  }
}
