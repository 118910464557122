import * as React from 'react'
import {
  getMultiSelectOptionsFromValues,
  InputMultiSelect,
  InputMultiSelectOption
} from '../input-multi-select/input-multi-select-ui'
import { DateRangeSelector } from '../date-range-selector/date-range-selector-ui'
import { Button } from '../button/button'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import {
  SortByDirection,
  WinnersSortBy,
  WinnersSortByDirection
} from '../winners-table/winners-table-header'
import { parseFieldFromUrl, validateField, WinnersFormField } from './helpers'
import { useFormData } from '@Client/hooks/use-form-data/use-form-data'
import { Optional } from '@Util/utility-types'
import { DateFormat, dateUtil } from '@Util/date-util'
import { getObjectKeys } from '@Util/object-utilities'
import { insecureStringToHash } from '@Util/string-utilities'
import './styles.scss'

export type WinnersFormProps = {
  gameOptions: InputMultiSelectOption[]
  totalResults: number
  payload: WinnersFormPayload
  onWinnersFormSubmit: (payload: WinnersFormPayload) => void
}

export type WinnersFormPayload = {
  selectedGames?: string[]
  startDate?: string
  endDate?: string
  sortByDirection?: WinnersSortByDirection
  sortBy?: WinnersSortBy
  page?: number
}

export type WinnersFormFieldKey = keyof WinnersFormPayload

const ROOT_CLASS = 'winners-form'
const REMOVE_FILTERS_CLASS = getBlockClass(ROOT_CLASS, 'remove-filters')

export const WinnersForm = (props: WinnersFormProps) => {
  const [startDate, setStartDate] = React.useState<Optional<Date>>(
    props.payload.startDate
      ? dateUtil(props.payload.startDate).toDate()
      : undefined
  )
  const [endDate, setEndDate] = React.useState<Optional<Date>>(
    props.payload.endDate ? dateUtil(props.payload.endDate).toDate() : undefined
  )
  const [selectedGames, setSelectedGames] = React.useState<
    Optional<InputMultiSelectOption[]>
  >(
    props.payload.selectedGames
      ? props.payload.selectedGames.map((game) => ({
          label: game,
          value: game
        }))
      : undefined
  )

  const [lastSubmissionHash, setLastSubmissionHash] =
    React.useState<Optional<number>>()

  const { formData, updateField, clearForm } = useFormData<WinnersFormPayload>({
    useDeepLinks: true,
    fieldIds: Object.values(WinnersFormField),
    parseFieldFromUrl: parseFieldFromUrl,
    validateField: validateField
  })

  const onDateChange = (startDate?: Date, endDate?: Date) => {
    if (!startDate && !endDate) {
      setStartDate(undefined)
      setEndDate(undefined)
      updateField(WinnersFormField.startDate, undefined)
      updateField(WinnersFormField.endDate, undefined)
    }
    if (startDate) {
      setStartDate(startDate)
      updateField(
        WinnersFormField.startDate,
        dateUtil(startDate).format(DateFormat.yearMonthDay)
      )
    }
    if (endDate) {
      setEndDate(endDate)
      updateField(
        WinnersFormField.endDate,
        dateUtil(endDate).format(DateFormat.yearMonthDay)
      )
    }
  }

  const onGameChange = (selectedGames: readonly InputMultiSelectOption[]) => {
    setSelectedGames(
      selectedGames.length === 0 ? undefined : [...selectedGames]
    )
    updateField(
      WinnersFormField.selectedGames,
      selectedGames.length === 0
        ? undefined
        : selectedGames.map((option) => option.value)
    )
  }

  const removeFilters = () => {
    setSelectedGames(undefined)
    setStartDate(undefined)
    setEndDate(undefined)
    props.onWinnersFormSubmit({
      selectedGames: undefined,
      startDate: undefined,
      endDate: undefined,
      sortByDirection: SortByDirection.descending,
      sortBy: WinnersSortBy.date,
      page: 1
    })
    clearForm()
  }

  const handleFormSubmit = (formEvent?: React.FormEvent<HTMLFormElement>) => {
    formEvent?.preventDefault()
    formEvent?.stopPropagation()
    props.onWinnersFormSubmit(formData)
    const currentFormHash = insecureStringToHash(JSON.stringify(formData))
    setLastSubmissionHash(currentFormHash)
  }

  // Lifecycle for empty form reset
  React.useEffect(() => {
    const formIsEmpty =
      !getObjectKeys(formData).length ||
      Object.values(formData).every(
        (value) => !value || (Array.isArray(value) && !value.length)
      )
    const currentFormHash = insecureStringToHash(JSON.stringify(formData))
    if (
      formIsEmpty &&
      lastSubmissionHash &&
      lastSubmissionHash !== currentFormHash
    ) {
      handleFormSubmit()
    }
  }, [formData, lastSubmissionHash])

  //Init
  React.useEffect(() => {
    updateField(WinnersFormField.selectedGames, props.payload.selectedGames)
  }, [])

  const hasFilters =
    (formData[WinnersFormField.selectedGames] &&
      formData[WinnersFormField.selectedGames].length > 0) ||
    (startDate && endDate)

  return (
    <div className={ROOT_CLASS}>
      <span className={getBlockClass(ROOT_CLASS, 'filter-list-title')}>
        Filter list by
      </span>
      <div className={getBlockClass(ROOT_CLASS, 'container')}>
        <InputMultiSelect
          className={getBlockClass(ROOT_CLASS, 'game-multi-select')}
          id={'winners-game-selector'}
          ariaLabel={'Game Selector'}
          label='Game'
          placeholder='Begin typing...'
          options={props.gameOptions}
          values={getMultiSelectOptionsFromValues(
            props.gameOptions,
            selectedGames?.map((option) => option.value)
          )}
          onChange={(selectedGames) => {
            onGameChange(selectedGames)
          }}
        />
        <DateRangeSelector
          className={getBlockClass(ROOT_CLASS, 'date-range-selector')}
          label={'Draw Range'}
          isDateDisabled={(date) => dateUtil().isBefore(date)}
          inputName={'date-range-selector'}
          startDate={startDate}
          endDate={endDate}
          onDateChange={(startDate, endDate) =>
            onDateChange(startDate, endDate)
          }
        />
        <Button
          className={getBlockClass(ROOT_CLASS, 'filter-button')}
          ariaLabel='Filter Button'
          onClick={() =>
            props.onWinnersFormSubmit({
              selectedGames: selectedGames?.map((option) => option.value),
              startDate: startDate
                ? dateUtil(startDate).format(DateFormat.yearMonthDay)
                : undefined,
              endDate: startDate
                ? dateUtil(endDate).format(DateFormat.yearMonthDay)
                : undefined
            })
          }
        >
          Filter
        </Button>
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'lower-container')}>
        <span className={getBlockClass(ROOT_CLASS, 'total-results')}>
          {props.totalResults !== -1
            ? `Showing ${props.totalResults} results`
            : ''}
        </span>
        <Button
          className={joinClasses([
            REMOVE_FILTERS_CLASS,
            getModifierClass(REMOVE_FILTERS_CLASS, 'disabled', !hasFilters)
          ])}
          ariaLabel='Remove Filters Button'
          onClick={removeFilters}
          disabled={!hasFilters}
        >
          Remove Filters
        </Button>
      </div>
    </div>
  )
}
