import { EntryFieldTypes, EntryLink } from 'contentful'
import { CmsModel } from './cms-models'
import { PageModel, PageSkeleton } from './page-model'
import { MarketingModalSkeleton } from './marketing-modal-model'

export enum LinkField {
  name = 'name',
  page = 'page',
  route = 'route',
  modal = 'modal'
}

export type LinkSkeleton = {
  contentTypeId: CmsModel.link
  fields: {
    [LinkField.name]: EntryFieldTypes.Symbol
    [LinkField.page]?: EntryFieldTypes.EntryLink<PageSkeleton>
    [LinkField.route]: EntryFieldTypes.Symbol
    [LinkField.modal]?: EntryFieldTypes.EntryLink<MarketingModalSkeleton>
  }
}

export type LinkModelPublish = {
  name: string
  route: string
  page?: {
    sys: EntryLink
  }
  modal?: {
    sys: EntryLink
  }
}

export type LinkModel = {
  route: string
  isAbsolute: boolean
  page?: PageModel
}

export const LinkFields = Object.values(LinkField)
