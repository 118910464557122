import * as React from 'react'
import { EmailSubscribe } from './email-subscribe-ui'
import { useFetchServer } from '@Client/hooks/use-fetch/use-fetch'
import { SalesforceSubscriber } from '@Server/manager/salesforce-manager/salesforce-manager'
import { SalesforceCreateSubscriberResponse } from '@Server/controllers/salesforce/salesforce-controller'

export const EmailSubscribeContainer = () => {
  const { isLoading, error, data, fetchData, resetData } =
    useFetchServer<SalesforceCreateSubscriberResponse>(
      'api/v1/salesforce/create-subscriber',
      undefined,
      'POST'
    )

  const onSubmit = async (subscriber: SalesforceSubscriber) => {
    await fetchData(
      undefined,
      { 'Content-Type': 'application/json' },
      JSON.stringify(subscriber)
    )
  }

  return (
    <EmailSubscribe
      isLoading={isLoading}
      onSubmit={onSubmit}
      error={error}
      clearSubmissionSuccess={resetData}
      submissionSuccess={Boolean(
        data && 'subscriberCreated' in data && data.subscriberCreated
      )}
    />
  )
}
