import * as React from 'react'
import { Api } from '@Client/api'
import { joinClasses } from '../component-helpers'
import './styles.scss'

export type ErrorMessageProps = {
  message?: string
  className?: string
}

export const ErrorMessage = (props: ErrorMessageProps) => (
  <span className={joinClasses(['error-message', props.className])}>
    {props.message ?? Api.DEFAULT_ERROR}
  </span>
)
