import * as React from 'react'
import { getBlockClass } from '../component-helpers'
import {
  getMultiSelectOptionsFromValues,
  InputMultiSelect,
  InputMultiSelectOption
} from '../input-multi-select/input-multi-select-ui'
import { FilterButton } from '../button-filter/filter-button'
import { Button } from '../button/button'
import { useFormData } from '@Client/hooks/use-form-data/use-form-data'
import { useIsDesktop } from '@Client/hooks/use-media-query/use-media-query'
import { getObjectKeys } from '@Util/object-utilities'
import { insecureStringToHash } from '@Util/string-utilities'
import { Optional } from '@Util/utility-types'
import {
  parseFieldFromUrl,
  PrizesRemainingFormData,
  PrizesRemainingFormField,
  validateUrlListValue
} from './constants'
import './styles.scss'

export type PrizesRemainingFormProps = {
  gameOptions: InputMultiSelectOption[]
  ticketCostOptions: InputMultiSelectOption[]
  prizeAmountOptions: InputMultiSelectOption[]
  onFormSubmit: (payload: PrizesRemainingFormData) => Promise<void>
  totalResults: number
  dataIsReady: boolean
}

const ROOT_CLASS = 'prizes-remaining-form'

export const PrizesRemainingForm = (props: PrizesRemainingFormProps) => {
  const [lastSubmissionHash, setLastSubmissionHash] =
    React.useState<Optional<number>>()
  const isDesktop = useIsDesktop()
  const { formData, updateField, clearForm } =
    useFormData<PrizesRemainingFormData>({
      useDeepLinks: true,
      fieldIds: Object.values(PrizesRemainingFormField),
      parseFieldFromUrl: parseFieldFromUrl,
      validateField: (formData, fieldId, fieldValue) => {
        switch (fieldId) {
          case PrizesRemainingFormField.prizeAmounts:
            return validateUrlListValue(props.prizeAmountOptions, fieldValue)
          case PrizesRemainingFormField.games:
            return validateUrlListValue(props.gameOptions, fieldValue)
          case PrizesRemainingFormField.ticketCosts:
            return validateUrlListValue(props.ticketCostOptions, fieldValue)
          case PrizesRemainingFormField.topPrizeOnly: {
            const stringValue = String(fieldValue)
            if (stringValue !== 'true' && stringValue !== 'false') {
              return 'Invalid value'
            }
            break
          }
          default:
            break
        }
      }
    })

  const handleFormSubmit = (formEvent?: React.FormEvent<HTMLFormElement>) => {
    formEvent?.preventDefault()
    formEvent?.stopPropagation()
    props.onFormSubmit(formData)
    const currentFormHash = insecureStringToHash(JSON.stringify(formData))
    setLastSubmissionHash(currentFormHash)
  }

  // Lifecycle for empty form reset
  React.useEffect(() => {
    const formIsEmpty =
      !getObjectKeys(formData).length ||
      Object.values(formData).every(
        (value) => !value || (Array.isArray(value) && !value.length)
      )
    const currentFormHash = insecureStringToHash(JSON.stringify(formData))
    if (
      formIsEmpty &&
      lastSubmissionHash &&
      lastSubmissionHash !== currentFormHash
    ) {
      handleFormSubmit()
    }
  }, [formData, lastSubmissionHash])

  // Initial submit
  React.useEffect(() => {
    if (props.dataIsReady && !lastSubmissionHash) {
      props.onFormSubmit(formData)
      handleFormSubmit()
    }
  }, [props.dataIsReady, formData, lastSubmissionHash, setLastSubmissionHash])

  const getFormDataLength = (field: PrizesRemainingFormField) => {
    const fieldData = formData[field]
    return fieldData && Array.isArray(fieldData) ? fieldData.length : 0
  }

  const activeFilters =
    getFormDataLength(PrizesRemainingFormField.games) +
    getFormDataLength(PrizesRemainingFormField.prizeAmounts) +
    getFormDataLength(PrizesRemainingFormField.ticketCosts) +
    (formData[PrizesRemainingFormField.topPrizeOnly] ? 1 : 0)

  const topPrizeOnlyButton = (
    <FilterButton
      isActive={formData[PrizesRemainingFormField.topPrizeOnly] ?? false}
      identifier='top-prize-filter'
      categoryIdentifier='top-prize-category'
      ariaLabel='Sort results by top prize'
      onToggle={() => {
        updateField(
          PrizesRemainingFormField.topPrizeOnly,
          !formData[PrizesRemainingFormField.topPrizeOnly]
        )
      }}
    >
      Top Prize Available
    </FilterButton>
  )

  return (
    <>
      <form className={ROOT_CLASS} onSubmit={handleFormSubmit}>
        <span className={getBlockClass(ROOT_CLASS, 'filter-list-title')}>
          Filter list by
        </span>
        {!isDesktop && topPrizeOnlyButton}
        <div className={getBlockClass(ROOT_CLASS, 'desktop-top-row')}>
          <InputMultiSelect
            id={PrizesRemainingFormField.games}
            label='Game'
            ariaLabel='Game Selector'
            placeholder='Begin typing...'
            options={props.gameOptions}
            values={getMultiSelectOptionsFromValues(
              props.gameOptions,
              formData[PrizesRemainingFormField.games]
            )}
            onChange={(selectedOptions) => {
              updateField(
                PrizesRemainingFormField.games,
                selectedOptions.map((option) => option.value)
              )
            }}
          />
          <InputMultiSelect
            id={PrizesRemainingFormField.ticketCosts}
            label='Ticket Cost'
            ariaLabel='Ticket Cost Selector'
            placeholder='Select options...'
            options={props.ticketCostOptions}
            values={getMultiSelectOptionsFromValues(
              props.ticketCostOptions,
              formData[PrizesRemainingFormField.ticketCosts]
            )}
            onChange={(selectedOptions) => {
              updateField(
                PrizesRemainingFormField.ticketCosts,
                selectedOptions.map((option) => option.value)
              )
            }}
          />
        </div>
        <div className={getBlockClass(ROOT_CLASS, 'desktop-bottom-row')}>
          <InputMultiSelect
            id={PrizesRemainingFormField.prizeAmounts}
            label='Prize Amount'
            ariaLabel='Prize Amount Selector'
            placeholder='Select options...'
            options={props.prizeAmountOptions}
            values={getMultiSelectOptionsFromValues(
              props.prizeAmountOptions,
              formData[PrizesRemainingFormField.prizeAmounts]
            )}
            onChange={(selectedOptions) => {
              updateField(
                PrizesRemainingFormField.prizeAmounts,
                selectedOptions.map((option) => option.value)
              )
            }}
          />
          {isDesktop && topPrizeOnlyButton}
          <Button
            type='submit'
            ariaLabel='Filter Prizes Remaining'
            className={getBlockClass(ROOT_CLASS, 'filter-submit-button')}
          >
            Filter
          </Button>
        </div>
      </form>
      <div className={getBlockClass(ROOT_CLASS, 'meta-container')}>
        <p>{`Showing ${props.totalResults} results`}</p>
        <Button
          ariaLabel='Remove Filters'
          className={getBlockClass(ROOT_CLASS, 'filter-remove-button')}
          disabled={activeFilters === 0}
          onClick={clearForm}
        >
          {activeFilters === 0
            ? 'Remove Filters'
            : `Remove Filters (${activeFilters})`}
        </Button>
      </div>
    </>
  )
}
