import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Footer } from './footer-ui'
import { iLotteryActions } from '@Client/reducers/ilottery/ilottery-reducer'

export const FooterContainer = () => {
  const dispatch = useDispatch()

  const onILotteryHelpClick = () => {
    dispatch(iLotteryActions.showContactUs())
  }

  return <Footer onILotteryHelpClick={onILotteryHelpClick} />
}
