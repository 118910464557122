import { AssetLink, EntryFieldTypes, EntryLink } from 'contentful'
import { CmsModel } from './cms-models'
import { FilterTagModel, FilterTagSkeleton } from './filter-tag-model'
import {
  GameConfigDataServiceModel,
  GameConfigDataServiceSkeleton
} from './game-configuration-data-service-model'
import {
  GameConfigILotteryModel,
  GameConfigILotterySkeleton
} from './game-configuration-ilottery-model'
import { AccordionModel, AccordionSkeleton } from './accordion-model'
import { GameSeriesModel, GameSeriesSkeleton } from './game-series-model'
import { GameRibbonModel, GameRibbonSkeleton } from './game-ribbon-model'
import { GameJackpotGroupSkeleton } from './game-jackpot-group-model'
import { CsvTableModel, CsvTableSkeleton } from './csv-table-model'

export enum GameField {
  expirationDate = 'expirationDate',
  icon = 'icon',
  previewIcon = 'previewIcon',
  identifier = 'identifier',
  filterTags = 'filterTags',
  name = 'name',
  price = 'price',
  priceList = 'priceList',
  topPrizeDisplay = 'topPrizeDisplay',
  odds = 'odds',
  prizeTable = 'prizeTable',
  startDate = 'startDate',
  type = 'type',
  rules = 'rules',
  ticketsOrdered = 'ticketsOrdered',
  gameConfigurationDataServices = 'gameConfigurationDataServices',
  gameConfigurationILottery = 'gameConfigurationILottery',
  ribbon = 'ribbon',
  series = 'series',
  jackpotGroup = 'jackpotGroup',
  isQaEnabled = 'isQaEnabled',
  isUatEnabled = 'isUatEnabled',
  isProdEnabled = 'isProdEnabled'
}

export enum GameType {
  online = 'online',
  draw = 'draw',
  scratch = 'scratch',
  rapid = 'rapid',
  fastPlay = 'fast-play'
}

export type GameSkeleton = {
  contentTypeId: CmsModel.game
  fields: {
    [GameField.name]: EntryFieldTypes.Symbol
    [GameField.icon]: EntryFieldTypes.AssetLink
    [GameField.previewIcon]?: EntryFieldTypes.AssetLink
    [GameField.identifier]: EntryFieldTypes.Symbol
    [GameField.filterTags]?: EntryFieldTypes.Array<
      EntryFieldTypes.EntryLink<FilterTagSkeleton>
    >
    [GameField.startDate]: EntryFieldTypes.Date
    [GameField.isQaEnabled]: EntryFieldTypes.Boolean
    [GameField.isUatEnabled]: EntryFieldTypes.Boolean
    [GameField.isProdEnabled]: EntryFieldTypes.Boolean
    [GameField.expirationDate]?: EntryFieldTypes.Date
    [GameField.price]: EntryFieldTypes.Number
    [GameField.priceList]?: EntryFieldTypes.Array<EntryFieldTypes.Symbol>
    [GameField.topPrizeDisplay]?: EntryFieldTypes.Symbol
    [GameField.odds]?: EntryFieldTypes.Number
    [GameField.type]: EntryFieldTypes.Symbol<GameType>
    [GameField.rules]?: EntryFieldTypes.Array<
      EntryFieldTypes.EntryLink<AccordionSkeleton>
    >
    [GameField.ticketsOrdered]?: EntryFieldTypes.Integer
    [GameField.prizeTable]?: EntryFieldTypes.EntryLink<CsvTableSkeleton>
    [GameField.gameConfigurationDataServices]: EntryFieldTypes.EntryLink<GameConfigDataServiceSkeleton>
    [GameField.gameConfigurationILottery]?: EntryFieldTypes.EntryLink<GameConfigILotterySkeleton>
    [GameField.series]?: EntryFieldTypes.EntryLink<GameSeriesSkeleton>
    [GameField.jackpotGroup]?: EntryFieldTypes.EntryLink<GameJackpotGroupSkeleton>
    [GameField.ribbon]?: EntryFieldTypes.EntryLink<GameRibbonSkeleton>
  }
}

export type GameBranding = {
  fontColorHex: string
  backgroundColorHex: string
  bonusNumbers?: {
    fontColorHex: string
    backgroundColorHex: string
    name: string
  }
  multipliers?: {
    name: string
  }
}

export type GameConfigDrawGameMetadata = {
  numWinningNumbers: number
  hasBonusNumber: boolean
}

export type GameModelMeta = {
  ribbon?: GameRibbonModel
  series?: GameSeriesModel
}

export type GamePrice = {
  priceInCents: number
  priceFormatted: string
}

export type GameModel = {
  name: string
  identifier: string
  filterTags: FilterTagModel[]
  startDate: string // MM-DD-YYYY
  expirationDate?: string // MM-DD-YYYY
  topPrizeDisplay?: string
  type: GameType
  configuration: {
    dataServices: GameConfigDataServiceModel
    iLottery?: GameConfigILotteryModel
    drawGame?: GameConfigDrawGameMetadata
  }
  imageUrl: string
  previewImageUrl?: string
  price: {
    priceInCents: number | null
    priceFormatted: string
  }
  priceOptions: GamePrice[]
  odds?: number
  ticketsOrdered?: number
  branding?: GameBranding
  meta: GameModelMeta
}

export type GameModelPublish = Omit<
  GameModel,
  | 'meta'
  | 'price'
  | 'imageUrl'
  | 'configuration'
  | 'filterTags'
  | 'priceOptions'
> & {
  price: number // in dollars
  priceOptions?: string[]
  gameConfigurationDataServices: {
    sys: EntryLink
  }
  gameConfigurationILottery?: {
    sys: EntryLink
  }
  ribbon?: { sys: EntryLink }
  series?: { sys: EntryLink }
  icon: {
    sys: AssetLink
  }
  previewIcon?: {
    sys: AssetLink
  }
  filterTags: { sys: EntryLink }[]
  rules?: { sys: EntryLink }[]
  prizeTable?: { sys: EntryLink }
  ticketsOrdered?: number
  startDate: string
}

export type GameSkeletonOnlyRules = Omit<GameSkeleton, 'fields'> & {
  fields: Pick<GameSkeleton['fields'], GameField.rules>
}

export type GameSkeletonOnlyPrizeTable = Omit<GameSkeleton, 'fields'> & {
  fields: Pick<GameSkeleton['fields'], GameField.prizeTable>
}

export type GameRulesModel = {
  rules: AccordionModel[]
}

export type GamePrizeTableModel = {
  prizeTable: CsvTableModel
}

export type RapidOrDrawGame = Omit<GameModel, 'type'> & {
  type: GameType.rapid | GameType.draw
}

export type RapidGame = Omit<GameModel, 'type'> & {
  type: GameType.rapid | GameType.draw
}

export const isRapidOrDrawGame = (game: GameModel): game is RapidOrDrawGame =>
  game.type === GameType.draw || game.type === GameType.rapid

export const isRapidGame = (game: GameModel): game is RapidOrDrawGame =>
  game.type === GameType.rapid

export const GameFields = Object.values(GameField)
export const GameTypeValues = Object.values(GameType)
export const isValidGameType = (varToCheck: any): varToCheck is GameType =>
  GameTypeValues.includes(varToCheck)
