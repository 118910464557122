import * as React from 'react'
import { GameModel, GameType } from '@Cms/model-types/game-model'
import { CurrencyUtilities } from '@Util/currency-utilities'
import { ControlledActionMenu } from '../action-menu/controlled-action-menu'
import { getBlockClass, getCustomCssProperties } from '../component-helpers'
import { NeoGamesGamePlayModeShort } from '@Client/services/pam/ng-types'
import { DynamicUrl } from '@Client/route-paths'
import { Button } from '../button/button'
import { Nullable } from '@Util/utility-types'
import { GeneralLink } from '../link/link-ui'
import { DateFormat, dateUtil } from '@Util/date-util'
import { SpotlightCarouselContext } from '../carousel-spotlight/constants'
import './styles.scss'

export type GameActionMenuProps = {
  game: GameModel
  nextDrawingDate?: Date
  priceMinInCents: number
  priceMaxInCents: number
  isILotteryFrameworkReady: boolean
  hasILottery: boolean
  loadGame: (mode: NeoGamesGamePlayModeShort) => void
  buttonHexColor?: string
}

type GameActionMenuButton = {
  type: 'button'
  text: string
  onClick: () => void
  ariaLabel: string
}

type GameActionMenuLink = {
  type: 'link'
  text: string
  dest: string
  ariaLabel: string
}

type GameActionMenuCta = GameActionMenuButton | GameActionMenuLink

const ROOT_CLASS = 'game-action-menu'
const INFO_TEXT_CLASS = getBlockClass(ROOT_CLASS, 'info-text')
const PRIMARY_CTA_CLASS = getBlockClass(ROOT_CLASS, 'primary-cta')
const SECONDARY_CTA_CLASS = getBlockClass(ROOT_CLASS, 'secondary-cta')

const BASE_TAGLINE = 'Must be 18+ to play. '

export const GameActionMenu = React.forwardRef(
  (props: GameActionMenuProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const spotlightCarouselContext = React.useContext(SpotlightCarouselContext)
    const maxPriceFormatted =
      CurrencyUtilities.getDollarsFromCentsWithRemainder(props.priceMaxInCents)
    const [isOpen, setIsOpen] = React.useState<boolean>(false)

    const getGameInfoText = () => {
      if (props.game.type === GameType.draw && props.nextDrawingDate) {
        const drawingDateFormatted = dateUtil(props.nextDrawingDate).format(
          DateFormat.shortMonthDateAndTime
        )
        return `Next drawing: ${drawingDateFormatted}`
      }
      if (props.priceMinInCents === props.priceMaxInCents) {
        return `$${maxPriceFormatted}`
      }
      return `$${CurrencyUtilities.getDollarsFromCentsWithRemainder(props.priceMinInCents)} - $${maxPriceFormatted}`
    }

    const getPrimaryCta = (): Nullable<GameActionMenuCta> => {
      switch (props.game.type) {
        case GameType.online:
          return {
            type: 'button',
            text: 'Play',
            onClick: () => {
              setIsOpen(false)
              props.loadGame('M')
            },
            ariaLabel: `Play ${props.game.name}`
          }
        case GameType.draw:
        case GameType.rapid:
          if (!props.hasILottery) {
            return {
              type: 'link',
              text: 'Game info',
              dest: DynamicUrl.getGameByIdentifierUrl(props.game.identifier),
              ariaLabel: `Go to ${props.game.name} details`
            }
          }
          return {
            type: 'button',
            text: 'Buy Now',
            onClick: () => {
              setIsOpen(false)
              props.loadGame('M')
            },
            ariaLabel: `Buy ${props.game.name} tickets online`
          }
        case GameType.scratch:
          return {
            type: 'link',
            text: 'Game info',
            dest: DynamicUrl.getGameByIdentifierUrl(props.game.identifier),
            ariaLabel: `Go to ${props.game.name} details`
          }
        default:
          return null
      }
    }

    const getSecondaryCta = (): Nullable<GameActionMenuCta> => {
      switch (props.game.type) {
        case GameType.online:
          return {
            type: 'button',
            text: 'Demo',
            onClick: () => {
              setIsOpen(false)
              props.loadGame('D')
            },
            ariaLabel: `Demo ${props.game.name}`
          }
        case GameType.draw:
        case GameType.rapid:
          return {
            type: 'link',
            text: 'Past winning numbers',
            dest: DynamicUrl.getPastResultsForGame(props.game.identifier),
            ariaLabel: `View past winning numbers for ${props.game.name}`
          }
        case GameType.scratch:
          return {
            type: 'link',
            text: 'Prizes remaining',
            dest: DynamicUrl.getPrizesRemainingForGame(
              props.game.configuration.dataServices.gameDataServiceId
            ),
            ariaLabel: `View remaining prizes for ${props.game.name}`
          }
        default: {
          return null
        }
      }
    }

    const primaryCta = getPrimaryCta()
    const secondaryCta = getSecondaryCta()

    const tagLine = props.game.meta.series
      ? `Estimated jackpot. ${BASE_TAGLINE}`
      : BASE_TAGLINE

    return (
      <ControlledActionMenu
        ref={ref}
        onClose={spotlightCarouselContext.onChildInactive}
        onOpen={spotlightCarouselContext.onChildActive}
        buttonFillColorHex={props.buttonHexColor}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <div className={ROOT_CLASS}>
          {props.game.meta.series && (
            <span
              className={getBlockClass(ROOT_CLASS, 'series')}
              style={getCustomCssProperties({
                '--series-bkg-color': props.game.meta.series.backgroundColor,
                '--series-color': props.game.meta.series.textColor
              })}
            >
              {props.game.meta.series.name}
            </span>
          )}
          <div className={getBlockClass(ROOT_CLASS, 'content')}>
            <div className={getBlockClass(ROOT_CLASS, 'info')}>
              <span className={INFO_TEXT_CLASS}>{props.game.name}</span>
              <span className={INFO_TEXT_CLASS}>{getGameInfoText()}</span>
            </div>
            <div className={getBlockClass(ROOT_CLASS, 'ctas')}>
              {primaryCta &&
                (primaryCta.type === 'button' ? (
                  <Button
                    className={PRIMARY_CTA_CLASS}
                    ariaLabel={primaryCta.ariaLabel}
                    onClick={primaryCta.onClick}
                  >
                    {primaryCta.text}
                  </Button>
                ) : (
                  <GeneralLink
                    className={PRIMARY_CTA_CLASS}
                    dest={primaryCta.dest}
                    ariaLabel={primaryCta.ariaLabel}
                  >
                    {primaryCta.text}
                  </GeneralLink>
                ))}
              {secondaryCta &&
                (secondaryCta.type === 'button' ? (
                  <Button
                    className={SECONDARY_CTA_CLASS}
                    onClick={secondaryCta.onClick}
                    ariaLabel={secondaryCta.ariaLabel}
                  >
                    {secondaryCta.text}
                  </Button>
                ) : (
                  <GeneralLink
                    className={SECONDARY_CTA_CLASS}
                    dest={secondaryCta.dest}
                    ariaLabel={secondaryCta.ariaLabel}
                  >
                    {secondaryCta.text}
                  </GeneralLink>
                ))}
            </div>
            <span className={getBlockClass(ROOT_CLASS, 'tagline')}>
              {tagLine}
            </span>
          </div>
        </div>
      </ControlledActionMenu>
    )
  }
)
