import { keyBy } from 'lodash'
import { FilterTagWithState } from '@Components/game-lobby-query/game-lobby-query-filters-ui'
import { deepCopy, getObjectKeys } from '@Util/object-utilities'
import { Nullable } from '@Util/utility-types'
import {
  FilterCategoryField,
  FilterCategoryModel
} from '@Cms/model-types/filter-category-model'
import {
  FilterTagField,
  FilterTagModel
} from '@Cms/model-types/filter-tag-model'
import { compact } from '@Util/array-utilities'
import { GameField, GameModel } from '@Cms/model-types/game-model'
import { GameLobbyParamKey, decodeFilters } from './url-helpers'

export type LobbyFilterTag = FilterTagWithState & {
  isInSpotlight: boolean
}
export type FilterCategoryWithState = Omit<
  FilterCategoryModel,
  'identifier' | 'filterTags'
> & {
  filterTagsByIdentifier: FilterTagsByIdentifier
}

export type FilterTagsByIdentifier = Record<string, LobbyFilterTag>
export type FilterCategoryByIdentifier = Record<string, FilterCategoryWithState>

export const getDefaultFilterState = (
  filterCategories: FilterCategoryModel[],
  spotlightFilters: FilterTagModel[],
  getParam: (paramKey: string) => Nullable<string>
): FilterCategoryByIdentifier => {
  const urlFilterIdentifiers: string[] = decodeFilters(
    getParam(GameLobbyParamKey.filters)
  )
  const categoriesByKey: Record<string, FilterCategoryModel> = keyBy(
    filterCategories,
    FilterCategoryField.identifier
  )
  const standaloneSpotlights = deepCopy(spotlightFilters)
  const statefulCategories: FilterCategoryByIdentifier = {}

  getObjectKeys(categoriesByKey).forEach((categoryIdentifier: string) => {
    const category = categoriesByKey[categoryIdentifier]
    const statefulTagsByIdentifier: FilterTagsByIdentifier = {}
    for (const tag of category[FilterCategoryField.filterTags]) {
      // add categories to any spotlight tags
      const tagSpotlightIndex = standaloneSpotlights.findIndex(
        (spotlightTag) =>
          spotlightTag[FilterTagField.identifier] ===
          tag[FilterTagField.identifier]
      )
      const isInSpotlight = tagSpotlightIndex !== -1
      if (isInSpotlight) {
        standaloneSpotlights.splice(tagSpotlightIndex, 1)
      }
      statefulTagsByIdentifier[tag[FilterTagField.identifier]] = {
        ...tag,
        isActive: urlFilterIdentifiers.includes(tag[FilterTagField.identifier]),
        isInSpotlight,
        categoryIdentifier
      }
    }
    statefulCategories[categoryIdentifier] = {
      [FilterCategoryField.displayText]:
        category[FilterCategoryField.displayText],
      filterTagsByIdentifier: statefulTagsByIdentifier
    }
  })

  // give standalone filters their own category (tag identifier)
  standaloneSpotlights.forEach((tag) => {
    const isActive = urlFilterIdentifiers.includes(
      tag[FilterTagField.identifier]
    )
    statefulCategories[tag[FilterTagField.identifier]] = {
      [FilterCategoryField.displayText]: tag[FilterTagField.displayText],
      filterTagsByIdentifier: {
        [tag[FilterTagField.identifier]]: {
          ...tag,
          isInSpotlight: true,
          isActive,
          categoryIdentifier: tag[FilterTagField.identifier]
        }
      }
    }
  })
  return statefulCategories
}

export const updateFilterState = (
  filtersByCategory: FilterCategoryByIdentifier,
  categoryIdentifier: string,
  identifier: string,
  isActive: boolean
) => {
  const currentTag =
    filtersByCategory[categoryIdentifier].filterTagsByIdentifier[identifier]

  const newFiltersByCategory: FilterCategoryByIdentifier = {
    ...filtersByCategory,
    [categoryIdentifier]: {
      ...filtersByCategory[categoryIdentifier],
      filterTagsByIdentifier: {
        ...filtersByCategory[categoryIdentifier].filterTagsByIdentifier,
        [identifier]: {
          ...currentTag,
          isActive
        }
      }
    }
  }
  return newFiltersByCategory
}

export const getFilters = (
  filtersByCategory: FilterCategoryByIdentifier,
  tagFilter?: (tag: LobbyFilterTag) => boolean
) =>
  compact(
    getObjectKeys(filtersByCategory)
      .map((categoryIdentifier) => {
        const tags = Object.values(
          filtersByCategory[categoryIdentifier].filterTagsByIdentifier
        )
        return !tagFilter ? tags : tags.filter(tagFilter)
      })
      .flat()
  )

export const getStatefulSpotlightFilters = (
  filtersByCategory: FilterCategoryByIdentifier
) => getFilters(filtersByCategory, (tag: LobbyFilterTag) => tag.isInSpotlight)

export const getActiveFilters = (
  filtersByCategory: FilterCategoryByIdentifier
) => getFilters(filtersByCategory, (tag: LobbyFilterTag) => tag.isActive)

export const getStatefulExtraFilters = (
  filtersByCategory: FilterCategoryByIdentifier
) => getFilters(filtersByCategory, (tag: LobbyFilterTag) => !tag.isInSpotlight)

export const getActiveFiltersIdentifiers = (
  filtersByCategory: FilterCategoryByIdentifier
) => {
  const activeTags = getFilters(filtersByCategory, (tag) => tag.isActive)
  return activeTags.map((tag) => tag[FilterTagField.identifier])
}

export const filterGame = (
  game: GameModel,
  filtersByCategory: FilterCategoryByIdentifier,
  activeCategoryIdentifiers: string[]
) => {
  let valid = true
  for (const categoryIdentifier of activeCategoryIdentifiers) {
    let categoryValid = false
    const categoryTags = Object.values(
      filtersByCategory[categoryIdentifier].filterTagsByIdentifier
    )
    for (const tag of categoryTags) {
      if (!tag.isActive) {
        continue
      }
      const gameHasTag = game[GameField.filterTags].some(
        (gameTag) =>
          gameTag[FilterTagField.identifier] === tag[FilterTagField.identifier]
      )
      categoryValid = categoryValid || gameHasTag
      if (gameHasTag) {
        break
      }
    }
    valid = valid && categoryValid
  }
  return valid
}
