import * as React from 'react'
import './styles.scss'

export const FacebookIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
    viewBox='0 0 374.32 323.97'
    width={40}
    height={40}
  >
    <title>Facebook Icon</title>
    <g>
      <g>
        <path
          className='facebook-icon'
          d='M159.33,295.06h51.56v-118.94h38.09l7.62-48.05h-45.7v-36.33c0-13.48,12.89-21.09,25.19-21.09h22.27V30.8l-39.84-1.76c-38.09-2.34-59.18,27.54-59.18,63.28v35.74h-43.36v48.05h43.36v118.94Z'
        />
      </g>
    </g>
  </svg>
)
