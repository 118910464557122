import * as React from 'react'
import { useIsDesktop } from '@Client/hooks/use-media-query/use-media-query'
import { getBlockClass } from '../component-helpers'
import { PastResultsCell } from './past-results-cell'
import { PastResultsTableProps, ROOT_CLASS } from './constants'
import { PastResultsTableHeader } from './past-results-table-header'
import { ErrorMessage } from '../error-message/error-message-ui'
import './styles.scss'

enum SortByDirection {
  ascending = 'asc',
  descending = 'desc'
}

const JACKPOTS = ['powerball', 'mega-millions', 'megabucks']
const getStyle = (i: number): 'primary' | 'secondary' =>
  i % 2 === 0 ? 'primary' : 'secondary'

export const PastResultsTable = (props: PastResultsTableProps) => {
  const hasJackpot = JACKPOTS.includes(props.activeGame.identifier)
  const isDesktop = useIsDesktop()
  const [sortDirection, setSortDirection] = React.useState<SortByDirection>(
    SortByDirection.descending
  )

  const toggleSortDirection = () => {
    const newSortDirection =
      sortDirection === SortByDirection.ascending
        ? SortByDirection.descending
        : SortByDirection.ascending
    setSortDirection(newSortDirection)
  }

  React.useEffect(() => {
    setSortDirection(SortByDirection.descending)
  }, [props.activeGame])

  const sortedResults = React.useMemo(
    () =>
      sortDirection === SortByDirection.ascending
        ? props.results
        : props.results?.slice().reverse(),
    [props.results, sortDirection]
  )

  return !sortedResults ? null : sortedResults.length > 0 ? (
    <table className={ROOT_CLASS}>
      <thead className={getBlockClass(ROOT_CLASS, 'header')}>
        <tr className={getBlockClass(ROOT_CLASS, 'header-row')}>
          <PastResultsTableHeader
            {...props}
            isDesktop={isDesktop}
            toggleSortDirection={toggleSortDirection}
            hasJackpot={hasJackpot}
          />
        </tr>
      </thead>
      <tbody>
        {sortedResults.map((result, i) => (
          <PastResultsCell
            hasYourNumbers={props.hasYourNumbers}
            yourNumbers={props.yourNumbers}
            yourNumbersSpecialBall={props.yourNumbersSpecialBall}
            key={`${result.gameId}-${result.drawDate.toString()}`}
            style={getStyle(i)}
            {...result}
          />
        ))}
      </tbody>
    </table>
  ) : (
    <ErrorMessage
      className={getBlockClass(ROOT_CLASS, 'no-results')}
      message='No results. Please try a different query'
    />
  )
}
