import { InputMultiSelectOption } from '../input-multi-select/input-multi-select-ui'

export type PrizesRemainingFormData = {
  [PrizesRemainingFormField.topPrizeOnly]?: boolean
  [PrizesRemainingFormField.games]?: string[]
  [PrizesRemainingFormField.ticketCosts]?: string[]
  [PrizesRemainingFormField.prizeAmounts]?: string[]
}

export type PrizesRemainingPayload = {
  topPrizeFilterIsActive: boolean
  selectedGames: InputMultiSelectOption[]
  selectedTicketCost: InputMultiSelectOption[]
  selectedPrizeAmount: InputMultiSelectOption[]
}

export enum PrizesRemainingFormField {
  topPrizeOnly = 'topPrizeOnly',
  games = 'games',
  ticketCosts = 'ticketCosts',
  prizeAmounts = 'prizeAmounts'
}

// Future optimization:
// Create get all game prices API, and use that to populate the ticket costs
export const prizesRemainingTicketCosts: InputMultiSelectOption[] = [
  { label: '$1', value: '100' },
  { label: '$2', value: '200' },
  { label: '$3', value: '300' },
  { label: '$5', value: '500' },
  { label: '$10', value: '1000' },
  { label: '$20', value: '2000' },
  { label: '$25', value: '2500' }
]

export const prizesRemainingPrizeAmounts: InputMultiSelectOption[] = [
  { label: '$9,999 or below', value: JSON.stringify({ min: 0, max: 99999 }) },
  {
    label: '$10,000 - $99,999',
    value: JSON.stringify({ min: 10000, max: 99999 })
  },
  {
    label: '$100,000 - $499,999',
    value: JSON.stringify({ min: 100000, max: 499999 })
  },
  {
    label: '$500,000 - $999,999',
    value: JSON.stringify({ min: 500000, max: 999999 })
  },
  {
    label: '$1,000,000 and above',
    value: JSON.stringify({ min: 1000000, max: Number.MAX_VALUE })
  }
]

export const validateUrlListValue = (
  options: InputMultiSelectOption[],
  fieldValue?: string[] | boolean
) => {
  if (typeof fieldValue === 'boolean' || !fieldValue) {
    return undefined
  }
  const validValues = fieldValue.every((value) =>
    options.find((option) => option.value === value)
  )
  return validValues ? undefined : 'Invalid value'
}

export const parseFieldFromUrl = (
  fieldId: PrizesRemainingFormField,
  value: string
) => {
  switch (fieldId) {
    case PrizesRemainingFormField.prizeAmounts:
    case PrizesRemainingFormField.games:
    case PrizesRemainingFormField.ticketCosts:
      return JSON.parse(value)
    case PrizesRemainingFormField.topPrizeOnly:
      return value === 'true'
    default:
      throw new Error(`Invalid fieldId: ${fieldId}`)
  }
}
