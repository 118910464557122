import * as React from 'react'
import { useIsDesktop } from '@Client/hooks/use-media-query/use-media-query'
import { SpotlightCarousel } from '../carousel-spotlight/spotlight-carousel-ui'
import { GameModel } from '@Cms/model-types/game-model'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { GameTileContainer } from '../game-tile/game-tile-container'
import { getBlockClass } from '../component-helpers'
import './styles.scss'

type NewEInstantsProps = {
  isLoading: boolean
  newInstants: GameModel[]
}

const ROOT_CLASS = 'new-e-instants'

export const NewEInstants = (props: NewEInstantsProps) => {
  const isDesktop = useIsDesktop()
  return (
    <div className={ROOT_CLASS}>
      {props.isLoading ? (
        <LoadingSpinner />
      ) : props.newInstants ? (
        isDesktop ? (
          <div className={getBlockClass(ROOT_CLASS, 'desktop-container')}>
            <h2 className={getBlockClass(ROOT_CLASS, 'title')}>
              NEW E-INSTANT GAMES
            </h2>
            <div
              className={getBlockClass(ROOT_CLASS, 'desktop-tile-container')}
            >
              {props.newInstants?.map((game) => (
                <GameTileContainer
                  game={game}
                  key={game.identifier}
                  isILotteryGame
                />
              ))}
            </div>
          </div>
        ) : (
          <div className={getBlockClass(ROOT_CLASS, 'mobile-container')}>
            <h2 className={getBlockClass(ROOT_CLASS, 'title')}>
              NEW E-INSTANT GAMES
            </h2>
            <SpotlightCarousel
              dotColor='light'
              items={props.newInstants?.map((game) => (
                <div
                  className={getBlockClass(ROOT_CLASS, 'mobile-tile-container')}
                  key={game.identifier}
                >
                  <GameTileContainer game={game} isILotteryGame />
                </div>
              ))}
              name={'New E-Instants'}
              isLoading={false}
              autoRotateInterval={4500}
            />
          </div>
        )
      ) : null}
    </div>
  )
}
