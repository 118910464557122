import { GameModel } from '@Cms/model-types/game-model'
import { RootState } from '..'
import { getObjectKeys } from '@Util/object-utilities'
import { CurrencyUtilities } from '@Util/currency-utilities'
import { DateFormat, dateUtil } from '@Util/date-util'

export const selectJackpotForFastPlayGame = (
  state: RootState,
  game: GameModel
) => {
  const defaultTopPrizeText = game.topPrizeDisplay
  const jackpotGroupsById = state.jackpots.groupsById
  const currentJackpotsById = state.jackpots.jackpotsByGameId
  const matchingGroupId = getObjectKeys(jackpotGroupsById).find((key) =>
    jackpotGroupsById[key].games.find(
      (jackpotGroupGame) => jackpotGroupGame.identifier === game.identifier
    )
  )
  if (!matchingGroupId) {
    return defaultTopPrizeText
  }
  const jackpotGroup = currentJackpotsById[matchingGroupId]
  if (!jackpotGroup || jackpotGroup.type !== 'fastPlay') {
    return defaultTopPrizeText
  }
  const amountInCents = jackpotGroup.jackpot.jackpot?.jackpotAmountInCents
  return amountInCents
    ? CurrencyUtilities.numericFormat(
        CurrencyUtilities.getDollarsFromCents(amountInCents)
      )
    : defaultTopPrizeText
}

export const selectUpdateTimeForFastPlayGame = (
  state: RootState,
  game: GameModel
) => {
  const jackpotGroupsById = state.jackpots.groupsById
  const currentJackpotsById = state.jackpots.jackpotsByGameId
  const matchingGroupId = getObjectKeys(jackpotGroupsById).find((key) =>
    jackpotGroupsById[key].games.find(
      (jackpotGroupGame) => jackpotGroupGame.identifier === game.identifier
    )
  )
  if (!matchingGroupId) {
    return undefined
  }
  const jackpotGroup = currentJackpotsById[matchingGroupId]
  if (!jackpotGroup || jackpotGroup.type !== 'fastPlay') {
    return undefined
  }
  const time = jackpotGroup.jackpot.jackpot?.asOf
  return time ? dateUtil(time).format(DateFormat.fullDateAndTime) : undefined
}
