import * as React from 'react'
import { useNavigate } from 'react-router-dom'

export const useExposeGlobals = () => {
  const navigate = useNavigate()

  React.useEffect(() => {
    window.navigateReact = navigate
    return () => {
      delete window.navigateReact
    }
  }, [])
}
