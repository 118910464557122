import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@Client/hooks/redux-hooks'
import { Optional } from '@Util/utility-types'
import { GameModel } from '@Cms/model-types/game-model'
import { RootState } from '@Client/reducers'
import { CommonJackpotLoadedProps, JackpotCard } from './jackpot-card-ui'
import { CurrentJackpotState } from '@Client/reducers/jackpots/jackpot-reducer-types'
import { iLotteryActions } from '@Client/reducers/ilottery/ilottery-reducer'
import {
  getCurrentJackpot,
  getNextDrawingDate,
  getPromoText,
  getStatus
} from './helpers'

type DrawGameJackpotCardContainerProps = {
  gameDataServiceId: string
}

export const DrawGameJackpotCardContainer = (
  props: DrawGameJackpotCardContainerProps
) => {
  const dispatch = useAppDispatch()
  const { isFrameworkReady } = useAppSelector((state) => state.iLottery)
  const cmsGame: Optional<GameModel> = useAppSelector(
    (state) => state.cmsGames.gamesByDataServiceId[props.gameDataServiceId]
  )
  const jackpotGroup = useAppSelector(
    (state: RootState) => state.jackpots.groupsById[props.gameDataServiceId]
  )
  const numGames = jackpotGroup?.games.length ?? 0
  const jackpotState = useAppSelector((state: RootState) => state.jackpots)
  const gameJackpotState: Optional<CurrentJackpotState> =
    jackpotState.jackpotsByGameId[props.gameDataServiceId]

  const currentJackpot = getCurrentJackpot(gameJackpotState)
  const isJackpotGroup = !!jackpotGroup

  if (!cmsGame && !jackpotGroup) {
    return null
  } else if (
    !gameJackpotState ||
    jackpotState.error ||
    jackpotState.isLoading
  ) {
    return <JackpotCard isLoading={true} error={jackpotState?.error} />
  }

  const source = isJackpotGroup ? jackpotGroup : cmsGame

  const openGameWidget = () => {
    const iLotteryId = cmsGame?.configuration.iLottery?.iLotteryId
    if (!iLotteryId) {
      return
    }
    dispatch(iLotteryActions.openDrawGameWidget(Number(iLotteryId)))
  }

  const commonProps: CommonJackpotLoadedProps = {
    isLoading: false,
    gameIconSrc: source.imageUrl,
    gameName: source.name,
    gameType: gameJackpotState.type,
    nextDrawingDate: getNextDrawingDate(gameJackpotState),
    promoText: getPromoText(gameJackpotState, numGames, source.name),
    estimatedJackpotCents: currentJackpot ?? 0,
    status: getStatus(gameJackpotState),
    error: jackpotState.error,
    isILotteryFrameworkReady: isFrameworkReady,
    openGameWidget
  }

  switch (gameJackpotState.type) {
    case 'draw':
      return (
        <JackpotCard
          {...commonProps}
          jackpotType='estimated'
          gameIdentifier={cmsGame?.identifier ?? ''}
          estimatedCashAmountJackpotCents={
            gameJackpotState.progressiveJackpot.jackpot
              ?.jackpotCashAmountInCents ?? 0
          }
        />
      )
    case 'rapid':
      return (
        <JackpotCard
          {...commonProps}
          jackpotType='current'
          asOf={new Date(gameJackpotState.jackpot?.asOf ?? new Date())}
        />
      )
    case 'fastPlay':
      return (
        <JackpotCard
          {...commonProps}
          jackpotType='current'
          asOf={new Date(gameJackpotState.jackpot?.jackpot?.asOf ?? new Date())}
          gameIdentifierLink={
            numGames
              ? jackpotGroup.games[jackpotGroup.games.length - 1].identifier
              : undefined
          }
        />
      )
    default:
      return null
  }
}
