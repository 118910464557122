import * as React from 'react'
import { useDeepLink } from '../use-deep-link/use-deep-link'
import { Nullable, Optional } from '@Util/utility-types'

type BaseFormData = Record<
  string,
  Optional<string | number | boolean | string[] | number[]>
>

export type UseFormDataProps<T extends BaseFormData> = {
  useDeepLinks: boolean
  fieldIds: (keyof T)[]
  validateField?: (
    formData: T,
    fieldId: keyof T,
    fieldValue?: T[typeof fieldId]
  ) => Optional<string>
  getDefaultValue?: (fieldId: keyof T) => Optional<T[typeof fieldId]>
  parseFieldFromUrl?: (fieldId: keyof T, value: string) => T[typeof fieldId]
}

const getInitialFormValues = <T extends BaseFormData>(
  getParam: (paramKey: string) => Nullable<string>,
  fieldIds: (keyof T)[],
  getDefaultValue?: UseFormDataProps<T>['getDefaultValue'],
  validateField?: UseFormDataProps<T>['validateField'],
  parseFieldFromUrl?: (fieldId: keyof T, value: string) => T[typeof fieldId]
): T => {
  const initialValues: T = {} as T
  for (const fieldId of fieldIds) {
    const stringFieldId = String(fieldId)
    const urlValue = getParam(stringFieldId) as Nullable<string>
    if (urlValue) {
      const value = (
        parseFieldFromUrl ? parseFieldFromUrl(fieldId, urlValue) : urlValue
      ) as T[typeof fieldId]
      if (validateField) {
        const errorMessage = validateField(initialValues, fieldId, value)
        if (!errorMessage) {
          initialValues[fieldId] = value
        }
      } else {
        initialValues[fieldId] = value
      }
    } else if (getDefaultValue) {
      const defaultValue = getDefaultValue(fieldId)
      if (defaultValue) {
        initialValues[fieldId] = defaultValue
      }
    }
  }
  return initialValues
}

export const useFormData = <T extends BaseFormData>(
  props: UseFormDataProps<T>
) => {
  const { updateParam, getParam, stripParam } = useDeepLink()
  const [formData, setFormData] = React.useState<T>(
    getInitialFormValues(
      getParam,
      props.fieldIds,
      props.getDefaultValue,
      props.validateField,
      props.parseFieldFromUrl
    )
  )

  const cleanBadUrlValues = () => {
    props.fieldIds.forEach((field) => {
      const urlValue = getParam(String(field))
      if (!urlValue) {
        return
      }
      const value = props.parseFieldFromUrl
        ? props.parseFieldFromUrl(field, urlValue)
        : urlValue
      const errorMessage = props.validateField?.(
        formData,
        field,
        value as T[typeof field]
      )
      if (errorMessage) {
        stripParam(String(field))
      }
    })
  }

  React.useEffect(cleanBadUrlValues, [])

  const [errors, setErrors] = React.useState<Record<keyof T, Optional<string>>>(
    () => {
      const initialErrors = {} as Record<keyof T, Optional<string>>
      for (const fieldId of props.fieldIds) {
        initialErrors[fieldId] = props.validateField?.(
          formData,
          fieldId,
          formData[fieldId]
        )
      }
      return initialErrors
    }
  )

  const updateField = (field: keyof T, value?: T[keyof T]) => {
    const newFormData: T = {
      ...formData,
      [field]: value
    }
    setFormData(newFormData)
    if (props.useDeepLinks) {
      const fieldName = String(field)
      const isEmptyObject =
        typeof value === 'object' && !Object.keys(value).length
      if (value !== null && value !== undefined && !isEmptyObject) {
        const urlValue =
          typeof value === 'object' ? JSON.stringify(value) : String(value)
        updateParam(fieldName, urlValue)
      } else {
        stripParam(fieldName)
      }
    }

    if (props.validateField) {
      const newErrors: typeof errors = {} as typeof errors
      for (const field of props.fieldIds) {
        const errorMessage = props.validateField(
          newFormData,
          field,
          newFormData[field]
        )
        newErrors[field] = errorMessage
      }
      setErrors(newErrors)
    }
  }

  const clearForm = () => {
    setFormData({} as T)
    for (const fieldId of props.fieldIds) {
      stripParam(String(fieldId))
    }
  }

  const isValid = Object.values(errors).every((error) => !error)

  return {
    formData,
    clearForm,
    updateField,
    errors,
    isValid
  }
}
