import * as React from 'react'
import { GameModel } from '@Cms/model-types/game-model'
import { DateFormat, dateUtil } from '@Util/date-util'
import { GameDetailsPageStructure } from '../game-details-page/game-details-page-structure'
import { AsyncImage } from '../async-image/async-image-ui'
import { GameRibbon } from '../game-ribbon/game-ribbon'
import { getBlockClass } from '../component-helpers'
import { GameRulesContainer } from '../game-rules/game-rules-container'
import { CsvTableModel } from '@Cms/model-types/csv-table-model'
import { Table } from '../table/table-ui'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { ErrorMessage } from '../error-message/error-message-ui'
import { GamePrice } from '../game-price/game-price-ui'
import './styles.scss'

export type ScratchGameDetailsPageLoadedProps = {
  game: GameModel
  topPrizeDisplay?: string
  prizeTableData?: CsvTableModel
  isPrizeTableLoading: boolean
  prizeTableError?: string
  gameId?: string
}

export type ScratchGameDetailsPageProps = ScratchGameDetailsPageLoadedProps

const ROOT_CLASS = 'scratch-game-details-page'
const HORIZONTAL_CLASS = getBlockClass(ROOT_CLASS, 'horizontal')
const VERTICAL_CLASS = getBlockClass(ROOT_CLASS, 'vertical')
const LABEL_CLASS = getBlockClass(ROOT_CLASS, 'label')
const VALUE_CLASS = getBlockClass(ROOT_CLASS, 'value')

const ScratchGameDetailsPageInfo = (
  props: ScratchGameDetailsPageLoadedProps
) => (
  <div className={getBlockClass(ROOT_CLASS, 'page-info')}>
    <div className={getBlockClass(ROOT_CLASS, 'image-container')}>
      <AsyncImage
        src={props.game.imageUrl}
        alt={`${props.game.name} image`}
        className={getBlockClass(ROOT_CLASS, 'image')}
      />
      {props.game.meta.ribbon && <GameRibbon {...props.game.meta.ribbon} />}
    </div>
    <div className={getBlockClass(ROOT_CLASS, 'content')}>
      <div className={VERTICAL_CLASS}>
        <div className={HORIZONTAL_CLASS}>
          <GamePrice
            className={getBlockClass(ROOT_CLASS, 'price')}
            value={props.game.price.priceFormatted}
          />
          <h1 className={getBlockClass(ROOT_CLASS, 'title')}>
            {props.game.name}
          </h1>
        </div>
        {props.topPrizeDisplay && (
          <span className={getBlockClass(ROOT_CLASS, 'prize')}>
            {`Win up to ${props.topPrizeDisplay}!`}
          </span>
        )}
      </div>
      <div className={VERTICAL_CLASS}>
        {props.game.odds && (
          <div className={HORIZONTAL_CLASS}>
            <span className={LABEL_CLASS}>Overall Odds</span>
            <span className={VALUE_CLASS}>1 in {props.game.odds}*</span>
          </div>
        )}
        {props.gameId && (
          <div className={HORIZONTAL_CLASS}>
            <span className={LABEL_CLASS}>Game Number</span>
            <span className={VALUE_CLASS}>{props.gameId}</span>
          </div>
        )}
        {props.game.ticketsOrdered && (
          <div className={HORIZONTAL_CLASS}>
            <span className={LABEL_CLASS}>Tickets Ordered</span>
            <span className={VALUE_CLASS}>
              {props.game.ticketsOrdered.toLocaleString()}
            </span>
          </div>
        )}
        <div className={HORIZONTAL_CLASS}>
          <span className={LABEL_CLASS}>On Sale</span>
          <span className={VALUE_CLASS}>
            {dateUtil(props.game.startDate).format(DateFormat.monthDayYear)}
          </span>
        </div>
      </div>
    </div>
    <GameRulesContainer game={props.game} />
  </div>
)

const ScratchGameDetailsResults = (props: ScratchGameDetailsPageProps) => (
  <div className={getBlockClass(ROOT_CLASS, 'results')}>
    <h2 className={getBlockClass(ROOT_CLASS, 'odds-header')}>Odds Breakdown</h2>
    {props.isPrizeTableLoading ? (
      <LoadingSpinner />
    ) : props.prizeTableError ? (
      <ErrorMessage />
    ) : props.prizeTableData ? (
      <>
        <Table table={props.prizeTableData} />
        <span className={getBlockClass(ROOT_CLASS, 'disclaimer')}>
          *The OVERALL ODDS of a game refer to the total number tickets in a
          game divided by the total number of prizes. All prizes are randomly
          distributed amongst all the printed tickets in the whole game. A
          game&apos;s odds are to be used as a guide and are not used to
          determine the placement of winning tickets. These odds are calculated
          before sales begin and will change as prizes are won. Tickets may
          continue to be sold after all top prizes have been claimed.
        </span>
      </>
    ) : null}
  </div>
)

export const ScratchGameDetailsPage = (props: ScratchGameDetailsPageProps) => (
  <GameDetailsPageStructure
    gameInfo={<ScratchGameDetailsPageInfo {...props} />}
    gameResults={<ScratchGameDetailsResults {...props} />}
    className={ROOT_CLASS}
  />
)
