import { isServerRender } from '@Util/ssr-util'
import { UserAgentUtil } from '@Client/util/user-agent'
import { BREAKPOINTS } from '@Client/hooks/use-media-query/use-media-query'
import {
  NeoGamesFrameworkPreferences,
  NeoGamesHasActiveBonus,
  NeoGamesLoadGameParams
} from './ng-types'

enum NgPlatform {
  mobileWeb = 'C',
  desktopWeb = 'W',
  nativeApp = 'A'
}

const getPlatform = (): NgPlatform =>
  isServerRender()
    ? NgPlatform.desktopWeb
    : window.innerWidth < BREAKPOINTS.desktop
      ? NgPlatform.mobileWeb
      : NgPlatform.desktopWeb

export const defaultILotteryFrameworkPreferences: NeoGamesFrameworkPreferences =
  {
    BrandID: '172',
    Language: 'ENG',
    Currency: 'USD',
    Platform: getPlatform(),
    URLParams: isServerRender() ? '' : window.location.search.replace('?', ''),
    IsGameStandaloneMode: false
  }

const closeAllGames = () => getFramework()?.closeAllGames()

const getFrameworkPreferences = (): NeoGamesFrameworkPreferences => {
  const isMobileOrTabletUserAgent = UserAgentUtil.isMobileOrTablet()
  const isTabletViewport = window.innerWidth < BREAKPOINTS.desktop
  const isMobileOrTablet = isMobileOrTabletUserAgent || isTabletViewport
  return {
    ...defaultILotteryFrameworkPreferences,
    Platform: isMobileOrTablet ? NgPlatform.mobileWeb : NgPlatform.desktopWeb
  }
}

const getFramework = () => window.ng_fw
const getHost = () => window.ng_hs

const getBalance = () => getFramework()?.getDisplayBalance() ?? undefined

const getSessionDetails = () => getFramework()?.getSessionDetails() ?? null

const getHasActiveBonus = (
  callback: (params: NeoGamesHasActiveBonus) => void
) => getFramework()?.checkActiveBonuses(callback)

const getShoppingCartStatus = () =>
  getFramework()?.getShoppingCartStatus() ?? undefined

const keepAlive = () => {
  getFramework()?.keepAlive()
}

const loadGame = (launchGameParams: NeoGamesLoadGameParams) =>
  getFramework()?.loadGame(launchGameParams)

const loadSubscriptionGame = (launchGameParams: NeoGamesLoadGameParams) =>
  getFramework()?.loadGame({ ...launchGameParams, subscription: true })

const logout = () => {
  getFramework()?.logout()
}

const updateGamesList = () => {
  getFramework()?.updateGamesList()
}

const showDeposit = () => {
  getFramework()?.showDeposit()
}

const showChat = () => {
  getFramework()?.showChat()
}

const showContactUs = () => {
  getFramework()?.showContactUs()
}

const showLogin = () => {
  getFramework()?.showLogin()
}

const showMyAccount = () => {
  getFramework()?.showMyAccount()
}

const showMyBonuses = () => {
  getFramework()?.showMyBonuses()
}

const showRegistration = () => {
  getFramework()?.showRegistration()
}

const showRegistrationOrLogin = () => {
  getFramework()?.showRegistrationOrLogin()
}

const showShoppingCart = () => {
  getFramework()?.showShoppingCart()
}

const showWithdraw = () => {
  getFramework()?.showWithdraw()
}

export const NeoGamesService = {
  closeAllGames,
  getBalance,
  getFramework,
  getFrameworkPreferences,
  getHost,
  getSessionDetails,
  getShoppingCartStatus,
  getHasActiveBonus,
  keepAlive,
  loadGame,
  loadSubscriptionGame,
  logout,
  updateGamesList,
  showDeposit,
  showChat,
  showContactUs,
  showLogin,
  showMyAccount,
  showMyBonuses,
  showRegistration,
  showRegistrationOrLogin,
  showShoppingCart,
  showWithdraw
}
