import * as React from 'react'
import { DateFormat, dateUtil } from '@Util/date-util'
import { GameType } from '@Cms/model-types/game-model'
import { SortingArrowheads } from '@Client/icons/sorting-arrowheads'
import { AccessibleKey, TabIndex } from '@Client/constants'
import { PastResultsTableHeaderProps, ROOT_CLASS } from './constants'
import { getBlockClass } from '../component-helpers'
import './styles.scss'

const CLICKABLE_HEADER_CLASS = getBlockClass(ROOT_CLASS, 'clickable-header')

export const PastResultsTableHeader = (props: PastResultsTableHeaderProps) => {
  const handleSortToggle = (e: React.KeyboardEvent<HTMLTableCellElement>) => {
    if (e.key === AccessibleKey.enter || e.key === AccessibleKey.space) {
      e.preventDefault()
      props.toggleSortDirection()
    }
  }
  if (props.activeGame.type === GameType.rapid) {
    const maxDate = dateUtil(props.maxDate)
    const minDate = dateUtil(props.minDate)
    const maxDateFormatted = maxDate.format(
      DateFormat.shortDayShortMonthDateAndYear
    )
    const minDateFormatted = minDate.format(
      DateFormat.shortDayShortMonthDateAndYear
    )
    return (
      <>
        {props.isDesktop ? (
          <>
            <td
              onClick={props.toggleSortDirection}
              aria-label='Toggle Sort Direction for Drawing Number'
              role={'button'}
              tabIndex={TabIndex.accessible}
              onKeyDown={handleSortToggle}
            >
              <span className={CLICKABLE_HEADER_CLASS}>
                Drawing Number
                <SortingArrowheads />
              </span>
            </td>
            <td>Time</td>
            <td>Winning Numbers</td>
            <td />
            {!!props.yourNumbers.length && <td>Your Winnings</td>}
          </>
        ) : (
          <td className={getBlockClass(ROOT_CLASS, 'rapid-mobile-header')}>
            {maxDate && minDate ? (
              <span>
                {maxDate.isSame(minDate, 'date')
                  ? maxDateFormatted
                  : `${minDateFormatted} - ${maxDateFormatted}`}
              </span>
            ) : (
              <span />
            )}
            <span
              className={getBlockClass(ROOT_CLASS, 'rapid-mobile-subheader')}
            >
              <span>Drawing Number</span>
              <span>Time</span>
            </span>
          </td>
        )}
      </>
    )
  }
  return (
    <>
      {props.isDesktop ? (
        <td
          onClick={props.toggleSortDirection}
          aria-label='Toggle Sort Direction for Drawing Number'
          role={'button'}
          tabIndex={TabIndex.accessible}
          onKeyDown={handleSortToggle}
        >
          <span className={CLICKABLE_HEADER_CLASS}>
            Date
            <SortingArrowheads />
          </span>
        </td>
      ) : (
        <td>Date</td>
      )}
      {props.isDesktop ? (
        <>
          <td>Winning Numbers</td>
          {props.hasYourNumbers ? (
            <td>Your Winnings</td>
          ) : (
            props.hasJackpot && <td>Jackpot</td>
          )}
        </>
      ) : null}
    </>
  )
}
