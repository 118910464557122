import * as React from 'react'
import { WinningNumbers } from '../winning-numbers/winning-numbers-ui'
import { GameType } from '@Cms/model-types/game-model'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { DateFormat, dateUtil } from '@Util/date-util'
import { CurrencyUtilities } from '@Util/currency-utilities'
import { PastResultDraw, PastResultRapid } from './constants'
import {
  getYourWinningsDisplay,
  DEFAULT_YOUR_WINNINGS_TEXT,
  getKenoWinnerDisplay
} from '../past-results-your-winnings/helpers'
import { Nullable } from '@Util/utility-types'
import './styles.scss'

type PastResultsCellCommonProps = {
  style: 'primary' | 'secondary'
  hasYourNumbers: boolean
  yourNumbers: Nullable<number>[]
  yourNumbersSpecialBall: Nullable<number>
}

export type PastResultsRapidCellProps = PastResultRapid &
  PastResultsCellCommonProps

export type PastResultsDrawCellProps = PastResultDraw &
  PastResultsCellCommonProps

export type PastResultsCellProps =
  | PastResultsRapidCellProps
  | PastResultsDrawCellProps

const ROOT_CLASS = 'past-results-cell'
const YOUR_WINNINGS_CLASS = getBlockClass(ROOT_CLASS, 'your-winnings')

export const getBonusText = (bonus: number) =>
  bonus <= 1 ? 'No plus' : `Plus ${bonus}x`

const PastResultsRapidCell = (props: PastResultsRapidCellProps) => {
  const drawTime = dateUtil(props.drawDate).format(DateFormat.time)
  const kenoWinnerDisplay = getKenoWinnerDisplay(
    props.yourNumbers,
    props.winningNumbers.numbers
  )

  return (
    <tr
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, 'rapid')
      ])}
    >
      {/* Mobile */}
      <td className={getBlockClass(ROOT_CLASS, 'rapid-draw-number-and-time')}>
        <span>{props.drawNumber}</span>
        <span>{drawTime}</span>
      </td>
      {/* End Mobile */}
      {/* Desktop */}
      <td className={getBlockClass(ROOT_CLASS, 'rapid-draw-number')}>
        {props.drawNumber}
      </td>
      <td className={getBlockClass(ROOT_CLASS, 'rapid-draw-time')}>
        {drawTime}
      </td>
      {/* End Desktop */}
      <td>
        <WinningNumbers
          {...props.winningNumbers}
          yourNumbers={props.yourNumbers}
          style={props.style}
        />
      </td>
      <td>{getBonusText(props.bonus)}</td>
      {!!props.yourNumbers.length && <td>{kenoWinnerDisplay}</td>}
    </tr>
  )
}

const PastResultsDrawCell = (props: PastResultsDrawCellProps) => {
  const hasLabel = props.winningNumbers.label !== undefined
  const hasJackpot = props.jackpotInCents > 0
  const hasWinnerInfo = !!props.jackpotWinLocation
  const date = dateUtil(props.drawDate).format(
    DateFormat.shortDayShortMonthDateAndYear
  )

  const yourWinningsText = props.hasYourNumbers
    ? getYourWinningsDisplay(
        props.gameIdentifier,
        props.winningNumbers.numbers,
        props.yourNumbers,
        props.winningNumbers.specialBalls?.[0]?.value,
        props.yourNumbersSpecialBall
      )
    : ''

  const isWinningText = yourWinningsText !== DEFAULT_YOUR_WINNINGS_TEXT

  return (
    <tr
      className={joinClasses([
        ROOT_CLASS,
        hasLabel
          ? getModifierClass(ROOT_CLASS, 'draw-label')
          : getModifierClass(ROOT_CLASS, 'draw')
      ])}
    >
      <td>{date}</td>
      <td>
        <WinningNumbers
          {...props.winningNumbers}
          yourNumbers={props.yourNumbers}
          style={props.style}
        />
      </td>
      {props.hasYourNumbers ? (
        <td
          className={joinClasses([
            YOUR_WINNINGS_CLASS,
            getModifierClass(YOUR_WINNINGS_CLASS, 'winner', isWinningText)
          ])}
        >
          {yourWinningsText}
        </td>
      ) : (
        hasJackpot && (
          <td>
            {hasJackpot && (
              <span className={getBlockClass(ROOT_CLASS, 'draw-jackpot')}>
                {props.jackpotWinLocation === 'ROLLOVER' ? (
                  <span>{`Est. ${CurrencyUtilities.numericFormat(CurrencyUtilities.getDollarsFromCents(props.jackpotInCents))} jackpot - Rollover`}</span>
                ) : (
                  <span>{`${CurrencyUtilities.numericFormat(CurrencyUtilities.getDollarsFromCents(props.jackpotInCents))} jackpot`}</span>
                )}
              </span>
            )}
            {hasWinnerInfo && (
              <span className={getBlockClass(ROOT_CLASS, 'jackpot-won-text')}>
                {`Jackpot Won: ${props.jackpotWinLocation}`}
              </span>
            )}
          </td>
        )
      )}
    </tr>
  )
}

export const PastResultsCell = (props: PastResultsCellProps) => {
  return props.type === GameType.rapid ? (
    <PastResultsRapidCell {...props} />
  ) : (
    <PastResultsDrawCell {...props} />
  )
}
