import * as React from 'react'
import './styles.scss'
import { getModifierClass, joinClasses } from '../component-helpers'

export type DotNotificationProps = {
  isActive: boolean
  count?: number
}

const ROOT_CLASS = 'dot-notification'

export const DotNotification = (props: DotNotificationProps) => {
  if (!props.isActive) return null
  const count = props.count && props.count > 99 ? '99+' : props.count

  return (
    <div
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, 'no-count', props.count === undefined)
      ])}
    >
      <span>{count}</span>
    </div>
  )
}
