import * as React from 'react'
import * as RadixCollapsible from '@radix-ui/react-collapsible'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { ChevronDown } from '@Client/icons/chevron-down'
import { AccessibleKey, TabIndex } from '@Client/constants'
import './styles.scss'

export type ControlledCollapsibleProps = {
  header: JSX.Element
  content: JSX.Element
  className?: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  hideChevron?: boolean
}

const ROOT_CLASS = 'collapsible'
const TRIGGER_CLASS = getBlockClass(ROOT_CLASS, 'trigger')
const CONTENT_CLASS = getBlockClass(ROOT_CLASS, 'content')

export const ControlledCollapsible = (props: ControlledCollapsibleProps) => {
  const propsContentClassName = props.className
    ? getBlockClass(props.className, 'content')
    : undefined
  const propsHeaderClassName = props.className
    ? getBlockClass(props.className, 'header')
    : undefined
  return (
    <RadixCollapsible.Root
      className={joinClasses([ROOT_CLASS, props.className])}
      open={props.isOpen}
      onOpenChange={props.setIsOpen}
    >
      <RadixCollapsible.Trigger asChild>
        <div
          className={joinClasses([
            getBlockClass(ROOT_CLASS, 'header-container'),
            propsHeaderClassName
          ])}
          role='button'
          tabIndex={TabIndex.accessible}
          aria-label={
            props.content.props.name
              ? `Toggle ${props.content.props.name} collapsible`
              : 'Toggle collapsible state'
          }
          aria-pressed={props.isOpen}
          onKeyDown={(e) => {
            if (
              e.key === AccessibleKey.enter ||
              e.key === AccessibleKey.space
            ) {
              e.preventDefault()
              props.setIsOpen(!props.isOpen)
            }
          }}
        >
          {props.header}
          {props.hideChevron !== true && (
            <span
              className={joinClasses([
                TRIGGER_CLASS,
                getModifierClass(TRIGGER_CLASS, 'is-open', props.isOpen)
              ])}
            >
              <ChevronDown />
            </span>
          )}
        </div>
      </RadixCollapsible.Trigger>
      <RadixCollapsible.Content
        className={joinClasses([
          CONTENT_CLASS,
          getModifierClass(CONTENT_CLASS, 'is-open', props.isOpen),
          propsContentClassName
        ])}
      >
        {props.content}
      </RadixCollapsible.Content>
    </RadixCollapsible.Root>
  )
}
