import { iLotteryActions } from '@Client/reducers/ilottery/ilottery-reducer'
import { Dispatch } from '@reduxjs/toolkit'
import {
  NeoGamesSessionUpdate,
  NeoGamesBalance,
  NeoGamesGamePlayModeShort,
  NeoGamesOpenPageCustomInfo,
  NeoGamesShoppingCartStatus
} from './ng-types'
import { NeoGamesService } from './ng-service'
import { NeoGamesPageHandler } from './open-page/ng-open-page-handler'
import { Nullable } from '@Util/utility-types'
import { uiActions } from '@Client/reducers/ui/ui-reducer'

const FRAMEWORK_READY_EVENT = 'fwReady'

const initialize = (dispatch: Dispatch) => {
  window.getFrameworkPreferences = NeoGamesService.getFrameworkPreferences
  dispatch(iLotteryActions.setIsReadyToLoadLibrary(true))
  document.addEventListener(FRAMEWORK_READY_EVENT, () => {
    const host = NeoGamesService.getHost()
    const framework = NeoGamesService.getFramework()

    const updateSession = () => {
      const session = framework?.getSessionDetails() ?? null
      dispatch(iLotteryActions.setSession(session))
    }
    if (host) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      host.onSessionStatusUpdate((params: NeoGamesSessionUpdate) => {
        updateSession()
      })
      host.onGamesListUpdated((data) => {
        dispatch(
          iLotteryActions.setUpdatedGames({
            instantGames: data.games.instant,
            drawGames: data.games.dbg
          })
        )
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      host.instantGame.gameLoadStart(
        (game: number, mode: NeoGamesGamePlayModeShort) => {
          dispatch(
            iLotteryActions.setInstantGameLoaded({
              isLoaded: true,
              gameId: game,
              playMode: mode
            })
          )
        }
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      host.instantGame.gameClosed((game: number, mode: string) => {
        dispatch(iLotteryActions.closeGame())
      })
      host.clearURLParams((paramsToRemove) => {
        const currentParams = new URLSearchParams(window.location.search)
        paramsToRemove.forEach((param) => {
          currentParams.delete(param)
        })
        const paramsString = currentParams.toString()
        const newUrl = `${window.location.pathname}${paramsString.length > 0 ? `?${paramsString}` : ''}`
        window.history.replaceState({}, '', newUrl)
      })
      host.onBalanceUpdated((params: NeoGamesBalance) => {
        dispatch(iLotteryActions.setBalance(params))
      })
      host.openPage(
        (
          key: string,
          destination: Nullable<string>,
          customInfo: Nullable<NeoGamesOpenPageCustomInfo>
        ) => {
          NeoGamesPageHandler.processOpenPageRequest(
            key,
            destination,
            customInfo
          )
        }
      )
      host.onVisitedBonuses((error?: boolean) => {
        if (!error) {
          dispatch(iLotteryActions.setHasUnviewedBonuses(false))
        }
      })
      host.shoppingCartStatusUpdated((params: NeoGamesShoppingCartStatus) => {
        const count = params && !params.IsError ? params.NumberOfItems : 0
        dispatch(iLotteryActions.setShoppingCartCount(count))
      })
      host.loginAborted(() => {
        dispatch(iLotteryActions.loginAborted())
      })
      host.registrationAborted(() => {
        dispatch(iLotteryActions.registrationAborted())
      })
      host.onStateChange((state: 'active' | 'idle') => {
        dispatch(uiActions.setILotteryWidgetOpen(state === 'active'))
      })
    }
    dispatch(iLotteryActions.setIsFrameworkReady(true))
    updateSession()
    dispatch(iLotteryActions.requestUpdatedGames())
    dispatch(iLotteryActions.requestBalance())
    dispatch(iLotteryActions.requestShoppingCartStatus())
  })
}

export const NeoGamesHost = {
  initialize
}
