import * as React from 'react'
import { Page } from '../page/page-ui'
import { DrawGame as DrawGameResponse } from '@interaction-gaming/game-data-service-browser-client'
import { useIsMobileOrTablet } from '@Client/hooks/use-media-query/use-media-query'
import { WinningNumbersCardContainer } from '../winning-numbers-card/winning-numbers-card-container'
import { SlidingCarousel } from '../carousel-sliding/sliding-carousel-ui'
import { getBlockClass } from '../component-helpers'
import { RoutePath } from '@Client/route-paths'
import { OnlineGameJackpotCardContainer } from '../jackpot-card/jackpot-card-container-online'
import { DrawGameJackpotCardContainer } from '../jackpot-card/jackpot-card-container-draw'
import { compact } from '@Util/array-utilities'
import { BannerCarouselContainer } from '../carousel-banner/banner-carousel-container'
import { ConstantContentName } from '@Cms/model-types/constant-content-model'
import { NewEInstantsContainer } from '../new-e-instants/new-e-instants-container'
import { AccountDepositWidgetContainer } from '../account-deposit-widget/account-deposit-widget-container'
import { MobileAppCard } from '../mobile-app-card/mobile-app-ui'
import { TypedPageContentModel } from '@Cms/model-types/page-model'
import { CmsGenericContent } from '../cms-generic-content/cms-generic-content-ui'
import './styles.scss'

export type HomePageProps = {
  drawGames: DrawGameResponse[]
  jackpotGameIds: string[]
  iLotteryJackpotIds: number[]
  genericContent?: TypedPageContentModel[]
}

const ROOT_CLASS = 'home-page'

export const HomePage = (props: HomePageProps) => {
  const isMobileOrTablet = useIsMobileOrTablet()
  return (
    <Page className={ROOT_CLASS}>
      <BannerCarouselContainer
        name={ConstantContentName.homepageBannerCarousel}
      />
      {isMobileOrTablet && (
        <div className={getBlockClass(ROOT_CLASS, 'account-widget-container')}>
          <AccountDepositWidgetContainer />
        </div>
      )}
      <div className={getBlockClass(ROOT_CLASS, 'carousels')}>
        <SlidingCarousel
          header={{
            type: 'static',
            text: 'Thrilling Jackpots'
          }}
          items={compact([
            ...props.jackpotGameIds.map((gameId) => (
              <DrawGameJackpotCardContainer
                gameDataServiceId={gameId}
                key={`${gameId}-jackpot`}
              />
            )),
            ...props.iLotteryJackpotIds.map((gameId) => (
              <OnlineGameJackpotCardContainer
                iLotteryGameId={gameId}
                key={`${gameId}-ilottery-jackpot`}
              />
            ))
          ])}
        />
        <SlidingCarousel
          header={{
            type: 'link',
            text: 'Latest Winning Numbers',
            dest: RoutePath.winningNumbers,
            helperText: 'View All'
          }}
          items={props.drawGames.map((game) => (
            <WinningNumbersCardContainer
              drawGameMetadata={game}
              key={`${game.id}-draw-games`}
            />
          ))}
        />
      </div>
      <NewEInstantsContainer />
      <div className={getBlockClass(ROOT_CLASS, 'cms-content')}>
        {props.genericContent?.map((content) => (
          <CmsGenericContent
            rootClassName={ROOT_CLASS}
            content={content}
            key={JSON.stringify(content.data)}
          />
        ))}
      </div>
      <MobileAppCard />
    </Page>
  )
}
