import * as React from 'react'
import { getBlockClass } from '../component-helpers'
import { FooterIconGroup } from './footer-icon-group'
import { FooterLinkGroup, FooterStandaloneLinks } from './footer-link-group'
import { footerCategoriesByName } from './constants'
import { FooterContactBlock } from './footer-contact-block'

const ROOT_CLASS = 'footer-desktop'

export type FooterDesktopProps = {
  onILotteryHelpClick: () => void
}

export const FooterDesktop = (props: FooterDesktopProps) => (
  <div className={ROOT_CLASS}>
    <div className={getBlockClass(ROOT_CLASS, 'column')}>
      <FooterIconGroup />
      <FooterStandaloneLinks />
    </div>
    <FooterContactBlock onILotteryHelpClick={props.onILotteryHelpClick} />
    <FooterLinkGroup title='Games' links={footerCategoriesByName.Games} />
    <FooterLinkGroup title='Partners' links={footerCategoriesByName.Partners} />
    <FooterLinkGroup title='Legal' links={footerCategoriesByName.Legal} />
  </div>
)
