import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AlertsModalState,
  AlertsState,
  FetchAllMarketingModalsSuccessPayload
} from './alerts-reducer-types'

export const initialAlertsModalState: AlertsModalState = {
  byRoute: {},
  isLoading: false,
  error: null
}

export const initialAlertsState: AlertsState = {
  modals: initialAlertsModalState
}

const alertsSlice = createSlice({
  name: 'alerts',
  initialState: initialAlertsState,
  reducers: {
    fetchAllMarketingModalsRequest: (state) => {
      state.modals.isLoading = true
      state.modals.error = null
    },
    fetchAllMarketingModalsSuccess: (
      state,
      action: PayloadAction<FetchAllMarketingModalsSuccessPayload>
    ) => {
      state.modals.isLoading = false
      state.modals.error = null
      state.modals.byRoute = action.payload
    },
    fetchAllMarketingModalsFailure: (state, action: PayloadAction<string>) => {
      state.modals.isLoading = false
      state.modals.error = action.payload
    }
  }
})

export const alertsActions = alertsSlice.actions
export const alertsReducer = alertsSlice.reducer
