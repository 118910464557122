import { DynamicUrl, GameCollection, RoutePath } from '@Client/route-paths'

const FAQ_ROUTE = 'https://faq.nhlottery.com/support/home'

export const redirectMap: Record<string, string> = {
  '/auto-renewal': '/ilottery-games/auto-renewal',
  '/games': DynamicUrl.getGameCollectionUrl(GameCollection.iLottery),
  '/games/fast-play': DynamicUrl.getGameCollectionUrl(GameCollection.inStore, {
    filters: 'gameTypeFastPlay'
  }),
  '/games/gimme-5': DynamicUrl.getGameByIdentifierUrl('gimme-five'),
  '/games/keno-603': DynamicUrl.getGameByIdentifierUrl('keno'),
  '/games/lucky-for-life': DynamicUrl.getGameByIdentifierUrl('lucky-for-life'),
  '/games/mega-millions': DynamicUrl.getGameByIdentifierUrl('mega-millions'),
  '/games/megabucks': DynamicUrl.getGameByIdentifierUrl('megabucks'),
  '/games/pick-3-pick-4': DynamicUrl.getGameByIdentifierUrl('pick-three'),
  '/games/powerball': DynamicUrl.getGameByIdentifierUrl('powerball'),
  '/games/scratch-tickets': DynamicUrl.getGameCollectionUrl(
    GameCollection.inStore,
    {
      filters: 'scratchGame'
    }
  ),
  '/games/scratch-tickets/unclaimed-top-prizes': RoutePath.prizesRemaining,
  '/ilottery-info': RoutePath.about,
  '/ilottery-games': DynamicUrl.getGameCollectionUrl(GameCollection.iLottery),
  '/ilottery-games/ilottery-faqs': FAQ_ROUTE,
  '/play-ilottery': DynamicUrl.getGameCollectionUrl(GameCollection.iLottery),
  '/winning-numbers': RoutePath.winningNumbers,
  '/winners': RoutePath.winners,
  '/sports/draftkings-mobile-sportsbook':
    '/sports/draftkings-mobile-sportsbook-1',
  '/sports/mobile-internet-sports-betting':
    '/sports/draftkings-mobile-sportsbook-1',
  '/sports/sports-book-locations': '/Sports/DraftKings-Sportsbook-Locations',
  '/about-us': RoutePath.about,
  '/about-us/faqs': FAQ_ROUTE,
  '/about-us/lottery-news': RoutePath.news,
  '/about-us/mission-statement': RoutePath.about,
  '/about-us/nh-lottery-app-faqs': FAQ_ROUTE,
  '/about-us/prize-claiming': FAQ_ROUTE,
  '/find-a-retailer': RoutePath.findARetailer,
  '/where-the-money-goes': '/About-Us/Financial-Reports',
  '/illegal-foreign-lotteries':
    'https://consumer.ftc.gov/articles/fake-prize-sweepstakes-and-lottery-scams',
  '/privacy-policy': RoutePath.termsAndConditions,
  '/careers': '/about/careers',
  '/winning-numbers?game=21&utm_campaign=dailywinningnumbersemail&utm_content=kenocta&utm_source=email&utm_medium=email&par=kenocta': `${DynamicUrl.getPastResultsForGame('keno')}&utm_campaign=dailywinningnumbersemail&utm_content=kenocta&utm_source=email&utm_medium=email&par=kenocta`,
  '/ilottery-games/?neodl=game&gameid=3500&utm_campaign=dailywinningnumbersemail&utm_content=gimme5cta&utm_source=email&utm_medium=email&par=gimme5cta': `${DynamicUrl.getGameCollectionUrl(GameCollection.iLottery)}?NeoDL=Game&GameID=3500&utm_campaign=DailyWinningNumbersEmail&utm_content=Gimme5CTA&utm_source=Email&utm_medium=email&PAR=Gimme5CTA`,
  '/ilottery-games/?neodl=game&gameid=3521&utm_campaign=dailywinningnumbersemail&utm_content=megabuckscta&utm_source=email&utm_medium=email&par=megabuckscta': `${DynamicUrl.getGameCollectionUrl(GameCollection.iLottery)}?NeoDL=Game&GameID=3521&utm_campaign=DailyWinningNumbersEmail&utm_content=MegaBucksCTA&utm_source=Email&utm_medium=email&PAR=MegaBucksCTA`,
  '/ilottery-games/?neodl=game&gameid=502&utm_campaign=dailywinningnumbersemail&utm_content=megamillionscta&utm_source=email&utm_medium=email&par=megamillionscta': `${DynamicUrl.getGameCollectionUrl(GameCollection.iLottery)}?NeoDL=Game&GameID=502&utm_campaign=DailyWinningNumbersEmail&utm_content=MegaMillionsCTA&utm_source=Email&utm_medium=email&PAR=MegaMillionsCTA`,
  '/ilottery-games/?neodl=game&gameid=507&utm_campaign=dailywinningnumbersemail&utm_content=luckyforlifecta&utm_source=email&utm_medium=email&par=luckyforlifecta': `${DynamicUrl.getGameCollectionUrl(GameCollection.iLottery)}?NeoDL=Game&GameID=507&utm_campaign=DailyWinningNumbersEmail&utm_content=LuckyForLifeCTA&utm_source=Email&utm_medium=email&PAR=LuckyForLifeCTA`,
  '/ilottery-games/?neodl=game&gameid=500&utm_campaign=dailywinningnumbersemail&utm_content=powerballcta&utm_source=email&utm_medium=email&par=powerballcta': `${DynamicUrl.getGameCollectionUrl(GameCollection.iLottery)}?NeoDL=Game&GameID=500&utm_campaign=DailyWinningNumbersEmail&utm_content=PowerballCTA&utm_source=Email&utm_medium=email&PAR=PowerballCTA`,
  '/giant': '/?PAR=BMP_PTX_NST_DEP_17399_NSP&NeoDL=registration&BC=GIANT'
}
