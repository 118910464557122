import mqtt from 'mqtt'
import { Api } from '@Client/api'
import { Dispatch } from '@reduxjs/toolkit'
import { MqttApiInfo } from '@Server/adapters/iwg-adapter'
import { getObjectKeys } from '@Util/object-utilities'
import { put, select, spawn, takeEvery } from 'redux-saga/effects'
import { IwgJackpotResponse } from '@Client/reducers/iwg-jackpots/iwg-jackpots-reducer-types'
import { iwgJackpotsActions } from '@Client/reducers/iwg-jackpots/iwg-jackpots-reducer'
import { IwgAllJackpotsResponse } from '@Server/controllers/jackpot/jackpot-controller'

const TRACKED_JACKPOT_IDS: string[] = []

export function* setupIwgJackpotSaga(dispatch: Dispatch) {
  yield takeEvery(
    iwgJackpotsActions.fetchAllIwgJackpotsRequest.type,
    handleFetchAllIwgJackpots
  )
  yield takeEvery(
    iwgJackpotsActions.fetchAllIwgJackpotsSuccess.type,
    handleNewIwgJackpots,
    dispatch
  )
}

function* handleFetchAllIwgJackpots() {
  try {
    const allIwgJackpotUrlsResponse: IwgAllJackpotsResponse =
      yield Api.callServer('/api/v1/jackpot/iwg-jackpot-url/all')
    if (allIwgJackpotUrlsResponse.status === 'success') {
      yield put(
        iwgJackpotsActions.fetchAllIwgJackpotsSuccess(
          allIwgJackpotUrlsResponse.data.jackpotUrlById
        )
      )
    } else {
      throw new Error(allIwgJackpotUrlsResponse.message)
    }
  } catch (error) {
    console.error('Error fetching all IWG jackpots:', error)
    yield put(
      iwgJackpotsActions.fetchAllIwgJackpotsFailure('Something went wrong')
    )
  }
}

const clientsByUrl: Record<string, mqtt.MqttClient> = {}
const gameIdByTopic: Record<string, string> = {}

function subscribeToJackpot(
  dispatch: Dispatch, // yield put cannot be used, as generator cannot be passed to message handler callback
  iwgId: string,
  mqttInfo: MqttApiInfo
) {
  if (!clientsByUrl[mqttInfo.signedUrl]) {
    clientsByUrl[mqttInfo.signedUrl] = mqtt.connect(mqttInfo.signedUrl, {
      clientId: mqttInfo.clientId
    })
  }

  clientsByUrl[mqttInfo.signedUrl].on('connect', () => {
    mqttInfo.topics.forEach((topic) => {
      if (getObjectKeys(gameIdByTopic).includes(topic.value)) {
        return
      }
      clientsByUrl[mqttInfo.signedUrl].subscribe(topic.value, (err) => {
        if (err) {
          console.error('Subscription error:', err)
        } else {
          gameIdByTopic[topic.value] = iwgId
        }
      })
    })
  })

  clientsByUrl[mqttInfo.signedUrl].on('message', function (topic, message) {
    if (gameIdByTopic[topic] !== iwgId) {
      return
    }
    const parsedMessage: IwgJackpotResponse = JSON.parse(message.toString())
    if (
      !Array.isArray(parsedMessage) ||
      parsedMessage.length === 0 ||
      !parsedMessage[0].a ||
      !parsedMessage[0].id
    ) {
      console.warn('Invalid jackpot message:', parsedMessage)
      return
    }
    for (const jackpot of parsedMessage) {
      dispatch(
        iwgJackpotsActions.updateIwgJackpotValue({
          jackpot,
          iwgIdentifier: iwgId
        })
      )
    }
  })

  clientsByUrl[mqttInfo.signedUrl].on('error', (err) => {
    console.error('MQTT error:', err)
  })
  clientsByUrl[mqttInfo.signedUrl].on('close', async (...args: any) => {
    console.warn('MQTT connection closed. Attempting to reconnect...', args)
    clientsByUrl[mqttInfo.signedUrl].end()
    delete clientsByUrl[mqttInfo.signedUrl]
    try {
      await subscribeToJackpot(dispatch, iwgId, mqttInfo)
    } catch (error) {
      console.error('Reconnection failed:', error)
    }
  })
}

function* handleNewIwgJackpots(dispatch: Dispatch) {
  const iwgJackpots: Record<string, MqttApiInfo> = yield select(
    (state) => state.iwgJackpots.mqttByIwgIdentifier
  )
  for (const iwgId of getObjectKeys(iwgJackpots)) {
    if (!TRACKED_JACKPOT_IDS.includes(iwgId)) {
      TRACKED_JACKPOT_IDS.push(iwgId)
      yield spawn(subscribeToJackpot, dispatch, iwgId, iwgJackpots[iwgId])
    }
  }
}
