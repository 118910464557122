import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { SalesforceSubscriber } from '@Server/manager/salesforce-manager/salesforce-manager'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { REGEX } from '@Client/regex-constants'
import { Button } from '../button/button'
import { InputText } from '../input-text/input-text'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { Modal, ModalRoot } from '../modal/modal'
import { ErrorMessage } from '../error-message/error-message-ui'
import { Optional } from '@Util/utility-types'
import './styles.scss'

export type EmailSubscribeProps = {
  onSubmit: (subscriber: SalesforceSubscriber) => void
  isLoading: boolean
  error?: string
  submissionSuccess?: boolean
  clearSubmissionSuccess: () => void
}

const ROOT_CLASS = 'email-subscribe'
const FORM_CLASS = getBlockClass(ROOT_CLASS, 'form')

enum EmailSubscribeFormField {
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName'
}

export const EmailSubscribe = (props: EmailSubscribeProps) => {
  const [emailValidateError, setEmailValidateError] =
    React.useState<Optional<string>>()
  const location = useLocation()
  const [emailSubmitted, setEmailSubmitted] = React.useState(false)
  const [email, setEmail] = React.useState<string>('')
  const [firstName, setFirstName] = React.useState<string>('')
  const [lastName, setLastName] = React.useState<string>('')
  const [showSuccess, setShowSuccess] = React.useState(false)
  const emailRef = React.useRef<HTMLInputElement>(null)

  const resetData = () => {
    setEmailSubmitted(false)
    setEmail('')
    setFirstName('')
    setLastName('')
    if (emailRef.current) {
      emailRef.current.value = ''
    }
  }

  React.useEffect(() => {
    resetData()
  }, [location.pathname])

  React.useEffect(() => {
    if (props.submissionSuccess) {
      setShowSuccess(true)
    }
  }, [props.submissionSuccess])

  const clearSuccess = () => {
    setShowSuccess(false)
    props.clearSubmissionSuccess()
    resetData()
  }

  return (
    <>
      <div className={ROOT_CLASS}>
        <div className={getBlockClass(ROOT_CLASS, 'content')}>
          <div className={getBlockClass(ROOT_CLASS, 'text')}>
            <h2 className={getBlockClass(ROOT_CLASS, 'header')}>
              Stay connected
            </h2>
            <span className={getBlockClass(ROOT_CLASS, 'subheader')}>
              Get the news on all things lottery, winning numbers, and jackpot
              alerts!
            </span>
          </div>
          <form
            className={joinClasses([
              FORM_CLASS,
              getModifierClass(FORM_CLASS, 'email-submitted', emailSubmitted)
            ])}
            onSubmit={(e) => {
              e.preventDefault()
              if (!emailSubmitted) {
                const isValidEmail = REGEX.EMAIL.test(email)
                if (emailRef.current?.value && isValidEmail) {
                  setEmailSubmitted(true)
                }
                if (!isValidEmail) {
                  setEmailValidateError('Please enter a valid email address')
                } else if (emailValidateError) {
                  setEmailValidateError(undefined)
                }
              } else {
                if (email && firstName && lastName) {
                  props.onSubmit({
                    email,
                    firstName,
                    lastName
                  })
                }
              }
            }}
          >
            <div className={getBlockClass(ROOT_CLASS, 'first-row')}>
              <InputText
                id={EmailSubscribeFormField.email}
                placeholder='Enter Email Address'
                labelText='Email'
                ariaLabel='Email Address'
                onChange={setEmail}
                ref={emailRef}
                error={emailValidateError}
              />
              {!emailSubmitted && (
                <Button
                  type='submit'
                  ariaLabel='Next'
                  className={getBlockClass(ROOT_CLASS, 'submit')}
                  disabled={!emailRef.current?.value}
                >
                  Next
                </Button>
              )}
            </div>
            {emailSubmitted && (
              <>
                <InputText
                  id={EmailSubscribeFormField.firstName}
                  placeholder='Enter First Name'
                  ariaLabel='First Name'
                  onChange={setFirstName}
                />
                <InputText
                  id={EmailSubscribeFormField.lastName}
                  placeholder='Enter Last Name'
                  ariaLabel='Last Name'
                  onChange={setLastName}
                />
              </>
            )}
            {emailSubmitted && (
              <Button
                ariaLabel='Submit Email Subscription'
                type='submit'
                className={getBlockClass(ROOT_CLASS, 'submit')}
                disabled={!email || !firstName || !lastName || props.isLoading}
              >
                {props.isLoading ? (
                  <LoadingSpinner
                    color='white'
                    id={getBlockClass(ROOT_CLASS, 'loading')}
                  />
                ) : (
                  'Submit'
                )}
              </Button>
            )}
            {props.error && (
              <ErrorMessage message='Something went wrong, please try again' />
            )}
          </form>
        </div>
      </div>
      <ModalRoot
        isOpen={showSuccess}
        onOpenChange={(open) => {
          setShowSuccess(open)
          clearSuccess()
        }}
      >
        <Modal
          useCloseButton
          ariaTitle='You are now signed up'
          ariaDescription='Email submission was successful'
          mobileFullScreen={false}
        >
          <div className={getBlockClass(ROOT_CLASS, 'success-content')}>
            <span className={getBlockClass(ROOT_CLASS, 'success-header')}>
              You are now signed up!
            </span>
            {email && (
              <span className={getBlockClass(ROOT_CLASS, 'success-text')}>
                {`${email} has been subscribed.`}
              </span>
            )}
            <Button
              ariaLabel='Close success modal'
              onClick={clearSuccess}
              className={getBlockClass(ROOT_CLASS, 'success-close')}
            >
              Close
            </Button>
          </div>
        </Modal>
      </ModalRoot>
    </>
  )
}
