import * as React from 'react'
import {
  DateSelector,
  DateSelectorProps
} from '../date-selector/date-selector-ui'
import { ChevronLeft } from '@Client/icons/chevron-left'
import { ChevronRight } from '@Client/icons/chevron-right'
import { Button } from '../button/button'
import { getBlockClass } from '../component-helpers'
import './styles.scss'

export type DateSelectorWithArrowsProps = DateSelectorProps & {
  getNextDate: (currentDate?: Date) => Date
  getPreviousDate: (currentDate?: Date) => Date
}

const ROOT_CLASS = 'date-selector-with-arrows'

export const DateSelectorWithArrows = (props: DateSelectorWithArrowsProps) => {
  return (
    <div className={ROOT_CLASS}>
      <Button
        onClick={() => {
          props.onDateChange(props.getPreviousDate(props.selectedDate))
        }}
        ariaLabel='Select previous valid date'
        className={getBlockClass(ROOT_CLASS, 'arrow')}
      >
        <ChevronLeft />
      </Button>
      <DateSelector {...props} />
      <Button
        onClick={() => {
          props.onDateChange(props.getNextDate(props.selectedDate))
        }}
        ariaLabel='Select next valid date'
        className={getBlockClass(ROOT_CLASS, 'arrow')}
        disabled={props.isDateDisabled?.(props.getNextDate(props.selectedDate))}
      >
        <ChevronRight />
      </Button>
    </div>
  )
}
