import * as React from 'react'
import { ImageBannerModel } from '@Cms/model-types/image-banner-model'
import { AsyncImage } from '../async-image/async-image-ui'
import { GeneralLink } from '../link/link-ui'
import { LinkField } from '@Cms/model-types/link-model'
import {
  getBlockClass,
  getCustomCssProperties,
  joinClasses
} from '../component-helpers'
import './styles.scss'

export type ImageBannerProps = {
  imageBanner: ImageBannerModel
  className?: string
}

const ROOT_CLASS = 'image-banner'
const MIN_HEIGHT_PX = 300

export const ImageBanner = React.forwardRef(
  (props: ImageBannerProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const bannerContent = (
      <div
        className={joinClasses([ROOT_CLASS, props.className])}
        style={getCustomCssProperties({
          '--background-color': props.imageBanner.backgroundColorHex
        })}
        ref={ref}
      >
        <AsyncImage
          src={props.imageBanner.image.mobileImage.imageUrl}
          alt={props.imageBanner.image.description}
          className={getBlockClass(ROOT_CLASS, 'mobile-image')}
          minHeightPx={MIN_HEIGHT_PX}
        />
        <AsyncImage
          src={props.imageBanner.image.desktopImage.imageUrl}
          alt={props.imageBanner.image.description}
          className={getBlockClass(ROOT_CLASS, 'desktop-image')}
          minHeightPx={MIN_HEIGHT_PX}
        />
      </div>
    )
    if (props.imageBanner.link) {
      return (
        <GeneralLink dest={props.imageBanner.link[LinkField.route]}>
          {bannerContent}
        </GeneralLink>
      )
    }

    return bannerContent
  }
)
