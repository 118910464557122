import * as React from 'react'
import { GameModel, GameRulesModel } from '@Cms/model-types/game-model'
import { GameRules } from './game-rules-ui'
import { useFetchFunction } from '@Client/hooks/use-fetch/use-fetch-function'
import { CmsGamesApi } from '@Client/api/cms-games'
import { ApiResponse } from '@Server/controllers/controller-helpers'

export type GameRulesContainerProps = {
  game: GameModel
}

export const GameRulesContainer = (props: GameRulesContainerProps) => {
  const { isLoading, data, error, fetchData } =
    useFetchFunction<ApiResponse<GameRulesModel>>()

  React.useEffect(() => {
    fetchData(
      async () => await CmsGamesApi.fetchGameRules(props.game.identifier)
    )
  }, [props.game.identifier])

  return (
    <GameRules
      isLoading={isLoading}
      error={error}
      data={data?.status === 'success' ? data.data : undefined}
    />
  )
}
