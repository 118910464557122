import * as React from 'react'
import { GameRulesModel } from '@Cms/model-types/game-model'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { ErrorMessage } from '../error-message/error-message-ui'
import { Collapsible } from '../collapsible/collapsible-ui'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { CmsGenericContent } from '../cms-generic-content/cms-generic-content-ui'
import './styles.scss'

export type GameRulesProps = {
  isLoading: boolean
  error?: string
  data?: GameRulesModel
}

const ROOT_CLASS = 'game-rules'

export const GameRules = (props: GameRulesProps) => {
  return (
    <div
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(
          ROOT_CLASS,
          'with-state',
          props.isLoading || !!props.error
        )
      ])}
    >
      {props.isLoading ? (
        <LoadingSpinner />
      ) : props.error ? (
        <ErrorMessage message={props.error} />
      ) : props.data ? (
        props.data.rules.map((accordion) => {
          return (
            <Collapsible
              className={getBlockClass(ROOT_CLASS, 'accordion')}
              key={accordion.title}
              header={
                <span className={getBlockClass(ROOT_CLASS, 'header')}>
                  {accordion.title}
                </span>
              }
              content={
                <div className={getBlockClass(ROOT_CLASS, 'content')}>
                  {accordion.content.map((content) => (
                    <CmsGenericContent
                      key={`${accordion.title}-${JSON.stringify(content.data)}`}
                      content={content}
                      rootClassName={ROOT_CLASS}
                    />
                  ))}
                </div>
              }
            />
          )
        })
      ) : null}
    </div>
  )
}
