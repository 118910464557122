import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeEvery } from 'redux-saga/effects'
import { drawResultsActions } from '@Client/reducers/draw-results/draw-results-reducer'
import { FetchDrawResultPayload } from '@Client/reducers/draw-results/draw-results-reducer-types'
import { DrawGameApi, DrawingDetailsResponse } from '@Client/api/draw-games'
import { dateUtil } from '@Util/date-util'
import { Optional } from '@Util/utility-types'
import { serializeDates } from '@Util/object-utilities'
import { Api } from '@Client/api'

export function* setupDrawResultsSaga() {
  yield takeEvery(
    drawResultsActions.fetchDrawResultRequest.type,
    fetchDrawResult
  )
}

function* fetchDrawResult(action: PayloadAction<FetchDrawResultPayload>) {
  try {
    const date = dateUtil(action.payload.asOfISO).toDate()
    const response: Optional<DrawingDetailsResponse> =
      yield DrawGameApi.getDrawingDetails(action.payload.gameId, date)
    if (!response) {
      throw new Error('Error fetching drawing details')
    }
    for (const drawing of [response.currentDrawing, response.previousDrawing]) {
      if (drawing) {
        yield put(
          drawResultsActions.fetchDrawResultSuccess({
            gameId: action.payload.gameId,
            dateString: drawing.when.drawTime.toISOString(),
            drawResult: serializeDates(drawing)
          })
        )
      }
    }
  } catch (error) {
    yield put(
      drawResultsActions.fetchDrawResultFailure({
        gameId: action.payload.gameId,
        error: (error as Error)?.message ?? Api.DEFAULT_ERROR
      })
    )
  }
}
