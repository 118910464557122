import * as React from 'react'
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef
} from '@vis.gl/react-google-maps'
import { MapPin } from '@Client/icons/map-pin'
import { Nullable } from '@Util/utility-types'

const ROOT_CLASS = 'map-marker'

export type MarkerProps = {
  position: {
    lat: number
    lng: number
  }
  infoWindowContent: React.ReactNode
  id: string
  openMarkerId: Nullable<string>
  setOpenMarkerId: (id: Nullable<string>) => void
}

export const MapMarker = (props: MarkerProps) => {
  const [markerRef, marker] = useAdvancedMarkerRef()

  const handleMarkerClick = () => {
    const marker = props.openMarkerId === props.id ? null : props.id
    props.setOpenMarkerId(marker)
  }

  return (
    <>
      <AdvancedMarker
        className={ROOT_CLASS}
        position={props.position}
        onClick={handleMarkerClick}
        ref={markerRef}
      >
        <MapPin />
      </AdvancedMarker>
      {props.openMarkerId === props.id && (
        <InfoWindow anchor={marker} onClose={handleMarkerClick}>
          {props.infoWindowContent}
        </InfoWindow>
      )}
    </>
  )
}
