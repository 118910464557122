import * as React from 'react'
import { getCustomCssProperties } from '../component-helpers'
import './styles.scss'

export type GameCalendarHeaderProps = {
  gameName: string
  textColor?: string
  backgroundColor?: string
}

const ROOT_CLASS = 'game-calendar-header'

export const GameCalendarHeader = (props: GameCalendarHeaderProps) => (
  <div
    className={ROOT_CLASS}
    style={
      props.textColor || props.backgroundColor
        ? getCustomCssProperties({
            '--game-text-color': props.textColor ?? '',
            '--game-bkg-color': props.backgroundColor ?? ''
          })
        : undefined
    }
  >
    {props.gameName}
  </div>
)
