import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import {
  createBrowserRouter,
  matchRoutes,
  RouterProvider
} from 'react-router-dom'
import { routes } from './routes'
import { store } from './reducers/store'
import { sagaMiddleware, spawnAllSagas } from './sagas'
import { NeoGamesHost } from './services/pam/ng-host'

// Load the lazy matches and update the routes before creating router
// so we can hydrate the SSR-rendered content synchronously
const hydrate = async () => {
  const lazyMatches = matchRoutes(routes, window.location)?.filter(
    (match) => match.route.lazy
  )
  if (lazyMatches && lazyMatches?.length > 0) {
    await Promise.all(
      lazyMatches.map(async (m) => {
        const routeModule = await m.route.lazy?.()
        Object.assign(m.route, {
          ...routeModule,
          lazy: undefined
        })
      })
    )
  }
  const router = createBrowserRouter(routes)
  sagaMiddleware.run(spawnAllSagas, store.dispatch)

  const rootElement = document.getElementById('root')
  if (rootElement) {
    ReactDOM.hydrateRoot(
      rootElement,
      <Provider store={store}>
        <RouterProvider router={router} fallbackElement={null} />
      </Provider>
    )
    delete window.__PRELOADED_STATE__
  } else {
    console.error('No root element to hydrate')
  }
}

NeoGamesHost.initialize(store.dispatch)

hydrate()
