import { EntryFieldTypes, EntryLink } from 'contentful'
import { CmsModel } from './cms-models'
import {
  ResponsiveImageModel,
  ResponsiveImageSkeleton
} from './responsive-image-model'
import { TextModel, TextSkeleton } from './text-model'
import { CtaModel, CtaSkeleton } from './cta-model'

export enum FeedItemField {
  cta = 'cta',
  feed = 'feed',
  header = 'header',
  date = 'date',
  previewImage = 'previewImage',
  previewText = 'previewText'
}

export enum FeedType {
  news = 'News',
  winningStories = 'Winning Stories'
}

export const FeedTypes = Object.values(FeedType)

export const isValidFeedType = (feedType: any): feedType is FeedType =>
  FeedTypes.includes(feedType)

export type FeedItemSkeleton = {
  contentTypeId: CmsModel.feedItem
  fields: {
    [FeedItemField.cta]?: EntryFieldTypes.EntryLink<CtaSkeleton>
    [FeedItemField.feed]: EntryFieldTypes.Symbol<FeedType>
    [FeedItemField.date]: EntryFieldTypes.Date
    [FeedItemField.header]: EntryFieldTypes.Symbol
    [FeedItemField.previewImage]?: EntryFieldTypes.EntryLink<ResponsiveImageSkeleton>
    [FeedItemField.previewText]: EntryFieldTypes.EntryLink<TextSkeleton>
  }
}

export type FeedItemModelPublish = {
  cta?: {
    sys: EntryLink
  }
  previewImage?: {
    sys: EntryLink
  }
  previewText: {
    sys: EntryLink
  }
  date: string
  feed: FeedType
  header: string
}

export type FeedItemModel = {
  cta?: CtaModel
  feed: FeedType
  header: string
  previewImage?: ResponsiveImageModel
  previewText: TextModel
  formattedDate: string
}

export const FeedItemFields = Object.values(FeedItemField)
