import * as React from 'react'
import { GameModel } from '@Cms/model-types/game-model'
import { GameDetailsPageStructure } from '../game-details-page/game-details-page-structure'
import { AsyncImage } from '../async-image/async-image-ui'
import { getBlockClass } from '../component-helpers'
import { DateSelectorWithArrows } from '../date-selector-with-arrows/date-selector-with-arrows-ui'
import { GameRulesContainer } from '../game-rules/game-rules-container'
import {
  WinningNumbers,
  WinningNumbersProps
} from '../winning-numbers/winning-numbers-ui'
import { Nullable } from '@Util/utility-types'
import { DateFormat, dateUtil } from '@Util/date-util'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { GameDetailsCtas } from '../game-details-page-ctas/game-details-ctas'
import { GameCalendarHeader } from '../game-calendar-header/game-calendar-header'
import { CsvTableModel } from '@Cms/model-types/csv-table-model'
import { ErrorMessage } from '../error-message/error-message-ui'
import { Table } from '../table/table-ui'
import { getGameSpecificDisclaimers, getYouTubeLink } from './helpers'
import { GeneralLink } from '../link/link-ui'
import { PendingAnimation } from '../pending-animation/pending-animation'
import './styles.scss'

export type GameDetailsWinningNumbersProps = {
  winningNumberGroups: WinningNumbersProps[]
  getNextDrawDate: (currentDate?: Date) => Date
  getPreviousDrawDate: (currentDate?: Date) => Date
  onNextMonth: (date: Date) => void
  onPreviousMonth: (date: Date) => void
  isDateDisabled: (date: Date) => boolean
  selectedDate?: Date
  onDateChange: (date?: Date) => void
  isLoading: boolean
  error?: Nullable<string>
}

export type GameDetailsJackpotProps =
  | {
      estimatedJackpotDisplay: string
      jackpotCashValueDisplay?: string
      status: 'ACTIVE'
    }
  | {
      status: 'PENDING'
    }
  | {
      status: 'LOADING'
    }

export type DrawGameDetailsPageLoadedProps = {
  game: GameModel
  winningNumbersProps: GameDetailsWinningNumbersProps
  type: 'PROGRESSIVE' | 'FIXED'
  jackpot: GameDetailsJackpotProps
  nextDrawing: {
    drawBreak: Date
    drawTime: Date
  }
  isLoading: false
  isILotteryEnabled: boolean
  onBuyNowClick: () => void
  onBuySubscriptionClick: () => void
  prizeTableData?: CsvTableModel
  isPrizeTableLoading: boolean
  prizeTableError?: string
  overallOdds?: number
  hasILottery: boolean
}

export type DrawGameDetailsPageProps =
  | DrawGameDetailsPageLoadedProps
  | {
      isLoading: true
    }

const ROOT_CLASS = 'draw-game-details-page'
const INFO_LABEL_CLASS = getBlockClass(ROOT_CLASS, 'info-label')
const INFO_VALUES_CLASS = getBlockClass(ROOT_CLASS, 'info-values')
const EST_JACKPOT_CLASS = getBlockClass(ROOT_CLASS, 'est-jackpot')

const DrawGameDetailsPageInfo = (props: DrawGameDetailsPageLoadedProps) => {
  const now = dateUtil()
  const drawTimeObj = dateUtil(props.nextDrawing.drawTime)
  const drawBreakObj = dateUtil(props.nextDrawing.drawBreak)
  return (
    <>
      <div className={getBlockClass(ROOT_CLASS, 'info')}>
        <AsyncImage
          src={props.game.imageUrl}
          alt={`${props.game.name} Game Image`}
          className={getBlockClass(ROOT_CLASS, 'game-image')}
        />
        <div className={getBlockClass(ROOT_CLASS, 'current-info')}>
          <div className={getBlockClass(ROOT_CLASS, 'jackpot-info')}>
            {props.type === 'PROGRESSIVE' ? (
              <>
                <span className={INFO_LABEL_CLASS}>Estimated Jackpot</span>
                <div className={INFO_VALUES_CLASS}>
                  <span className={EST_JACKPOT_CLASS}>
                    {props.jackpot.status === 'ACTIVE' ? (
                      `${props.jackpot.estimatedJackpotDisplay}`
                    ) : (
                      <PendingAnimation
                        alignment='center'
                        useAsterisk={false}
                      />
                    )}
                  </span>
                  {props.jackpot.status === 'ACTIVE' &&
                    props.jackpot.jackpotCashValueDisplay && (
                      <span className={getBlockClass(ROOT_CLASS, 'cash-value')}>
                        {`Cash Value ${props.jackpot.jackpotCashValueDisplay}`}
                      </span>
                    )}
                </div>
              </>
            ) : (
              <>
                <span className={INFO_LABEL_CLASS}>Win up to</span>
                <span className={EST_JACKPOT_CLASS}>
                  {props.jackpot.status === 'ACTIVE' ? (
                    `${props.jackpot.estimatedJackpotDisplay}`
                  ) : (
                    <PendingAnimation alignment='center' useAsterisk={false} />
                  )}
                </span>
              </>
            )}
          </div>
          <div className={getBlockClass(ROOT_CLASS, 'next-drawing-info')}>
            <span className={INFO_LABEL_CLASS}>Next Drawing</span>
            <div className={INFO_VALUES_CLASS}>
              <span className={getBlockClass(ROOT_CLASS, 'next-drawing-time')}>
                {`${
                  drawTimeObj.isSame(now, 'day')
                    ? 'Today'
                    : drawTimeObj.format(DateFormat.shortDayShortMonthDate)
                } at ${drawTimeObj.format(DateFormat.time)}`}
              </span>
              <span className={getBlockClass(ROOT_CLASS, 'next-drawing-break')}>
                {`Drawing Closes ${drawBreakObj.isSame(now, 'day') ? 'Today' : drawBreakObj.format(DateFormat.shortDay)} at ${drawBreakObj.format(DateFormat.time)}`}
              </span>
            </div>
          </div>
          <GameDetailsCtas
            game={props.game}
            hasILottery={props.hasILottery}
            onBuyNowClick={props.onBuyNowClick}
            onBuySubscriptionClick={props.onBuySubscriptionClick}
            isILotteryEnabled={props.isILotteryEnabled}
          />
        </div>
      </div>
      <GameRulesContainer game={props.game} />
    </>
  )
}

const DrawGameDetailsPageResults = (props: DrawGameDetailsPageLoadedProps) => {
  const gameSpecificDisclaimers = getGameSpecificDisclaimers(
    props.game.identifier
  )
  const youTubeLink = getYouTubeLink(props.game.identifier)
  return (
    <>
      <div className={getBlockClass(ROOT_CLASS, 'winning-numbers')}>
        <span className={getBlockClass(ROOT_CLASS, 'winning-numbers-title')}>
          Latest Winning Numbers
        </span>
        <div className={getBlockClass(ROOT_CLASS, 'winning-numbers-container')}>
          <DateSelectorWithArrows
            inputName={`${props.game.name}-winning-numbers-date`}
            onDateChange={props.winningNumbersProps.onDateChange}
            getNextDate={props.winningNumbersProps.getNextDrawDate}
            getPreviousDate={props.winningNumbersProps.getPreviousDrawDate}
            onNextMonth={props.winningNumbersProps.onNextMonth}
            onPreviousMonth={props.winningNumbersProps.onPreviousMonth}
            selectedDate={props.winningNumbersProps.selectedDate}
            isDateDisabled={props.winningNumbersProps.isDateDisabled}
            headerElement={
              <GameCalendarHeader
                gameName={props.game.name}
                backgroundColor={props.game.branding?.backgroundColorHex}
                textColor={props.game.branding?.fontColorHex}
              />
            }
          />
          {props.winningNumbersProps.isLoading ? (
            <LoadingSpinner />
          ) : (
            props.winningNumbersProps.winningNumberGroups.map(
              (winningNumbers, i) => (
                <WinningNumbers
                  {...winningNumbers}
                  align={winningNumbers.label ? undefined : 'center'}
                  key={`${props.winningNumbersProps.selectedDate?.toISOString()}-${i}`}
                />
              )
            )
          )}
        </div>
        {youTubeLink && (
          <GeneralLink
            dest={youTubeLink}
            className={getBlockClass(ROOT_CLASS, 'youtube-link')}
          >
            Watch Drawings
          </GeneralLink>
        )}
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'prize-table-section')}>
        {props.isPrizeTableLoading ? (
          <LoadingSpinner />
        ) : props.prizeTableError ? (
          <ErrorMessage />
        ) : props.prizeTableData ? (
          <div className={getBlockClass(ROOT_CLASS, 'prize-table-container')}>
            <span className={getBlockClass(ROOT_CLASS, 'prize-table-header')}>
              Prizes and Odds
            </span>
            <Table table={props.prizeTableData} />
          </div>
        ) : null}
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'overall-odds')}>
        {props.overallOdds && (
          <span className={getBlockClass(ROOT_CLASS, 'overall-odds-text')}>
            Overall Odds of Winning
            <span className={getBlockClass(ROOT_CLASS, 'overall-odds-value')}>
              1:{props.overallOdds}
            </span>
          </span>
        )}
        <div className={getBlockClass(ROOT_CLASS, 'odds-disclaimers')}>
          <span>Odds rounded to the nearest whole number.</span>
          <span>
            You win the highest prize shown above for the number of matches in a
            single play.{' '}
          </span>
          {gameSpecificDisclaimers?.map((disclaimer) => (
            <span
              className={getBlockClass(ROOT_CLASS, 'game-disclaimer')}
              key={disclaimer}
            >
              {disclaimer}
            </span>
          ))}
        </div>
      </div>
    </>
  )
}

export const DrawGameDetailsPage = (props: DrawGameDetailsPageProps) => {
  return props.isLoading ? (
    <div className={getBlockClass(ROOT_CLASS, 'state')}>
      <LoadingSpinner />
    </div>
  ) : (
    <GameDetailsPageStructure
      gameInfo={<DrawGameDetailsPageInfo {...props} />}
      gameResults={<DrawGameDetailsPageResults {...props} />}
      className={ROOT_CLASS}
    />
  )
}
