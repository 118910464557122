import * as React from 'react'
import { debounce } from 'lodash'
import { useAppSelector } from '../redux-hooks'
import { NeoGamesService } from '@Client/services/pam/ng-service'

const DEBOUNCE_DELAY_MS = 30000
const TRACKED_CLIENT_EVENTS = ['click', 'keydown', 'scroll']

const manageListenerForEvents = (
  listenerAction: 'addEventListener' | 'removeEventListener',
  events: string[],
  eventHandler: _.DebouncedFunc<() => void>
) =>
  events.forEach((eventName) =>
    document[listenerAction](eventName, eventHandler)
  )

const subscribeToEventTracking = (handler: _.DebouncedFunc<() => void>) => {
  manageListenerForEvents('addEventListener', TRACKED_CLIENT_EVENTS, handler)
}

const unsubscribeFromEventTracking = (handler: _.DebouncedFunc<() => void>) => {
  manageListenerForEvents('removeEventListener', TRACKED_CLIENT_EVENTS, handler)
  handler.cancel()
}

export const useMaintainAuth = () => {
  const isAuthenticated = useAppSelector(
    (state) => state.iLottery.isPlayerAuthenticated
  )

  const handleActiveClient = () => {
    if (isAuthenticated) {
      NeoGamesService.keepAlive()
    }
  }

  const debouncedHandleActiveClient = debounce(
    handleActiveClient,
    DEBOUNCE_DELAY_MS,
    {
      leading: true,
      maxWait: DEBOUNCE_DELAY_MS
    }
  )

  React.useEffect(() => {
    isAuthenticated
      ? subscribeToEventTracking(debouncedHandleActiveClient)
      : unsubscribeFromEventTracking(debouncedHandleActiveClient)

    return () => unsubscribeFromEventTracking(debouncedHandleActiveClient)
  }, [isAuthenticated])
}
