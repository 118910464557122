export function formatPhoneNumber(rawPhoneNumber: string): string {
  if (rawPhoneNumber.length === 10) {
    return rawPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  }
  return rawPhoneNumber.replace(/(\d{3})(\d{4})/, '$1-$2')
}

// shout-out https://www.geeksforgeeks.org/how-to-create-hash-from-string-in-javascript/#
export function insecureStringToHash(string: string): number {
  let hash = 0

  if (string.length == 0) {
    return hash
  }

  for (let i = 0; i < string.length; ++i) {
    const char = string.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  }

  return hash
}
