import * as React from 'react'
import './styles.scss'

export const YoutubeIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
    viewBox='0 0 374.32 323.97'
    width={40}
    height={40}
  >
    <title>Youtube Icon</title>
    <g>
      <g>
        <path
          className='youtube-path'
          d='M322.31,107.06c-4.31-31.77-20.46-37.15-52.23-39.85-27.46-2.15-58.69-3.23-82.92-3.23s-56,1.08-82.92,3.23c-31.77,2.69-48.46,8.08-52.77,39.85-1.62,14.54-2.69,34.46-2.69,54.92s1.08,40.92,2.69,54.92c4.31,32.31,21,37.15,52.77,39.85,26.92,2.69,58.69,3.23,82.92,3.23s55.46-.54,82.92-3.23c31.77-2.69,47.92-7.54,52.23-39.85,2.15-14,3.23-34.46,3.23-54.92s-1.08-40.38-3.23-54.92ZM159.16,203.99v-83.46l72.15,41.46-72.15,42Z'
        />
      </g>
    </g>
  </svg>
)
