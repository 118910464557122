import { Optional } from '@Util/utility-types'
import { dateUtil } from '@Util/date-util'
import { WinnersFormFieldKey, WinnersFormPayload } from './winners-form-ui'

export enum WinnersFormField {
  startDate = 'startDate',
  endDate = 'endDate',
  selectedGames = 'selectedGames'
}

export const validateField = (
  formData: WinnersFormPayload,
  fieldId: WinnersFormFieldKey,
  fieldValue?: string | number | string[]
): Optional<string> => {
  switch (fieldId) {
    case WinnersFormField.startDate:
      if (
        fieldValue &&
        !Array.isArray(fieldValue) &&
        dateUtil().isBefore(dateUtil(fieldValue))
      ) {
        return 'Start date must be in the past'
      }
      break
    case WinnersFormField.endDate:
      if (
        fieldValue &&
        !Array.isArray(fieldValue) &&
        dateUtil().isBefore(dateUtil(fieldValue))
      ) {
        return 'End date must be in the past'
      }
      if (
        formData.endDate &&
        fieldValue &&
        !Array.isArray(fieldValue) &&
        dateUtil(fieldValue).isBefore(dateUtil(formData.startDate))
      ) {
        return 'End date must be after start date'
      }
      break
    case WinnersFormField.selectedGames:
      if (Array.isArray(fieldValue) && fieldValue.length === 0) {
        return 'At least one game must be selected'
      }
      break
    default:
      console.warn('Unhandled fieldId:', fieldId)
      break
  }
}

export const parseFieldFromUrl = (
  fieldId: WinnersFormFieldKey,
  value: string
) => {
  switch (fieldId) {
    case WinnersFormField.startDate:
      return value
    case WinnersFormField.endDate:
      return value
    case WinnersFormField.selectedGames:
      return value.split(',')
    default:
      throw new Error(`Invalid fieldId: ${fieldId}`)
  }
}
