import * as React from 'react'
import { WinningNumbers } from '../winning-numbers/winning-numbers-ui'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { Nullable } from '@Util/utility-types'
import { GeneralLink } from '../link/link-ui'
import { Api } from '@Client/api'
import { DynamicUrl } from '@Client/route-paths'
import { DateSelectorWithArrows } from '../date-selector-with-arrows/date-selector-with-arrows-ui'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { ErrorMessage } from '../error-message/error-message-ui'
import { GameImageLink } from '../game-image-link/game-image-link'
import { AnnotatedWinningNumbersProps } from '@Client/hooks/use-winning-numbers/helpers'
import { GameCalendarHeader } from '../game-calendar-header/game-calendar-header'
import { GameBranding } from '@Cms/model-types/game-model'
import './styles.scss'

export type WinningNumbersCardProps = {
  gameIdentifier: string
  gameName: string
  multiplierName?: string
  winningNumberGroups: AnnotatedWinningNumbersProps[]
  branding?: GameBranding
  gameIconSrc: string
  getNextDrawDate: (currentDate?: Date) => Date
  getPreviousDrawDate: (currentDate?: Date) => Date
  onNextMonth: (date: Date) => void
  onPreviousMonth: (date: Date) => void
  isDateDisabled: (date: Date) => boolean
  selectedDate?: Date
  onDateChange: (date?: Date) => void
  isLoading: boolean
  error?: Nullable<string>
  hasProgressiveJackpots: boolean
}

const ROOT_CLASS = 'winning-numbers-card'
const WIN_LOCATION_CLASS = getBlockClass(ROOT_CLASS, 'win-location')

const getMultiplierText = (
  multipliers: Nullable<number[]>,
  multiplierName?: string
) => {
  return multipliers
    ? `${multiplierName ?? ''} ${multipliers.map((multiplier) => `${multiplier}x`).join(', ')}`
    : undefined
}

export const WinningNumbersCard = (props: WinningNumbersCardProps) => {
  return (
    <div
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, 'is-loading', props.isLoading),
        getModifierClass(ROOT_CLASS, 'has-error', Boolean(props.error))
      ])}
    >
      <div className={getBlockClass(ROOT_CLASS, 'core-content')}>
        <div className={getBlockClass(ROOT_CLASS, 'icon-and-date')}>
          <GameImageLink
            image={{
              src: props.gameIconSrc,
              className: getBlockClass(ROOT_CLASS, 'game-icon')
            }}
            gameIdentifier={props.gameIdentifier}
            gameName={props.gameName}
          />
          <DateSelectorWithArrows
            headerElement={
              <GameCalendarHeader
                gameName={props.gameName}
                backgroundColor={props.branding?.backgroundColorHex}
                textColor={props.branding?.fontColorHex}
              />
            }
            onDateChange={props.onDateChange}
            selectedDate={props.selectedDate}
            inputName={`${props.gameIdentifier}-winning-numbers-date`}
            headerText='Select a draw date'
            getNextDate={props.getNextDrawDate}
            getPreviousDate={props.getPreviousDrawDate}
            isDateDisabled={props.isDateDisabled}
            onNextMonth={props.onNextMonth}
            onPreviousMonth={props.onPreviousMonth}
          />
        </div>
        {props.isLoading ? (
          <div className={getBlockClass(ROOT_CLASS, 'loading-container')}>
            <LoadingSpinner />
          </div>
        ) : props.error ? (
          <ErrorMessage message={Api.DEFAULT_ERROR} />
        ) : (
          <div
            className={getBlockClass(ROOT_CLASS, 'winning-numbers-container')}
          >
            {props.winningNumberGroups.map((winningNumberGroup, i) => {
              const multiplierText = getMultiplierText(
                winningNumberGroup.multipliers,
                props.multiplierName
              )
              return (
                <React.Fragment
                  key={`${props.gameIdentifier}-${props.selectedDate?.toISOString()}-${i}`}
                >
                  <WinningNumbers
                    {...winningNumberGroup}
                    multipliers={[]}
                    multiplierText={multiplierText}
                    multipleDraws={props.winningNumberGroups.length > 1}
                  />
                  {winningNumberGroup.jackpotWinLocation && (
                    <span
                      className={joinClasses([
                        WIN_LOCATION_CLASS,
                        getModifierClass(
                          WIN_LOCATION_CLASS,
                          'no-multiplier',
                          multiplierText === undefined
                        )
                      ])}
                    >
                      <span
                        className={getBlockClass(
                          ROOT_CLASS,
                          'win-location-label'
                        )}
                      >
                        {props.hasProgressiveJackpots
                          ? 'Jackpot Won:'
                          : 'Grand Prize Winner:'}{' '}
                      </span>
                      <span
                        className={getBlockClass(
                          ROOT_CLASS,
                          'win-location-value'
                        )}
                      >
                        {winningNumberGroup.jackpotWinLocation}
                      </span>
                    </span>
                  )}
                </React.Fragment>
              )
            })}
          </div>
        )}
      </div>
      <GeneralLink
        dest={DynamicUrl.getGameByIdentifierUrl(props.gameIdentifier)}
        className={getBlockClass(ROOT_CLASS, 'final-link')}
      >
        Prizes Won {'>'}
      </GeneralLink>
    </div>
  )
}
