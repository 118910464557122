import * as React from 'react'
import { useResizeObserver } from '@Client/hooks/use-resize-observer/use-resize-observer'
import { useIsDesktop } from '@Client/hooks/use-media-query/use-media-query'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { GeneralLink } from '../link/link-ui'
import { Nullable } from '@Util/utility-types'
import { DotNavigation } from '../dot-navigation/dot-navigation-ui'
import './styles.scss'

export type CarouselHeader =
  | {
      type: 'static'
      text: string
    }
  | {
      type: 'link'
      text: string
      dest: string
      helperText?: string
    }

export type CarouselProps = {
  header: CarouselHeader
  items: JSX.Element[]
  dotColor?: 'light' | 'dark'
}

const ITEM_GAP_PX = 16
const ROOT_CLASS = 'sliding-carousel'
const ITEMS_CONTAINER_CLASS = getBlockClass(ROOT_CLASS, 'items-container')
const ITEMS_CLASS = getBlockClass(ROOT_CLASS, 'items')

export const SlidingCarousel = (
  props: React.PropsWithChildren<CarouselProps>
) => {
  const isDesktop = useIsDesktop()
  const sliderRef = React.useRef<HTMLDivElement>(null)
  const [focusedItem, setFocusedItem] = React.useState<number>(0)
  const [isAutoScrolling, setIsAutoScrolling] = React.useState<boolean>(false)
  const [isOverflowing, setIsOverflowing] = React.useState<boolean>(false)
  const itemRefs = React.useRef<Nullable<HTMLDivElement>[]>(
    Array(props.items.length).fill(null)
  )

  useResizeObserver(() => {
    if (!sliderRef.current) {
      return
    }
    setIsOverflowing(
      sliderRef.current.scrollWidth > sliderRef.current.offsetWidth
    )
  }, sliderRef)

  const onSliderScroll = () => {
    if (isAutoScrolling) return

    let minScroll = Infinity
    let focusedIndex: number = 0
    if (!sliderRef.current) {
      return
    }
    const isAtEnd =
      sliderRef.current.offsetWidth + sliderRef.current.scrollLeft >=
      sliderRef.current.scrollWidth

    if (isAtEnd) {
      setFocusedItem(props.items.length - 1)
      return
    }

    itemRefs.current.forEach((item, itemIndex) => {
      if (!item || !sliderRef.current) {
        return
      }
      const itemScroll = Math.abs(
        item.offsetLeft - sliderRef.current.scrollLeft
      )
      if (itemScroll < minScroll) {
        minScroll = itemScroll
        focusedIndex = itemIndex
      }
    })
    setFocusedItem(focusedIndex)
  }

  const wrappedScrollToItem = (itemIndex: number) => {
    const scrollToItem = (itemIndex: number) => {
      const itemToFocus = itemRefs.current?.[itemIndex]
      if (!itemToFocus) {
        return
      }
      let scrollLeft = itemToFocus.offsetLeft
      if (isDesktop) {
        const delta = itemIndex - focusedItem
        scrollLeft =
          (sliderRef.current?.scrollLeft ?? 0) +
          (itemToFocus.offsetWidth + ITEM_GAP_PX) * delta
      }
      sliderRef.current?.scrollTo({
        left: scrollLeft,
        behavior: 'smooth'
      })

      if (isAutoScrolling) {
        return
      }

      setIsAutoScrolling(true)
      const intervalId = setInterval(() => {
        setFocusedItem((prevFocusedItem) => {
          if (prevFocusedItem < itemIndex) {
            return prevFocusedItem + 1
          } else if (prevFocusedItem > itemIndex) {
            return prevFocusedItem - 1
          } else {
            clearInterval(intervalId)
            setIsAutoScrolling(false)
            return prevFocusedItem
          }
        })
      }, 130)
    }

    let indexToScrollTo = itemIndex
    if (indexToScrollTo >= props.items.length) {
      indexToScrollTo = 0
    } else if (indexToScrollTo < 0) {
      indexToScrollTo = props.items.length - 1
    }
    scrollToItem(indexToScrollTo)
  }

  return (
    <div
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, 'with-scroll', isOverflowing)
      ])}
    >
      {props.header.type === 'link' ? (
        <GeneralLink
          dest={props.header.dest}
          className={getBlockClass(ROOT_CLASS, 'title-link')}
        >
          <h2 className={getBlockClass(ROOT_CLASS, 'title')}>
            {props.header.text}
          </h2>
          <div className={getBlockClass(ROOT_CLASS, 'title-link-helper')}>
            {props.header.helperText ? (
              <span
                className={getBlockClass(ROOT_CLASS, 'title-link-helper-text')}
              >
                {props.header.helperText}
              </span>
            ) : null}

            {'>'}
          </div>
        </GeneralLink>
      ) : (
        <h2 className={getBlockClass(ROOT_CLASS, 'title')}>
          {props.header.text}
        </h2>
      )}
      <div
        className={joinClasses([
          ITEMS_CONTAINER_CLASS,
          getModifierClass(ITEMS_CONTAINER_CLASS, 'with-scroll', isOverflowing),
          getModifierClass(
            ITEMS_CONTAINER_CLASS,
            'with-left-scroll-remaining',
            focusedItem !== 0
          ),
          getModifierClass(
            ITEMS_CONTAINER_CLASS,
            'with-right-scroll-remaining',
            focusedItem !== props.items.length - 1
          )
        ])}
      >
        <div
          className={joinClasses([
            ITEMS_CLASS,
            getModifierClass(ITEMS_CLASS, 'with-scroll', isOverflowing)
          ])}
          ref={sliderRef}
          onScroll={onSliderScroll}
        >
          {props.items.map((item, i) => (
            <div
              id={`${props.header.text}-item-${i}`}
              key={`${props.header.text}-${i}`}
              className={getBlockClass(ROOT_CLASS, 'item')}
              ref={(el) => (itemRefs.current[i] = el)}
              data-item-index={i}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <DotNavigation
        name={props.header.text}
        activeIndex={focusedItem}
        navigateToIndex={wrappedScrollToItem}
        count={props.items.length}
        showDotsInDesktop={false}
        dotColor={props.dotColor ?? 'dark'}
      />
    </div>
  )
}
