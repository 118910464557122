import * as React from 'react'
import { AlertBannerModel } from '@Cms/model-types/alert-banner-model'
import { AlertBanner } from './alert-banner-ui'
import { useLocalStorage } from '@Client/hooks/use-local-storage/use-local-storage'

const ALERT_BANNER_ID_KEY = 'alertBannerId'

export type AlertBannerContainerProps = {
  alertBanner: AlertBannerModel
}

type AlertBannerStoragePayload = {
  alertBannerId: string
  isDismissed: boolean
}

export const AlertBannerContainer = (props: AlertBannerContainerProps) => {
  const { write, value: storedAlertBannerData } =
    useLocalStorage<AlertBannerStoragePayload>({
      key: ALERT_BANNER_ID_KEY
    })

  React.useEffect(() => {
    if (
      storedAlertBannerData &&
      storedAlertBannerData.alertBannerId !== props.alertBanner.uuid
    ) {
      write({
        alertBannerId: props.alertBanner.uuid,
        isDismissed: false
      })
    }
  }, [])

  const onClose = () => {
    write({
      alertBannerId: props.alertBanner.uuid,
      isDismissed: true
    })
  }

  if (
    storedAlertBannerData &&
    storedAlertBannerData.alertBannerId === props.alertBanner.uuid &&
    storedAlertBannerData.isDismissed
  ) {
    return null
  }

  return <AlertBanner alertBanner={props.alertBanner} onClose={onClose} />
}
