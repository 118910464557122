import { Nullable } from '@Util/utility-types'

export const FILTERS_DELIMITER = ','

export enum GameLobbyParamKey {
  filters = 'filters',
  search = 'search',
  sort = 'sort',
  price = 'price'
}

export const encodeFilters = (filterIdentifiers: string[]) =>
  encodeURI(filterIdentifiers.join(FILTERS_DELIMITER))

export const decodeFilters = (
  filterIdentifiersRaw: Nullable<string>
): string[] => filterIdentifiersRaw?.split(FILTERS_DELIMITER) ?? []

export const openInNewTab = (url: string) => window.open(url, '_blank')
