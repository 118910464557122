import * as React from 'react'
import { PastResults } from './past-results-ui'
import { useAppSelector } from '@Client/hooks/redux-hooks'
import { isRapidOrDrawGame, RapidOrDrawGame } from '@Cms/model-types/game-model'
import { DrawGameApi } from '@Client/api/draw-games'
import { usePaginatedFetch } from '@Client/hooks/use-fetch/use-paginated-fetch'
import { Nullable } from '@Util/utility-types'
import { dateUtil } from '@Util/date-util'
import { getResultsFromDrawings, PastResultsResponse } from './helpers'
import { useDeepLink } from '@Client/hooks/use-deep-link/use-deep-link'
import { RapidGameApi } from '@Client/api/rapid-games'
import {
  DrawGameFormPayload,
  PastResultsFieldId,
  RapidGameFormPayload
} from '../past-results-form/constants'

export const PastResultsContainer = () => {
  const { getParam } = useDeepLink()
  const [isDateRangeLoading, setIsDateRangeLoading] = React.useState(false)
  const [desiredStartDate, setDesiredStartDate] =
    React.useState<Nullable<Date>>(null)
  const [earliestRequestedDate, setLastRequestedDate] =
    React.useState<Nullable<Date>>(null)
  const {
    paginatedData,
    fetchPaginatedData,
    isLoading,
    error,
    resetData,
    setError
  } = usePaginatedFetch<PastResultsResponse, PastResultsResponse>({})
  const [activeGameIdentifier, setActiveGameIdentifier] = React.useState<
    Nullable<string>
  >(() => getParam(PastResultsFieldId.GAME_IDENTIFIER))

  const gamesById = useAppSelector(
    (state) => state.cmsGames.gamesByDataServiceId
  )
  const gameIdByIdentifier = useAppSelector(
    (state) => state.cmsGames.dataServiceIdByGameIdentifier
  )
  const drawAndRapidGames: RapidOrDrawGame[] = React.useMemo(
    () =>
      Object.values(gamesById)
        .filter((game): game is RapidOrDrawGame => isRapidOrDrawGame(game))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [gamesById]
  )

  const updateGameId = (identifier: Nullable<string>) => {
    setActiveGameIdentifier(identifier)
    resetData()
    setDesiredStartDate(null)
    setLastRequestedDate(null)
  }

  const onDrawGameSubmit = async (payload: DrawGameFormPayload) => {
    setActiveGameIdentifier(payload.gameIdentifier)
    const now = dateUtil()
    const [value, unit] = payload.timeSpan.split(' ')
    try {
      if (unit !== 'days' && unit !== 'months' && unit !== 'year') {
        throw new Error('Invalid unit in drawings')
      }
      let startDate = now
        .subtract(parseInt(value), unit)
        .startOf('day')
        .toDate()
      setDesiredStartDate(startDate)

      const startDateDaysDiff = now.diff(startDate, 'days')
      if (startDateDaysDiff > DrawGameApi.GAME_DRAWINGS_MAX_DAYS) {
        startDate = now
          .subtract(DrawGameApi.GAME_DRAWINGS_MAX_DAYS, 'days')
          .startOf('day')
          .toDate()
      }

      await fetchPaginatedData(async () => {
        return DrawGameApi.getGameDrawings(
          gameIdByIdentifier[payload.gameIdentifier],
          startDate,
          now.toDate()
        )
      }, true)
      setLastRequestedDate(startDate)
    } catch (error) {
      setError(
        `Error fetching game drawings for gameIdentifier: ${payload.gameIdentifier}`
      )
    }
  }

  const onRapidGameSubmit = async (payload: RapidGameFormPayload) => {
    setActiveGameIdentifier(payload.gameIdentifier)

    const dateObj = dateUtil(payload.drawDate)
    const startOfDay = dateObj.startOf('day').toDate()
    const endOfDay = dateObj.endOf('day').toDate()

    let startDrawingNumber = payload.drawNumberStart
    let endDrawingNumber = payload.drawNumberEnd

    if (!startDrawingNumber || !endDrawingNumber) {
      try {
        setIsDateRangeLoading(true)
        const drawingsInRange = await RapidGameApi.getDrawingNumbersByDateRange(
          {
            gameId: gameIdByIdentifier[payload.gameIdentifier],
            startDate: startOfDay,
            endDate: endOfDay
          }
        )
        if (!drawingsInRange.max || !drawingsInRange.min) {
          throw new Error('No results. Please try another date.')
        }
        startDrawingNumber = drawingsInRange.min
        endDrawingNumber = drawingsInRange.max
        setIsDateRangeLoading(false)
      } catch (error) {
        setError('No results. Please try another date.')
        setIsDateRangeLoading(false)
        return
      }
    }

    await fetchPaginatedData(
      async () =>
        await RapidGameApi.getDrawingsByDrawNumberRange({
          gameId: gameIdByIdentifier[payload.gameIdentifier],
          startDrawNumber: startDrawingNumber,
          endDrawNumber: endDrawingNumber
        }),
      true
    )
  }

  const loadMoreResults = async () => {
    if (!activeGameIdentifier || !earliestRequestedDate) {
      return
    }
    const startDate = dateUtil(earliestRequestedDate)
      .subtract(DrawGameApi.GAME_DRAWINGS_MAX_DAYS, 'days')
      .startOf('day')

    const startDateRequest =
      desiredStartDate && startDate.isBefore(desiredStartDate)
        ? desiredStartDate
        : startDate.toDate()
    try {
      await fetchPaginatedData(async () => {
        return DrawGameApi.getGameDrawings(
          gameIdByIdentifier[activeGameIdentifier],
          startDateRequest,
          earliestRequestedDate
        )
      })
      setLastRequestedDate(startDateRequest)
    } catch (error) {
      setError(
        `Error fetching more drawings for gameIdentifier: ${activeGameIdentifier}`
      )
    }
  }

  const hasMoreResults = React.useMemo(() => {
    if (
      !earliestRequestedDate ||
      !desiredStartDate ||
      dateUtil(earliestRequestedDate).isSame(desiredStartDate, 'day')
    ) {
      return false
    }
    return true
  }, [desiredStartDate, earliestRequestedDate])

  const matchingGame =
    activeGameIdentifier &&
    isRapidOrDrawGame(gamesById[gameIdByIdentifier[activeGameIdentifier]])
      ? (gamesById[gameIdByIdentifier[activeGameIdentifier]] as RapidOrDrawGame)
      : undefined

  const results = React.useMemo(
    () =>
      paginatedData && matchingGame
        ? getResultsFromDrawings(paginatedData as any, matchingGame).sort(
            (a, b) =>
              new Date(a.drawDate).getTime() - new Date(b.drawDate).getTime()
          )
        : undefined,
    [paginatedData, matchingGame]
  )

  return (
    <PastResults
      games={drawAndRapidGames}
      error={error}
      isLoading={isLoading || isDateRangeLoading}
      activeGame={matchingGame}
      setActiveGameId={updateGameId}
      results={results}
      onDrawGameSubmit={onDrawGameSubmit}
      onRapidGameSubmit={onRapidGameSubmit}
      hasMoreResults={hasMoreResults}
      loadMoreResults={loadMoreResults}
    />
  )
}
