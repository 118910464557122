const numericFormat = (dollars: number) => {
  const formattedNumber = dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })
  const [dollarsFormatted, centsFormatted] = formattedNumber.split('.')
  return centsFormatted === '00' ? dollarsFormatted : formattedNumber
}

const compactFormat = (dollars: number) => {
  if (dollars < 1000000) {
    return numericFormat(dollars)
  }

  const compactNumber = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'long',
    unitDisplay: 'long',
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
  }).format(dollars)
  const humanized = compactNumber.replace(/([A-Z])/g, ' $1illion')
  const trillionChecked = humanized.replace('Tillion', 'Trillion')
  return trillionChecked
}

const parse = (value: string) => {
  return parseFloat(value.replace(/[^0-9.-]+/g, ''))
}

const getCentsFromDollars = (dollars: number) => Math.round(dollars * 100)
const getDollarsFromCents = (cents: number) => Math.floor(cents / 100)
const getDollarsFromCentsWithRemainder = (cents: number) =>
  (cents / 100).toFixed(2)

export const CurrencyUtilities = {
  getCentsFromDollars,
  getDollarsFromCents,
  getDollarsFromCentsWithRemainder,
  compactFormat,
  numericFormat,
  parse
}
