import * as React from 'react'
import { LoaderFunction, useLoaderData } from 'react-router-dom'
import { useAppDispatch } from '@Client/hooks/redux-hooks'
import { NavigationCategoriesResponse } from '@Server/controllers/navigation/navigation-controller'
import { ConstantContentName } from '@Cms/model-types/constant-content-model'
import { ConstantContentResponse } from '@Server/controllers/content/content-controller'
import { ConfigResponse } from '@Server/controllers/operations/operations-controller'
import { OperationsApi } from '@Client/api/operations'
import { ContentApi } from '@Client/api/content'
import { Optional } from '@Util/utility-types'
import { App } from './app'
import { useRecurringAction } from '@Client/hooks/use-recurring-action/use-recurring-action'
import { iLotteryActions } from '@Client/reducers/ilottery/ilottery-reducer'
import { useCmsPreview } from '@Client/hooks/use-cms-preview/use-cms-preview'

const PROGRESSIVE_JACKPOTS_UPDATE_MS = 90000

export type AppLoaderData = {
  navCategories: NavigationCategoriesResponse
  alertBannerConstantContent: Optional<ConstantContentResponse>
  config: Optional<ConfigResponse>
}

export const appLoader: LoaderFunction = async () => {
  const navCategories = await ContentApi.fetchNavigationCategories()
  let alertBannerConstantContent: Optional<ConstantContentResponse> = undefined
  let config: Optional<ConfigResponse> = undefined

  try {
    alertBannerConstantContent = await ContentApi.fetchConstantContent(
      ConstantContentName.generalAlertBanner
    )
  } catch (error) {
    console.error('Error fetching alert banner content for app', error)
  }
  try {
    config = await OperationsApi.fetchConfig()
  } catch (error) {
    console.error('Error fetching the config for app', error)
  }
  return {
    navCategories,
    alertBannerConstantContent,
    config
  }
}

export const AppContainer = () => {
  const { navCategories, alertBannerConstantContent, config } =
    useLoaderData() as AppLoaderData
  const dispatch = useAppDispatch()
  useCmsPreview()

  useRecurringAction(() => {
    dispatch(iLotteryActions.requestProgressiveJackpots())
  }, PROGRESSIVE_JACKPOTS_UPDATE_MS)

  const navigationCategories =
    navCategories.status === 'success'
      ? navCategories.data.categories
      : undefined

  const alertBanner =
    alertBannerConstantContent?.status === 'success'
      ? alertBannerConstantContent.data
      : undefined

  const isMaintenanceMode =
    config?.status === 'success' ? config.data.MAINTENANCE_MODE : false

  return (
    <App
      navigationCategories={navigationCategories}
      alertBannerConstantContent={alertBanner}
      isMaintenanceMode={isMaintenanceMode}
    />
  )
}
