import { Api } from '.'
import { ApiConfig } from './config'

export type ProgressiveEInstantJackpot = {
  GameProvider: string
  GlobalIdentifier: string
  PJPID: number
  PJPName: string
  CurrencyCode: string
  PJPBalancePast: string
  PJPBalanceCurrent: string
  LastWin: {
    DateWon: string
    WinAmount: string
    LastWinTimeElapsedInSeconds: number
  }
  Period: null
}

export type ProgressiveEInstantsResponse = {
  ProgressiveJackpots: ProgressiveEInstantJackpot[]
  BTMID: string
  ErrorCode: number
  ErrorMessage: string
}

const getAllGroups = async () => {
  try {
    return await Api.externalCall<ProgressiveEInstantsResponse>({
      url: ApiConfig.progressiveJackpotUrl
    })
  } catch (error) {
    console.error('ProgressiveEInstantsApi.getAll', error)
    throw new Error(Api.DEFAULT_ERROR)
  }
}

export const ProgressiveEInstantsApi = {
  getAllGroups
}
