import * as React from 'react'
import './styles.scss'
import { joinClasses } from '../component-helpers'

export type GamePriceProps = {
  value: string
  className?: string
}

const ROOT_CLASS = 'game-price'

export const GamePrice = (props: GamePriceProps) => {
  return (
    <span className={joinClasses([ROOT_CLASS, props.className])}>
      {props.value}
    </span>
  )
}
