import * as React from 'react'
import {
  NavCategoryField,
  NavCategoryModel
} from '@Cms/model-types/nav-category-model'
import { getBlockClass } from '@Components/component-helpers'
import { keyBy } from 'lodash'
import { Sidebar } from '../sidebar/sidebar-ui'
import { Button } from '../button/button'
import { CloseIcon } from '@Client/icons/close-icon'
import { NavigationGamesStacked } from '../navigation-games/navigation-games-stacked-ui'
import { NavigationCategory } from '@Components/navigation-category/navigation-category-ui'
import { NAV_ORDER } from './constants'
import './styles.scss'

export type NavProps = {
  navCategories: NavCategoryModel[]
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const ROOT_CLASS = 'mobile-navigation'

export const MobileNavigation = (props: NavProps) => {
  const categoriesByName: Record<string, NavCategoryModel> = keyBy(
    props.navCategories,
    NavCategoryField.name
  )

  const closeMenu = () => props.setIsOpen(false)

  return (
    <Sidebar
      isOpen={props.isOpen}
      onOpenChange={props.setIsOpen}
      side={'left'}
      ariaTitle={'Navigation'}
      ariaDescription={'Site Navigation menu items'}
    >
      <nav className={ROOT_CLASS}>
        <div className={getBlockClass(ROOT_CLASS, 'content-header')}>
          <Button
            ariaLabel='Close Navigation Menu'
            onClick={() => props.setIsOpen(!props.isOpen)}
            id={getBlockClass(ROOT_CLASS, 'close-button')}
          >
            <CloseIcon />
          </Button>
        </div>
        <NavigationGamesStacked onLinkClick={closeMenu} />
        <div className={getBlockClass(ROOT_CLASS, 'categories')}>
          {NAV_ORDER.map((categoryName) =>
            categoriesByName[categoryName] ? (
              <NavigationCategory
                closeMenus={closeMenu}
                key={categoryName}
                {...categoriesByName[categoryName]}
              />
            ) : null
          )}
        </div>
      </nav>
    </Sidebar>
  )
}
