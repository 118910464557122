import { EntryFieldTypes } from 'contentful'
import { CmsModel } from './cms-models'
import { NavItemModel, NavItemSkeleton } from './nav-item-model'

export enum NavCategoryField {
  name = 'name',
  navItems = 'navItems'
}

export enum NavCategoryName {
  about = 'About',
  games = 'Games',
  homeSpotlight = 'Home Spotlight',
  winning = 'Winning',
  prizes = 'Prizes',
  more = 'More',
  mobileMore = 'More (Mobile)'
}

export type NavCategorySkeleton = {
  contentTypeId: CmsModel.navCategory
  fields: {
    [NavCategoryField.name]: EntryFieldTypes.Symbol
    [NavCategoryField.navItems]: EntryFieldTypes.Array<
      EntryFieldTypes.EntryLink<NavItemSkeleton>
    >
  }
}

export type NavCategoryModel = {
  [NavCategoryField.name]: NavCategoryName
  [NavCategoryField.navItems]: NavItemModel[]
}

export const navCategoryNames = Object.values(NavCategoryName)
export const navCategoryFields = Object.values(NavCategoryField)

export const isValidNavCategory = (
  categoryName: string
): categoryName is NavCategoryName =>
  navCategoryNames.includes(categoryName as NavCategoryName)
export const isMobileNavCategory = (navCategoryName: NavCategoryName) =>
  navCategoryName.includes('(Mobile)')
