import * as React from 'react'
import { useAppDispatch, useAppSelector } from '../redux-hooks'
import { GameModel } from '@Cms/model-types/game-model'
import { Optional } from '@Util/utility-types'
import { selectGameDrawResultsState } from '@Client/reducers/draw-results/draw-results-selectors'
import { drawResultsActions } from '@Client/reducers/draw-results/draw-results-reducer'
import { useRecurringAction } from '../use-recurring-action/use-recurring-action'
import { fetchDrawTimesWindow } from '@Client/reducers/draw-times/helpers'
import { GameDrawTimesState } from '@Client/reducers/draw-times/draw-times-reducer-types'
import { dateUtil } from '@Util/date-util'
import { getWinningNumbersFromDrawResultResponse } from './helpers'

export type UseWinningNumbersProps = {
  drawGameServicesId: string
}

const NINETY_SECONDS_MS = 90 * 1000

export const useWinningNumbers = (props: UseWinningNumbersProps) => {
  const dispatch = useAppDispatch()
  const [drawingDateIndex, setDrawingDateIndex] = React.useState<number>(0)
  const cmsGame: Optional<GameModel> = useAppSelector(
    (state) => state.cmsGames.gamesByDataServiceId[props.drawGameServicesId]
  )
  const drawTimesState: Optional<GameDrawTimesState> = useAppSelector(
    (state) => state.drawTimes.byGameId[props.drawGameServicesId]
  )
  const drawResultState = useAppSelector((state) =>
    selectGameDrawResultsState(props.drawGameServicesId, state)
  )

  const fetchLatestDrawResult = () => {
    dispatch(
      drawResultsActions.fetchDrawResultRequest({
        gameId: props.drawGameServicesId
      })
    )
  }

  useRecurringAction(fetchLatestDrawResult, NINETY_SECONDS_MS)

  const fetchMoreDrawTimes = () => {
    const lastAvailableDrawing =
      drawTimesState?.drawDates[drawTimesState.drawDates.length - 1]
    const isDrawTimesLoading = drawTimesState?.isLoading ?? false
    if (!lastAvailableDrawing && !isDrawTimesLoading) {
      dispatch(fetchDrawTimesWindow(props.drawGameServicesId))
      return
    }
    dispatch(
      fetchDrawTimesWindow(
        props.drawGameServicesId,
        new Date(lastAvailableDrawing.drawDate)
      )
    )
  }

  React.useEffect(() => {
    if (
      !drawTimesState?.drawDates.length ||
      drawResultState?.isLoading ||
      drawResultState?.error
    ) {
      return
    }
    const drawTimes = drawTimesState.drawDates[drawingDateIndex].drawings.map(
      (drawing) => drawing.drawTime
    )
    for (const drawTime of drawTimes) {
      if (!drawResultState?.drawResults?.[drawTime]) {
        dispatch(
          drawResultsActions.fetchDrawResultRequest({
            gameId: props.drawGameServicesId,
            asOfISO: drawTime
          })
        )
      }
    }
  }, [
    props.drawGameServicesId,
    drawTimesState,
    drawingDateIndex,
    dispatch,
    drawResultState
  ])

  const fetchMoreDrawTimesIfNecessary = (dateToCheck: Date) => {
    if (!cmsGame) {
      return
    }
    const dateToCheckObj = dateUtil(dateToCheck)
    const lastIndex = drawTimesState?.drawDates.length - 1
    const lastIndexDateObj = dateUtil(
      drawTimesState?.drawDates[lastIndex]?.drawDate,
      true
    )
    const isLoading = drawTimesState?.isLoading ?? false
    if (
      !isLoading &&
      (dateToCheckObj.isBefore(lastIndexDateObj, 'day') ||
        dateToCheckObj.isSameOrBefore(lastIndexDateObj, 'month'))
    ) {
      fetchMoreDrawTimes()
    }
  }

  const selectedDateStr = drawTimesState?.drawDates[drawingDateIndex]?.drawDate
  const selectedDate = dateUtil(selectedDateStr, true).toDate()

  React.useEffect(() => {
    fetchMoreDrawTimesIfNecessary(selectedDate)
  }, [drawTimesState, selectedDate])

  const { drawResults, isLoading, error } = drawResultState ?? {}
  const drawTimesForDate: string[] = selectedDateStr
    ? drawResultState?.timeStampsByDateString?.[selectedDateStr] ?? []
    : []

  const winningNumbersForDate =
    drawTimesForDate?.map((drawTime) => drawResults?.[drawTime]?.results) ?? []

  const labelsForDate =
    drawTimesForDate?.map(
      (drawTime) => drawResults?.[drawTime]?.when?.label ?? ''
    ) ?? undefined

  const winningNumberGroups = getWinningNumbersFromDrawResultResponse(
    cmsGame?.name ?? '',
    winningNumbersForDate,
    drawTimesForDate,
    labelsForDate
  )

  const getNextDrawDate = (currentDate?: Date) => {
    if (dateUtil(currentDate).isSame(dateUtil(), 'day')) {
      return new Date(drawTimesState?.drawDates[0]?.drawDate)
    }
    return new Date(drawTimesState?.drawDates[drawingDateIndex - 1]?.drawDate)
  }

  const getPreviousDrawDate = () =>
    new Date(drawTimesState?.drawDates[drawingDateIndex + 1]?.drawDate)

  const isDateDisabled = (date: Date) => {
    if (!drawTimesState?.drawDates.length) {
      return false
    }
    const isInFuture = dateUtil(date).isAfter(dateUtil(), 'day')
    if (isInFuture) {
      return true
    }
    const drawingOnDate = drawTimesState?.drawDates.find((drawing) =>
      dateUtil(drawing.drawDate, true).isSame(date, 'day')
    )
    return !drawingOnDate
  }

  const onDateChange = (date?: Date) => {
    if (!date) {
      return
    }
    const drawingDateIndex = drawTimesState?.drawDates.findIndex((drawing) =>
      dateUtil(drawing.drawDate, true).isSame(date, 'day')
    )
    if (drawingDateIndex !== -1) {
      setDrawingDateIndex(drawingDateIndex)
    }
  }

  return {
    isLoading: isLoading ?? false,
    error,
    winningNumberGroups,
    selectedDate,
    cmsGame,
    multiplierName: cmsGame?.branding?.multipliers?.name,
    getNextDrawDate,
    getPreviousDrawDate,
    isDateDisabled,
    onDateChange,
    onNextMonth: fetchMoreDrawTimesIfNecessary,
    onPreviousMonth: fetchMoreDrawTimesIfNecessary
  }
}
