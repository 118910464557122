import {
  DynamicUrl,
  GameCollection,
  GameCollectionFilters,
  GameCollectionParam,
  RoutePath
} from '@Client/route-paths'

export const YOUTUBE_CHANNEL_LINK = 'https://www.youtube.com/@nhlottery8541'
export const FACEBOOK_LINK = 'https://www.facebook.com/NewHampshireLottery/'
export const LINKEDIN_LINK =
  'https://www.linkedin.com/company/new-hampshire-lottery/'
export const INSTAGRAM_LINK = 'https://www.instagram.com/nhlottery/'
export const NH_INVESTIGATE_COMPLIANCE_LINK = 'https://www.racing.nh.gov/'

export type FooterLink = {
  route: RoutePath | string
  text: string
}

export const footerCategoriesByName: Record<string, FooterLink[]> = {
  Games: [
    {
      route: DynamicUrl.getGameCollectionUrl(GameCollection.iLottery),
      text: 'iLottery'
    },
    {
      route: DynamicUrl.getGameCollectionUrl(GameCollection.inStore),
      text: 'In-Store'
    },
    {
      route: DynamicUrl.getGameCollectionUrl(GameCollection.inStore, {
        [GameCollectionParam.filters]: [GameCollectionFilters.draw]
      }),
      text: 'Draw Games'
    },
    {
      route: RoutePath.sports,
      text: 'Sports'
    },
    {
      route: RoutePath.winnerStories,
      text: 'Winners'
    },
    {
      route: RoutePath.iLotteryInfo,
      text: 'How to Play iLottery'
    },
    {
      route: RoutePath.findARetailer,
      text: 'Find a Retailer'
    },
    {
      route: RoutePath.claimPrize,
      text: 'How to Claim a Prize'
    }
  ],
  Partners: [
    {
      route: 'https://www.compliance.lottery.nh.gov/',
      text: 'Investigation and Compliance Division'
    },
    {
      route: RoutePath.playResponsibly,
      text: 'Play Responsibly'
    },
    {
      route: RoutePath.whereTheMoneyGoes,
      text: 'Where The Money Goes'
    },
    {
      route: RoutePath.retailers,
      text: 'Retailer Site'
    }
  ],
  Legal: [
    {
      route: RoutePath.careers,
      text: 'Careers'
    },
    {
      route: RoutePath.termsAndConditions,
      text: 'Terms & Conditions'
    },
    {
      route:
        'https://consumer.ftc.gov/articles/fake-prize-sweepstakes-and-lottery-scams',
      text: 'Illegal Foreign Lotteries'
    }
  ]
}
