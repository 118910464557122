export enum StagingEnvironment {
  develop = 'develop',
  qa = 'qa',
  uat = 'uat',
  uatAi = 'uat-ai',
  prod = 'prod'
}

export const StagingEnvironmentValues = Object.values(StagingEnvironment)
export const isValidEnvironment = (
  stagingEnv: any
): stagingEnv is StagingEnvironment =>
  StagingEnvironmentValues.includes(stagingEnv)
