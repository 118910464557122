import * as React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { redirectMap } from './redirect-map'

export const useRedirectHandler = () => {
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    const fullPath = `${location.pathname}${location.search}`.toLowerCase()
    const redirectFullUrl = redirectMap[fullPath]
    const redirectPath = redirectMap[location.pathname.toLowerCase()]
    if (redirectFullUrl) {
      navigate(redirectFullUrl, { replace: true })
    } else if (redirectPath) {
      navigate(`${redirectPath}${location.search}`, { replace: true })
    }
  }, [navigate, location])
}
