import * as React from 'react'

export const getBlockClass = (rootClass: string, blockName: string) =>
  `${rootClass}__${blockName}`

export const getModifierClass = (
  rootClass: string,
  modifierName: string,
  predicate: boolean = true
) => (predicate ? `${rootClass}--${modifierName}` : undefined)

export const joinClasses = (classNames: (string | undefined)[]) =>
  Array.from(new Set(classNames)).filter(Boolean).join(' ')

export const getCustomCssProperties = (
  properties: Record<string, string>,
  styles: React.CSSProperties = {}
) =>
  ({
    ...styles,
    ...properties
  }) as React.CSSProperties
