import { GameModel } from '@Cms/model-types/game-model'
import { SortOptionModel } from '@Cms/model-types/sort-option-model'
import { getObjectKeys } from '@Util/object-utilities'
import { compact } from '@Util/array-utilities'
import { gameMatchesSearch } from './search-helpers'
import { sortGamesByOrder } from '@Cms/mappers/sort-option/game-sorts'
import {
  FilterCategoryByIdentifier,
  LobbyFilterTag,
  filterGame
} from './filter-helpers'

export const getValidGames = (
  games: GameModel[],
  filtersByCategory: FilterCategoryByIdentifier,
  searchTerm: string,
  currentSort: SortOptionModel,
  priceFilter?: string
): GameModel[] => {
  const activeCategoryIdentifiers = compact(
    getObjectKeys(filtersByCategory).map((identifier) =>
      Object.values(filtersByCategory[identifier].filterTagsByIdentifier).some(
        (tag: LobbyFilterTag) => tag.isActive
      )
        ? identifier
        : undefined
    )
  )

  let filteredGames = !activeCategoryIdentifiers.length
    ? games
    : games.filter((game) =>
        filterGame(game, filtersByCategory, activeCategoryIdentifiers)
      )

  if (priceFilter) {
    filteredGames = filteredGames.filter((game) => {
      const numericPriceInCents = Number(priceFilter)
      return game.priceOptions.some(
        (priceOpt) => priceOpt.priceInCents === numericPriceInCents
      )
    })
  }

  const activeGames = !searchTerm
    ? filteredGames
    : filteredGames.filter((game) => gameMatchesSearch(game, searchTerm))

  const sortedGames = sortGamesByOrder(activeGames, currentSort)
  return sortedGames
}
