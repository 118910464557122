import * as React from 'react'
import { getBlockClass } from '../component-helpers'
import { GeneralLink } from '../link/link-ui'
import { DynamicUrl } from '@Client/route-paths'
import { RapidOrDrawGame } from '@Cms/model-types/game-model'
import { GameImageLink } from '../game-image-link/game-image-link'
import './rapid-game-card.styles.scss'
import './styles.scss'

export type RapidGameCardProps = {
  game: RapidOrDrawGame
}

const ROOT_CLASS = 'rapid-game-card'

export const RapidGameCard = (props: RapidGameCardProps) => {
  return (
    <div className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'core-content')}>
        <div className={getBlockClass(ROOT_CLASS, 'icon-and-date')}>
          <GameImageLink
            image={{
              src: props.game.imageUrl,
              className: getBlockClass(ROOT_CLASS, 'game-icon')
            }}
            gameIdentifier={props.game.identifier}
            gameName={props.game.name}
          />
        </div>
        <div className={getBlockClass(ROOT_CLASS, 'text')}>
          <span className={getBlockClass(ROOT_CLASS, 'time')}>
            From 11:05 AM to 1:00 AM{' '}
          </span>
          <span className={getBlockClass(ROOT_CLASS, 'pace')}>
            Drawings Every 5 Minutes
          </span>
        </div>
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'ctas')}>
        <GeneralLink
          dest={DynamicUrl.getGameByIdentifierUrl(props.game.identifier)}
          className={getBlockClass(ROOT_CLASS, 'primary-link')}
        >
          Game Info
        </GeneralLink>
        <GeneralLink
          dest={DynamicUrl.getKenoFindARetailerUrl()}
          className={getBlockClass(ROOT_CLASS, 'secondary-link')}
        >
          Find a Retailer
        </GeneralLink>
      </div>
      <GeneralLink
        dest={DynamicUrl.getPastResultsForGame(props.game.identifier)}
        className={getBlockClass(ROOT_CLASS, 'final-link')}
      >
        Current Winning Numbers {'>'}
      </GeneralLink>
    </div>
  )
}
