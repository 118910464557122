import {
  GameField,
  GameModel,
  GameType,
  RapidOrDrawGame
} from '@Cms/model-types/game-model'
import {
  getSpecialBallType,
  SpecialBall,
  WinningNumbersProps
} from '../winning-numbers/winning-numbers-ui'
import { Drawing } from '@interaction-gaming/game-data-service-browser-client'
import { Nullable } from '@Util/utility-types'
import { ListDrawingsResponse } from '@Client/api/draw-games'
import { RapidDrawRangeResponse } from '@Client/api/rapid-games'
import {
  areDrawResults,
  areRapidResults,
  PastResult
} from '../past-results-table/constants'
import { jsonToCsv } from '@Util/object-utilities'
import { DateFormat, dateUtil } from '@Util/date-util'
import { getBonusText } from '../past-results-table/past-results-cell'
import { PastResultsFieldId } from '../past-results-form/constants'
import { downloadFileBlob } from '@Client/util/file-util'
import { CurrencyUtilities } from '@Util/currency-utilities'

export type PastResultsResponse = ListDrawingsResponse | RapidDrawRangeResponse

const isDrawGameResponse = (
  drawings: PastResultsResponse
): drawings is ListDrawingsResponse =>
  'results' in drawings[0] && 'when' in drawings[0]

const isRapidGameResponse = (
  drawings: PastResultsResponse
): drawings is RapidDrawRangeResponse => 'result' in drawings[0]

export const getResultsFromDrawings = (
  drawings: PastResultsResponse,
  game: RapidOrDrawGame
): PastResult[] => {
  const getWinningNumbers = (drawing: Drawing): WinningNumbersProps => {
    const specialBallType = game
      ? getSpecialBallType(game[GameField.name])
      : null
    const specialBalls: Nullable<SpecialBall[]> = specialBallType
      ? drawing.results.drawResult?.primary?.bonusNumbers?.map((value) => ({
          value,
          type: specialBallType
        })) ?? null
      : null
    return {
      numbers: drawing.results.drawResult?.primary?.winningNumbers ?? [],
      status: drawing.results.status,
      specialBalls: specialBalls,
      multipliers: drawing.results.drawResult?.primary?.multipliers ?? null,
      label: drawing.when.label ?? undefined
    }
  }

  if (!drawings.length) {
    return []
  }

  switch (game.type) {
    case GameType.draw:
      if (!isDrawGameResponse(drawings)) {
        console.error('Invalid response for draw game')
        return []
      }
      return drawings.map((drawing) => ({
        drawDate: drawing.when.drawTime,
        winningNumbers: getWinningNumbers(drawing),
        jackpotInCents:
          drawing.progressiveJackpot.jackpot?.jackpotAmountInCents ?? 0,
        gameId: game.configuration.dataServices.gameDataServiceId,
        gameIdentifier: game.identifier,
        type: GameType.draw,
        jackpotWinLocation:
          drawing.results.drawResult?.drawResultMetadata?.wINNERDETAILS
      }))

    case GameType.rapid:
      if (!isRapidGameResponse(drawings)) {
        console.error('Invalid response for rapid game')
        return []
      }
      return drawings.map((drawing) => ({
        drawNumber: drawing.result.drawingNumber ?? 0,
        drawDate: drawing.result.drawingDate,
        gameIdentifier: game.identifier,
        winningNumbers: {
          numbers:
            drawing.result.primary?.winningNumbers.sort((a, b) => a - b) ?? [],
          status: 'ACTIVE',
          specialBalls: null,
          multipliers: null
        },
        bonus: drawing.result.primary?.multipliers?.[0] ?? 0,
        gameId: game.configuration.dataServices.gameDataServiceId,
        type: GameType.rapid
      }))
    default:
      return []
  }
}

const getPastResultsCsv = (results: PastResult[]): string => {
  if (areDrawResults(results)) {
    // TODO multiple draw sequences
    const data = results.map((result) => {
      const csvData: Record<string, number | string> = {
        'Draw Date': dateUtil(result.drawDate).format(DateFormat.monthDayYear),
        'Winning Numbers': result.winningNumbers.numbers.join(',')
      }
      if (result.winningNumbers.specialBalls?.length) {
        csvData['Special Ball'] = result.winningNumbers.specialBalls
          ?.map((ball) => ball.value)
          .join(',')
      }
      if (result.winningNumbers.multipliers?.length) {
        csvData['Multiplier'] = result.winningNumbers.multipliers.join(',')
      }
      if (result.jackpotInCents) {
        csvData['Jackpot'] = CurrencyUtilities.getDollarsFromCents(
          result.jackpotInCents
        )
      }
      if (result.winningNumbers.label) {
        csvData['Label'] = result.winningNumbers.label // Evening or Day
      }
      return csvData
    })
    return jsonToCsv(data)
  } else if (areRapidResults(results)) {
    const data = results.map((result) => ({
      'Draw Date': dateUtil(result.drawDate).format(DateFormat.monthDayYear),
      'Draw Number': result.drawNumber,
      'Winning Numbers': result.winningNumbers.numbers.join(','),
      Bonus: getBonusText(result.bonus)
    }))
    return jsonToCsv(data)
  }
  return ''
}

export const handleDownloadClick = (
  results?: PastResult[],
  activeGame?: GameModel
) => {
  if (!results || !results.length) {
    return
  }
  const csvString = getPastResultsCsv(results)
  downloadFileBlob(
    new Blob([csvString], { type: 'text/csv' }),
    `${activeGame?.name.toLowerCase() ?? ''}-past-results-${dateUtil().format(DateFormat.monthDayYear)}.csv`
  )
}

export const getInitialYourNumbers = (
  getParam: (paramKey: string) => Nullable<string>
) => {
  const paramValue = getParam(PastResultsFieldId.YOUR_NUMBERS)
  if (!paramValue) {
    return []
  }
  return paramValue
    .split(',')
    .map((value: string) =>
      value && !Number.isNaN(value) ? parseInt(value) : null
    )
}

export const getInitialBonusNumber = (
  getParam: (paramKey: string) => Nullable<string>
) => {
  const urlSpecialBall = getParam(PastResultsFieldId.YOUR_NUMBERS_SPECIAL_BALL)
  return urlSpecialBall && !Number.isNaN(urlSpecialBall)
    ? parseInt(urlSpecialBall)
    : null
}
