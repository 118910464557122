import * as React from 'react'
import { useDispatch } from 'react-redux'
import ReactFocusLock from 'react-focus-lock'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import * as Dialog from '@radix-ui/react-dialog'
import { uiActions } from '@Client/reducers/ui/ui-reducer'
import { CloseIcon } from '@Client/icons/close-icon'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '@Components/component-helpers'
import { ModalCloseButton } from './modal-close-button'
import './styles.scss'

export type ModalProps = React.PropsWithChildren<{
  useCloseButton?: boolean
  ariaTitle: string
  ariaDescription: string
  mobileFullScreen: boolean
  className?: string
}>

export type ModalRootProps = React.PropsWithChildren<{
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => any
  mobileFullScreen?: boolean
}>

const ROOT_CLASS = 'modal'
const getContentClass = (className: string = ROOT_CLASS) =>
  getBlockClass(className, 'content')
const getOverlayClass = (className: string = ROOT_CLASS) =>
  getBlockClass(className, 'overlay')

/**
 * All Modals must be wrapped in a ModalRoot component.
 * The ModalRoot component handles the open/close state of the modal.
 * These are separated because the trigger button location and the modal location will differ.
 */
export const Modal = (props: ModalProps) => {
  return (
    <Dialog.Portal>
      <Dialog.Overlay
        className={joinClasses([
          getOverlayClass(),
          props.className ? getOverlayClass(props.className) : undefined
        ])}
      />
      {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
      <ReactFocusLock autoFocus={false} returnFocus>
        <Dialog.Content
          className={joinClasses([
            getContentClass(),
            getModifierClass(
              getContentClass(),
              'mobile-fullscreen',
              props.mobileFullScreen
            ),
            props.className ? getContentClass(props.className) : undefined
          ])}
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          <VisuallyHidden.Root asChild>
            <Dialog.Title>{props.ariaTitle}</Dialog.Title>
          </VisuallyHidden.Root>
          <VisuallyHidden.Root asChild>
            <Dialog.Description>{props.ariaDescription}</Dialog.Description>
          </VisuallyHidden.Root>
          {props.useCloseButton && (
            <ModalCloseButton
              className={getBlockClass(ROOT_CLASS, 'default-close-button')}
            >
              <CloseIcon />
            </ModalCloseButton>
          )}
          {props.children}
        </Dialog.Content>
      </ReactFocusLock>
    </Dialog.Portal>
  )
}

/**
 * All Modals must be wrapped in a ModalRoot component.
 */
export const ModalRoot = (props: ModalRootProps) => {
  const dispatch = useDispatch()
  const onOpenChange = (isOpen: boolean) => {
    dispatch(
      uiActions.setModalOpen({
        isModalOpen: isOpen,
        isFullScreenModal: props.mobileFullScreen || false
      })
    )
    props.onOpenChange(isOpen)
  }
  return (
    <Dialog.Root open={props.isOpen} onOpenChange={onOpenChange}>
      {props.children}
    </Dialog.Root>
  )
}
