import * as React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import './styles.scss'

export type SidebarProps = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  side: 'left' | 'right'
  ariaTitle: string
  ariaDescription: string
}

const ROOT_CLASS = 'sidebar'
const CONTENT_CLASS = getBlockClass(ROOT_CLASS, 'content')

export const Sidebar = (props: React.PropsWithChildren<SidebarProps>) => {
  return (
    <Dialog.Root open={props.isOpen} onOpenChange={props.onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className={getBlockClass(ROOT_CLASS, 'overlay')} />
        <Dialog.Content
          className={joinClasses([
            CONTENT_CLASS,
            getModifierClass(CONTENT_CLASS, props.side, true)
          ])}
        >
          <VisuallyHidden.Root asChild>
            <Dialog.Title>{props.ariaTitle}</Dialog.Title>
          </VisuallyHidden.Root>
          <VisuallyHidden.Root asChild>
            <Dialog.Description>{props.ariaDescription}</Dialog.Description>
          </VisuallyHidden.Root>
          {props.children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
