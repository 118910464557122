import { EntryFieldTypes } from 'contentful'
import { CmsModel } from './cms-models'
import {
  GenericContentArrayField,
  TypedGenericContentModel
} from './generic-content-models'

export enum ColumnLayoutField {
  name = 'name',
  ratio = 'ratio',
  columnOne = 'columnOne',
  columnTwo = 'columnTwo',
  columnThree = 'columnThree'
}

export enum ColumnRatio {
  equal = 'Equal',
  oneThirdTwoThirds = 'Two Columns: 1/3, 2/3',
  twoThirdsOneThird = 'Two Columns: 2/3, 1/3'
}

export type ColumnLayoutSkeleton = {
  contentTypeId: CmsModel.columnLayout
  fields: {
    [ColumnLayoutField.name]: EntryFieldTypes.Symbol
    [ColumnLayoutField.ratio]: EntryFieldTypes.Symbol<ColumnRatio>
    [ColumnLayoutField.columnOne]: GenericContentArrayField
    [ColumnLayoutField.columnTwo]: GenericContentArrayField
    [ColumnLayoutField.columnThree]: GenericContentArrayField
  }
}

export type ColumnLayoutModel = {
  columnRatioString: ColumnRatio
  columnRatios: number[]
  columns: TypedGenericContentModel[][]
}

export const ColumnLayoutFields = Object.values(ColumnLayoutField)
