import * as React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useAppDispatch } from '../redux-hooks'
import { uiActions } from '@Client/reducers/ui/ui-reducer'

export const BREAKPOINTS = {
  mobileSmall: 320,
  tablet: 600,
  desktop: 1008
}

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = React.useState(false)
  const hookIsMobile = useMediaQuery({ maxWidth: BREAKPOINTS.tablet - 1 })
  React.useEffect(() => {
    setIsMobile(hookIsMobile)
  }, [hookIsMobile])

  return isMobile
}

export const useIsTablet = () => {
  const [isTablet, setIsTablet] = React.useState(false)
  const hookIsTablet = useMediaQuery({
    minWidth: BREAKPOINTS.tablet,
    maxWidth: BREAKPOINTS.desktop - 1
  })
  React.useEffect(() => {
    setIsTablet(hookIsTablet)
  }, [hookIsTablet])

  return isTablet
}

export const useIsMobileOrTablet = () => {
  const [isMobileOrTablet, setIsMobileOrTablet] = React.useState(false)
  const hookIsMobileOrTablet = useMediaQuery({
    maxWidth: BREAKPOINTS.desktop - 1
  })
  React.useEffect(() => {
    setIsMobileOrTablet(hookIsMobileOrTablet)
  }, [hookIsMobileOrTablet])

  return isMobileOrTablet
}

export const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = React.useState(false)
  const hookIsDesktop = useMediaQuery({ minWidth: BREAKPOINTS.desktop })
  React.useEffect(() => {
    setIsDesktop(hookIsDesktop)
  }, [hookIsDesktop])

  return isDesktop
}

export const useUpdateIsMobileState = () => {
  const dispatch = useAppDispatch()
  const isMobile = useIsMobile()

  React.useEffect(() => {
    dispatch(uiActions.setMobileViewport(isMobile))
  }, [dispatch, isMobile])
}
