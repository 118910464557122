import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DrawResultsState,
  FetchDrawResultFailurePayload,
  FetchDrawResultPayload,
  FetchDrawResultSuccessPayload
} from './draw-results-reducer-types'
import { DateFormat, dateUtil } from '@Util/date-util'

export const initialDrawResultsState: DrawResultsState = {
  drawResultsByDataServiceId: {}
}

export const drawResultsSlice = createSlice({
  name: 'drawResults',
  initialState: initialDrawResultsState,
  reducers: {
    fetchDrawResultRequest: (
      state,
      action: PayloadAction<FetchDrawResultPayload>
    ) => {
      const existingGameState =
        state.drawResultsByDataServiceId[action.payload.gameId] ?? {}
      state.drawResultsByDataServiceId[action.payload.gameId] = {
        ...existingGameState,
        isLoading: true,
        error: null
      }
    },
    fetchDrawResultSuccess: (
      state,
      action: PayloadAction<FetchDrawResultSuccessPayload>
    ) => {
      const dateString = dateUtil(action.payload.dateString).format(
        DateFormat.monthDayYear
      )
      const existingGameState =
        state.drawResultsByDataServiceId[action.payload.gameId] ?? {}
      const timestamps =
        new Set(existingGameState.timeStampsByDateString?.[dateString]) ?? []
      timestamps.add(action.payload.dateString)
      const timestampsForDateString = Array.from(timestamps).sort((a, b) =>
        a.localeCompare(b)
      )
      let nextDrawingTimestamp = existingGameState.nextDrawingTimestamp
      const dateObj = dateUtil(action.payload.dateString, true)
      if (!nextDrawingTimestamp || dateObj.isAfter(nextDrawingTimestamp)) {
        nextDrawingTimestamp = action.payload.dateString
      }

      state.drawResultsByDataServiceId[action.payload.gameId] = {
        ...existingGameState,
        isLoading: false,
        error: null,
        drawResults: {
          ...existingGameState.drawResults,
          [action.payload.dateString]: action.payload.drawResult
        },
        timeStampsByDateString: {
          ...existingGameState.timeStampsByDateString,
          [dateString]: timestampsForDateString
        },
        nextDrawingTimestamp
      }
    },
    fetchDrawResultFailure: (
      state,
      action: PayloadAction<FetchDrawResultFailurePayload>
    ) => {
      const existingGameState =
        state.drawResultsByDataServiceId[action.payload.gameId] ?? {}
      state.drawResultsByDataServiceId[action.payload.gameId] = {
        ...existingGameState,
        isLoading: false,
        error: action.payload.error
      }
    }
  }
})

export const drawResultsActions = drawResultsSlice.actions
export const drawResultsReducer = drawResultsSlice.reducer
