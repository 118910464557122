import * as React from 'react'
import { AlertBannerModel } from '@Cms/model-types/alert-banner-model'
import { useResizeObserver } from '@Client/hooks/use-resize-observer/use-resize-observer'
import { Button } from '../button/button'
import { CloseIcon } from '@Client/icons/close-icon'
import {
  getBlockClass,
  getCustomCssProperties,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { CmsText } from '../cms-text/cms-text-ui'
import './styles.scss'

export type AlertBannerProps = {
  alertBanner: AlertBannerModel
  onClose: () => void
}

const ROOT_CLASS = 'alert-banner'
const INITIAL_RENDER_DELAY_MS = 500
const ANIMATION_DURATION_MS = 300

export const AlertBanner = React.forwardRef(
  (props: AlertBannerProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const [isVisible, setIsVisible] = React.useState(false)
    const [initialAnimationComplete, setInitialAnimationComplete] =
      React.useState(false)
    const [contentHeight, setContentHeight] = React.useState(0)
    const contentRef = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
      setTimeout(() => setIsVisible(true), INITIAL_RENDER_DELAY_MS)
      // remove transition delay when resizing window after initial appearance
      setTimeout(
        () => setInitialAnimationComplete(true),
        INITIAL_RENDER_DELAY_MS + ANIMATION_DURATION_MS
      )
    }, [])

    useResizeObserver(() => {
      if (!contentRef.current) {
        return
      }
      setContentHeight(contentRef.current.clientHeight)
    }, contentRef)

    return (
      <div
        ref={ref}
        className={joinClasses([
          ROOT_CLASS,
          getModifierClass(ROOT_CLASS, 'is-visible', isVisible),
          getModifierClass(
            ROOT_CLASS,
            'initial-animation-complete',
            initialAnimationComplete
          )
        ])}
        style={getCustomCssProperties({
          '--alert-content-height': `${contentHeight}px`
        })}
      >
        <div className={getBlockClass(ROOT_CLASS, 'content')} ref={contentRef}>
          <span className={getBlockClass(ROOT_CLASS, 'title')}>
            {props.alertBanner.title}
          </span>
          <CmsText textModel={props.alertBanner.text} />
          <Button
            onClick={props.onClose}
            ariaLabel='Close alert banner'
            className={getBlockClass(ROOT_CLASS, 'close-button')}
          >
            <CloseIcon />
          </Button>
        </div>
      </div>
    )
  }
)
