import * as React from 'react'
import {
  LoaderFunction,
  LoaderFunctionArgs,
  useLoaderData,
  useParams
} from 'react-router-dom'
import { GameCollectionResponse } from '@Server/controllers/game/game-controller'
import { Api } from '@Client/api'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { GameCollection } from './game-collection-ui'
import { CmsGamesApi } from '@Client/api/cms-games'
import { useAppDispatch, useAppSelector } from '@Client/hooks/redux-hooks'
import { useRecurringAction } from '@Client/hooks/use-recurring-action/use-recurring-action'
import { jackpotActions } from '@Client/reducers/jackpots/jackpots-reducer'
import { GameCollectionIdentifier } from '@Cms/model-types/game-collection-model'
import { getObjectKeys } from '@Util/object-utilities'
import { iwgJackpotsActions } from '@Client/reducers/iwg-jackpots/iwg-jackpots-reducer'

const NINETY_SECONDS_MS = 90 * 1000

export const gameCollectionLoader: LoaderFunction = async (
  args: LoaderFunctionArgs
) => {
  const { identifier } = args.params
  return identifier
    ? await CmsGamesApi.fetchGameCollectionByIdentifier(identifier)
    : { status: 'error', message: Api.DEFAULT_ERROR }
}

export const GameCollectionContainer = () => {
  const dispatch = useAppDispatch()
  const { mqttByIwgIdentifier, isLoading: mqttIsLoading } = useAppSelector(
    (state) => state.iwgJackpots
  )

  useRecurringAction(() => {
    dispatch(jackpotActions.fetchJackpotsRequest())
  }, NINETY_SECONDS_MS)
  const loaderData = useLoaderData() as GameCollectionResponse
  const { identifier } = useParams()
  const isILottery = identifier === GameCollectionIdentifier.iLottery

  React.useEffect(() => {
    const existingMqttKeys = getObjectKeys(mqttByIwgIdentifier)
    if (isILottery && existingMqttKeys.length === 0 && !mqttIsLoading) {
      dispatch(iwgJackpotsActions.fetchAllIwgJackpotsRequest())
    }
  }, [isILottery, mqttByIwgIdentifier, mqttIsLoading])

  return loaderData.status === 'success' ? (
    <GameCollection
      collection={loaderData.data}
      key={identifier}
      isILottery={isILottery}
    />
  ) : (
    <LoadingSpinner />
  )
}
