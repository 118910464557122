import * as React from 'react'
import { Button } from '../button/button'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import './styles.scss'

export type ToggleButtonOption = {
  ariaLabel: string
  text: string
  icon?: JSX.Element
}

export type ToggleButtonProps = {
  options: ToggleButtonOption[]
  selectedOptionIndex: number
  name: string
  onSelect: (selectedIndex: number) => void
}

const ROOT_CLASS = 'toggle-button'
const OPTION_CLASS = getBlockClass(ROOT_CLASS, 'option')

export const ToggleButton = (props: ToggleButtonProps) => {
  const [selectedDomRect, setSelectedDomRect] = React.useState<DOMRect>()
  const [selectedOffsetLeft, setSelectedOffsetLeft] = React.useState<number>()

  const setSelectedBackground = () => {
    const optionGroup = document.querySelector(`#${props.name}`)
    const selectedOption = optionGroup?.querySelector(
      `.${OPTION_CLASS}[aria-selected="true"]`
    )
    setSelectedDomRect(selectedOption?.getBoundingClientRect())
    if (selectedOption instanceof HTMLButtonElement) {
      setSelectedOffsetLeft(selectedOption.offsetLeft)
    }
  }

  React.useEffect(() => {
    setSelectedBackground()
  }, [])

  return (
    <div className={ROOT_CLASS} id={props.name}>
      <span
        className={getBlockClass(ROOT_CLASS, 'selected-bkg')}
        aria-hidden
        style={{
          width: selectedDomRect?.width,
          left: selectedOffsetLeft
        }}
      />
      {props.options.map((option, i) => (
        <Button
          key={`${props.name}-${i}-option`}
          onClick={(e) => {
            props.onSelect(i)
            const element = e?.target
            if (element instanceof HTMLButtonElement) {
              setSelectedDomRect(element.getBoundingClientRect())
              setSelectedOffsetLeft(element.offsetLeft)
            }
          }}
          ariaLabel={option.ariaLabel}
          htmlAttributes={{
            'aria-selected': props.selectedOptionIndex === i
          }}
          className={joinClasses([
            OPTION_CLASS,
            getModifierClass(
              OPTION_CLASS,
              'selected',
              props.selectedOptionIndex === i
            )
          ])}
        >
          {option.text}
        </Button>
      ))}
    </div>
  )
}
