import * as React from 'react'
import { CmsModel } from '@Cms/model-types/cms-models'
import { TypedPageContentModel } from '@Cms/model-types/page-model'
import { CmsText } from '../cms-text/cms-text-ui'
import { Table } from '../table/table-ui'
import { CTA } from '../cms-cta/cta'
import { ResponsiveImage } from '../cms-responsive-image/responsive-image-ui'
import { getBlockClass } from '../component-helpers'
import { TypedGenericContentModel } from '@Cms/model-types/generic-content-models'
import { ColumnLayout } from '../column-layout/column-layout-ui'
import { FeedContainer } from '../cms-feed/feed-container'
import { ImageBanner } from '../banner-image/banner-image-ui'
import { CmsAccordion } from '../cms-accordion/cms-accordion-ui'

type CmsGenericContentProps = {
  content: TypedPageContentModel | TypedGenericContentModel
  rootClassName: string
}

export const CmsGenericContent = (props: CmsGenericContentProps) => {
  switch (props.content.contentType) {
    case CmsModel.text:
      return <CmsText textModel={props.content.data} />
    case CmsModel.csvTable:
      return <Table table={props.content.data} />
    case CmsModel.cta:
      return (
        <CTA
          cta={props.content.data}
          className={getBlockClass(props.rootClassName, 'cta')}
        />
      )
    case CmsModel.responsiveImage:
      return (
        <ResponsiveImage
          image={props.content.data}
          className={getBlockClass(props.rootClassName, 'responsive-image')}
        />
      )
    case CmsModel.imageBanner:
      return (
        <ImageBanner
          imageBanner={props.content.data}
          className={getBlockClass(props.rootClassName, 'image-banner')}
        />
      )
    case CmsModel.accordion:
      return (
        <CmsAccordion
          className={getBlockClass(props.rootClassName, 'accordion')}
          accordion={props.content.data}
        />
      )
    case CmsModel.columnLayout:
      return <ColumnLayout columnLayout={props.content.data} />
    case CmsModel.feed:
      return <FeedContainer pageFeed={props.content.data} />
    default:
      return null
  }
}
