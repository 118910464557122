import * as React from 'react'
import {
  NavCategoryField,
  NavCategoryModel
} from '@Cms/model-types/nav-category-model'
import { GeneralLink } from '../link/link-ui'
import { getBlockClass } from '../component-helpers'
import { TopLevelRoutesByName } from '@Client/constants'
import { NavItemField } from '@Cms/model-types/nav-item-model'
import './styles.scss'

export type NavigationCategoryProps = NavCategoryModel & {
  closeMenus: () => void
}

const ROOT_CLASS = 'navigation-category'

export const NavigationCategory = (props: NavigationCategoryProps) => {
  const name = props[NavCategoryField.name]
  return (
    <div className={ROOT_CLASS}>
      {props[NavCategoryField.navItems].length === 0 ? (
        <GeneralLink
          dest={TopLevelRoutesByName[name]}
          className={getBlockClass(ROOT_CLASS, 'standalone-link')}
        >
          {name}
        </GeneralLink>
      ) : (
        <>
          <span className={getBlockClass(ROOT_CLASS, 'header')}>{name}</span>
          <ul className={getBlockClass(ROOT_CLASS, 'links')}>
            {props[NavCategoryField.navItems].map((navItem) => (
              <li
                key={navItem[NavItemField.route]}
                className={getBlockClass(ROOT_CLASS, 'link-li')}
              >
                <GeneralLink
                  onClick={props.closeMenus}
                  dest={navItem[NavItemField.route]}
                  className={getBlockClass(ROOT_CLASS, 'link')}
                >
                  {navItem[NavItemField.displayText]}
                </GeneralLink>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}
