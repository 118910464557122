import * as React from 'react'
import { PastResultsFieldId, ROOT_CLASS } from './constants'
import { ValidGameRange } from './form-helpers'
import { getBlockClass, getCustomCssProperties } from '../component-helpers'
import { InputLabel } from '../input-label/input-label-ui'
import { InputNumber } from '../input-number/input-number'
import { Nullable } from '@Util/utility-types'
import './styles.scss'

export type PastResultsYourNumbersProps = {
  numberCount: number
  onChange: (value: Nullable<number>, index: number) => void
  defaultValues?: Nullable<number>[]
  hasBonusNumber: boolean
  onBonusNumberChange: (value: Nullable<number>) => void
  defaultBonusNumber?: Nullable<number>
  specialBallColorHex?: string
  validGameRange?: ValidGameRange
  gameIdentifier?: string
}

const MAX_INPUT_DIGITS = 2
const getYourNumbersInputId = (index: number, gameIdentifier?: string) =>
  `${gameIdentifier}-${PastResultsFieldId.YOUR_NUMBERS}-${index}`

export const PastResultsYourNumbers = (props: PastResultsYourNumbersProps) => {
  const focusNextInput = (index: number, value?: number) => {
    const numDigits = value ? value.toString().length : 0
    if (numDigits >= MAX_INPUT_DIGITS) {
      const focusElement =
        document.getElementById(
          getYourNumbersInputId(index + 1, props?.gameIdentifier)
        ) ??
        document.getElementById(PastResultsFieldId.YOUR_NUMBERS_SPECIAL_BALL)
      focusElement?.focus()
    }
  }

  return props.numberCount === 0 ? null : (
    <div className={getBlockClass(ROOT_CLASS, 'your-numbers')}>
      <InputLabel for={getYourNumbersInputId(0, props?.gameIdentifier)}>
        Your Numbers (optional)
      </InputLabel>
      <div className={getBlockClass(ROOT_CLASS, 'your-numbers-inputs')}>
        {Array.from({ length: props.numberCount }).map((_, index) => {
          const id = getYourNumbersInputId(index, props?.gameIdentifier)
          return (
            <InputNumber
              key={id}
              id={id}
              min={props.validGameRange?.min}
              max={props.validGameRange?.max}
              ariaLabel={'Your number filter #' + (index + 1)}
              fieldClassName={getBlockClass(ROOT_CLASS, 'number')}
              onChange={(value) => {
                props.onChange(
                  Number.isSafeInteger(value) ? Number(value) : null,
                  index
                )
                focusNextInput(index, value)
              }}
              defaultValue={props.defaultValues?.[index] ?? undefined}
            />
          )
        })}
        {props.hasBonusNumber ? (
          <InputNumber
            id={PastResultsFieldId.YOUR_NUMBERS_SPECIAL_BALL}
            onChange={(number) =>
              props.onBonusNumberChange(
                number !== null && number !== undefined ? number : null
              )
            }
            ariaLabel={'Your number filter: bonus ball'}
            fieldClassName={getBlockClass(ROOT_CLASS, 'special-ball')}
            defaultValue={props.defaultBonusNumber ?? undefined}
            htmlProps={{
              style: props.specialBallColorHex
                ? getCustomCssProperties({
                    '--special-ball-color': props.specialBallColorHex
                  })
                : undefined
            }}
            min={props.validGameRange?.specialMin ?? 0}
            max={props.validGameRange?.specialMax}
          />
        ) : null}
      </div>
    </div>
  )
}
