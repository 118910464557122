import { Optional } from '@Util/utility-types'
import { DrawResultState } from './draw-results-reducer-types'
import { RootState } from '..'
import { getObjectKeys } from '@Util/object-utilities'

export const selectGameDrawResultsState = (
  gameDataServiceId: string,
  state: RootState
): Optional<DrawResultState> =>
  state.drawResults.drawResultsByDataServiceId[gameDataServiceId]

export const selectNextDrawTime = (
  state: RootState,
  gameDataServiceId: string
) => {
  const drawResultsState = selectGameDrawResultsState(gameDataServiceId, state)
  if (
    !drawResultsState ||
    !getObjectKeys(drawResultsState.drawResults).length
  ) {
    return
  }
  const dateStrings = getObjectKeys(drawResultsState.drawResults)
  const upcomingDrawing = dateStrings.reduce((closest, current) => {
    const closestTime = new Date(closest)
    const currentTime = new Date(current)
    return currentTime > closestTime ? current : closest
  }, dateStrings[0])

  return upcomingDrawing
}
