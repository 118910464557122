import { GameRulesModel, GameType } from '@Cms/model-types/game-model'
import { Api } from '.'
import { ApiResponse } from '@Server/controllers/controller-helpers'
import { GamesQueryResponse } from '@Server/controllers/game/game-controller'

const fetchGameRules = async (
  gameIdentifier: string
): Promise<ApiResponse<GameRulesModel>> => {
  try {
    return await Api.callServer('/api/v1/game/rules', {
      identifier: gameIdentifier
    })
  } catch (error) {
    console.error('Error fetching game rules', error)
    throw error
  }
}

const fetchGamesByType = async (
  gameType: GameType
): Promise<GamesQueryResponse> => {
  try {
    return await Api.callServer('/api/v1/game/query', {
      type: gameType
    })
  } catch (error) {
    console.error('Error fetching games by type', error)
    throw error
  }
}

const fetchAllGames = async (): Promise<GamesQueryResponse> => {
  try {
    return await Api.callServer('/api/v1/game/all')
  } catch (error) {
    console.error('Error fetching all games', error)
    throw error
  }
}

const fetchGameCollectionByIdentifier = async (
  identifier: string
): Promise<GamesQueryResponse> => {
  try {
    return await Api.callServer('/api/v1/game/collection', {
      identifier
    })
  } catch (error) {
    console.error('Error fetching game collection', error)
    throw error
  }
}

const fetchGameLobbyByIdentifier = async (
  identifier: string
): Promise<GamesQueryResponse> => {
  try {
    return await Api.callServer('/api/v1/game/lobby', {
      identifier
    })
  } catch (error) {
    console.error('Error fetching game lobby', error)
    throw error
  }
}

export const CmsGamesApi = {
  fetchAllGames,
  fetchGameCollectionByIdentifier,
  fetchGameLobbyByIdentifier,
  fetchGamesByType,
  fetchGameRules
}
