export function union<T>(a: T[], b: T[]): T[] {
  return Array.from(new Set([...a, ...b]))
}

export function intersection<T>(a: T[], b: T[]): T[] {
  const bSet = new Set([...b])
  return a.filter((x) => bSet.has(x))
}

export function compact<T>(list: (undefined | null | T)[]): T[] {
  return list.filter((item): item is T => item !== null && item !== undefined)
}

export function getArrayOfLength<T>(length: number, initialValue?: T) {
  return Array(length).fill(initialValue ?? null)
}

export function keyObjectsBy<T>(objs: T[], key: keyof T): Record<string, T[]> {
  return objs.reduce((acc, obj) => {
    if (!acc[obj[key]]) {
      acc[obj[key]] = []
    }
    acc[obj[key]].push(obj)
    return acc
  }, {} as any)
}
