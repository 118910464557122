import * as React from 'react'
import { Modal } from '../modal/modal'
import { ModalCloseButton } from '@Components/modal/modal-close-button'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '@Components/component-helpers'
import { FilterCategoryByIdentifier } from '@Components/game-lobby/helpers/filter-helpers'
import { FilterCategory } from '@Components/filter-category/filter-category'
import { getObjectKeys } from '@Util/object-utilities'
import { FilterCategoryField } from '@Cms/model-types/filter-category-model'
import { InputSelect } from '@Components/input-select/input-select-ui'
import { PRICE_FILTER_CATEGORY_IDENTIFIER } from '../game-lobby-query/constants'
import { GamePrice } from '@Cms/model-types/game-model'
import {
  SortOptionField,
  SortOptionModel
} from '@Cms/model-types/sort-option-model'
import {
  getSelectOptionsFromSortOptions,
  setMatchingSort
} from '../game-lobby/helpers/sort-helpers'
import './styles.scss'

export type GameLobbyMoreFiltersProps = {
  lobbyDisplayText: string
  filterCategories: FilterCategoryByIdentifier
  setFilterActive: (
    categoryIdentifier: string,
    filterIdentifier: string,
    isActive: boolean
  ) => void
  activePrice?: string
  setActivePrice: (price: string) => void
  currentSort: SortOptionModel
  onSortChange: (currentSort: SortOptionModel) => void
  supportedSorts: SortOptionModel[]
  priceOptions: GamePrice[]
  isILottery: boolean
}

const ROOT_CLASS = 'game-lobby-more-filters'
const CLOSE_BUTTON_CLASS = getBlockClass(ROOT_CLASS, 'close-button')

export const GameLobbyMoreFilters = (props: GameLobbyMoreFiltersProps) => {
  return (
    <Modal
      ariaTitle='Game Lobby More Filters'
      ariaDescription='Select from more filters and sort games'
      mobileFullScreen
    >
      <section
        className={joinClasses([
          ROOT_CLASS,
          getModifierClass(ROOT_CLASS, 'is-ilottery', props.isILottery)
        ])}
      >
        <header className={getBlockClass(ROOT_CLASS, 'header')}>
          <h2>{`Filter ${props.lobbyDisplayText}`}</h2>
        </header>
        <div className={getBlockClass(ROOT_CLASS, 'body')}>
          <div className={getBlockClass(ROOT_CLASS, 'filters')}>
            {props.supportedSorts.length !== 0 && (
              <InputSelect
                options={getSelectOptionsFromSortOptions(props.supportedSorts)}
                className={getBlockClass(ROOT_CLASS, 'sort')}
                onChange={(value) =>
                  setMatchingSort(
                    value,
                    props.supportedSorts,
                    props.onSortChange
                  )
                }
                label={'Sort By'}
                id={'game-lobby-sort'}
                defaultValue={props.currentSort[SortOptionField.identifier]}
              />
            )}
            {getObjectKeys(props.filterCategories).map((categoryIdentifier) => {
              const category = props.filterCategories[categoryIdentifier]
              return (
                <FilterCategory
                  key={categoryIdentifier}
                  identifier={categoryIdentifier}
                  displayText={category[FilterCategoryField.displayText]}
                  filters={Object.values(category.filterTagsByIdentifier)}
                  setFilterActive={props.setFilterActive}
                  isTransparent={props.isILottery}
                />
              )
            })}
            <FilterCategory
              identifier={PRICE_FILTER_CATEGORY_IDENTIFIER}
              displayText={'Price'}
              filters={props.priceOptions.map((option) => ({
                isInSpotlight: false,
                isActive: props.activePrice === String(option.priceInCents),
                categoryIdentifier: PRICE_FILTER_CATEGORY_IDENTIFIER,
                displayText: option.priceFormatted,
                identifier: String(option.priceInCents)
              }))}
              setFilterActive={(categoryIdentifier, filterIdentifier) => {
                props.setActivePrice(filterIdentifier)
              }}
              isTransparent={props.isILottery}
            />
          </div>
          <ModalCloseButton
            className={joinClasses([
              CLOSE_BUTTON_CLASS,
              getModifierClass(
                CLOSE_BUTTON_CLASS,
                'is-ilottery',
                props.isILottery
              )
            ])}
          >
            Done
          </ModalCloseButton>
        </div>
      </section>
    </Modal>
  )
}
