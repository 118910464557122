import { Nullable } from '@Util/utility-types'
import { NeoGamesOpenPageCustomInfo } from '../ng-types'
import { FreshDesk } from '@Client/util/freshdesk'
import { OpenPageIdentifier } from './ng-open-page-map'
import { OpenPageRoute } from './ng-open-page-util'
import { compact } from 'lodash'

const processOpenPageRequest = (
  key: string,
  destination: Nullable<string>,
  customInfo: Nullable<NeoGamesOpenPageCustomInfo>
) => {
  const identifier = compact([
    key,
    destination,
    customInfo?.page,
    customInfo?.params
  ]).join('_') as OpenPageIdentifier

  if (
    !OpenPageRoute.isNgOpenPageRoute(identifier) &&
    !OpenPageRoute.isNgOpenPageRoute(key)
  ) {
    console.error(
      `Invalid information provided for open page request with key ${key} destination ${destination} and custom info ${JSON.stringify(customInfo)}`
    )
    return
  }

  if (key.startsWith(OpenPageIdentifier.GAME_RULES)) {
    OpenPageRoute.openInternalRoute(OpenPageIdentifier.GAME_RULES)
  } else if (FreshDesk.isFreshDesk(identifier)) {
    FreshDesk.openArticle(identifier)
  } else if (OpenPageRoute.isInternalRoute(identifier)) {
    OpenPageRoute.openInternalRoute(identifier)
  } else if (OpenPageRoute.isExternalRoute(identifier)) {
    OpenPageRoute.openExternalRoute(identifier)
  }
}

export const NeoGamesPageHandler = {
  processOpenPageRequest
}
