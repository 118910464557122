import * as React from 'react'
import { Feed } from './feed-ui'
import { ContentPageFeedModel, FeedModel } from '@Cms/model-types/feed-model'
import { usePaginatedFetch } from '@Client/hooks/use-fetch/use-fetch'
import { FeedItemModel, FeedType } from '@Cms/model-types/feed-item-model'
import { FeedRequest } from '@Server/controllers/content/content-controller-validation'

export type FeedContainerProps = {
  pageFeed: ContentPageFeedModel
}

const getPaginatedFeedParams = (
  startAt: number,
  feedType: FeedType,
  cmsPreview: boolean = false
): FeedRequest => ({
  startAt,
  feedType,
  cmsPreview,
  limit: 18
})

export const FeedContainer = (props: FeedContainerProps) => {
  const [startAt, setStartAt] = React.useState<number>(0)
  const { fetchMoreData, paginatedData, latestFetchData, isLoading, error } =
    usePaginatedFetch<FeedModel, FeedItemModel>(
      'api/v1/content/feed',
      getPaginatedFeedParams(startAt, props.pageFeed.type),
      'GET',
      'feedItems'
    )

  const fetchNextPage = async () => {
    try {
      const newStartAt = paginatedData.length
      await fetchMoreData(
        getPaginatedFeedParams(newStartAt, props.pageFeed.type)
      )
      setStartAt(newStartAt)
    } catch (error) {
      console.error('Error fetching next page of feed items', error)
    }
  }

  React.useEffect(() => {
    fetchNextPage()
    return () => {}
  }, [])

  const totalResults = latestFetchData?.totalResults ?? 0
  const resultMax = latestFetchData?.resultMax ?? 0

  return (
    <Feed
      feed={{
        header: props.pageFeed.header,
        feedItems: paginatedData,
        resultMin: latestFetchData?.resultMin ?? 0,
        resultMax,
        totalResults
      }}
      onLoadMore={fetchNextPage}
      isLoading={isLoading}
      error={error}
      hideShowMore={resultMax >= totalResults && totalResults !== 0}
    />
  )
}
