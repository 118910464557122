import * as React from 'react'
import { Button } from '@Components/button/button'
import './styles.scss'

const ID = 'skip-to-main'

export const SkipToMainButton = () => (
  <Button
    id={ID}
    ariaLabel='Skip to Main Content'
    onClick={() => {
      const main = document.querySelector('main')
      const mainTop = main?.getBoundingClientRect().top ?? 0
      window.scrollTo({ top: mainTop })
      main?.focus()
    }}
  >
    Skip to Main Content
  </Button>
)
