import * as React from 'react'
import {
  InstantGame,
  PrizesRemaining as PrizesRemainingModel
} from '@interaction-gaming/game-data-service-browser-client'
import { PrizesRemainingForm } from '../prizes-remaining-form/prizes-remaining-form-ui'
import { InputMultiSelectOption } from '../input-multi-select/input-multi-select-ui'
import {
  PrizesRemainingFormData,
  prizesRemainingPrizeAmounts,
  prizesRemainingTicketCosts
} from '../prizes-remaining-form/constants'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { getBlockClass } from '../component-helpers'
import {
  deepCopy,
  getObjectKeys,
  SerializedDates
} from '@Util/object-utilities'
import { PrizesRemainingGameCard } from '../prizes-remaining-dropdown-card/prizes-remaining-game-card'
import { useIsDesktop } from '@Client/hooks/use-media-query/use-media-query'
import { DateFormat, dateUtil } from '@Util/date-util'
import { ErrorMessage } from '../error-message/error-message-ui'
import './styles.scss'

const ROOT_CLASS = 'prizes-remaining'
const NUM_SPOTLIGHT_PRIZES = 3

export type PrizesByGameId = Record<string, PrizesRemainingModel[]>

export type PrizesRemainingProps = {
  gameOptions?: InputMultiSelectOption[]
  instantGamesByGameId?: Record<string, SerializedDates<InstantGame>>
  prizesByGameId: PrizesByGameId
  onFormSubmit: (payload: PrizesRemainingFormData) => Promise<void>
  isLoading: boolean
  error?: string
  dataIsReady: boolean
  lastUpdated?: Date
}

const PrizesRemainingList = (props: PrizesRemainingProps) => (
  <div className={getBlockClass(ROOT_CLASS, 'list')}>
    {getObjectKeys(props.prizesByGameId).map((gameId) => {
      const gamePrizes = deepCopy(props.prizesByGameId?.[gameId] ?? [])
      const game = props.instantGamesByGameId?.[gameId]
      if (!game) {
        return null
      }
      const minTicketPriceInCents = Math.min(...game.ticketCostOptionsInCents)
      const spotlightPrizes = gamePrizes.splice(0, NUM_SPOTLIGHT_PRIZES)
      return (
        <PrizesRemainingGameCard
          key={gameId}
          gameName={game.name}
          gameNumber={game.gameId}
          gamePrice={minTicketPriceInCents}
          spotlightPrizes={spotlightPrizes}
          additionalPrizes={gamePrizes}
        />
      )
    })}
  </div>
)

export const PrizesRemaining = (props: PrizesRemainingProps) => {
  const isDesktop = useIsDesktop()
  const totalResults = getObjectKeys(props.prizesByGameId ?? {}).length
  const lastUpdated = dateUtil(props.lastUpdated).format(
    DateFormat.fullDateAndTime
  )
  const { evenResults, oddResults } = React.useMemo(() => {
    const oddResults: PrizesByGameId = {}
    const evenResults: PrizesByGameId = {}

    getObjectKeys(props.prizesByGameId).forEach((gameId, index) => {
      const gamePrizes = props.prizesByGameId[gameId]
      if (index % 2 === 0) {
        evenResults[gameId] = gamePrizes
      } else {
        oddResults[gameId] = gamePrizes
      }
    })
    return { evenResults, oddResults }
  }, [props.prizesByGameId])
  return (
    <div className={ROOT_CLASS}>
      {props.gameOptions?.length ? (
        <PrizesRemainingForm
          gameOptions={props.gameOptions}
          ticketCostOptions={prizesRemainingTicketCosts}
          prizeAmountOptions={prizesRemainingPrizeAmounts}
          onFormSubmit={props.onFormSubmit}
          totalResults={totalResults}
          dataIsReady={props.dataIsReady}
        />
      ) : (
        <div className={getBlockClass(ROOT_CLASS, 'form-loading')}>
          <LoadingSpinner />
        </div>
      )}
      {props.isLoading ? (
        <div className={getBlockClass(ROOT_CLASS, 'state')}>
          <LoadingSpinner />
        </div>
      ) : totalResults > 0 ? (
        <>
          {isDesktop ? (
            <div className={getBlockClass(ROOT_CLASS, 'lists-container')}>
              <PrizesRemainingList {...props} prizesByGameId={evenResults} />
              <PrizesRemainingList {...props} prizesByGameId={oddResults} />
            </div>
          ) : (
            <PrizesRemainingList {...props} />
          )}
          <div className={getBlockClass(ROOT_CLASS, 'disclaimer')}>
            <strong>
              *$1,000,000 PRIZE PAID AS ANNUITY OVER 25 YRS, OR AS CASH OF
              $700,000 BEFORE TAXES
            </strong>
            <br />
            <strong>
              **$2,000,000 PRIZE PAID AS ANNUITY OVER 30 YRS, OR AS CASH OF
              $1,350,000 BEFORE TAXES
            </strong>
            <br />
            <br />
            <strong>
              Tickets may continue to be sold after all top prizes have been
              claimed.
            </strong>
            <br />
            <br />
            <span>
              Chances of winning and the number of winning tickets are
              established before ticket sales begin, and will change as prizes
              are won.
            </span>
            <br />
            <br />
            {totalResults > 0 && props.lastUpdated && (
              <span>Results last updated: {lastUpdated}</span>
            )}
          </div>
        </>
      ) : (
        <ErrorMessage message='No Results. Please select different filters.' />
      )}
    </div>
  )
}
