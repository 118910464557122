import * as React from 'react'
import { GameModel, GameType } from '@Cms/model-types/game-model'
import { DynamicUrl, RoutePath } from '@Client/route-paths'
import { getBlockClass } from '../component-helpers'
import { Button } from '../button/button'
import { GeneralLink } from '../link/link-ui'
import './styles.scss'

export type DrawGameDetailsCtasProps = {
  game: GameModel
  hasILottery: boolean
  onBuyNowClick: () => void
  onBuySubscriptionClick: () => void
  isILotteryEnabled: boolean
}

const ROOT_CLASS = 'game-details-ctas'
const PRIMARY_BUTTON_CLASS = getBlockClass(ROOT_CLASS, 'primary-button')
const SECONDARY_BUTTON_CLASS = getBlockClass(ROOT_CLASS, 'secondary-button')

export const GameDetailsCtas = (props: DrawGameDetailsCtasProps) => {
  const retailerLinkDest =
    props.game.type === GameType.rapid
      ? DynamicUrl.getKenoFindARetailerUrl()
      : RoutePath.findARetailer
  const findARetailerText =
    props.game.type === GameType.rapid
      ? `Find ${props.game.name} Retailers`
      : 'Find a Retailer'

  return (
    <div className={ROOT_CLASS}>
      {props.hasILottery ? (
        <>
          <Button
            ariaLabel={`Buy ${props.game.name} now`}
            onClick={props.onBuyNowClick}
            className={PRIMARY_BUTTON_CLASS}
            disabled={!props.isILotteryEnabled}
          >
            Buy Now
          </Button>
          <Button
            ariaLabel={`Buy ${props.game.name} now`}
            onClick={props.onBuySubscriptionClick}
            className={SECONDARY_BUTTON_CLASS}
            disabled={!props.isILotteryEnabled}
          >
            Buy Subscription
          </Button>
        </>
      ) : (
        <>
          <GeneralLink
            dest={DynamicUrl.getPastResultsForGame(props.game.identifier)}
            className={PRIMARY_BUTTON_CLASS}
          >
            Past Winning Numbers
          </GeneralLink>
          <GeneralLink
            dest={retailerLinkDest}
            className={SECONDARY_BUTTON_CLASS}
          >
            {findARetailerText}
          </GeneralLink>
        </>
      )}
    </div>
  )
}
