import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GameModel } from '@Cms/model-types/game-model'
import { FetchGamePayload, CmsGameState } from './cms-game-reducer-types'

export const initialCmsGameState: CmsGameState = {
  gamesByDataServiceId: {},
  dataServiceIdByGameIdentifier: {},
  gameIdentifiersByiLotteryId: {},
  isLoading: false,
  error: null
}

const cmsGamesSlice = createSlice({
  name: 'cmsGames',
  initialState: initialCmsGameState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchGamesRequest: (state, action: PayloadAction<FetchGamePayload>) => {
      state.isLoading = true
      state.error = null
    },
    fetchGamesSuccess: (state, action: PayloadAction<GameModel[]>) => {
      const gamesById = action.payload.reduce(
        (acc: Record<string, GameModel>, game) => {
          acc[game.configuration.dataServices.gameDataServiceId] = game
          return acc
        },
        {}
      )
      const gameIdentifiersByid = action.payload.reduce(
        (acc: Record<string, string>, game) => {
          acc[game.identifier] =
            game.configuration.dataServices.gameDataServiceId
          return acc
        },
        {}
      )
      const gameIdentifiersByiLotteryId = action.payload.reduce(
        (acc: Record<string, string>, game) => {
          if (game.configuration.iLottery) {
            acc[game.configuration.iLottery.iLotteryId] = game.identifier
          }
          return acc
        },
        {}
      )
      state.gamesByDataServiceId = {
        ...state.gamesByDataServiceId,
        ...gamesById
      }
      state.dataServiceIdByGameIdentifier = {
        ...state.dataServiceIdByGameIdentifier,
        ...gameIdentifiersByid
      }
      state.gameIdentifiersByiLotteryId = gameIdentifiersByiLotteryId

      state.isLoading = false
      state.error = null
    },
    fetchGamesFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    }
  }
})

export const cmsGameActions = cmsGamesSlice.actions
export const cmsGamesReducer = cmsGamesSlice.reducer
