import * as React from 'react'
import { getBlockClass } from '../component-helpers'
import { useIsDesktop } from '@Client/hooks/use-media-query/use-media-query'
import { dateUtil } from '@Util/date-util'
import { FooterMobile } from './footer-mobile-ui'
import { FooterDesktop } from './footer-desktop-ui'
import './styles.scss'

export type FooterProps = {
  onILotteryHelpClick: () => void
}

const ROOT_CLASS = 'footer'

export const Footer = (props: FooterProps) => {
  const isDesktop = useIsDesktop()
  return (
    <footer className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'content')}>
        {isDesktop ? (
          <FooterDesktop onILotteryHelpClick={props.onILotteryHelpClick} />
        ) : (
          <FooterMobile onILotteryHelpClick={props.onILotteryHelpClick} />
        )}
        <div className={getBlockClass(ROOT_CLASS, 'bottom')}>
          <span
            className={getBlockClass(ROOT_CLASS, 'copyright')}
          >{`Copyright © ${dateUtil().year()} New Hampshire Lottery Commission®. Must be 18+ to play the lottery in New Hampshire. All rights reserved.`}</span>
        </div>
      </div>
    </footer>
  )
}
