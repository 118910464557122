import * as React from 'react'
import { InputLabel } from '@Components/input-label/input-label-ui'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '@Components/component-helpers'
import { ChevronDown } from '@Client/icons/chevron-down'
import { getInputErrorId, InputError } from '../input-error/input-error-ui'
import './styles.scss'

export type InputSelectOption = {
  displayText: string
  value: string
}

export type InputSelectProps = {
  options: InputSelectOption[]
  placeholder?: string
  onChange: (value: string) => any
  label?: string
  id: string
  defaultValue?: string
  value?: string
  isBorderless?: boolean
  className?: string
  error?: string
}

const ROOT_CLASS = 'input-select'
const INPUT_CLASS = getBlockClass(ROOT_CLASS, 'input')

export const InputSelect = (props: InputSelectProps) => {
  return (
    <div className={joinClasses([ROOT_CLASS, props.className])}>
      {props.label !== undefined && (
        <InputLabel for={props.id} error={props.error}>
          {props.label}
        </InputLabel>
      )}
      <div className={getBlockClass(ROOT_CLASS, 'input-wrapper')}>
        <select
          required
          id={props.id}
          name={props.id}
          aria-invalid={!!props.error}
          aria-errormessage={getInputErrorId(props.id)}
          className={joinClasses([
            INPUT_CLASS,
            getModifierClass(
              INPUT_CLASS,
              'no-border',
              props.isBorderless || false
            ),
            getModifierClass(INPUT_CLASS, 'error', !!props.error),
            props.className
              ? getBlockClass(props.className, 'input')
              : undefined
          ])}
          onChange={(e) => {
            props.onChange(e.target.value)
          }}
          value={props.value ?? props.defaultValue ?? ''}
        >
          {props.placeholder && !props.value && (
            <option disabled value={''}>
              {props.placeholder}
            </option>
          )}
          {props.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.displayText}
            </option>
          ))}
        </select>
        <span
          className={joinClasses([
            getBlockClass(ROOT_CLASS, 'chevron'),
            props.className
              ? getBlockClass(props.className, 'chevron')
              : undefined
          ])}
        >
          <ChevronDown />
        </span>
      </div>
      <InputError id={props.id} error={props.error} />
    </div>
  )
}
