import * as React from 'react'

export const CartIcon = () => (
  <svg
    aria-hidden={true}
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
  >
    <title>Shopping Cart</title>
    <path
      d='M0.825978 8.80783e-05C0.718063 -0.00156109 0.610921 0.0199824 0.510778 0.0634663C0.410635 0.10695 0.319489 0.171507 0.242636 0.253386C0.165784 0.335265 0.104757 0.432832 0.0631037 0.540418C0.0214501 0.648004 -6.6744e-08 0.763463 -7.69394e-08 0.880084C-8.71348e-08 0.996706 0.02145 1.11216 0.0631036 1.21975C0.104757 1.32734 0.165784 1.4249 0.242636 1.50678C0.319489 1.58866 0.410635 1.65322 0.510778 1.6967C0.610921 1.74019 0.718063 1.76173 0.825978 1.76008L2.35612 1.76008C2.54009 1.76008 2.6936 1.88457 2.74581 2.07633L6.21647 14.826C6.52107 15.9451 7.47897 16.72 8.5594 16.72L17.4985 16.72C18.5966 16.72 19.5662 15.9181 19.8557 14.7744L21.9712 6.3921C22.0042 6.26152 22.0089 6.12447 21.9849 5.99161C21.9609 5.85875 21.9089 5.73366 21.8329 5.62607C21.7569 5.51848 21.6589 5.43129 21.5466 5.37128C21.4343 5.31127 21.3107 5.28006 21.1855 5.28007L5.3162 5.28007L4.30936 1.57961L4.30777 1.57961C4.05377 0.646739 3.25527 8.82907e-05 2.35612 8.82121e-05L0.825978 8.80783e-05ZM8.96977 18.48C8.53779 18.48 8.12351 18.6654 7.81806 18.9955C7.51261 19.3256 7.34101 19.7732 7.34101 20.24C7.34101 20.7068 7.51261 21.1544 7.81806 21.4845C8.12351 21.8146 8.53779 22 8.96977 22C9.40174 22 9.81602 21.8146 10.1215 21.4845C10.4269 21.1544 10.5985 20.7068 10.5985 20.24C10.5985 19.7732 10.4269 19.3256 10.1215 18.9955C9.81602 18.6654 9.40174 18.48 8.96977 18.48ZM17.1136 18.48C16.6816 18.48 16.2673 18.6654 15.9619 18.9955C15.6564 19.3256 15.4848 19.7732 15.4848 20.24C15.4848 20.7068 15.6564 21.1544 15.9619 21.4845C16.2673 21.8146 16.6816 22 17.1136 22C17.5455 22 17.9598 21.8146 18.2653 21.4845C18.5707 21.1544 18.7423 20.7068 18.7423 20.24C18.7423 19.7732 18.5707 19.3256 18.2653 18.9955C17.9598 18.6654 17.5455 18.48 17.1136 18.48Z'
      fill='#186259'
    />
  </svg>
)
