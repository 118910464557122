import * as React from 'react'
import './styles.scss'

export const InstagramIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
    viewBox='0 0 374.32 323.97'
    width={40}
    height={40}
  >
    <title>Instagram Icon</title>
    <g>
      <g>
        <path
          className='instagram-icon'
          d='M125.19,27.78h123.94c39.81,0,72.56,32.75,72.56,72.56v123.3c0,39.81-32.75,72.56-72.56,72.56h-123.94c-39.81,0-72.56-32.75-72.56-72.56v-123.3c0-39.81,32.75-72.56,72.56-72.56h0ZM259.4,74.65h0c8.35,0,15.41,7.06,15.41,15.41h0c0,8.35-7.06,15.41-15.41,15.41h0c-8.99,0-15.41-7.06-15.41-15.41h0c0-8.35,6.42-15.41,15.41-15.41h0ZM186.84,88.78h.64c39.81,0,73.21,33.39,73.21,73.21h0c0,40.46-33.39,73.21-73.21,73.21h-.64c-39.81,0-72.56-32.75-72.56-73.21h0c0-39.81,32.75-73.21,72.56-73.21h0ZM186.84,113.83h.64c26.33,0,48.16,21.83,48.16,48.16h0c0,26.97-21.83,48.8-48.16,48.8h-.64c-26.33,0-48.16-21.83-48.16-48.8h0c0-26.33,21.83-48.16,48.16-48.16h0ZM125.83,50.89h122.65c27.61,0,50.09,22.48,50.09,50.09v122.01c0,27.61-22.48,50.09-50.09,50.09h-122.65c-27.61,0-50.09-22.48-50.09-50.09v-122.01c0-27.61,22.48-50.09,50.09-50.09h0Z'
        />
      </g>
    </g>
  </svg>
)
