import * as React from 'react'
import { PrizesRemaining } from '@interaction-gaming/game-data-service-browser-client'
import { getBlockClass } from '../component-helpers'
import { Collapsible } from '../collapsible/collapsible-ui'
import { CurrencyUtilities } from '@Util/currency-utilities'
import { GamePrice } from '../game-price/game-price-ui'
import './styles.scss'

export type PrizesRemainingGameCardProps = {
  gameName: string
  gameNumber: string
  gamePrice: number
  spotlightPrizes: PrizesRemaining[]
  additionalPrizes: PrizesRemaining[]
}

const ROOT_CLASS = 'prizes-remaining-game-card'

const PrizesRemainingGameCardList = (props: { prizes: PrizesRemaining[] }) => (
  <div className={getBlockClass(ROOT_CLASS, 'list')}>
    {props.prizes.map((prize, index) => (
      <div key={index} className={getBlockClass(ROOT_CLASS, 'list-item')}>
        <span>
          {CurrencyUtilities.compactFormat(prize.prizeAmountInDollars)}
          {prize.prizeAmountInDollars >= 1000000 && '*'}
          {prize.prizeAmountInDollars >= 2000000 && '*'}
        </span>
        <span className={getBlockClass(ROOT_CLASS, 'subtext')}>
          {prize.remainingCount} of {prize.startingCount}
        </span>
      </div>
    ))}
  </div>
)

export const PrizesRemainingGameCard = (
  props: PrizesRemainingGameCardProps
) => {
  const gamePrice = CurrencyUtilities.getDollarsFromCents(props.gamePrice)
  const gamePriceFormatted = `$${gamePrice}`

  return (
    <div className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'header')}>
        <GamePrice
          className={getBlockClass(ROOT_CLASS, 'price')}
          value={gamePriceFormatted}
        />
        <div className={getBlockClass(ROOT_CLASS, 'vertical')}>
          <span>{props.gameName}</span>
          <span className={getBlockClass(ROOT_CLASS, 'subtext')}>
            Game number {props.gameNumber}
          </span>
        </div>
      </div>
      <PrizesRemainingGameCardList prizes={props.spotlightPrizes} />
      {props.additionalPrizes.length ? (
        <Collapsible
          className={getBlockClass(ROOT_CLASS, 'dropdown')}
          header={
            <span className={getBlockClass(ROOT_CLASS, 'dropdown-header')}>
              Additional Prizes
            </span>
          }
          content={
            <PrizesRemainingGameCardList prizes={props.additionalPrizes} />
          }
        />
      ) : null}
    </div>
  )
}
