import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@Client/hooks/redux-hooks'
import { Optional } from '@Util/utility-types'
import {
  PrizeApi,
  PrizesRemainingList,
  PrizesRemainingWithLastUpdatedResponse
} from '@Client/api/prizes-remaining'
import { useFetchFunction } from '@Client/hooks/use-fetch/use-fetch-function'
import { PrizesRemainingFormData } from '../prizes-remaining-form/constants'
import { getObjectKeys } from '@Util/object-utilities'
import { instantGameActions } from '@Client/reducers/instant-games/instant-games-reducer'
import { keyObjectsBy } from '@Util/array-utilities'
import { getInstantGameOptions, getValidPrizes } from './helpers'
import { PrizesByGameId, PrizesRemaining } from './prizes-remaining-ui'

const getPrizesByGameId = (prizes: PrizesRemainingList) =>
  keyObjectsBy(prizes, 'instantGameId')

export const PrizesRemainingContainer = () => {
  const dispatch = useAppDispatch()
  const instantGamesState = useAppSelector((state) => state.instantGames)
  const instantGamesByDataServiceId =
    instantGamesState.instantGamesByDataServiceId
  const [validPrizesByGameId, setValidPrizesByGameId] =
    React.useState<PrizesByGameId>({})
  const { fetchData, isLoading, data } =
    useFetchFunction<Optional<PrizesRemainingWithLastUpdatedResponse>>()

  const prizesByGameId = React.useMemo(
    () => getPrizesByGameId(data?.prizesRemaining ?? []),
    [data]
  )

  React.useEffect(() => {
    fetchData(async () => PrizeApi.getPrizesRemaining())
    if (!getObjectKeys(instantGamesState.instantGamesByDataServiceId).length) {
      dispatch(instantGameActions.fetchInstantGamesRequest())
    }
  }, [])

  const onFormSubmit = async (payload: PrizesRemainingFormData) => {
    if (!getObjectKeys(prizesByGameId).length) {
      return
    }
    const validPrizesByGameId = getValidPrizes(
      instantGamesByDataServiceId,
      prizesByGameId,
      payload
    )
    setValidPrizesByGameId(validPrizesByGameId)
  }

  const gameOptions = React.useMemo(
    () =>
      getInstantGameOptions(
        Object.values(instantGamesByDataServiceId),
        prizesByGameId
      ),
    [instantGamesByDataServiceId, prizesByGameId]
  )

  return (
    <PrizesRemaining
      onFormSubmit={onFormSubmit}
      prizesByGameId={validPrizesByGameId}
      isLoading={isLoading}
      instantGamesByGameId={instantGamesByDataServiceId}
      gameOptions={gameOptions}
      dataIsReady={getObjectKeys(prizesByGameId).length > 0}
      lastUpdated={data?.lastUpdated}
    />
  )
}
