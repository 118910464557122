import {
  DynamicUrl,
  GameCollection,
  GameCollectionFilters,
  GameCollectionParam,
  RoutePath
} from '@Client/route-paths'

export enum OpenPageIdentifier {
  FAQ_REGISTRATION = 'FAQ_Registration',
  TERMS_REGISTRATION = 'Terms_Registration',
  HELP_DEPOSIT_METHODS = 'HelpDepositMethods',
  HELP_DEPOSIT = 'HelpDeposit',
  HELP_DEPOSIT_BY_CREDIT = 'HelpDeposit_bycredit',
  SUCCESSFUL_DEPOSIT = 'SuccessfulDeposit',
  UNSUCCESSFUL_DEPOSIT = 'UnsuccessfulDeposit',
  FAQ_FORGOT_PASSWORD = 'FAQ_ForgotPassword',
  HELP_CASHOUT = 'HelpCashout',
  HELP_CASHOUT_HOW_TO = 'HelpCashoutHowTo',
  HELP_CASHOUT_POLICY = 'HelpCashoutPolicy',
  HELP_CASHOUT_POLICY_WITHDRAW_ERROR_WITHDRAW_NOT_ALLOWED_FAQ = 'HelpCashoutPolicy_WithdrawError_WithdrawNotAllowedFaq',
  HELP_CASHOUT_POLICY_WITHDRAW_FUNDS_WITHDRAW_PROCESS = 'HelpCashoutPolicy_WithdrawFundsWithdrawProcess',
  HELP_CASHOUT_POLICY_WITHDRAW_ERROR_WITHDRAW_FUNDS_WITHDRAW_PROCESS = 'HelpCashoutPolicy_WithdrawError_WithdrawFundsWithdrawProcess',
  FAQ_MY_ACCOUNT = 'FAQ_MyAccount',
  RESPONSIBLE_GAMING = 'ResponsibleGaming',
  RESPONSIBLE_GAMING_DEPOSIT_LIMIT_RESPONSIBLE_GAMING = 'ResponsibleGaming_DepositLimitResponsibleGaming',
  RESPONSIBLE_GAMING_SELF_EXCLUSION = 'ResponsibleGaming_SelfExclusion',
  RESPONSIBLE_GAMING_SELF_EXCLUSION_RESPONSIBLE_GAMING = 'ResponsibleGaming_SelfExclusionResponsibleGaming',
  RESPONSIBLE_GAMING_DEPOSIT_LIMIT_FAQ = 'ResponsibleGaming_DepositLimitFaq',
  FAQ_HISTORY_TRANSACTIONS_FAQ = 'FAQ_History_TransactionsFaq',
  FAQ_UPLOAD_DOCUMENTS = 'FAQ_UploadDocuments',
  FAQ_PERSONAL_INFO = 'FAQ_PersonalInfo',
  FAQ_NOTIFICATIONS_PREFERENCES_FAQ = 'FAQ_Notifications_PreferencesFaq',
  BONUSES_NEW_BONUS_OFFERS_FAQ = 'Bonuses_NewBonusOffersFaq',
  BONUSES_MY_BONUS_ACTIVITY_FAQ = 'Bonuses_MyBonusActivityFaq',
  GAMES_LOBBY = 'GamesLobby',
  FAQ_GEOLOCATION = 'FAQ_Geolocation',
  BONUS_POLICY = 'BonusPolicy',
  HELP_DEPOSIT_ONLINE_BANKING = 'HelpDeposit_onlinebanking',
  HELP_DEPOSIT_PAYPAL = 'HelpDeposit_paypal',
  HELP_DEPOSIT_ACH_BANK_TRANSFER = 'HelpDeposit_ach-banktransfer',
  HELP_DEPOSIT_VENMO = 'HelpDeposit_venmo',
  PLAY = 'Play',
  PRIVACY = 'Privacy',
  TERMS_CLAIMS_TAX_REPORTED_PRIZES_FAQ = 'Terms_Claims_TaxReportedPrizesFaq',
  TERMS_CLAIMS_TERMS_TAX_REPORTED_PRIZES_VIEW_CLAIM_POLICY = 'terms_ClaimsTerms_TaxReportedPrizesViewClaimPolicy',
  TERMS_ACH_BANK_TRANSFER = 'Terms_ach-banktransfer',
  OPEN_REWARDS_CENTER = 'OpenRewardsCenter',
  FAQ_REWARD_HISTORY_REWARDS_FAQ = 'FAQ_RewardHistory_RewardsFaq',
  EMAIL_VERIFICATION_FAQ_REGISTRATION = 'EmailVerificationFAQ_Registration',
  ADD_NEW_TICKET = 'AddNewTicket',
  FAQ = 'FAQ',
  GAME_RULES = 'GameRules',
  FAQ_SHOPPING_CART_FAQ = 'FAQ_ShoppingCartFaq',
  FAQ_UPCOMING_PURCHASED_DRAWS_FAQ = 'FAQ_UpcomingPurchasedDrawsFaq',
  FAQ_FAVORITE_PURCHASE_FAQ = 'FAQ_FavoritePurchaseFaq',
  FAQ_HISTORY_PREVIOUSLY_PLAYED_GAMES_FAQ = 'FAQ_History_PreviouslyPlayedGamesFaq',
  FAQ_TRANSACTION_FAQ_HISTORY = 'FAQ_transactionFaq_history',
  FAQ_PAST_SUBSCRIPTIONS = 'FAQ_PastSubscriptions',
  FAQ_UPCOMING_SUBSCRIPTIONS = 'FAQ_UpcomingSubscriptions'
}

export type InternalPathHandler = {
  path: RoutePath | string
  newTab: boolean
}

export type InternalHandlerByOpenPageIdMap = Partial<
  Record<OpenPageIdentifier, InternalPathHandler>
>
export const InternalHandlerByOpenPageId: InternalHandlerByOpenPageIdMap = {
  [OpenPageIdentifier.GAME_RULES]: {
    path: RoutePath.adminGameRules,
    newTab: true
  },
  [OpenPageIdentifier.TERMS_REGISTRATION]: {
    path: RoutePath.termsAndConditions,
    newTab: true
  },
  [OpenPageIdentifier.RESPONSIBLE_GAMING]: {
    path: RoutePath.playResponsibly,
    newTab: true
  },
  [OpenPageIdentifier.RESPONSIBLE_GAMING_DEPOSIT_LIMIT_RESPONSIBLE_GAMING]: {
    path: RoutePath.playResponsibly,
    newTab: true
  },
  [OpenPageIdentifier.RESPONSIBLE_GAMING_SELF_EXCLUSION_RESPONSIBLE_GAMING]: {
    path: RoutePath.playResponsibly,
    newTab: true
  },
  [OpenPageIdentifier.GAMES_LOBBY]: {
    path: DynamicUrl.getGameCollectionUrl(GameCollection.iLottery),
    newTab: false
  },
  [OpenPageIdentifier.PLAY]: {
    path: DynamicUrl.getGameCollectionUrl(GameCollection.iLottery),
    newTab: false
  },
  [OpenPageIdentifier.PRIVACY]: {
    path: RoutePath.termsAndConditions,
    newTab: true
  },
  [OpenPageIdentifier.HELP_CASHOUT_POLICY]: {
    path: RoutePath.termsAndConditions,
    newTab: true
  },
  [OpenPageIdentifier.HELP_CASHOUT_POLICY_WITHDRAW_FUNDS_WITHDRAW_PROCESS]: {
    path: RoutePath.termsAndConditions,
    newTab: true
  },
  [OpenPageIdentifier.TERMS_CLAIMS_TERMS_TAX_REPORTED_PRIZES_VIEW_CLAIM_POLICY]:
    { path: RoutePath.termsAndConditions, newTab: true },
  [OpenPageIdentifier.ADD_NEW_TICKET]: {
    path: DynamicUrl.getGameCollectionUrl(GameCollection.iLottery, {
      [GameCollectionParam.filters]: [GameCollectionFilters.draw]
    }),
    newTab: false
  }
}

type RoutePathByExternalOpenPageIdMap = Partial<
  Record<OpenPageIdentifier, RoutePath | string>
>
export const RoutePathByExternalOpenPageId: RoutePathByExternalOpenPageIdMap = {
  [OpenPageIdentifier.TERMS_ACH_BANK_TRANSFER]:
    'https://www.vippreferred.com/terms-of-service/'
}
