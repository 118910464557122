import * as React from 'react'
import { isEqual } from 'lodash'
import { GameLobbyModel } from '@Cms/model-types/game-lobby-model'
import { getBlockClass } from '@Components/component-helpers'
import { GameLobbyQuery } from '@Components/game-lobby-query/game-lobby-query-ui'
import { GameTileContainer } from '../game-tile/game-tile-container'
import { GameField, GameModel } from '@Cms/model-types/game-model'
import { useDeepLink } from '@Client/hooks/use-deep-link/use-deep-link'
import { useIsDesktop } from '@Client/hooks/use-media-query/use-media-query'
import { Optional } from '@Util/utility-types'
import {
  SortOptionField,
  SortOptionModel
} from '@Cms/model-types/sort-option-model'
import {
  FilterCategoryByIdentifier,
  getActiveFilters,
  getActiveFiltersIdentifiers,
  getDefaultFilterState,
  getFilters,
  getStatefulSpotlightFilters,
  updateFilterState
} from './helpers/filter-helpers'
import {
  GameLobbyParamKey,
  decodeFilters,
  encodeFilters
} from './helpers/url-helpers'
import { getInitialSort } from './helpers/sort-helpers'
import { getValidGames } from './helpers/query-helpers'
import './styles.scss'

export type GameLobbyProps = {
  lobby: GameLobbyModel
  freeGames?: GameModel[]
  isILottery: boolean
}

const ROOT_CLASS = 'game-lobby'

export const GameLobby = (props: GameLobbyProps) => {
  const isDesktop = useIsDesktop()
  const searchRef = React.useRef<HTMLInputElement>(null)
  const { updateParam, getParam, stripParam } = useDeepLink()

  const [searchTerm, setSearchTerm] = React.useState<string>(
    getParam(GameLobbyParamKey.search) ?? ''
  )
  const [priceFilter, setPriceFilter] = React.useState<Optional<string>>(
    getParam(GameLobbyParamKey.price) ?? undefined
  )
  const [currentSort, setCurrentSort] = React.useState<SortOptionModel>(
    getInitialSort(
      props.lobby.defaultSortOrder,
      props.lobby.supportedGameSorts,
      getParam,
      stripParam
    )
  )

  React.useEffect(() => {
    setCurrentSort(
      getInitialSort(
        props.lobby.defaultSortOrder,
        props.lobby.supportedGameSorts,
        getParam,
        stripParam
      )
    )
  }, [
    props.lobby.games,
    props.lobby.defaultSortOrder,
    props.lobby.supportedGameSorts
  ])

  const getFiltersFromUrl = () =>
    getDefaultFilterState(
      props.lobby.supportedFilterCategories,
      props.lobby.spotlightFilters,
      getParam
    )
  const [filtersByCategory, setFiltersByCategory] =
    React.useState<FilterCategoryByIdentifier>(getFiltersFromUrl())

  React.useEffect(() => {
    if (searchRef.current) {
      searchRef.current.value = searchTerm
    }
  }, [])

  const setFilterActive = (
    categoryIdentifier: string,
    identifier: string,
    isActive: boolean
  ) => {
    const newState = updateFilterState(
      filtersByCategory,
      categoryIdentifier,
      identifier,
      isActive
    )
    setFiltersByCategory(newState)
    const activeFilterIdentifiers = getActiveFiltersIdentifiers(newState)
    updateParam(
      GameLobbyParamKey.filters,
      encodeFilters(activeFilterIdentifiers)
    )
  }

  const setSearchValue = (value: string) => {
    setSearchTerm(value)
    updateParam(GameLobbyParamKey.search, value)
  }

  const setSortValue = (sortOption: SortOptionModel) => {
    setCurrentSort(sortOption)
    if (
      sortOption[SortOptionField.identifier] ===
      props.lobby.defaultSortOrder[SortOptionField.identifier]
    ) {
      stripParam(GameLobbyParamKey.sort)
    } else {
      updateParam(
        GameLobbyParamKey.sort,
        sortOption[SortOptionField.identifier]
      )
    }
  }

  const setPrice = (price: string) => {
    setPriceFilter(price)
    updateParam(GameLobbyParamKey.price, price)
  }

  const resetFilters = () => {
    stripParam(GameLobbyParamKey.filters)
    stripParam(GameLobbyParamKey.price)
    setFiltersByCategory(getFiltersFromUrl())
    setPriceFilter(undefined)
  }

  const getTotalActiveFilters = () =>
    getActiveFilters(filtersByCategory).length + (priceFilter ? 1 : 0)

  const updateLinkFilters = () => {
    // Necessary when player is on game lobby page and selects deep link from nav
    // search and sort not currently supported by in-app deep links
    const urlFilters = getParam(GameLobbyParamKey.filters)
    const decodedUrlFilters = decodeFilters(urlFilters)
    const activeFilterIdentifiers =
      getActiveFiltersIdentifiers(filtersByCategory)
    if (!isEqual(decodedUrlFilters, activeFilterIdentifiers)) {
      setFiltersByCategory(getFiltersFromUrl())
    }
  }

  updateLinkFilters()

  const games = getValidGames(
    props.lobby.games,
    filtersByCategory,
    searchTerm,
    currentSort,
    priceFilter
  )

  return (
    <section className={ROOT_CLASS}>
      <GameLobbyQuery
        currentSort={currentSort}
        onSortChange={setSortValue}
        supportedSorts={props.lobby.supportedGameSorts}
        onResetFilters={resetFilters}
        numActiveFilters={getTotalActiveFilters()}
        spotlightFilters={
          isDesktop
            ? getFilters(filtersByCategory)
            : getStatefulSpotlightFilters(filtersByCategory)
        }
        onFilterToggle={setFilterActive}
        onSearchChange={setSearchValue}
        gameLobbyIdentifier={props.lobby.identifier}
        setFilterActive={setFilterActive}
        lobbyDisplayText={props.lobby.title}
        filterCategories={filtersByCategory}
        numGames={games.length}
        activePrice={priceFilter}
        setActivePrice={setPrice}
        isILottery={props.isILottery}
        priceOptions={props.lobby.priceOptions}
        ref={searchRef}
      />
      <div className={getBlockClass(ROOT_CLASS, 'games')}>
        {props.freeGames?.map((game) => (
          <GameTileContainer
            key={game[GameField.identifier]}
            game={game}
            isILotteryGame={props.isILottery}
          />
        ))}
        {games.map((game) => (
          <GameTileContainer
            key={game[GameField.identifier]}
            game={game}
            isILotteryGame={props.isILottery}
          />
        ))}
      </div>
    </section>
  )
}
