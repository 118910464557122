import { NavCategoryName } from '@Cms/model-types/nav-category-model'

export enum AccessibleKey {
  enter = 'Enter',
  space = ' ',
  upArrow = 'ArrowUp',
  leftArrow = 'ArrowLeft',
  downArrow = 'ArrowDown',
  rightArrow = 'ArrowRight',
  escape = 'Escape',
  tab = 'Tab'
}

export enum TabIndex {
  accessible = 0,
  inaccessible = 1
}

export const TopLevelRoutesByName: Record<NavCategoryName, string> = {
  [NavCategoryName.homeSpotlight]: '/',
  [NavCategoryName.mobileMore]: '/',
  [NavCategoryName.games]: '/games',
  [NavCategoryName.more]: '/more',
  [NavCategoryName.prizes]: '/prizes',
  [NavCategoryName.about]: '/about',
  [NavCategoryName.winning]: '/winning'
}

export enum AppStore {
  APP_STORE_BADGE = 'https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred_2x.png',
  PLAY_STORE_BADGE = 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/180px-Google_Play_Store_badge_EN.svg.png',
  APP_STORE_LINK = 'https://apps.apple.com/us/app/nh-lottery/id1606406533',
  PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.nhlottery.app.android&hl=en_US'
}
