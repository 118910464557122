import * as React from 'react'
import { InputSelect, InputSelectOption } from '../input-select/input-select-ui'
import { InputLabel } from '../input-label/input-label-ui'
import { getBlockClass } from '../component-helpers'
import './styles.scss'

export type CmsPreviewProps = {
  onChange: (isActive: boolean) => void
  isActive: boolean
}

const ROOT_CLASS = 'cms-preview'
const SELECT_ID = `${ROOT_CLASS}-select`

const ON_VALUE = 'on'
const OFF_VALUE = 'off'

const cmsPreviewOptions: InputSelectOption[] = [
  { displayText: 'On', value: ON_VALUE },
  { displayText: 'Off', value: OFF_VALUE }
]

export const CmsPreview = (props: CmsPreviewProps) => {
  return (
    <div className={ROOT_CLASS}>
      <InputLabel for={SELECT_ID}>CMS Preview</InputLabel>
      <InputSelect
        options={cmsPreviewOptions}
        onChange={(value: string) => {
          props.onChange(value === ON_VALUE)
        }}
        defaultValue={OFF_VALUE}
        value={props.isActive ? ON_VALUE : OFF_VALUE}
        id={SELECT_ID}
      />
      <span className={getBlockClass(ROOT_CLASS, 'disclaimer')}>
        Toggle to view unpublished CMS content. The application may behave in an
        unexpected manner while preview mode is on.
      </span>
      <span className={getBlockClass(ROOT_CLASS, 'disclaimer')}>
        This section is not visible within the production application.
      </span>
    </div>
  )
}
