import * as React from 'react'
import { LocationApi, LocationsApiResponse } from '@Client/api/locations'
import { RetailFinder } from './retail-finder-ui'
import { useFetchFunction } from '@Client/hooks/use-fetch/use-fetch-function'
import { Optional } from '@Util/utility-types'
import { LatLong } from '../map/map-ui'
import { APIProvider } from '@vis.gl/react-google-maps'
import { ClientConfig } from '@Client/config'

const DEFAULT_RADIUS = 30
const DEFAULT_POSITION: LatLong = {
  lat: 43.2081,
  lng: -71.5376
}
// Concord, NH

export const RetailFinderContainer = () => {
  const { data, fetchData, isLoading } =
    useFetchFunction<Optional<LocationsApiResponse>>()
  const [locations, setLocations] = React.useState<LocationsApiResponse>([])
  const [userPosition, setUserPosition] = React.useState<Optional<LatLong>>()
  const [positionRequestComplete, setPositionRequestComplete] =
    React.useState<boolean>(false)

  const fetchLocation = async (center: LatLong) => {
    await fetchData(
      async () =>
        await LocationApi.getLocations({
          radius: DEFAULT_RADIUS,
          latitude: center.lat,
          longitude: center.lng
        })
    )
  }

  React.useEffect(() => {
    navigator.permissions.query({ name: 'geolocation' }).then((status) => {
      if (status.state === 'denied') {
        setPositionRequestComplete(true)
        return
      }
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          })
          setPositionRequestComplete(true)
        },
        () => {
          setPositionRequestComplete(true)
        }
      )
    })
  }, [])

  React.useEffect(() => {
    if (data) {
      setLocations(data)
    }
  }, [data, setLocations])

  return (
    <APIProvider apiKey={ClientConfig.MAPS_API_KEY}>
      <RetailFinder
        isLoading={isLoading}
        positionRequestComplete={positionRequestComplete}
        defaultCenter={userPosition ?? DEFAULT_POSITION}
        onBoundsChanged={fetchLocation}
        locations={
          locations?.map((location) => ({
            ...location,
            latitude: location.lat,
            longitude: location._long
          })) ?? []
        }
      />
    </APIProvider>
  )
}
