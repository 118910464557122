import * as React from 'react'
import { Button } from '../button/button'
import { InputSelect } from '../input-select/input-select-ui'
import { Nullable } from '@Util/utility-types'
import { useDeepLink } from '@Client/hooks/use-deep-link/use-deep-link'
import { GameModel, GameType } from '@Cms/model-types/game-model'
import { deepCopy } from '@Util/object-utilities'
import { useFormData } from '@Client/hooks/use-form-data/use-form-data'
import { dateUtil } from '@Util/date-util'
import { PastResultsYourNumbers } from './past-results-your-numbers-filter'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import {
  PastResultsDrawGameForm,
  PastResultsRapidGameForm
} from './past-results-typed-forms'
import {
  PastResultsFieldId,
  PastResultsFormProps,
  ROOT_CLASS
} from './constants'
import {
  getPastResultsDefaultValue,
  getValidRangeByGameIdentifier,
  PastResultFormData,
  validatePastResultsField
} from './form-helpers'
import './styles.scss'

const RAPID_GAME_YOUR_NUMBERS = 12
const DRAW_GAME_YOUR_NUMBERS = 5

const getNumYourNumbers = (activeGame?: GameModel) => {
  if (!activeGame) {
    return DRAW_GAME_YOUR_NUMBERS
  }
  if (
    activeGame.identifier === 'pick-three' ||
    activeGame.identifier === 'pick-four'
  )
    return 0
  if (activeGame.type === GameType.rapid) {
    return RAPID_GAME_YOUR_NUMBERS
  }
  return (
    activeGame.configuration.drawGame?.numWinningNumbers ||
    DRAW_GAME_YOUR_NUMBERS
  )
}

export const PastResultsForm = (props: PastResultsFormProps) => {
  const { stripParam } = useDeepLink()
  const { updateField, errors, isValid, formData } =
    useFormData<PastResultFormData>({
      useDeepLinks: true,
      fieldIds: Object.values(PastResultsFieldId),
      validateField: validatePastResultsField,
      getDefaultValue: getPastResultsDefaultValue
    })

  const isDrawGame = React.useMemo(
    () => props.activeGame?.type === GameType.draw,
    [props.activeGame]
  )
  const isRapidGame = React.useMemo(
    () => props.activeGame?.type === GameType.rapid,
    [props.activeGame]
  )

  const validGameRange = props.activeGame?.identifier
    ? getValidRangeByGameIdentifier(props.activeGame.identifier)
    : undefined

  React.useEffect(() => {
    if (isDrawGame) {
      stripParam(PastResultsFieldId.DRAW_DATE)
      stripParam(PastResultsFieldId.DRAW_NUMBER_START)
      stripParam(PastResultsFieldId.DRAW_NUMBER_END)
    } else {
      stripParam(PastResultsFieldId.YOUR_NUMBERS_SPECIAL_BALL)
      stripParam(PastResultsFieldId.TIME_SPAN)
    }
  }, [isDrawGame])

  React.useEffect(() => {
    if (!props.activeGame?.configuration.drawGame?.hasBonusNumber) {
      onBonusNumberChange(null)
    }
  }, [props.activeGame])

  const onGameChange = (identifier: string) => {
    props.clearYourNumbers()
    updateField(PastResultsFieldId.YOUR_NUMBERS, undefined)
    props.setActiveGameId(identifier)
    updateField(PastResultsFieldId.GAME_IDENTIFIER, identifier)
  }

  const onDrawDateChange = (date?: Date) => {
    updateField(PastResultsFieldId.DRAW_DATE, date?.toISOString())
  }

  const onYourNumbersChange = (value: Nullable<number>, index: number) => {
    const newNumbers = deepCopy(props.yourNumbers)
    newNumbers[index] = value

    const onlyNumbers = newNumbers.filter((number) => number !== null)
    updateField(
      PastResultsFieldId.YOUR_NUMBERS,
      onlyNumbers.length ? newNumbers.join(',') : undefined
    )
    props.onYourNumbersChange(value, index)
  }

  const onBonusNumberChange = (value: Nullable<number>) => {
    updateField(
      PastResultsFieldId.YOUR_NUMBERS_SPECIAL_BALL,
      value !== null && Number.isSafeInteger(value) ? value : undefined
    )
    props.onBonusNumberChange(value)
  }

  const handleFormSubmit = (formEvent: React.FormEvent<HTMLFormElement>) => {
    formEvent.preventDefault()
    formEvent.stopPropagation()
    const gameIdentifier = formData[PastResultsFieldId.GAME_IDENTIFIER]
    if (isDrawGame) {
      const timeSpan = formData[PastResultsFieldId.TIME_SPAN]
      if (!timeSpan) {
        return
      }
      props.onDrawGameSubmit({ gameIdentifier, timeSpan })
    } else {
      const drawDate = formData[PastResultsFieldId.DRAW_DATE]
      const drawNumberStart = formData[PastResultsFieldId.DRAW_NUMBER_START]
      const drawNumberEnd = formData[PastResultsFieldId.DRAW_NUMBER_END]
      if (!drawDate) {
        return
      }
      props.onRapidGameSubmit({
        gameIdentifier,
        drawDate: new Date(drawDate),
        drawNumberStart: drawNumberStart,
        drawNumberEnd: drawNumberEnd
      })
    }
  }

  return (
    <form
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, 'no-game', !props.activeGame),
        getModifierClass(ROOT_CLASS, 'rapid', isRapidGame),
        getModifierClass(ROOT_CLASS, 'draw', isDrawGame)
      ])}
      onSubmit={handleFormSubmit}
    >
      <div className={getBlockClass(ROOT_CLASS, 'inputs')}>
        <InputSelect
          label='Game'
          options={props.gameOptions}
          onChange={onGameChange}
          id={PastResultsFieldId.GAME_IDENTIFIER}
          placeholder='Select a game'
          defaultValue={formData[PastResultsFieldId.GAME_IDENTIFIER]}
          className={getBlockClass(ROOT_CLASS, 'game-select')}
        />
        {isRapidGame ? (
          <PastResultsRapidGameForm
            {...props}
            defaultValues={formData}
            onFieldChange={updateField}
            onDateChange={onDrawDateChange}
            errors={errors}
            drawDate={dateUtil(formData[PastResultsFieldId.DRAW_DATE]).toDate()}
          />
        ) : (
          <PastResultsDrawGameForm
            {...props}
            defaultValues={formData}
            onFieldChange={updateField}
            errors={errors}
          />
        )}
        <PastResultsYourNumbers
          gameIdentifier={props.activeGame?.identifier}
          onChange={onYourNumbersChange}
          defaultValues={props.yourNumbers}
          numberCount={getNumYourNumbers(props.activeGame)}
          onBonusNumberChange={onBonusNumberChange}
          defaultBonusNumber={props.yourNumbersSpecialBall}
          specialBallColorHex={props.specialBallColorHex}
          hasBonusNumber={
            props.activeGame?.configuration.drawGame?.hasBonusNumber ?? false
          }
          validGameRange={validGameRange}
        />
      </div>
      <Button
        type='submit'
        ariaLabel='Search Past Results'
        className={getBlockClass(ROOT_CLASS, 'submit-button')}
        disabled={!isValid}
      >
        Search
      </Button>
    </form>
  )
}
