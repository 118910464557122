import * as React from 'react'
import { InputSearch } from '@Components/input-search/input-search-ui'
import { FilterCategoryByIdentifier } from '@Components/game-lobby/helpers/filter-helpers'
import { SortOptionField } from '@Cms/model-types/sort-option-model'
import {
  getSelectOptionsFromSortOptions,
  setMatchingSort
} from '@Components/game-lobby/helpers/sort-helpers'
import { InputSelect } from '@Components/input-select/input-select-ui'
import {
  GameLobbyMoreFilters,
  GameLobbyMoreFiltersProps
} from '@Components/game-lobby-more-filters/game-lobby-more-filters'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '@Components/component-helpers'
import { TextButton } from '@Components/button-text/text-button'
import { GamePrice } from '@Cms/model-types/game-model'
import { ModalRoot } from '../modal/modal'
import {
  GameLobbyQueryFilterProps,
  GameLobbyQueryFilters
} from './game-lobby-query-filters-ui'
import './styles.scss'

export type GameLobbyQueryProps = Omit<
  GameLobbyQueryFilterProps,
  'onClickMoreFilters'
> &
  Omit<GameLobbyMoreFiltersProps, 'isOpen' | 'setIsOpen'> & {
    onSearchChange: (value: string) => void
    gameLobbyIdentifier: string
    filterCategories: FilterCategoryByIdentifier
    numGames: number
    numActiveFilters: number
    onResetFilters: () => void
    activePrice?: string
    setActivePrice: (price: string) => void
    isILottery: boolean
    priceOptions: GamePrice[]
  }

const ROOT_CLASS = 'game-lobby-query'
const META_CLASS = getBlockClass(ROOT_CLASS, 'meta')
const RESET_BUTTON_CLASS = getBlockClass(ROOT_CLASS, 'reset')
const DESKTOP_LABEL_SORT_CLASS = getBlockClass(ROOT_CLASS, 'desktop-sort-label')
const NUM_GAMES_CLASS = getBlockClass(ROOT_CLASS, 'num-games')

const getNumGamesText = (numGames: number) =>
  `Showing ${numGames} ${numGames === 1 ? 'game' : 'games'}`

const getResetButtonText = (numActiveFilters: number) =>
  `Remove filters${numActiveFilters > 0 ? ` (${numActiveFilters})` : ''}`

export const GameLobbyQuery = React.forwardRef(
  (props: GameLobbyQueryProps, searchRef?: React.Ref<HTMLInputElement>) => {
    const [moreFiltersOpen, setMoreFiltersOpen] = React.useState<boolean>(false)
    const openMoreFilters = () => setMoreFiltersOpen(true)
    const resetButtonDisabled = props.numActiveFilters === 0

    const ResetButton = () => (
      <TextButton
        ariaLabel='Reset filters'
        disabled={resetButtonDisabled}
        onClick={props.onResetFilters}
        className={joinClasses([
          RESET_BUTTON_CLASS,
          getModifierClass(RESET_BUTTON_CLASS, 'is-ilottery', props.isILottery)
        ])}
      >
        {getResetButtonText(props.numActiveFilters)}
      </TextButton>
    )

    const NumGamesInfo = () => (
      <p
        className={joinClasses([
          NUM_GAMES_CLASS,
          getModifierClass(NUM_GAMES_CLASS, 'is-ilottery', props.isILottery)
        ])}
      >
        {getNumGamesText(props.numGames)}
      </p>
    )

    return (
      <>
        <ModalRoot
          isOpen={moreFiltersOpen}
          onOpenChange={setMoreFiltersOpen}
          mobileFullScreen
        >
          <GameLobbyMoreFilters
            currentSort={props.currentSort}
            onSortChange={props.onSortChange}
            supportedSorts={props.supportedSorts}
            lobbyDisplayText={props.lobbyDisplayText}
            filterCategories={props.filterCategories}
            setFilterActive={props.setFilterActive}
            activePrice={props.activePrice}
            setActivePrice={props.setActivePrice}
            isILottery={props.isILottery}
            priceOptions={props.priceOptions}
          />
          <div className={ROOT_CLASS}>
            <InputSearch
              placeholder='Search for a game'
              onChange={props.onSearchChange}
              id={`${props.gameLobbyIdentifier}-search`}
              ref={searchRef}
              ariaLabel={'Search for a game'}
            />
            <GameLobbyQueryFilters
              spotlightFilters={props.spotlightFilters}
              onFilterToggle={props.onFilterToggle}
              onClickMoreFilters={openMoreFilters}
              activePrice={props.activePrice}
              setActivePrice={props.setActivePrice}
              isILottery={props.isILottery}
              priceOptions={props.priceOptions}
            />
            <div
              className={joinClasses([
                META_CLASS,
                getModifierClass(META_CLASS, 'mobile', true)
              ])}
            >
              <NumGamesInfo />
              <ResetButton />
            </div>
          </div>
        </ModalRoot>
        <div
          className={joinClasses([
            META_CLASS,
            getModifierClass(META_CLASS, 'desktop', true)
          ])}
        >
          <div className={getBlockClass(ROOT_CLASS, 'meta-buttons')}>
            <NumGamesInfo />
            <ResetButton />
          </div>
          <div className={getBlockClass(ROOT_CLASS, 'desktop-sort')}>
            <label
              className={joinClasses([
                DESKTOP_LABEL_SORT_CLASS,
                getModifierClass(
                  DESKTOP_LABEL_SORT_CLASS,
                  'is-ilottery',
                  props.isILottery
                )
              ])}
              htmlFor='desktop-sort-input'
            >
              Sort by
            </label>
            <InputSelect
              options={getSelectOptionsFromSortOptions(props.supportedSorts)}
              onChange={(value) =>
                setMatchingSort(value, props.supportedSorts, props.onSortChange)
              }
              label={''}
              defaultValue={props.currentSort[SortOptionField.identifier]}
              id={'desktop-sort-input'}
              isBorderless
            />
          </div>
        </div>
      </>
    )
  }
)
