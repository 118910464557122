const capitalize = (str: string) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const capFirst = (fullName: string) => {
  return fullName
    .split(' ')
    .map((name) => capitalize(name.toLowerCase()))
    .join(' ')
}

export const formatWinner = (name: string, city: string) => {
  return `${capFirst(name)}, ${capFirst(city)}`
}
