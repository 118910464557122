import * as React from 'react'
import { Button } from '../button/button'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import './styles.scss'

type PaginationProps = {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
}

const MAX_VISIBLE_PAGES = 10
const ROOT_CLASS = 'pagination'

export const Pagination = (props: PaginationProps) => {
  const { currentPage, totalPages, onPageChange } = props

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  }

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1)
    }
  }

  const handlePageClick = (page: number) => {
    onPageChange(page)
  }

  const renderPageNumbers = () => {
    const startPage = Math.max(
      1,
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2)
    )
    const endPage = Math.min(totalPages, startPage + MAX_VISIBLE_PAGES - 1)
    const adjustedStartPage = Math.max(
      1,
      Math.min(startPage, totalPages - MAX_VISIBLE_PAGES + 1)
    )

    const pageRange = Array.from(
      { length: endPage - adjustedStartPage + 1 },
      (_, index) => adjustedStartPage + index
    )

    return pageRange.map((i) => {
      const pageNumberClass = joinClasses([
        getBlockClass(ROOT_CLASS, 'page-number'),
        i === currentPage
          ? getModifierClass(getBlockClass(ROOT_CLASS, 'page-number'), 'active')
          : ''
      ])

      return (
        <Button
          key={`pagination-page-button-${i}`}
          className={pageNumberClass}
          onClick={() => handlePageClick(i)}
          ariaLabel={i === currentPage ? 'Current Page' : `Page ${i}`}
          disabled={i === currentPage}
        >
          {i}
        </Button>
      )
    })
  }

  return (
    <div className={ROOT_CLASS}>
      <Button
        className={joinClasses([
          getBlockClass(ROOT_CLASS, 'button'),
          getModifierClass(getBlockClass(ROOT_CLASS, 'button'), 'previous')
        ])}
        ariaLabel='Previous Page'
        onClick={handlePrevious}
        disabled={currentPage === 1}
      >
        Previous
      </Button>
      {renderPageNumbers()}
      <Button
        className={joinClasses([
          getBlockClass(ROOT_CLASS, 'button'),
          getModifierClass(getBlockClass(ROOT_CLASS, 'button'), 'next')
        ])}
        ariaLabel='Next Page'
        onClick={handleNext}
        disabled={currentPage === totalPages}
      >
        Next
      </Button>
    </div>
  )
}
