import * as React from 'react'
import { GameModel } from '@Cms/model-types/game-model'
import { GameDetailsPageStructure } from '../game-details-page/game-details-page-structure'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { AsyncImage } from '../async-image/async-image-ui'
import { GameRibbon } from '../game-ribbon/game-ribbon'
import { KenoAppCard } from './rapid-game-details-page-keno-app-card'
import { getBlockClass, getModifierClass } from '../component-helpers'
import { GameRulesContainer } from '../game-rules/game-rules-container'
import { GameDetailsCtas } from '../game-details-page-ctas/game-details-ctas'
import './styles.scss'

export type KenoAppCardProps = {
  image: string
}

export type RapidGameDetailsPageLoadedProps = {
  game: GameModel
  isLoading: false
}

export type RapidGameDetailsPageProps =
  | RapidGameDetailsPageLoadedProps
  | {
      isLoading: true
    }

const ROOT_CLASS = 'rapid-game-details-page'

const RapidGameDetailsPageInfo = (props: RapidGameDetailsPageLoadedProps) => {
  return (
    <div className={getBlockClass(ROOT_CLASS, 'page-info')}>
      <div className={getBlockClass(ROOT_CLASS, 'image-container')}>
        <AsyncImage
          src={props.game.imageUrl}
          alt={`${props.game.name} image`}
          className={getBlockClass(ROOT_CLASS, 'image')}
        />
        {props.game.meta.ribbon && <GameRibbon {...props.game.meta.ribbon} />}
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'content')}>
        <div className={getBlockClass(ROOT_CLASS, 'horizontal')}>
          <span className={getBlockClass(ROOT_CLASS, 'text')}>
            Next Drawing
          </span>
          <div className={getBlockClass(ROOT_CLASS, 'vertical')}>
            <span
              className={getModifierClass(
                getBlockClass(ROOT_CLASS, 'text'),
                'large'
              )}
            >
              Drawings Every 5 Minutes
            </span>
            <span className={getBlockClass(ROOT_CLASS, 'text')}>
              Every day from 11:05 AM to 1:00 AM
            </span>
          </div>
        </div>
        <GameDetailsCtas
          game={props.game}
          isILotteryEnabled={false}
          onBuyNowClick={() => {}}
          onBuySubscriptionClick={() => {}}
          hasILottery={false}
        />
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'bottom')}>
        <GameRulesContainer game={props.game} />
        <KenoAppCard image={props.game.imageUrl} />
      </div>
    </div>
  )
}

export const RapidGameDetailsPage = (props: RapidGameDetailsPageProps) => {
  return props.isLoading ? (
    <div className={getBlockClass(ROOT_CLASS, 'state')}>
      <LoadingSpinner />
    </div>
  ) : (
    <GameDetailsPageStructure
      gameInfo={<RapidGameDetailsPageInfo {...props} />}
      gameResults={null}
      className={ROOT_CLASS}
    />
  )
}
