import { EntryFieldTypes } from 'contentful'
import { CmsModel } from './cms-models'
import { LinkSkeleton } from './link-model'

export enum NavItemField {
  displayText = 'displayText',
  route = 'route',
  nativeFeature = 'nativeFeature'
}

export enum NavItemNativeFeature {
  pastResults = 'Past Results',
  winners = 'Game Winners',
  findARetailer = 'Find a Retailer'
}

export enum NavItemViewType {
  pastResults = 'PAST_RESULTS',
  winners = 'GAME_WINNERS',
  findARetailer = 'FIND_A_RETAILER',
  cmsPage = 'CMS_PAGE',
  externalLink = 'EXTERNAL_LINK'
}

export type NavItemSkeleton = {
  contentTypeId: CmsModel.navItem
  fields: {
    [NavItemField.displayText]: EntryFieldTypes.Symbol
    [NavItemField.route]: EntryFieldTypes.EntryLink<LinkSkeleton>
    [NavItemField.nativeFeature]?: EntryFieldTypes.Symbol<NavItemNativeFeature>
  }
}

export type NavItemModel = {
  [NavItemField.displayText]: string
  [NavItemField.route]: string
  viewType: NavItemViewType
}

export const navItemFields = Object.values(NavItemField)
