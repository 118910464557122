import * as React from 'react'
import {
  LoaderFunction,
  LoaderFunctionArgs,
  useLoaderData,
  useParams
} from 'react-router-dom'
import { Api } from '@Client/api'
import { PageContentResponse } from '@Server/controllers/content/content-controller'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { CmsPage } from './cms-page-ui'
import { useCmsPreview } from '@Client/hooks/use-cms-preview/use-cms-preview'

export const cmsPageLoader: LoaderFunction = async (
  args: LoaderFunctionArgs
) => {
  const pathParam = args.params['*']
  const path = pathParam?.length ? `/${pathParam}` : ''
  return await Api.callServer('api/v1/content/page', { path })
}

export const CmsPageContainer = () => {
  useCmsPreview()
  const loaderData = useLoaderData() as PageContentResponse
  const params = useParams()

  return loaderData.status === 'success' ? (
    <CmsPage page={loaderData.data} key={JSON.stringify(params)} />
  ) : (
    <LoadingSpinner />
  )
}
