import * as React from 'react'
import { useSwipe } from '@Client/hooks/use-swipe/use-swipe'
import { useResizeObserver } from '@Client/hooks/use-resize-observer/use-resize-observer'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { DotNavigation } from '../dot-navigation/dot-navigation-ui'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { SpotlightCarouselContext } from './constants'
import './styles.scss'

export type SpotlightCarouselProps = {
  name: string
  isLoading: boolean
  items?: JSX.Element[]
  autoRotateInterval?: number // Milliseconds
  dotColor?: 'light' | 'dark'
}

const SWIPE_PX_MIN_DELTA = 10
const ROOT_CLASS = 'spotlight-carousel'
const ITEM_CLASS = getBlockClass(ROOT_CLASS, 'item')

export const SpotlightCarousel = (props: SpotlightCarouselProps) => {
  const rootEl = React.useRef<HTMLDivElement>(null)
  const [rootWidth, setRootWidth] = React.useState(0)
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [isChildActive, setIsChildActive] = React.useState(false)
  const [isAutoRotateEnabled, setIsAutoRotateEnabled] = React.useState(
    !!props.autoRotateInterval
  )
  const { xDelta, handleTouchStart, handleTouchMove, handleTouchEnd } =
    useSwipe({
      xDeltaMin: SWIPE_PX_MIN_DELTA,
      yDeltaMin: SWIPE_PX_MIN_DELTA
    })

  useResizeObserver(
    () => (rootEl.current ? setRootWidth(rootEl.current.clientWidth) : null),
    rootEl
  )

  const enableAutoRotate = () => setIsAutoRotateEnabled(true)
  const disableAutoRotate = () => setIsAutoRotateEnabled(false)

  const autoNavigate = () => {
    if (!isAutoRotateEnabled) {
      return
    }
    setActiveIndex((prevIndex) => {
      const nextIndex = prevIndex + 1
      return nextIndex === props.items?.length ? 0 : nextIndex
    })
  }

  React.useEffect(() => {
    if (!props.autoRotateInterval) {
      return
    }
    const intervalId = setInterval(autoNavigate, props.autoRotateInterval)
    return () => clearInterval(intervalId)
  }, [autoNavigate, props.autoRotateInterval])

  const navigateToItem = (index: number) => {
    const numItems = props.items?.length ?? 0
    disableAutoRotate()
    const correctedIndex =
      index < 0 ? numItems - 1 : index >= numItems ? 0 : index
    setActiveIndex(correctedIndex)
    enableAutoRotate()
  }

  const onTouchStart = (e: React.TouchEvent) => {
    disableAutoRotate()
    handleTouchStart(e)
  }

  const onTouchEnd = () => {
    const onSwipeLeft = () => {
      if (!props.items) {
        return
      }
      const nextIndex =
        props.items && activeIndex === props.items.length - 1
          ? 0
          : activeIndex + 1
      setActiveIndex(nextIndex)
    }
    const onSwipeRight = () => {
      if (!props.items) {
        return
      }
      const prevIndex =
        activeIndex === 0 ? props.items.length - 1 : activeIndex - 1
      setActiveIndex(prevIndex)
    }
    handleTouchEnd({ onSwipeLeft, onSwipeRight })
    enableAutoRotate()
  }

  if (props.items && !props.items?.length) {
    return null
  }

  const swipePercentage = (xDelta / rootWidth) * 100
  const translatePercentage = activeIndex * 100 + swipePercentage

  return (
    <SpotlightCarouselContext.Provider
      value={{
        onChildInactive: () => {
          setIsChildActive(false)
          enableAutoRotate()
        },
        onChildActive: () => {
          setIsChildActive(true)
          disableAutoRotate()
        }
      }}
    >
      <div
        ref={rootEl}
        className={joinClasses([
          ROOT_CLASS,
          getModifierClass(ROOT_CLASS, 'is-loading', props.isLoading)
        ])}
      >
        {props.isLoading ? (
          <LoadingSpinner />
        ) : (
          <ul
            className={getBlockClass(ROOT_CLASS, 'items')}
            style={{
              transform: `translateX(-${translatePercentage}%)`,
              transition: xDelta ? 'none' : undefined
            }}
            onTouchStart={onTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={onTouchEnd}
            onMouseEnter={disableAutoRotate}
            onMouseLeave={() => (!isChildActive ? enableAutoRotate() : null)}
          >
            {props.items?.map((item, i) => (
              <li
                key={`${props.name}-item-${i}`}
                className={joinClasses([
                  ITEM_CLASS,
                  getModifierClass(ITEM_CLASS, 'current', i === activeIndex)
                ])}
              >
                {item}
              </li>
            ))}
          </ul>
        )}
        {props.items && (
          <DotNavigation
            name={props.name}
            activeIndex={activeIndex}
            navigateToIndex={navigateToItem}
            count={props.items.length}
            showDotsInDesktop
            dotColor={props.dotColor ?? 'dark'}
          />
        )}
      </div>
    </SpotlightCarouselContext.Provider>
  )
}
