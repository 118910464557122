import { UrlUtil } from '@Util/url-utilities'
import { store } from '@Client/reducers/store'
import { ClientPlatform } from '@Server/controllers/controller-helpers'
import { ApiConfig } from './config'
import { getObjectKeys } from '@Util/object-utilities'
import { isServerRender } from '@Util/ssr-util'

const DEFAULT_ERROR = 'Something went wrong.'

type ApiCallParams = {
  url: string
  searchParams?: Record<string, any>
  method?: RequestInit['method']
}

const callServer = async (
  path: string,
  params: Record<string, any> = {},
  method: RequestInit['method'] = 'GET',
  headers?: Record<string, string>,
  body?: BodyInit
) => {
  const state = store.getState()
  let urlPath = path
  if (!path.startsWith('/')) {
    urlPath = `/${urlPath}`
  }
  params.platform = ClientPlatform.web
  params.cmsPreview = state.operations.cmsPreviewActive
  const pathAndParams = `${urlPath}?${UrlUtil.getQueryParamStr(params)}`
  const fullUrl = isServerRender()
    ? `${ApiConfig.apiRoot}${pathAndParams}`
    : pathAndParams
  const response = await fetch(fullUrl, {
    headers,
    method,
    body: method === 'GET' ? undefined : body
  })
  if (!response.ok) {
    try {
      const data = await response.json()
      throw new Error(data.message ?? DEFAULT_ERROR)
    } catch (error) {
      throw new Error(DEFAULT_ERROR)
    }
  }
  return await response.json()
}

const externalCall = async <T>(params: ApiCallParams): Promise<T> => {
  const callMethod: RequestInit['method'] = params.method ?? 'GET'
  const searchParamsStr =
    params.searchParams && getObjectKeys(params.searchParams)
      ? `?${UrlUtil.getQueryParamStr(params.searchParams)}`
      : ''

  try {
    const response = await fetch(`${params.url}${searchParamsStr}`, {
      method: callMethod
    })
    if (!response.ok) {
      throw new Error(DEFAULT_ERROR)
    }
    return await response.json()
  } catch (error) {
    throw new Error(DEFAULT_ERROR)
  }
}

export const Api = {
  DEFAULT_ERROR,
  callServer,
  externalCall
}
