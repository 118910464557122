import * as React from 'react'
import { JackpotStatus } from '@interaction-gaming/game-data-service-browser-client'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { Nullable } from '@Util/utility-types'
import { DateFormat, dateUtil } from '@Util/date-util'
import { Button } from '../button/button'
import { GeneralLink } from '../link/link-ui'
import { Api } from '@Client/api'
import { RoutePath } from '@Client/route-paths'
import { AddToCartIcon } from '@Client/icons/add-to-cart'
import { CurrencyUtilities } from '@Util/currency-utilities'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { ErrorMessage } from '../error-message/error-message-ui'
import { GameImageLink } from '../game-image-link/game-image-link'
import { AsyncImage } from '../async-image/async-image-ui'
import { PendingAnimation } from '../pending-animation/pending-animation'
import './styles.scss'

type JackpotInfo =
  | {
      jackpotType: 'current'
      asOf: Date
      gameIdentifierLink?: string
    }
  | {
      gameIdentifier: string
      jackpotType: 'estimated'
      estimatedCashAmountJackpotCents?: Nullable<number>
    }

export type CommonJackpotLoadedProps = {
  isILotteryFrameworkReady: boolean
  isLoading: false
  nextDrawingDate?: Date
  gameIconSrc: string
  gameName: string
  gameType?: string
  estimatedJackpotCents: number
  jackpotDisplay?: string
  promoText?: string
  status: JackpotStatus
  openGameWidget: () => void
  error?: Nullable<string>
}

export type JackpotCardLoadedProps = CommonJackpotLoadedProps & JackpotInfo

export type JackpotCardLoadingProps = {
  isLoading: true
  error?: Nullable<string>
}

export type JackpotCardProps = JackpotCardLoadedProps | JackpotCardLoadingProps

const ROOT_CLASS = 'jackpot-card'
const JACKPOT_VALUE_CLASS = getBlockClass(ROOT_CLASS, 'jackpot-value')
const GAME_INFO_CLASS = getBlockClass(ROOT_CLASS, 'game-info')

const getJackpotAnnotationText = (props: JackpotCardProps) => {
  if (props.isLoading) {
    return null
  }
  if (props.jackpotType === 'estimated') {
    return props.estimatedCashAmountJackpotCents
      ? `${CurrencyUtilities.numericFormat(
          CurrencyUtilities.getDollarsFromCents(
            props.estimatedCashAmountJackpotCents
          )
        )} cash value`
      : null
  }
  return `As of ${dateUtil(props.asOf, true).format(DateFormat.monthDayTime)}`
}

export const JackpotCard = (props: JackpotCardProps) => {
  if (props.error) {
    return (
      <div
        className={joinClasses([
          ROOT_CLASS,
          getModifierClass(ROOT_CLASS, 'has-error', Boolean(props.error))
        ])}
      >
        <ErrorMessage message={Api.DEFAULT_ERROR} />
      </div>
    )
  } else if (props.isLoading) {
    return (
      <div
        aria-busy
        className={joinClasses([
          ROOT_CLASS,
          getModifierClass(ROOT_CLASS, 'is-loading', props.isLoading)
        ])}
      >
        <LoadingSpinner />
      </div>
    )
  } else if (props.status === 'NO_JACKPOT') {
    return null
  }

  const isEstimatedJackpot = props.jackpotType === 'estimated'

  return (
    <div className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'primary-content')}>
        <div
          className={joinClasses([
            GAME_INFO_CLASS,
            getModifierClass(
              GAME_INFO_CLASS,
              'is-estimated',
              isEstimatedJackpot
            )
          ])}
        >
          {isEstimatedJackpot ? (
            <GameImageLink
              image={{ src: props.gameIconSrc }}
              gameName={props.gameName}
              gameIdentifier={props.gameIdentifier}
            />
          ) : props.gameIdentifierLink ? (
            <GameImageLink
              image={{ src: props.gameIconSrc }}
              gameName={props.gameName}
              gameIdentifier={props.gameIdentifierLink}
            />
          ) : (
            <AsyncImage
              src={props.gameIconSrc}
              alt={props.gameName}
              minHeightPx={60}
            />
          )}
          {isEstimatedJackpot && (
            <div className={getBlockClass(ROOT_CLASS, 'next-drawing-info')}>
              <span className={getBlockClass(ROOT_CLASS, 'next-drawing-text')}>
                Next Drawing
              </span>
              {props.nextDrawingDate ? (
                <>
                  <span
                    className={getBlockClass(ROOT_CLASS, 'next-drawing-date')}
                  >
                    {dateUtil(props.nextDrawingDate, true).format(
                      DateFormat.shortDayShortMonthDate
                    )}
                  </span>
                  <span
                    className={getBlockClass(ROOT_CLASS, 'next-drawing-time')}
                  >
                    {dateUtil(props.nextDrawingDate, true).format(
                      DateFormat.time
                    )}
                  </span>
                </>
              ) : null}
            </div>
          )}
        </div>
        <div className={getBlockClass(ROOT_CLASS, 'info-container')}>
          <div className={getBlockClass(ROOT_CLASS, 'jackpot-info')}>
            <span
              className={joinClasses([
                JACKPOT_VALUE_CLASS,
                getModifierClass(
                  JACKPOT_VALUE_CLASS,
                  'is-pending',
                  props.status === 'PENDING'
                )
              ])}
            >
              {props.status === 'ACTIVE' ? (
                props.jackpotDisplay ? (
                  props.jackpotDisplay
                ) : (
                  CurrencyUtilities.numericFormat(
                    CurrencyUtilities.getDollarsFromCents(
                      props.estimatedJackpotCents
                    )
                  )
                )
              ) : (
                <PendingAnimation useAsterisk={true} alignment='left' />
              )}
            </span>
            {props.status == 'ACTIVE' ? (
              <span className={getBlockClass(ROOT_CLASS, 'jackpot-annotation')}>
                {getJackpotAnnotationText(props)}
              </span>
            ) : null}
            <span className={getBlockClass(ROOT_CLASS, 'jackpot-text')}>
              {isEstimatedJackpot ? '*Estimated Jackpot' : '*Current Jackpot'}
            </span>
          </div>
        </div>
        {props.gameType === 'fastPlay' ? (
          <GeneralLink
            dest={RoutePath.findARetailer}
            ariaLabel={`Find ${props.gameName} tickets at a retailer`}
            className={getBlockClass(ROOT_CLASS, 'cta')}
          >
            Find a Retailer
          </GeneralLink>
        ) : props.gameType === 'draw' ? (
          <Button
            onClick={props.openGameWidget}
            ariaLabel={`Add ${props.gameName} ticket to cart`}
            className={getBlockClass(ROOT_CLASS, 'cta')}
            disabled={!props.isILotteryFrameworkReady}
          >
            <AddToCartIcon />
            <span>Buy Now</span>
          </Button>
        ) : props.gameType === 'online' ? (
          <Button
            onClick={props.openGameWidget}
            ariaLabel={`Add ${props.gameName} ticket to cart`}
            className={getBlockClass(ROOT_CLASS, 'cta')}
            disabled={!props.isILotteryFrameworkReady}
          >
            <span>Play Now</span>
          </Button>
        ) : null}
        {props.promoText ? (
          <span className={getBlockClass(ROOT_CLASS, 'promo-text')}>
            {props.promoText}
          </span>
        ) : null}
      </div>
    </div>
  )
}
