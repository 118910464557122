import {
  getSpecialBallType,
  WinningNumbersProps
} from '@Client/components/winning-numbers/winning-numbers-ui'
import {
  DrawResultResponse,
  RapidDrawingResultFormattedSecondary
} from '@interaction-gaming/game-data-service-browser-client'
import { compact } from '@Util/array-utilities'
import { DateFormat, dateUtil } from '@Util/date-util'
import { SerializedDates } from '@Util/object-utilities'
import { Optional } from '@Util/utility-types'

export type AnnotatedWinningNumbersProps = WinningNumbersProps & {
  jackpotWinLocation?: string
}

export const getSpecialBalls = (
  gameName: string,
  drawResult?: RapidDrawingResultFormattedSecondary
) => {
  const specialBalls = compact(
    drawResult?.bonusNumbers?.map((specialBallValue) => {
      const specialBallType = getSpecialBallType(gameName)
      return specialBallType
        ? {
            type: specialBallType,
            value: specialBallValue
          }
        : undefined
    }) ?? []
  )
  return specialBalls
}

const getDrawTimeText = (drawTime?: string) => {
  const drawTimeFormatted = dateUtil(drawTime).format(DateFormat.localTime)
  return drawTime ? `Drawing at ${drawTimeFormatted}` : undefined
}

export const getWinningNumbersFromDrawResultResponse = (
  gameName: string,
  drawResultResponses: Optional<SerializedDates<DrawResultResponse>>[],
  drawTimesForDate: Optional<string>[],
  labelResponses: Optional<string>[]
): AnnotatedWinningNumbersProps[] => {
  return drawResultResponses
    ? compact(
        drawResultResponses.map(
          (drawResultResponse, i): Optional<AnnotatedWinningNumbersProps> => {
            if (!drawResultResponse) {
              return undefined
            } else if (
              !drawResultResponse.drawResult?.primary ||
              drawResultResponse.status === 'PENDING'
            ) {
              return {
                status: 'PENDING',
                numbers: [],
                multipliers: null,
                specialBalls: null,
                drawTime: drawTimesForDate[i],
                label: labelResponses[i],
                drawTimeText: getDrawTimeText(drawTimesForDate[i])
              }
            }
            const specialBalls = getSpecialBalls(
              gameName,
              drawResultResponse.drawResult.primary
            )
            return {
              multipliers: drawResultResponse.drawResult.primary.multipliers,
              numbers: drawResultResponse.drawResult.primary.winningNumbers,
              status: drawResultResponse.status,
              specialBalls,
              drawTime: drawTimesForDate[i],
              label: labelResponses[i],
              jackpotWinLocation:
                drawResultResponse.drawResult?.drawResultMetadata?.wINNERDETAILS
            }
          }
        )
      )
    : []
}
