import * as React from 'react'
import { joinClasses } from '../component-helpers'
import './styles.scss'

type FormButtonProps = {
  type: 'submit' | 'reset'
}

type StandaloneButtonProps = {
  type?: 'button'
  onClick: (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<any> | any
}

export type ButtonProps = {
  className?: string
  disabled?: boolean
  ariaLabel: string
  id?: string
  htmlAttributes?: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
} & (FormButtonProps | StandaloneButtonProps)

const CLASS_NAME = 'button'

export const Button = React.forwardRef(
  (
    props: React.PropsWithChildren<ButtonProps>,
    ref?: React.Ref<HTMLButtonElement>
  ) => (
    <button
      {...(props.htmlAttributes ?? {})}
      type={props.type ?? 'button'}
      id={props.id}
      aria-label={props.ariaLabel}
      disabled={props.disabled}
      className={joinClasses([CLASS_NAME, props.className])}
      onClick={
        props.type === 'button' || !props.type ? props.onClick : undefined
      }
      ref={ref}
    >
      {props.children}
    </button>
  )
)
