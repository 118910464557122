import * as React from 'react'
import {
  getMapsUrl,
  getRetailerAddress,
  RetailerInfoWindowProps
} from './constants'
import { getBlockClass } from '../component-helpers'
import { formatPhoneNumber } from '@Util/string-utilities'
import { DrivingDirections } from '@Client/icons/driving-directions'
import { GeneralLink } from '../link/link-ui'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import './styles.scss'

export type RetailLocation = RetailerInfoWindowProps & {
  id: string
  tags: string[]
}

export type RetailFinderListProps = {
  locations: RetailLocation[]
  isLoading: boolean
}

const ROOT_CLASS = 'retail-finder-list'

export const RetailFinderList = (props: RetailFinderListProps) => (
  <div className={ROOT_CLASS}>
    <div className={getBlockClass(ROOT_CLASS, 'header')}>
      <span>Retailer</span>
    </div>
    {props.isLoading ? (
      <div className={getBlockClass(ROOT_CLASS, 'loading-container')}>
        <LoadingSpinner />
      </div>
    ) : props.locations.length === 0 ? (
      <span className={getBlockClass(ROOT_CLASS, 'no-results')}>
        No results
      </span>
    ) : (
      <ul className={getBlockClass(ROOT_CLASS, 'list')}>
        {props.locations.map((location) => {
          const address = getRetailerAddress(location)
          return (
            <li key={location.id} className={getBlockClass(ROOT_CLASS, 'item')}>
              <div className={getBlockClass(ROOT_CLASS, 'item-info')}>
                <span className={getBlockClass(ROOT_CLASS, 'name')}>
                  {location.name}
                </span>
                <span className={getBlockClass(ROOT_CLASS, 'address')}>
                  {address}
                </span>
                {location.phoneNumber && (
                  <span className={getBlockClass(ROOT_CLASS, 'phone')}>
                    {formatPhoneNumber(location.phoneNumber)}
                  </span>
                )}
              </div>
              <GeneralLink
                dest={getMapsUrl(address)}
                ariaLabel={`Get Directions to ${address}`}
              >
                <DrivingDirections />
              </GeneralLink>
            </li>
          )
        })}
      </ul>
    )}
  </div>
)
