import * as React from 'react'
import { InputLabel } from '../input-label/input-label-ui'
import { DateFormat, dateUtil } from '@Util/date-util'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { Modal, ModalRoot } from '../modal/modal'
import { DateSelectorCalendar } from '../date-selector/date-selector-calendar'
import { CalendarIcon } from '@Client/icons/calendar'
import { ModalTriggerButton } from '../modal/modal-trigger-button'
import './styles.scss'

export type InputDateProps = {
  selectedDate?: Date
  onChange: (value?: Date) => void
  disabled?: boolean
  labelText?: string
  placeholder?: string
  id: string
  fieldClassName?: string
  calendarHeaderText?: string
  isDateDisabled?: (date: Date) => boolean
  onNextMonth?: (date: Date) => void
  onPreviousMonth?: (date: Date) => void
}

const ROOT_CLASS = 'input-date'
const FIELD_CLASS = getBlockClass(ROOT_CLASS, 'field')

export const InputDate = (props: InputDateProps) => {
  const [calendarOpen, setCalendarOpen] = React.useState(false)

  const onDateChange = (date?: Date) => {
    props.onChange(date)
    setTimeout(() => {
      setCalendarOpen(false)
    }, 300)
  }

  return (
    <ModalRoot isOpen={calendarOpen} onOpenChange={setCalendarOpen}>
      {calendarOpen && (
        <Modal
          className={getBlockClass(ROOT_CLASS, 'modal')}
          ariaTitle={'Calendar Picker'}
          ariaDescription={'Select date'}
          mobileFullScreen={false}
        >
          <DateSelectorCalendar
            selectedDate={props.selectedDate}
            onDateChange={onDateChange}
            headerText={props.calendarHeaderText}
            inputName={props.id}
            isDateDisabled={props.isDateDisabled}
            onNextMonth={props.onNextMonth}
            onPreviousMonth={props.onPreviousMonth}
          />
        </Modal>
      )}
      <div className={ROOT_CLASS}>
        {props.labelText && (
          <InputLabel for={props.id}>{props.labelText}</InputLabel>
        )}
        <ModalTriggerButton
          ariaLabel='Select a date'
          id={props.id}
          className={joinClasses([
            FIELD_CLASS,
            getModifierClass(
              FIELD_CLASS,
              'placeholder',
              !!props.placeholder && !props.selectedDate
            ),
            props.fieldClassName
          ])}
        >
          {props.selectedDate
            ? dateUtil(props.selectedDate).format(
                DateFormat.shortDayShortMonthDateAndYear
              )
            : props.placeholder ?? ''}
          <span className={getBlockClass(ROOT_CLASS, 'calendar-icon')}>
            <CalendarIcon />
          </span>
        </ModalTriggerButton>
      </div>
    </ModalRoot>
  )
}
