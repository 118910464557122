import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  JackpotsByGameId,
  JackpotsState,
  SerializedJackpotsGroupsById,
  SerializedJackpotSummary
} from './jackpot-reducer-types'
import { getObjectKeys } from '@Util/object-utilities'

export const initialJackpotsState: JackpotsState = {
  isLoading: false,
  error: null,
  jackpotsByGameId: {},
  groupsById: {}
}

const jackpotsSlice = createSlice({
  name: 'jackpots',
  initialState: initialJackpotsState,
  reducers: {
    fetchJackpotsRequest: (state) => {
      state.isLoading = true
      state.error = null
    },
    fetchJackpotsSuccess: (
      state,
      action: PayloadAction<SerializedJackpotSummary>
    ) => {
      const jackpotsByGameId: JackpotsByGameId = {}
      getObjectKeys(action.payload).forEach(
        (gameType: keyof SerializedJackpotSummary) => {
          getObjectKeys(action.payload[gameType]).forEach((gameId) => {
            jackpotsByGameId[gameId] = {
              ...action.payload[gameType][gameId],
              type: gameType as any
            }
          })
        }
      )
      state.isLoading = false
      state.error = null
      state.jackpotsByGameId = jackpotsByGameId
    },
    fetchJackpotsFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    fetchJackpotGroupsRequest: (state) => {
      state.isLoading = true
      state.error = null
    },
    fetchJackpotGroupsSuccess: (
      state,
      action: PayloadAction<SerializedJackpotsGroupsById>
    ) => {
      state.isLoading = false
      state.error = null
      state.groupsById = action.payload
    },
    fetchJackpotGroupsFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    }
  }
})

export const jackpotActions = jackpotsSlice.actions
export const jackpotReducer = jackpotsSlice.reducer
