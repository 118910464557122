import * as React from 'react'
import {
  ControlledCollapsible,
  ControlledCollapsibleProps
} from './controlled-collapsible-ui'

export type CollapsibleProps = Omit<
  ControlledCollapsibleProps,
  'isOpen' | 'setIsOpen'
>

export const Collapsible = (props: CollapsibleProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  return (
    <ControlledCollapsible {...props} isOpen={isOpen} setIsOpen={setIsOpen} />
  )
}
