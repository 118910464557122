import * as React from 'react'

export type ChevronRightProps = {
  useLightColor?: boolean
}

export const ChevronRightSmall = (props: ChevronRightProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
  >
    <title>Right Chevron</title>
    <path
      d='M3.59826 2.00192C3.60075 2.10743 3.64484 2.20768 3.72091 2.28083L7.4381 5.99801L3.72091 9.7152C3.68259 9.75248 3.65214 9.79706 3.63134 9.8463C3.61054 9.89555 3.59982 9.94846 3.59982 10.0019C3.59984 10.0815 3.62359 10.1593 3.66804 10.2253C3.71248 10.2913 3.7756 10.3425 3.84933 10.3725C3.92305 10.4024 4.00403 10.4097 4.08191 10.3933C4.15979 10.377 4.23104 10.3379 4.28654 10.2808L8.28654 6.28083C8.36152 6.20581 8.40365 6.10408 8.40365 5.99801C8.40365 5.89195 8.36152 5.79022 8.28654 5.7152L4.28654 1.7152C4.23068 1.65711 4.15857 1.61725 4.07967 1.60083C4.00077 1.58441 3.91875 1.59221 3.84435 1.6232C3.76996 1.65419 3.70666 1.70693 3.66275 1.77451C3.61883 1.84209 3.59635 1.92135 3.59826 2.00192Z'
      fill={props.useLightColor ? '#dddfe1' : '#2B3037'}
    />
  </svg>
)

export const ChevronRight = (props: ChevronRightProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
  >
    <title>Right Chevron</title>
    <path
      d='M5.99726 3.33653C6.00141 3.51238 6.07489 3.67946 6.20168 3.80138L12.397 9.99669L6.20168 16.192C6.13782 16.2541 6.08706 16.3284 6.05239 16.4105C6.01773 16.4926 5.99987 16.5808 5.99986 16.6699C5.99989 16.8025 6.03948 16.9321 6.11356 17.0421C6.18763 17.1521 6.29283 17.2375 6.41571 17.2874C6.53858 17.3373 6.67355 17.3495 6.80335 17.3222C6.93315 17.295 7.05189 17.2298 7.14439 17.1347L13.8111 10.468C13.936 10.343 14.0062 10.1735 14.0062 9.99669C14.0062 9.81991 13.936 9.65036 13.8111 9.52534L7.14439 2.85867C7.05129 2.76185 6.93112 2.69541 6.79961 2.66805C6.66811 2.64069 6.53141 2.65369 6.40742 2.70534C6.28343 2.75699 6.17793 2.84489 6.10474 2.95752C6.03155 3.07014 5.99409 3.20225 5.99726 3.33653Z'
      fill={props.useLightColor ? '#dddfe1' : '#2B3037'}
    />
  </svg>
)
