import * as React from 'react'
import { FeedModel } from '@Cms/model-types/feed-model'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { FeedItem } from '../cms-feed-item/feed-item-ui'
import {
  ToggleButton,
  ToggleButtonOption
} from '../button-toggle/toggle-button-ui'
import { Button } from '../button/button'
import { ErrorMessage } from '../error-message/error-message-ui'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import './styles.scss'

export type FeedProps = {
  feed: FeedModel
  onLoadMore: () => Promise<void>
  hideShowMore: boolean
  isLoading: boolean
  error?: string
}

const ROOT_CLASS = 'feed'
const FEED_ITEMS_CLASS = getBlockClass(ROOT_CLASS, 'feed-items')

const toggleOptions: ToggleButtonOption[] = [
  { text: 'List', ariaLabel: 'Set List Style' },
  {
    text: 'Grid',
    ariaLabel: 'Set Grid Style'
  }
]

export const Feed = (props: FeedProps) => {
  const [style, setStyle] = React.useState<'listItem' | 'tile'>('listItem')
  return (
    <article className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'header-container')}>
        <h2 className={getBlockClass(ROOT_CLASS, 'header')}>
          {props.feed.header}
        </h2>
        <ToggleButton
          options={toggleOptions}
          onSelect={(index) => setStyle(index === 0 ? 'listItem' : 'tile')}
          selectedOptionIndex={style === 'listItem' ? 0 : 1}
          name={`${props.feed.header.toLowerCase().replace(/[^A-Z0-9]/gi, '-')}-feed-style`}
        />
      </div>
      <div
        className={joinClasses([
          FEED_ITEMS_CLASS,
          getModifierClass(FEED_ITEMS_CLASS, style, true)
        ])}
      >
        {props.feed.feedItems.map((item) => (
          <FeedItem style={style} item={item} key={JSON.stringify(item)} />
        ))}
      </div>
      {props.isLoading ? (
        <LoadingSpinner />
      ) : props.error ? (
        <ErrorMessage message={props.error} />
      ) : null}
      {props.hideShowMore ? null : (
        <Button
          ariaLabel='Show More Articles'
          onClick={props.onLoadMore}
          className={getBlockClass(ROOT_CLASS, 'show-more')}
          disabled={props.isLoading}
        >
          Show More
        </Button>
      )}
    </article>
  )
}
