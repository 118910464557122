import { EntryFieldTypes } from 'contentful'
import { CmsModel } from './cms-models'
import { GameCarouselModel, GameCarouselSkeleton } from './game-carousel-model'
import { GameLobbyModel, GameLobbySkeleton } from './game-lobby-model'
import { ImageBannerModel, ImageBannerSkeleton } from './image-banner-model'

export enum GameCollectionField {
  content = 'content',
  title = 'title',
  subtitle = 'subtitle',
  identifier = 'identifier'
}

export type GameCollectionSkeleton = {
  contentTypeId: CmsModel.gameCollection
  fields: {
    [GameCollectionField.identifier]: EntryFieldTypes.Symbol
    [GameCollectionField.title]: EntryFieldTypes.Symbol
    [GameCollectionField.subtitle]?: EntryFieldTypes.Symbol
    [GameCollectionField.content]: EntryFieldTypes.Array<
      EntryFieldTypes.EntryLink<
        GameCarouselSkeleton | GameLobbySkeleton | ImageBannerSkeleton
      >
    >
  }
}

export enum GameCollectionIdentifier {
  iLottery = 'ilottery',
  inStore = 'in-store'
}

export type GameLobbyContent = {
  type: CmsModel.gameLobby
  data: GameLobbyModel
}

export type GameCarouselContent = {
  type: CmsModel.gameCarousel
  data: GameCarouselModel
}

export type ImageBannerContent = {
  type: CmsModel.imageBanner
  data: ImageBannerModel
}

export type GameCollectionContent =
  | GameCarouselContent
  | GameLobbyContent
  | ImageBannerContent

export type GameCollectionModel = {
  title: string
  subtitle?: string
  identifier: string
  content: GameCollectionContent[]
}

export const GameCollectionFields = Object.values(GameCollectionField)
