import * as React from 'react'
import { AlertBannerModel } from '@Cms/model-types/alert-banner-model'
import { NavCategoryModel } from '@Cms/model-types/nav-category-model'
import { MenuBar } from './menu-bar-ui'
import { useAppSelector } from '@Client/hooks/redux-hooks'

export type MenuBarContainerProps = {
  navigationCategories: NavCategoryModel[]
  alertBanner?: AlertBannerModel
}

export const MenuBarContainer = (props: MenuBarContainerProps) => {
  const isAuthenticated = useAppSelector(
    (state) => state.iLottery.isPlayerAuthenticated
  )
  const isILotteryFrameworkReady = useAppSelector(
    (state) => state.iLottery.isFrameworkReady
  )
  const isILotteryGameActive = useAppSelector((state) => {
    const gameId = state.iLottery.activeGame.gameId
    if (!gameId) {
      return false
    }
    return !state.iLottery.drawGamesById[gameId]
  })

  return (
    <MenuBar
      navigationCategories={props.navigationCategories}
      alertBanner={props.alertBanner}
      isAuthenticated={isAuthenticated}
      isILotteryFrameworkReady={isILotteryFrameworkReady}
      isILotteryGameActive={isILotteryGameActive}
    />
  )
}
