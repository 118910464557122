import * as React from 'react'
import { GeneralLink } from '../link/link-ui'
import { DynamicUrl, GameCollection } from '@Client/route-paths'
import { getBlockClass } from '../component-helpers'
import { AsyncImage } from '../async-image/async-image-ui'
import OnlineGamesSrc from '@Client/assets/online-games-collection.png'
import InStoreGamesSrc from '@Client/assets/in-store-collection.png'
import './styles.scss'

export type GameCollectionFooterProps = {
  identifier: string
}

const ROOT_CLASS = 'game-collection-footer'
const IN_STORE_TEXT = 'in\u2011store' // non-breaking hyphen
const I_LOTTERY_TEXT = 'online'

export const GameCollectionFooter = (props: GameCollectionFooterProps) => {
  const isILottery = props.identifier === 'ilottery'
  const isInStore = props.identifier === 'in-store'
  const otherLobbyText = isILottery
    ? IN_STORE_TEXT
    : isInStore
      ? I_LOTTERY_TEXT
      : null
  const linkText = isInStore ? 'Online' : 'In-Store'
  const src = isInStore ? OnlineGamesSrc : InStoreGamesSrc

  return otherLobbyText ? (
    <div className={ROOT_CLASS}>
      <AsyncImage
        src={src}
        alt={`${linkText} Game Lobby`}
        className={getBlockClass(ROOT_CLASS, 'image')}
      />
      <div className={getBlockClass(ROOT_CLASS, 'text-content')}>
        <h2
          className={getBlockClass(ROOT_CLASS, 'text')}
        >{`Looking for ${otherLobbyText} games?`}</h2>
        <GeneralLink
          className={getBlockClass(ROOT_CLASS, 'link')}
          dest={
            isInStore
              ? DynamicUrl.getGameCollectionUrl(GameCollection.iLottery)
              : DynamicUrl.getGameCollectionUrl(GameCollection.inStore)
          }
        >{`View ${linkText} games`}</GeneralLink>
      </div>
    </div>
  ) : null
}
