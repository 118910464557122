import { PastResultsFieldId } from './components/past-results-form/constants'
import { PrizesRemainingFormField } from './components/prizes-remaining-form/constants'
import { FindARetailerFilterIdentifier } from './components/retail-finder/constants'

export enum RoutePath {
  home = '/',
  gameCollection = '/game-collection',
  gameCollectionByIdentifier = '/game-collection/:identifier',
  gameByIdentifier = '/game/:identifier',
  winningNumbers = '/winning/winning-numbers',
  pastResults = '/winning/past-results',
  winners = '/winning/winners',
  prizesRemaining = '/prizes/prizes-remaining',
  findARetailer = '/find-retailer',
  promotions = '/promotions',
  welcomeBonus = '/promotions/welcome-bonus',
  sports = '/sports',
  news = '/news',
  adminGameRules = '/About-Us/Administrative-and-Game-Rules',

  // Footer
  about = '/about',
  winnerStories = '/winning/winning-stories',
  iLotteryInfo = '/ilottery-info',
  claimPrize = '/prizes/how-to-claim',
  otherWebsites = '/other-websites',
  mobileApp = '/app',
  // Footer Partners
  playResponsibly = '/play-responsibly',
  retailers = '/retailers',
  whereTheMoneyGoes = '/About-Us/Financial-Reports',
  retailerSite = '/about/retailer-site',
  // Footer Legal
  careers = '/about/careers',
  termsAndConditions = '/terms-and-conditions',
  genericPage = '*'
}

export enum GameCollection {
  iLottery = 'ilottery',
  inStore = 'in-store'
}

export enum GameCollectionParam {
  filters = 'filters',
  search = 'search',
  sort = 'sort',
  price = 'price'
}

export enum GameCollectionFilters {
  eInstant = 'gameTypeEInstant',
  draw = 'drawGame',
  progressive = 'gameTypeProgressive',
  fastPlay = 'gameTypeFastPlay',
  scratch = 'scratchGame'
}

export const RoutesToAdaTest = [
  RoutePath.home,
  getGameCollectionUrl(GameCollection.iLottery),
  RoutePath.prizesRemaining,
  RoutePath.pastResults
  // RoutePath.findARetailer // commented out for CI to reduce API usage
]

const getGameByIdentifierUrl = (identifier: string) =>
  RoutePath.gameByIdentifier.replace(':identifier', identifier)

function getGameCollectionUrl(
  gameCollection: GameCollection,
  params?: Partial<Record<GameCollectionParam, string | string[]>>
): string {
  const url = `${RoutePath.gameCollection}/${gameCollection}`
  if (params && Object.keys(params).length > 0) {
    const searchParams = new URLSearchParams()
    Object.entries(params).forEach(([key, value]) => {
      const paramValue = Array.isArray(value) ? value.join(',') : value
      searchParams.append(key, paramValue)
    })
    return `${url}?${searchParams.toString()}`
  }
  return url
}

const getPastResultsForGame = (identifier: string) =>
  RoutePath.pastResults + `?${PastResultsFieldId.GAME_IDENTIFIER}=${identifier}`

const getKenoFindARetailerUrl = () =>
  RoutePath.findARetailer + `?${FindARetailerFilterIdentifier.KENO}=true`

const getPrizesRemainingForGame = (gameDataServiceId: string) =>
  RoutePath.prizesRemaining +
  `?${PrizesRemainingFormField.games}=${encodeURI(JSON.stringify([gameDataServiceId]))}`

export const DynamicUrl = {
  getKenoFindARetailerUrl,
  getGameByIdentifierUrl,
  getGameCollectionUrl,
  getPastResultsForGame,
  getPrizesRemainingForGame
}
