import * as React from 'react'
import { GameLobby } from './game-lobby-ui'
import { GameLobbyModel } from '@Cms/model-types/game-lobby-model'
import { useAppSelector } from '@Client/hooks/redux-hooks'
import { GameModel, GameType } from '@Cms/model-types/game-model'
import { GameRibbonModel } from '@Cms/model-types/game-ribbon-model'

export type GameLobbyContainerProps = {
  lobby: GameLobbyModel
  isILottery: boolean
}

const DEFAULT_FREE_RIBBON: GameRibbonModel = {
  text: 'Free!',
  backgroundColor: 'green',
  textColor: 'white'
}

export const GameLobbyContainer = (props: GameLobbyContainerProps) => {
  const freeGameIdObjs = useAppSelector(
    (state) => state.iLottery.balance?.FreeGames
  )
  const freeGameIds = new Set(freeGameIdObjs?.map((obj) => obj.GameID) ?? [])
  const iLotteryInstantGames = useAppSelector(
    (state) => state.iLottery.instantGamesById
  )

  const { lobbyGames, freeGames } = React.useMemo(() => {
    const lobbyGames: GameModel[] = []
    const freeGames: GameModel[] = []

    props.lobby.games.forEach((game) => {
      const iLotteryId = game.configuration.iLottery?.iLotteryId
      const numericILotteryId = iLotteryId ? Number(iLotteryId) : undefined
      const isFreeGame = numericILotteryId && freeGameIds.has(numericILotteryId)
      if (isFreeGame) {
        freeGames.push(game)
        return
      }
      if (game.type !== GameType.online) {
        lobbyGames.push(game)
        return
      }

      const isInFramework = Boolean(
        numericILotteryId && iLotteryInstantGames[numericILotteryId]
      )
      if (!isInFramework) {
        return
      }
      lobbyGames.push(game)
    })
    return { lobbyGames, freeGames }
  }, [freeGameIds, props.lobby.games, iLotteryInstantGames])

  const freeGamesWithRibbon: GameModel[] = React.useMemo(
    () =>
      freeGames.map((game) => ({
        ...game,
        meta: {
          ...game.meta,
          ribbon: DEFAULT_FREE_RIBBON
        }
      })),
    [freeGames]
  )

  const lobby = {
    ...props.lobby,
    games: lobbyGames
  }

  return (
    <GameLobby
      lobby={lobby}
      isILottery={props.isILottery}
      freeGames={props.isILottery ? freeGamesWithRibbon : undefined}
    />
  )
}
