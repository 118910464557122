import * as React from 'react'
import { SignInButton } from '../button-sign-in/sign-in-button'
import { RegisterButton } from '../button-register/register-button'
import './styles.scss'

const ROOT_CLASS = 'auth-buttons'

export const AuthButtons = () => (
  <div className={ROOT_CLASS}>
    <SignInButton />
    <RegisterButton />
  </div>
)
