import * as React from 'react'
import { ControlledCollapsible } from '../collapsible/controlled-collapsible-ui'
import { OutsideClickHandler } from '../outside-click-handler/outside-click-handler'
import { Button } from '../button/button'
import { getBlockClass } from '../component-helpers'
import { PlayerIcon } from '@Client/icons/player-icon'
import { AuthButtons } from '../auth-buttons/auth-buttons-ui'
import { DotNotification } from '../dot-notification/dot-notification'
import { useKeyDown } from '@Client/hooks/use-keydown/use-keydown'
import { AccessibleKey } from '@Client/constants'
import './styles.scss'

type AccountMenuAuthenticatedProps = {
  isAuthenticated: true
  playerFirstName: string
  accountBalance: string
}

type AccountMenuUnauthenticatedProps = {
  isAuthenticated: false
}

export type AccountMenuProps = {
  onMyAccountClick: () => void
  onMyBonusesClick: () => void
  onDepositClick: () => void
  onWithdrawClick: () => void
  onSignOutClick: () => void
  hasUnviewedBonuses: boolean
} & (AccountMenuAuthenticatedProps | AccountMenuUnauthenticatedProps)

const ROOT_CLASS = 'account-menu'
const ITEM_CLASS = getBlockClass(ROOT_CLASS, 'item')
const BUTTON_CLASS = getBlockClass(ROOT_CLASS, 'button')
const SIGN_OUT_CLASS = getBlockClass(ROOT_CLASS, 'sign-out-button')

export const AccountMenu = (props: AccountMenuProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const closeMenu = () => setIsOpen(false)

  useKeyDown({ key: AccessibleKey.escape, action: closeMenu })

  return (
    <OutsideClickHandler onOutsideClick={closeMenu}>
      <ControlledCollapsible
        className={ROOT_CLASS}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        hideChevron
        header={
          <div className={getBlockClass(ROOT_CLASS, 'player-container')}>
            <div className={getBlockClass(ROOT_CLASS, 'player-notification')}>
              <DotNotification isActive={props.hasUnviewedBonuses} />
            </div>
            <PlayerIcon />
          </div>
        }
        content={
          props.isAuthenticated ? (
            <div className={getBlockClass(ROOT_CLASS, 'menu-container')}>
              <div className={getBlockClass(ROOT_CLASS, 'player-info')}>
                <span className={getBlockClass(ROOT_CLASS, 'player-name')}>
                  {`HI, ${props.playerFirstName.toUpperCase()}`}
                </span>
                <span className={getBlockClass(ROOT_CLASS, 'player-balance')}>
                  {`Balance: ${props.accountBalance}`}
                </span>
              </div>
              <ul
                className={getBlockClass(ROOT_CLASS, 'list')}
                onClickCapture={closeMenu}
              >
                <li className={ITEM_CLASS}>
                  <Button
                    className={BUTTON_CLASS}
                    onClick={props.onMyAccountClick}
                    ariaLabel='My account'
                  >
                    MY ACCOUNT
                  </Button>
                </li>
                <li className={ITEM_CLASS}>
                  <Button
                    className={BUTTON_CLASS}
                    onClick={props.onMyBonusesClick}
                    ariaLabel='My bonuses'
                  >
                    MY BONUSES
                  </Button>
                </li>
                <li className={ITEM_CLASS}>
                  <Button
                    className={BUTTON_CLASS}
                    onClick={props.onDepositClick}
                    ariaLabel='Make a Deposit'
                  >
                    MAKE A DEPOSIT
                  </Button>
                </li>
                <li className={ITEM_CLASS}>
                  <Button
                    className={BUTTON_CLASS}
                    onClick={props.onWithdrawClick}
                    ariaLabel='Withdraw funds'
                  >
                    WITHDRAW FUNDS
                  </Button>
                </li>
                <li className={ITEM_CLASS}>
                  <Button
                    className={SIGN_OUT_CLASS}
                    onClick={props.onSignOutClick}
                    ariaLabel='Sign out'
                  >
                    Sign Out
                  </Button>
                </li>
              </ul>
            </div>
          ) : (
            <div
              className={getBlockClass(ROOT_CLASS, 'auth-buttons-container')}
              onClickCapture={closeMenu}
            >
              <AuthButtons />
            </div>
          )
        }
      />
    </OutsideClickHandler>
  )
}
