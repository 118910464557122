import * as React from 'react'
import {
  FilterTagModel,
  FilterTagField
} from '@Cms/model-types/filter-tag-model'
import { FilterButton, FilterButtonProps } from '../button-filter/filter-button'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { useResizeObserver } from '@Client/hooks/use-resize-observer/use-resize-observer'
import { InputSelect } from '../input-select/input-select-ui'
import { InputLabel } from '../input-label/input-label-ui'
import { PRICE_FILTER_CATEGORY_IDENTIFIER } from './constants'
import { GamePrice } from '@Cms/model-types/game-model'
import { ModalTriggerButton } from '../modal/modal-trigger-button'

export type FilterTagWithState = FilterTagModel &
  Pick<FilterButtonProps, 'isActive' | 'categoryIdentifier'>

export type GameLobbyQueryFilterProps = {
  spotlightFilters: FilterTagWithState[]
  priceOptions: GamePrice[]
  onFilterToggle: (
    categoryIdentifier: string,
    identifier: string,
    isActive: boolean
  ) => void
  onClickMoreFilters: () => void
  activePrice?: string
  setActivePrice: (price: string) => void
  isILottery: boolean
}

const ROOT_CLASS = 'game-lobby-query-filters'
const FILTER_BY_TEXT_CLASS = getBlockClass(ROOT_CLASS, 'filter-by-text')
const MORE_FILTERS_BUTTON_CLASS = getBlockClass(
  ROOT_CLASS,
  'more-filters-button'
)
const TAG_COLUMN_GAP_PX = 16

export const GameLobbyQueryFilters = (props: GameLobbyQueryFilterProps) => {
  const rootRef = React.useRef<HTMLDivElement>(null)
  const spotlightFiltersRef = React.useRef<HTMLDivElement>(null)
  const moreFiltersRef = React.useRef<HTMLButtonElement>(null)

  const setDisplayedFilters = () => {
    const spotlightDiv = spotlightFiltersRef.current
    const moreFiltersButton = moreFiltersRef.current
    if (!spotlightDiv || !moreFiltersButton) {
      return
    }
    const { width: containerWidth } = spotlightDiv.getBoundingClientRect()
    let runningWidth = 0
    for (const tag of spotlightDiv.children) {
      const tagWidth = tag.getBoundingClientRect().width + TAG_COLUMN_GAP_PX
      if (runningWidth + tagWidth < containerWidth) {
        runningWidth += tagWidth
        tag.setAttribute('style', 'visibility: visible;')
      } else {
        tag.removeAttribute('style')
      }
    }
  }

  useResizeObserver(setDisplayedFilters, rootRef)

  return (
    <div className={ROOT_CLASS} ref={rootRef}>
      <div className={getBlockClass(ROOT_CLASS, 'spotlight-filters-container')}>
        <span
          className={joinClasses([
            FILTER_BY_TEXT_CLASS,
            getModifierClass(
              FILTER_BY_TEXT_CLASS,
              'is-ilottery',
              props.isILottery
            )
          ])}
        >
          Filter by:
        </span>
        <div
          className={getBlockClass(ROOT_CLASS, 'spotlight-filters')}
          ref={spotlightFiltersRef}
        >
          {props.spotlightFilters.map((filterTag) => (
            <FilterButton
              key={filterTag[FilterTagField.identifier]}
              onToggle={props.onFilterToggle}
              identifier={filterTag[FilterTagField.identifier]}
              categoryIdentifier={filterTag.categoryIdentifier}
              isActive={filterTag.isActive}
              ariaLabel={`Toggle ${filterTag[FilterTagField.displayText]} filter`}
              isTransparent={props.isILottery}
            >
              {filterTag[FilterTagField.displayText]}
            </FilterButton>
          ))}
        </div>
      </div>
      <ModalTriggerButton
        className={joinClasses([
          MORE_FILTERS_BUTTON_CLASS,
          getModifierClass(
            MORE_FILTERS_BUTTON_CLASS,
            'is-transparent',
            props.isILottery
          )
        ])}
      >
        More Filters
      </ModalTriggerButton>
      <div
        className={joinClasses([
          getBlockClass(ROOT_CLASS, 'price-select'),
          getModifierClass(
            getBlockClass(ROOT_CLASS, 'price-select'),
            'is-ilottery',
            props.isILottery
          )
        ])}
      >
        <InputLabel for={PRICE_FILTER_CATEGORY_IDENTIFIER}>Price</InputLabel>
        <InputSelect
          value={props.activePrice}
          placeholder={'Select...'}
          options={props.priceOptions.map((priceOpt) => ({
            value: String(priceOpt.priceInCents),
            displayText: priceOpt.priceFormatted
          }))}
          onChange={props.setActivePrice}
          id={PRICE_FILTER_CATEGORY_IDENTIFIER}
          isBorderless
        />
      </div>
    </div>
  )
}
