import * as React from 'react'
import './styles.scss'

const ROOT_CLASS = 'draft-kings-locations-icon'

export const DraftKingsLocationsIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      id='Layer_1'
      viewBox='-100 -100 800 800'
    >
      <path
        className={ROOT_CLASS}
        d='M402.58,212.48c0-.65-.04-1.28-.05-1.93-.02-.83-.03-1.65-.06-2.47-.06-1.36-.15-2.71-.26-4.06,0-.09-.01-.18-.02-.27-2.09-24.84-13.01-47.17-29.65-63.8-18.57-18.57-44.22-30.05-72.54-30.05s-54,11.49-72.57,30.09c-16.65,16.68-27.58,39.1-29.62,64.01-.02.23-.03.47-.05.7-.09,1.15-.17,2.3-.21,3.46-.04.96-.06,1.92-.07,2.89,0,.47-.04.94-.04,1.42,0,.05,0,.11,0,.16,0,.06,0,.11,0,.17,0,.65.04,1.28.05,1.93.02.83.03,1.65.06,2.47.06,1.36.15,2.7.26,4.05,0,.09.01.19.02.28,2.09,24.84,13.01,47.17,29.65,63.8,14.33,14.33,32.9,24.41,53.64,28.28-.25,1.23-.38,2.5-.38,3.8v153.4c0,10.64,8.63,19.27,19.27,19.27s19.27-8.63,19.27-19.27v-153.4c0-1.3-.13-2.57-.38-3.8,20.77-3.87,39.35-13.96,53.69-28.33,16.65-16.68,27.58-39.1,29.62-64.01.02-.23.03-.47.05-.7.09-1.15.17-2.3.21-3.46.04-.96.06-1.92.07-2.89,0-.47.04-.94.04-1.42,0-.05,0-.11,0-.16s0-.11,0-.17ZM258.67,191.5c0-9.02,7.31-16.33,16.33-16.33s16.33,7.31,16.33,16.33-7.31,16.33-16.33,16.33-16.33-7.31-16.33-16.33Z'
      />
    </svg>
  )
}
