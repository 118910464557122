import * as React from 'react'

export const MapPin = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>Map Pin</title>
    <g id='icon/map-pin'>
      <path
        id='Vector'
        d='M19.9998 3.33325C15.4048 3.33325 11.6665 7.07159 11.6665 11.6666C11.6665 15.8359 14.747 19.2897 18.7498 19.8958V35.4166C18.7475 35.5822 18.7781 35.7467 18.8399 35.9004C18.9016 36.0541 18.9933 36.194 19.1096 36.312C19.2259 36.4299 19.3645 36.5236 19.5174 36.5875C19.6702 36.6515 19.8342 36.6844 19.9998 36.6844C20.1655 36.6844 20.3295 36.6515 20.4823 36.5875C20.6351 36.5236 20.7737 36.4299 20.89 36.312C21.0063 36.194 21.098 36.0541 21.1598 35.9004C21.2216 35.7467 21.2522 35.5822 21.2498 35.4166V19.8958C25.2526 19.2897 28.3332 15.8359 28.3332 11.6666C28.3332 7.07159 24.5948 3.33325 19.9998 3.33325ZM17.9165 8.33325C18.6065 8.33325 19.1665 8.89325 19.1665 9.58325C19.1665 10.2733 18.6065 10.8333 17.9165 10.8333C17.2265 10.8333 16.6665 10.2733 16.6665 9.58325C16.6665 8.89325 17.2265 8.33325 17.9165 8.33325Z'
        fill='#D64127'
      />
    </g>
  </svg>
)
