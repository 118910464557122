import * as React from 'react'
import { getBlockClass } from '@Client/components/component-helpers'
import './styles.scss'

const ROOT_CLASS = 'draw-games-icon'

export const DrawGamesIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='_ÎÓÈ_2'
    data-name='—ÎÓÈ_2'
    viewBox='0 0 585.11 578.01'
  >
    <defs></defs>
    <circle
      className={getBlockClass(ROOT_CLASS, 'cls-2')}
      cx='289'
      cy='289'
      r='289'
    />
    <g>
      <g>
        <path
          className={getBlockClass(ROOT_CLASS, 'cls-1')}
          d='M374.23,493.09h-172.37c-16.48,0-29.84-13.36-29.84-29.84V114.75c0-16.48,13.36-29.84,29.84-29.84h174.3c16.48,0,29.84,13.36,29.84,29.84v347.82'
        />
        <line
          className={getBlockClass(ROOT_CLASS, 'cls-1')}
          x1='172.02'
          y1='440.45'
          x2='405.99'
          y2='440.45'
        />
        <path
          className={getBlockClass(ROOT_CLASS, 'cls-1')}
          d='M221.39,84.91v21.88c0,11.65,9.44,21.09,21.09,21.09h93.05c11.65,0,21.09-9.44,21.09-21.09v-21.88'
        />
      </g>
      <path
        className={getBlockClass(ROOT_CLASS, 'cls-1')}
        d='M376.15,493.09c16.48,0,29.84-13.36,29.84-29.84'
      />
      <g>
        <circle
          className={getBlockClass(ROOT_CLASS, 'cls-1')}
          cx='287.37'
          cy='289'
          r='85.15'
          transform='translate(-120.19 287.85) rotate(-45)'
        />
        <path
          className={getBlockClass(ROOT_CLASS, 'cls-1')}
          d='M353.73,274.64c2.88,12.54,2.16,26.05-2.83,38.96'
        />
        <polygon
          className={getBlockClass(ROOT_CLASS, 'cls-1')}
          points='320 304.76 320 326.13 262.93 326.13 262.93 304.76 281.48 304.76 281.48 270.64 262.93 275.83 262.93 254.47 301.45 243.68 301.45 304.76 320 304.76'
        />
      </g>
    </g>
  </svg>
)
