import * as React from 'react'
import { WinnersForm } from '../winners-form/winners-form-ui'
import { WinnersTable } from '../winners-table/winners-table-ui'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { InputMultiSelectOption } from '../input-multi-select/input-multi-select-ui'
import { Winner } from '../winners-table/winners-cell'
import { WinnersFormPayload } from '../winners-form/winners-form-ui'
import { WinnersSortBy } from '../winners-table/winners-table-header'
import { ErrorMessage } from '../error-message/error-message-ui'
import { useIsDesktop } from '@Client/hooks/use-media-query/use-media-query'
import { getBlockClass } from '../component-helpers'
import './styles.scss'

export type WinnersProps = {
  isLoading: boolean
  error?: string
  winners?: Winner[]
  payload: WinnersFormPayload
  gameOptions: InputMultiSelectOption[]
  totalResults: number
  page: number
  totalPages: number
  onPageChange: (page: number) => void
  onWinnersFormSubmit: (payload: WinnersFormPayload) => void
  toggleSortDirection: (sortBy: WinnersSortBy) => void
}

const ROOT_CLASS = 'winners'

export const Winners = (props: WinnersProps) => {
  const isDesktop = useIsDesktop()
  return (
    <div className={ROOT_CLASS}>
      <WinnersForm
        gameOptions={props.gameOptions}
        totalResults={props.totalResults}
        payload={props.payload}
        onWinnersFormSubmit={props.onWinnersFormSubmit}
      />
      {props.isLoading || props.error ? (
        <div className={getBlockClass(ROOT_CLASS, 'state-container')}>
          {props.isLoading ? (
            <LoadingSpinner />
          ) : (
            <ErrorMessage message={props.error} />
          )}
        </div>
      ) : props.winners ? (
        <WinnersTable
          isDesktop={isDesktop}
          winners={props.winners}
          page={props.page}
          totalPages={props.totalPages}
          onPageChange={props.onPageChange}
          toggleSortDirection={props.toggleSortDirection}
        />
      ) : null}
    </div>
  )
}
