import * as React from 'react'
import { zip } from 'lodash'
import { CsvTableModel } from '@Cms/model-types/csv-table-model'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { Nullable } from '@Util/utility-types'
import './styles.scss'

export type TableProps = {
  table: CsvTableModel
  className?: string
}

type TableRowProps = {
  headers: Nullable<string>[]
  row: Nullable<string>[]
  rowNumber: number
  type: 'header' | 'body'
  hasFooter: boolean
}

const ROOT_CLASS = 'table'
const CONTAINER_CLASS = getBlockClass(ROOT_CLASS, 'container')
const BODY_CLASS = getBlockClass(ROOT_CLASS, 'body')
const CELL_CLASS = getBlockClass(ROOT_CLASS, 'cell')
const FOOTER_ROW_CLASS = getBlockClass(ROOT_CLASS, 'footer-row')
const ROW_GROUP_CLASS = getBlockClass(ROOT_CLASS, 'row-group')
const STACKED_CELL_CLASS = getModifierClass(CELL_CLASS, 'stacked', true)
const STACKED_FOOTER_ROW = getBlockClass(ROOT_CLASS, 'footer-stacked-row')
const MULTI_CELL_CLASS = getModifierClass(CELL_CLASS, 'multi', true)

const stackedCellClasses = joinClasses([CELL_CLASS, STACKED_CELL_CLASS])
const multiCellClasses = joinClasses([CELL_CLASS, MULTI_CELL_CLASS])

const getCellData = (cell?: Nullable<string>) =>
  cell
    ? cell.split('\n').map((row) => (
        <span key={row} className={getBlockClass(ROOT_CLASS, 'cell-text')}>
          {row}
        </span>
      ))
    : '\u00A0'

const TableRow = (props: TableRowProps) => {
  const ROW_CLASS = getBlockClass(ROOT_CLASS, `${props.type}-row`)
  return (
    <tr
      className={joinClasses([
        ROW_CLASS,
        getModifierClass(ROW_CLASS, 'no-footer', !props.hasFooter)
      ])}
    >
      {props.row.map((cell, i) =>
        props.type === 'body' ? (
          <td
            key={`${props.rowNumber}-${cell}-${i}`}
            className={multiCellClasses}
          >
            {getCellData(cell)}
          </td>
        ) : (
          <th
            key={`${props.rowNumber}-${cell}-${i}`}
            className={multiCellClasses}
          >
            {getCellData(cell)}
          </th>
        )
      )}
    </tr>
  )
}

const TableFooter = (props: TableProps) => {
  const numHeaders = props.table.headers.length
  const isSingleFooter = props.table.footer.length === 1
  const zippedData = zip(props.table.headers, props.table.footer)
  return (
    <tfoot className={getBlockClass(ROOT_CLASS, 'footer')}>
      <tr className={FOOTER_ROW_CLASS}>
        {props.table.footer.map((cell, i) => (
          <td
            key={`${cell}-${i}`}
            className={joinClasses([
              CELL_CLASS,
              getModifierClass(CELL_CLASS, 'single-footer', isSingleFooter)
            ])}
            colSpan={isSingleFooter ? numHeaders : 1}
          >
            {getCellData(cell)}
          </td>
        ))}
      </tr>
      {isSingleFooter ? (
        <tr className={STACKED_FOOTER_ROW}>
          <td
            className={joinClasses([
              CELL_CLASS,
              getModifierClass(CELL_CLASS, 'single-footer', isSingleFooter)
            ])}
            colSpan={isSingleFooter ? numHeaders : 1}
          >
            {getCellData(props.table.footer[0])}
          </td>
        </tr>
      ) : (
        zippedData.map((labeledCell, i) => (
          <tr
            key={`${labeledCell.join('')}-${i}`}
            className={STACKED_FOOTER_ROW}
          >
            {labeledCell.map((cell, i) => (
              <td
                key={`${cell}-${i}`}
                className={joinClasses([
                  stackedCellClasses,
                  getModifierClass(CELL_CLASS, 'stacked-header', i === 0)
                ])}
              >
                {getCellData(cell)}
              </td>
            ))}
          </tr>
        ))
      )}
    </tfoot>
  )
}

export const Table = (props: TableProps) => {
  const hasFooter = props.table.footer.length >= 1

  return (
    <div className={joinClasses([CONTAINER_CLASS, props.className])}>
      <table className={ROOT_CLASS}>
        <thead>
          <TableRow
            headers={props.table.headers}
            row={props.table.headers}
            type='header'
            hasFooter={hasFooter}
            rowNumber={0}
          />
        </thead>
        {/* Desktop "normal" columns */}
        <tbody className={BODY_CLASS}>
          {props.table.rows.map((row, i) => (
            <TableRow
              key={`${row.join('')}-${i}-desktop`}
              headers={props.table.headers}
              row={row}
              type='body'
              hasFooter={hasFooter}
              rowNumber={i + 1}
            />
          ))}
        </tbody>
        {/* Mobile stacked columns */}
        {props.table.rows.map((row, i) => {
          const zippedData = zip(props.table.headers, row)
          return (
            <tbody
              key={`${row.join('')}-${i}-mobile`}
              className={joinClasses([
                ROW_GROUP_CLASS,
                getModifierClass(
                  ROW_GROUP_CLASS,
                  'no-footer',
                  props.table.footer.length === 0
                )
              ])}
            >
              {zippedData.map((labeledCell) => (
                <tr key={`${labeledCell.join('')}-${i}`}>
                  {labeledCell.map((cell, i) => (
                    <td
                      key={`${cell}-${i}`}
                      className={joinClasses([
                        stackedCellClasses,
                        getModifierClass(CELL_CLASS, 'stacked-header', i === 0)
                      ])}
                    >
                      {getCellData(cell)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )
        })}
        {hasFooter ? <TableFooter {...props} /> : null}
      </table>
    </div>
  )
}
