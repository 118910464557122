import { Nullable } from '@Util/utility-types'
import {
  KenoWinnerBySpot,
  PrizePayoutsByIdentifier
} from './your-winnings-prize-map'
import { CurrencyUtilities } from '@Util/currency-utilities'

export const DEFAULT_YOUR_WINNINGS_TEXT = 'Try again!'
export const YOUR_WINNINGS_JACKPOT_TEXT = 'Jackpot!'
export const YOUR_WINNINGS_WINNER_TEXT = 'Winner!'

export const getYourWinningsDisplay = (
  gameIdentifier: string,
  winningNumbersBase: number[],
  yourNumbersBase: Nullable<number>[],
  winningNumbersExtra?: number,
  yourNumbersExtra?: Nullable<number>
) => {
  const gameMatrix = PrizePayoutsByIdentifier[gameIdentifier]
  if (!gameMatrix) {
    return ''
  }
  const numMatches = winningNumbersBase.reduce(
    (acc, currentWinningNumber) =>
      yourNumbersBase.includes(currentWinningNumber) ? acc + 1 : acc,
    0
  )
  const extraMatches = yourNumbersExtra === winningNumbersExtra
  if (numMatches === 0 && !extraMatches) {
    return DEFAULT_YOUR_WINNINGS_TEXT
  }
  const matchingPayout = gameMatrix[numMatches]
  const payout = extraMatches ? matchingPayout.withExtra : matchingPayout.base
  if (typeof payout === 'string') {
    return payout
  }
  return payout === 0
    ? DEFAULT_YOUR_WINNINGS_TEXT
    : CurrencyUtilities.numericFormat(payout)
}

const isValidSpot = (key: number): key is keyof typeof KenoWinnerBySpot => {
  return key in KenoWinnerBySpot
}

export const getKenoWinnerDisplay = (
  yourNumbers: Nullable<number>[],
  winningNumbers: number[]
) => {
  const spot = yourNumbers.filter((num) => num !== null).length
  if (spot === 0) return ''
  if (!isValidSpot(spot)) {
    throw new Error(`Invalid spot value: ${spot}`)
  }

  const yourSet = new Set(yourNumbers)
  const numMatches = winningNumbers.filter((num) => yourSet.has(num)).length
  return KenoWinnerBySpot[spot].includes(numMatches)
    ? YOUR_WINNINGS_WINNER_TEXT
    : DEFAULT_YOUR_WINNINGS_TEXT
}
