import * as React from 'react'
import { LoaderFunction, useLoaderData } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@Client/hooks/redux-hooks'
import { DrawGameApi, GetAllDrawGamesResponse } from '@Client/api/draw-games'
import { getObjectKeys } from '@Util/object-utilities'
import { HomePage } from './home-page-ui'
import { jackpotActions } from '@Client/reducers/jackpots/jackpots-reducer'
import { useRecurringAction } from '@Client/hooks/use-recurring-action/use-recurring-action'
import { drawTimesActions } from '@Client/reducers/draw-times/draw-times-reducer'
import { Api } from '@Client/api'
import { PageContentResponse } from '@Server/controllers/content/content-controller'
import { useCmsPreview } from '@Client/hooks/use-cms-preview/use-cms-preview'
import { Optional } from '@Util/utility-types'

type HomePageLoaderData = {
  drawGames?: GetAllDrawGamesResponse
  cmsPageContent?: PageContentResponse
}

const NINETY_SECONDS_MS = 90 * 1000

export const homePageLoader: LoaderFunction =
  async (): Promise<HomePageLoaderData> => {
    let drawGames: Optional<GetAllDrawGamesResponse>
    let cmsPageContent: Optional<PageContentResponse>
    try {
      drawGames = await DrawGameApi.getDrawGames()
    } catch (error) {
      console.error('Error fetching draw games', error)
    }
    try {
      cmsPageContent = await Api.callServer('api/v1/content/page', {
        path: '/'
      })
    } catch (error) {
      console.error('Error fetching home page CMS content', error)
    }
    return {
      drawGames,
      cmsPageContent
    }
  }

const jackpotOrderByIdentifier: Record<string, number> = {
  powerball: -3,
  'mega-millions': -2,
  megabucks: -1
}

export const HomePageContainer = () => {
  useCmsPreview()
  const dispatch = useAppDispatch()
  const homePageData = useLoaderData() as HomePageLoaderData
  const jackpotState = useAppSelector((state) => state.jackpots)
  const jackpotGameIds = getObjectKeys(jackpotState.jackpotsByGameId)
  const jackpotGroups = useAppSelector((state) => state.jackpots.groupsById)
  const progressiveJackpotsById = useAppSelector(
    (state) => state.iLottery.progressiveJackpotsById
  )
  const iLotteryJackpotGameIds = useAppSelector(
    (state) => state.iLottery.progressiveJackpotGameIds
  )
  const instantGamesById = useAppSelector(
    (state) => state.iLottery.instantGamesById
  )

  const iLotteryJackpotIds = iLotteryJackpotGameIds.filter((id) => {
    const game = instantGamesById[id]
    if (!game) {
      return false
    }
    const progressiveIds = game.PJPs
    if (!progressiveIds || !progressiveIds.length) {
      return false
    }
    const progressiveId = progressiveIds[0]
    const progressiveValueInDollarsStr =
      progressiveJackpotsById[progressiveId]?.PJPBalanceCurrent

    if (!progressiveValueInDollarsStr) {
      return false
    }
    return true
  })

  const cmsGames = useAppSelector(
    (state) => state.cmsGames.gamesByDataServiceId
  )
  const hasCmsGames = getObjectKeys(cmsGames).length > 0

  const requestCurrentJackpots = () => {
    if (jackpotState?.isLoading) {
      return
    }
    dispatch(jackpotActions.fetchJackpotsRequest())
  }

  useRecurringAction(() => {
    dispatch(drawTimesActions.fetchAllDrawTimes())
    requestCurrentJackpots()
  }, NINETY_SECONDS_MS)

  const validDrawGames = homePageData.drawGames?.filter(
    (drawGame) => !!cmsGames[drawGame.id]
  )
  const validJackpotGameIds = hasCmsGames
    ? jackpotGameIds
        .filter(
          (jackpotGameId) =>
            cmsGames[jackpotGameId] || jackpotGroups[jackpotGameId]
        )
        .sort((jackpotGameIdA, jackpotGameIdB) => {
          const gameIdentifierA = cmsGames[jackpotGameIdA]?.identifier
          const gameIdentifierB = cmsGames[jackpotGameIdB]?.identifier
          if (gameIdentifierA && gameIdentifierB) {
            const predefinedOrderA = jackpotOrderByIdentifier[gameIdentifierA]
            const predefinedOrderB = jackpotOrderByIdentifier[gameIdentifierB]
            return predefinedOrderA - predefinedOrderB
          }
          if (gameIdentifierA) {
            return -1
          } else if (gameIdentifierB) {
            return 1
          }
          try {
            return jackpotGroups[jackpotGameIdA].name.localeCompare(
              jackpotGroups[jackpotGameIdB].name
            )
          } catch (error) {
            return 1
          }
        })
    : []

  return (
    <HomePage
      drawGames={validDrawGames ?? []}
      jackpotGameIds={validJackpotGameIds}
      iLotteryJackpotIds={iLotteryJackpotIds}
      genericContent={
        homePageData.cmsPageContent?.status === 'success'
          ? homePageData.cmsPageContent.data.content
          : undefined
      }
    />
  )
}
