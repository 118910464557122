import { Optional } from '@Util/utility-types'
import { PastResultsFieldId } from './constants'
import { dateUtil } from '@Util/date-util'

export type PastResultFormData = {
  [PastResultsFieldId.GAME_IDENTIFIER]: string
  [PastResultsFieldId.DRAW_DATE]?: string
  [PastResultsFieldId.DRAW_NUMBER_START]?: number
  [PastResultsFieldId.DRAW_NUMBER_END]?: number
  [PastResultsFieldId.YOUR_NUMBERS]?: string
  [PastResultsFieldId.YOUR_NUMBERS_SPECIAL_BALL]?: number
  [PastResultsFieldId.TIME_SPAN]?: string
}

export type ValidGameRange = {
  min: number
  max: number
  specialMin?: number
  specialMax?: number
}

const MAX_API_DRAW_NUMBER_RANGE = 999

const getNumericValue = (value?: string | number): Optional<number> =>
  typeof value === 'string' ? parseInt(value) : value

export const validatePastResultsField = (
  formData: PastResultFormData,
  fieldId: PastResultsFieldId,
  fieldValue?: string | number
): Optional<string> => {
  const numericStartValue = getNumericValue(
    formData[PastResultsFieldId.DRAW_NUMBER_START]
  )
  const numericEndValue = getNumericValue(
    formData[PastResultsFieldId.DRAW_NUMBER_END]
  )

  switch (fieldId) {
    case PastResultsFieldId.DRAW_NUMBER_START:
      if (!numericStartValue && !numericEndValue) {
        break
      } else if (!numericStartValue && numericEndValue) {
        return 'Start number is required with end number'
      } else if (
        numericStartValue &&
        numericEndValue &&
        numericStartValue >= numericEndValue
      ) {
        return 'Start number must be less than end number'
      } else if (
        numericStartValue &&
        numericEndValue &&
        numericEndValue - numericStartValue > MAX_API_DRAW_NUMBER_RANGE
      ) {
        return `Draw number range must be less than ${MAX_API_DRAW_NUMBER_RANGE}`
      }
      break
    case PastResultsFieldId.DRAW_NUMBER_END: {
      if (!fieldValue && !numericStartValue) {
        break
      } else if (!fieldValue && numericStartValue) {
        return 'End number is required with start number'
      } else if (
        numericStartValue &&
        numericEndValue &&
        numericEndValue <= numericStartValue
      ) {
        return 'End number must be greater than start number'
      } else if (
        numericStartValue &&
        numericEndValue &&
        numericEndValue - numericStartValue > MAX_API_DRAW_NUMBER_RANGE
      ) {
        return `Draw number range must be less than ${MAX_API_DRAW_NUMBER_RANGE}`
      }
      break
    }
    case PastResultsFieldId.DRAW_DATE:
      {
        const today = dateUtil()
        if (fieldValue && today.isBefore(dateUtil(fieldValue), 'date')) {
          return 'Draw date must be in the past'
        }
      }
      break
    default:
      return
  }
}

export const getPastResultsDefaultValue = (
  fieldId: PastResultsFieldId
): Optional<string | number> => {
  switch (fieldId) {
    case PastResultsFieldId.DRAW_DATE:
      return new Date().toISOString()
    default:
      return undefined
  }
}

export const getValidRangeByGameIdentifier = (
  gameIdentifier: string
): ValidGameRange => {
  switch (gameIdentifier) {
    case 'powerball':
      return { min: 1, max: 69, specialMin: 1, specialMax: 26 }
    case 'mega-millions':
      return { min: 1, max: 70, specialMin: 1, specialMax: 25 }
    case 'megabucks':
      return { min: 1, max: 41, specialMin: 1, specialMax: 6 }
    case 'lucky-for-life':
      return { min: 1, max: 48, specialMin: 1, specialMax: 18 }
    case 'gimme-five':
      return { min: 1, max: 39 }
    case 'pick-three':
    case 'pick-four':
      return { min: 0, max: 9 }
    case 'keno':
      return { min: 1, max: 80 }
    default:
      throw new Error(`Invalid gameIdentifier: ${gameIdentifier}`)
  }
}
