import * as React from 'react'
import { useAppSelector } from '@Client/hooks/redux-hooks'
import { AccountDepositWidget } from './account-deposit-widget-ui'
import { NeoGamesService } from '@Client/services/pam/ng-service'
import { iLotterySelectors } from '@Client/reducers/ilottery/ilottery-reducer'

export const AccountDepositWidgetContainer = () => {
  const isAuthenticated = useAppSelector(
    (state) => state.iLottery.isPlayerAuthenticated
  )
  const session = useAppSelector((state) => state.iLottery.session)
  const playerBalanceDisplay = useAppSelector((state) =>
    iLotterySelectors.selectPlayerBalanceDisplay(state.iLottery)
  )
  if (!isAuthenticated || !session) {
    return <AccountDepositWidget isAuthenticated={false} />
  }
  return (
    <AccountDepositWidget
      isAuthenticated={true}
      firstName={session.PlayerName}
      accountBalanceDisplay={playerBalanceDisplay}
      onDepositClick={() => NeoGamesService.showDeposit()}
    />
  )
}
