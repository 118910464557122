import * as React from 'react'
import { GameModel } from '@Cms/model-types/game-model'
import { CsvTableModel } from '@Cms/model-types/csv-table-model'
import { GameDetailsPageStructure } from '../game-details-page/game-details-page-structure'
import { getBlockClass } from '../component-helpers'
import { AsyncImage } from '../async-image/async-image-ui'
import { GameRibbon } from '../game-ribbon/game-ribbon'
import { GameRulesContainer } from '../game-rules/game-rules-container'
import { GamePrice } from '../game-price/game-price-ui'
import './styles.scss'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { ErrorMessage } from '../error-message/error-message-ui'
import { Table } from '../table/table-ui'

export type FastPlayJackpotProps = {
  topPrizeType: 'PROGRESSIVE'
  jackpotValueFormatted: string
}

export type FastPlaceFixedPrizeProps = {
  topPrizeType: 'FIXED'
}

export type FastPlayGameDetailsPageProps = {
  game: GameModel
  topPrizeDisplay?: string
  jackpotUpdateTimeFormatted?: string
  isPrizeTableLoading: boolean
  prizeTableError?: string
  prizeTableData?: CsvTableModel
} & (FastPlayJackpotProps | FastPlaceFixedPrizeProps)

const ROOT_CLASS = 'fast-play-game-details-page'
const VALUE_AND_LABEL_CLASS = getBlockClass(ROOT_CLASS, 'value-and-label')

const FastPlayGameDetailsPageInfo = (props: FastPlayGameDetailsPageProps) => {
  return (
    <div className={getBlockClass(ROOT_CLASS, 'info')}>
      <div className={getBlockClass(ROOT_CLASS, 'image-container')}>
        <AsyncImage
          src={props.game.imageUrl}
          alt={`${props.game.name} image`}
          className={getBlockClass(ROOT_CLASS, 'image')}
        />
        {props.game.meta.ribbon && <GameRibbon {...props.game.meta.ribbon} />}
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'content')}>
        <div className={getBlockClass(ROOT_CLASS, 'horizontal')}>
          <GamePrice
            className={getBlockClass(ROOT_CLASS, 'price')}
            value={props.game.price.priceFormatted}
          />
          <h1 className={getBlockClass(ROOT_CLASS, 'game-name')}>
            {props.game.name}
          </h1>
        </div>
        {props.topPrizeType === 'PROGRESSIVE' && (
          <>
            <div className={VALUE_AND_LABEL_CLASS}>
              <span className={getBlockClass(ROOT_CLASS, 'inline-label')}>
                Current Jackpot:
              </span>
              <span className={getBlockClass(ROOT_CLASS, 'jackpot-value')}>
                {props.jackpotValueFormatted}
              </span>
            </div>
            {props.jackpotUpdateTimeFormatted && (
              <div className={VALUE_AND_LABEL_CLASS}>
                <span className={getBlockClass(ROOT_CLASS, 'inline-label')}>
                  As of:
                </span>
                <span className={getBlockClass(ROOT_CLASS, 'time-value')}>
                  {props.jackpotUpdateTimeFormatted}
                </span>
              </div>
            )}
          </>
        )}
        {props.topPrizeDisplay && (
          <div className={VALUE_AND_LABEL_CLASS}>
            <span className={getBlockClass(ROOT_CLASS, 'inline-label')}>
              Top Prize:
            </span>
            <span className={getBlockClass(ROOT_CLASS, 'prize-display-value')}>
              {props.topPrizeDisplay}
            </span>
          </div>
        )}
        {props.game.odds && (
          <div className={VALUE_AND_LABEL_CLASS}>
            <span className={getBlockClass(ROOT_CLASS, 'inline-label')}>
              Overall Odds
            </span>
            <span className={getBlockClass(ROOT_CLASS, 'odds-number')}>
              1 in {props.game.odds}
            </span>
          </div>
        )}
      </div>
      <GameRulesContainer game={props.game} />
    </div>
  )
}

const FastPlayGameDetailsPageResults = (
  props: FastPlayGameDetailsPageProps
) => {
  return (
    <div className={getBlockClass(ROOT_CLASS, 'results')}>
      <h2 className={getBlockClass(ROOT_CLASS, 'odds-header')}>
        Odds Breakdown
      </h2>
      {props.isPrizeTableLoading ? (
        <LoadingSpinner />
      ) : props.prizeTableError ? (
        <ErrorMessage />
      ) : props.prizeTableData ? (
        <>
          <Table table={props.prizeTableData} />
          <span className={getBlockClass(ROOT_CLASS, 'disclaimer')}>
            * Estimated
          </span>
        </>
      ) : null}
    </div>
  )
}

export const FastPlayGameDetailsPage = (
  props: FastPlayGameDetailsPageProps
) => {
  return (
    <GameDetailsPageStructure
      gameInfo={<FastPlayGameDetailsPageInfo {...props} />}
      gameResults={<FastPlayGameDetailsPageResults {...props} />}
      className={ROOT_CLASS}
    />
  )
}
