import { CurrentJackpotState } from '@Client/reducers/jackpots/jackpot-reducer-types'

const gameDescriptions: Map<string, string> = new Map([
  ['Granite State', '$1, $2 and $5 - Rolling Jackpot'],
  ['High Roller', '$5, $10 and $20 - Rolling Jackpot'],
  ['Jackpot 7s', '$2, $5 and $10 - Rolling Jackpot'],
  ['Live Free and Win', 'Rolling Jackpot'],
  ['Twenty 20s', 'Rolling Jackpot']
])

export const getCurrentJackpot = (gameJackpotState?: CurrentJackpotState) => {
  switch (gameJackpotState?.type) {
    case 'draw':
      return (
        gameJackpotState.progressiveJackpot.jackpot?.jackpotAmountInCents ?? 0
      )
    case 'rapid':
      return gameJackpotState.jackpot?.jackpotAmountInCents ?? 0
    case 'fastPlay':
      return gameJackpotState.jackpot?.jackpot?.jackpotAmountInCents ?? 0
    default:
      return undefined
  }
}

export const getNextDrawingDate = (gameJackpotState: CurrentJackpotState) => {
  switch (gameJackpotState.type) {
    case 'draw':
      return new Date(gameJackpotState.when.drawTime)
    default:
      return undefined
  }
}

export const getPromoText = (
  gameJackpotState: CurrentJackpotState,
  numGames: number,
  gameName: string
) => {
  // Switch to be built out by game type at a later date
  switch (gameJackpotState.type) {
    case 'draw':
      return 'Never miss a big jackpot with subscription play!'
    case 'fastPlay':
      if (gameDescriptions.has(gameName)) {
        return gameDescriptions.get(gameName)
      }
      if (numGames === 1) return numGames.toString() + ' game in the series'
      else return numGames.toString() + ' games in the series'
    case 'rapid':
      return 'Must be 18+ to play'
    default:
      return 'Never miss a big jackpot with subscription play!'
  }
}

export const getStatus = (gameJackpotState: CurrentJackpotState) => {
  switch (gameJackpotState.type) {
    case 'draw':
      return gameJackpotState.progressiveJackpot.status
    case 'fastPlay':
      return gameJackpotState.jackpot?.jackpotStatus
    case 'rapid':
      return gameJackpotState.status
    default:
      return 'NO_JACKPOT'
  }
}
