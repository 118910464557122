import * as React from 'react'
import { Api } from '@Client/api'
import { DataResponse } from '@Server/controllers/controller-helpers'
import { useAppSelector } from '../redux-hooks'

export const useFetchServer = <ResponseData>(
  path: string,
  params: Record<string, any> = {},
  method: RequestInit['method'] = 'GET'
) => {
  const cmsPreview = useAppSelector(
    (state) => state.operations.cmsPreviewActive
  )
  const [data, setData] = React.useState<ResponseData>()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>()

  const fetchData = async (
    paramsOverride: Record<string, any> = {},
    headers?: Record<string, string>,
    body?: BodyInit
  ) => {
    try {
      const fetchParams = { ...params, ...paramsOverride, cmsPreview }
      setIsLoading(true)
      setError(undefined)
      const response: DataResponse<ResponseData> = await Api.callServer(
        path,
        fetchParams,
        method,
        headers,
        body
      )
      setData(response.data)
      return response.data
    } catch (error) {
      setError(typeof error === 'string' ? error : Api.DEFAULT_ERROR)
      console.error('Error making fetch:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const resetData = () => {
    setData(undefined)
    setError(undefined)
  }

  return {
    data,
    error,
    isLoading,
    fetchData,
    resetData
  }
}

export const usePaginatedFetch = <
  ResponseData extends Record<string, any>,
  PaginatedData
>(
  path: string,
  initialParams: Record<string, any>,
  method: RequestInit['method'] = 'GET',
  paginatedDataKey: keyof ResponseData
) => {
  const {
    data: latestFetchData,
    error,
    isLoading,
    fetchData
  } = useFetchServer<ResponseData>(path, initialParams, method)
  const [paginatedData, setPaginatedData] = React.useState<PaginatedData[]>([])

  const fetchMoreData = async (paginatedParams: Record<string, any>) => {
    try {
      const newData = await fetchData(paginatedParams)
      if (newData) {
        setPaginatedData([...paginatedData, ...newData[paginatedDataKey]])
      }
    } catch (error) {
      console.error('Error fetching paginated data', error)
    }
  }

  return {
    isLoading,
    error,
    paginatedData,
    latestFetchData,
    fetchMoreData
  }
}
