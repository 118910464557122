import * as React from 'react'

export const MapPinSmall = () => (
  <svg
    width='16'
    height='17'
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>Map Pin</title>
    <g id='icon/map-pin'>
      <path
        id='Vector'
        d='M7.99984 1.83325C6.16184 1.83325 4.6665 3.32859 4.6665 5.16659C4.6665 6.8343 5.89872 8.21582 7.49984 8.45825V14.6666C7.4989 14.7328 7.51114 14.7986 7.53585 14.8601C7.56055 14.9216 7.59724 14.9776 7.64376 15.0247C7.69028 15.0719 7.74572 15.1094 7.80684 15.135C7.86797 15.1605 7.93357 15.1737 7.99984 15.1737C8.0661 15.1737 8.1317 15.1605 8.19283 15.135C8.25396 15.1094 8.30939 15.0719 8.35592 15.0247C8.40244 14.9776 8.43912 14.9216 8.46383 14.8601C8.48853 14.7986 8.50077 14.7328 8.49984 14.6666V8.45825C10.101 8.21582 11.3332 6.8343 11.3332 5.16659C11.3332 3.32859 9.83784 1.83325 7.99984 1.83325ZM7.1665 3.83325C7.4425 3.83325 7.6665 4.05725 7.6665 4.33325C7.6665 4.60925 7.4425 4.83325 7.1665 4.83325C6.8905 4.83325 6.6665 4.60925 6.6665 4.33325C6.6665 4.05725 6.8905 3.83325 7.1665 3.83325Z'
        fill='#2B3037'
      />
    </g>
  </svg>
)
