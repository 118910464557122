import { DrawTimeResponse } from '@interaction-gaming/game-data-service-browser-client'
import { DrawDateState } from './draw-times-reducer-types'
import { DateFormat, dateUtil } from '@Util/date-util'
import { serializeDates } from '@Util/object-utilities'
import { drawTimesActions } from './draw-times-reducer'

export const getDrawDateStateFromDrawTimes = (
  drawTimeResponse: DrawTimeResponse[]
): DrawDateState[] => {
  const drawTimesByDate: Record<string, DrawDateState> = {}
  drawTimeResponse.forEach((drawDateInfo) => {
    const drawTimeStr = dateUtil(drawDateInfo.drawTime).format(
      DateFormat.monthDayYear
    )
    if (!drawTimesByDate[drawTimeStr]) {
      drawTimesByDate[drawTimeStr] = {
        drawDate: drawTimeStr,
        drawings: []
      }
    }
    drawTimesByDate[drawTimeStr].drawings.push(serializeDates(drawDateInfo))
  })
  return Object.values(drawTimesByDate)
}

export const fetchDrawTimesWindow = (
  gameDataServiceId: string,
  endDate: Date = new Date(),
  monthsToFetch = 2
) => {
  const startDateEpoch =
    dateUtil(endDate).subtract(monthsToFetch, 'months').unix() * 1000
  return drawTimesActions.fetchDrawTimes({
    gameId: gameDataServiceId,
    startDateEpoch: startDateEpoch,
    endDateEpoch: endDate.getTime()
  })
}
