import * as React from 'react'
import { AsyncImage } from '../async-image/async-image-ui'
import { getBlockClass } from '../component-helpers'
import { GeneralLink } from '../link/link-ui'
import { AppStore } from '@Client/constants'
import './styles.scss'

export type KenoAppCardProps = {
  image: string
}

const ROOT_CLASS = 'keno-app-card'

export const KenoAppCard = (props: KenoAppCardProps) => {
  return (
    <div className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'horizontal')}>
        <AsyncImage
          src={props.image}
          alt={'Keno Image Small'}
          className={getBlockClass(ROOT_CLASS, 'image')}
        />
        <div className={getBlockClass(ROOT_CLASS, 'vertical')}>
          <span className={getBlockClass(ROOT_CLASS, 'title')}>
            Watch Keno 603 Anywhere!
          </span>
          <span className={getBlockClass(ROOT_CLASS, 'text')}>
            Use the NH Lottery Mobile App to watch the Keno 603 game
          </span>
        </div>
      </div>
      <div className={getBlockClass(ROOT_CLASS, 'horizontal')}>
        <GeneralLink
          dest={AppStore.APP_STORE_LINK}
          className={getBlockClass(ROOT_CLASS, 'app-store-link')}
          ariaLabel='App Store Link'
        >
          <AsyncImage
            src={AppStore.APP_STORE_BADGE}
            className={getBlockClass(ROOT_CLASS, 'badge')}
            alt={'Download on the App Store'}
          />
        </GeneralLink>
        <GeneralLink
          dest={AppStore.PLAY_STORE_LINK}
          className={getBlockClass(ROOT_CLASS, 'play-store-link')}
          ariaLabel='Play Store Link'
        >
          <AsyncImage
            src={AppStore.PLAY_STORE_BADGE}
            className={getBlockClass(ROOT_CLASS, 'badge')}
            alt={'Get it on Google Play'}
          />
        </GeneralLink>
      </div>
    </div>
  )
}
