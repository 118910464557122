import * as React from 'react'

type OutsideClickHandlerProps = {
  onOutsideClick: () => any
}

const ROOT_CLASS = 'outside-click-handler'

export const OutsideClickHandler = (
  props: React.PropsWithChildren<OutsideClickHandlerProps>
) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        event.target instanceof Node &&
        !wrapperRef.current?.contains(event.target)
      ) {
        props.onOutsideClick()
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [wrapperRef, props.onOutsideClick])

  return (
    <div className={ROOT_CLASS} ref={wrapperRef}>
      {props.children}
    </div>
  )
}
