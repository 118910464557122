import * as React from 'react'
import './styles.scss'

const ROOT_CLASS = 'scratch-games-icon'

export const ScratchGamesIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='_ÎÓÈ_2'
    data-name='—ÎÓÈ_2'
    viewBox='0 0 578.01 578.01'
  >
    <defs></defs>
    <g>
      <path
        className={ROOT_CLASS}
        d='M180.98,303.88c-.74-6.41,1.8-12.77,6.76-16.91l36.9-30.83c6.15-5.14,14.85-5.91,21.81-1.93l21.11,12.06c9.86,5.63,14.82,17.1,12.16,28.15l-8.15,33.9-28.05,24.97c-6.62,5.9-10.07,14.57-9.3,23.4l.42,4.82c.51,5.91,6.54,9.7,12.09,7.58l43.88-16.71c3.23-1.23,6.17-3.14,8.61-5.6l79.87-76.96,15.02-4.39'
      />
      <path
        className={ROOT_CLASS}
        d='M372.57,162.47l-150.18,16.7c-9.4,1.05-18.03,5.71-24.05,13l-57.64,69.76c-7.21,8.73-9.96,20.32-7.76,29.85l8.92,38.78'
      />
      <path
        className={ROOT_CLASS}
        d='M248.01,388.61c-6.99,19.91-25.95,34.19-48.25,34.19-28.24,0-51.12-22.89-51.12-51.12s22.89-51.12,51.12-51.12c20.77,0,38.65,12.39,46.65,30.17'
      />
      <ellipse
        className={ROOT_CLASS}
        cx='194.67'
        cy='371.67'
        rx='20.3'
        ry='23.61'
      />
    </g>
    <polygon
      className={ROOT_CLASS}
      points='372.57 162.47 398.85 312.52 445.81 302.82 420.65 155.21 372.57 162.47'
    />
    <circle
      className={ROOT_CLASS}
      cx='417.63'
      cy='278.84'
      r='4.22'
      transform='translate(50.3 621.23) rotate(-76.72)'
    />
    <line
      className={ROOT_CLASS}
      x1='271.57'
      y1='328.32'
      x2='302.69'
      y2='285.49'
    />
  </svg>
)
