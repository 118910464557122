import * as React from 'react'
import './styles.scss'

export type InputErrorProps = {
  error?: string
  id: string
}

const ROOT_CLASS = 'input-error'

export const getInputErrorId = (id: string) => `${id}-error`

export const InputError = (props: InputErrorProps) =>
  props.error ? (
    <span className={ROOT_CLASS} id={getInputErrorId(props.id)} role='alert'>
      {props.error}
    </span>
  ) : null
