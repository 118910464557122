import * as React from 'react'
import './styles.scss'

export const LinkedinIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
    viewBox='0 0 374.32 323.97'
    width={40}
    height={40}
  >
    <title>LinkedIn Icon</title>
    <g>
      <g>
        <path
          className='linkedin-icon'
          d='M58.84,118.12h53.03v178.08h-53.03V118.12ZM85.03,27.78c17.68,0,32.08,14.4,32.08,32.08s-14.4,32.08-32.08,32.08-31.43-14.4-31.43-32.08,13.75-32.08,31.43-32.08h0ZM143.3,118.12h52.38v24.88c11.13-16.37,29.46-26.84,50.41-26.84h14.4c33.39,0,60.23,27.5,60.23,60.23v119.81h-52.38v-16.37h0v-79.87h0c-.65-20.3-14.4-36.01-34.7-36.01h0c-20.3,0-37.32,15.71-37.97,36.01h0v96.24h-52.38V118.12Z'
        />
      </g>
    </g>
  </svg>
)
