import * as React from 'react'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import './styles.scss'

export type GameDetailsPageStructureProps = {
  gameInfo: React.ReactNode
  gameResults: React.ReactNode
  className?: string
}

const ROOT_CLASS = 'game-details-page-structure'
const INFO_BLOCK = 'info'
const RESULTS_BLOCK = 'results'

export const GameDetailsPageStructure = (
  props: GameDetailsPageStructureProps
) => {
  return (
    <section
      className={joinClasses([
        ROOT_CLASS,
        props.className,
        getModifierClass(ROOT_CLASS, 'no-results', !props.gameResults)
      ])}
    >
      {props.gameInfo && (
        <div
          className={joinClasses([
            getBlockClass(ROOT_CLASS, INFO_BLOCK),
            props.className ? getBlockClass(props.className, 'info') : undefined
          ])}
        >
          {props.gameInfo}
        </div>
      )}
      {props.gameResults && (
        <div
          className={joinClasses([
            getBlockClass(ROOT_CLASS, RESULTS_BLOCK),
            props.className
              ? getBlockClass(props.className, 'results')
              : undefined
          ])}
        >
          {props.gameResults}
        </div>
      )}
    </section>
  )
}
