import { getObjectKeys } from './object-utilities'

const getQueryParamStr = (params: Record<string, any>) => {
  const searchParams = new URLSearchParams()
  getObjectKeys(params).forEach((param) => {
    const value = params[param]
    if (value !== null && value !== undefined) {
      searchParams.set(param, value)
    }
  })
  return searchParams.toString()
}

function isValidUrl(urlOrPath: string) {
  try {
    new URL(urlOrPath)
    return true
  } catch (err) {
    return false
  }
}

export const UrlUtil = {
  getQueryParamStr,
  isValidUrl
}
