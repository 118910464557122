import * as React from 'react'
import { DrawGameResponse } from '@Client/api/draw-games'
import { WinningNumbersCard } from './winning-numbers-card-ui'
import { useWinningNumbers } from '@Client/hooks/use-winning-numbers/use-winning-numbers'

export type WinningNumbersCardContainerProps = {
  drawGameMetadata: DrawGameResponse
}

export const WinningNumbersCardContainer = (
  props: WinningNumbersCardContainerProps
) => {
  const winningNumbersProps = useWinningNumbers({
    drawGameServicesId: props.drawGameMetadata.id
  })

  if (!winningNumbersProps.cmsGame) {
    return null
  }

  return (
    <WinningNumbersCard
      {...winningNumbersProps}
      gameIdentifier={winningNumbersProps.cmsGame.identifier}
      gameIconSrc={winningNumbersProps.cmsGame.imageUrl ?? ''}
      gameName={winningNumbersProps.cmsGame.name}
      multiplierName={winningNumbersProps.multiplierName}
      branding={winningNumbersProps.cmsGame?.branding}
      hasProgressiveJackpots={props.drawGameMetadata.hasProgressiveJackpots}
    />
  )
}
