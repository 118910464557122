import * as React from 'react'
import { getBlockClass } from '../component-helpers'
import { GeneralLink } from '../link/link-ui'
import { formatPhoneNumber } from '@Util/string-utilities'
import { Button } from '../button/button'

const ROOT_CLASS = 'footer-contact-block'
const LINK_CLASS = getBlockClass(ROOT_CLASS, 'link')
const GROUP_CLASS = getBlockClass(ROOT_CLASS, 'group')
const GROUP_TITLE_CLASS = getBlockClass(ROOT_CLASS, 'group-title')

const HQ_PHONE = '6032713391'
const ILOTTERY_PHONE = '8662046954'
const MOBILE_APP_PHONE = '6032717612'

export type FooterContactBlockProps = {
  onILotteryHelpClick: () => void
}

export const FooterContactBlock = (props: FooterContactBlockProps) => (
  <div className={ROOT_CLASS}>
    <h2 className={getBlockClass(ROOT_CLASS, 'title')}>Contact</h2>
    <div className={GROUP_CLASS}>
      <span className={GROUP_TITLE_CLASS}>NH Lottery HQ Support</span>
      <GeneralLink className={LINK_CLASS} dest={`tel:${HQ_PHONE}}`}>
        {formatPhoneNumber(HQ_PHONE)}
      </GeneralLink>
      <GeneralLink
        className={LINK_CLASS}
        dest='mailto:webmaster@lottery.nh.gov'
      >
        Email Us
      </GeneralLink>
    </div>
    <div className={GROUP_CLASS}>
      <span className={GROUP_TITLE_CLASS}>iLottery Customer Support</span>
      <GeneralLink className={LINK_CLASS} dest={`tel:${ILOTTERY_PHONE}`}>
        {`${formatPhoneNumber(ILOTTERY_PHONE)} (Ext. 6)`}
      </GeneralLink>
      <GeneralLink className={LINK_CLASS} dest='mailto:support@nhlottery.com'>
        Email Us
      </GeneralLink>
    </div>
    <div className={GROUP_CLASS}>
      <span className={GROUP_TITLE_CLASS}>NH Lottery Mobile App Support</span>
      <GeneralLink className={LINK_CLASS} dest={`tel:${MOBILE_APP_PHONE}`}>
        {formatPhoneNumber(MOBILE_APP_PHONE)}
      </GeneralLink>
    </div>
    <div className={GROUP_CLASS}>
      <Button
        onClick={props.onILotteryHelpClick}
        ariaLabel='iLottery Chat'
        className={getBlockClass(ROOT_CLASS, 'chat-ilottery')}
      >
        iLottery Chat
      </Button>
    </div>
  </div>
)
