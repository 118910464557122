import * as React from 'react'
import './styles.scss'

const ROOT_CLASS = 'keno-icon'

export const KenoIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='_ÎÓÈ_2'
    data-name='—ÎÓÈ_2'
    viewBox='0 0 578.01 578.01'
  >
    <defs></defs>
    <g>
      <path
        className={ROOT_CLASS}
        d='M231.15,301.86c0,50.28-40.76,91.04-91.04,91.04s-91.04-40.76-91.04-91.04,40.76-91.04,91.04-91.04,91.04,40.76,91.04,91.04'
      />
      <path
        className={ROOT_CLASS}
        d='M211.06,286.5c3.07,13.41,2.31,27.85-3.02,41.65'
      />
    </g>
    <g>
      <path
        className={ROOT_CLASS}
        d='M198.94,225.55c0-50.28,40.76-91.04,91.04-91.04s91.04,40.76,91.04,91.04-40.76,91.04-91.04,91.04c-22.43,0-42.96-8.11-58.83-21.56'
      />
      <path
        className={ROOT_CLASS}
        d='M360.93,210.19c3.07,13.41,2.31,27.85-3.02,41.65'
      />
    </g>
    <g>
      <path
        className={ROOT_CLASS}
        d='M381.02,235.29c13.41-7.77,28.98-12.22,45.59-12.22,50.28,0,91.04,40.76,91.04,91.04s-40.76,91.04-91.04,91.04-91.04-40.76-91.04-91.04c0-2.28.08-4.54.25-6.78'
      />
      <path
        className={ROOT_CLASS}
        d='M497.56,298.75c3.07,13.41,2.31,27.85-3.02,41.65'
      />
    </g>
    <path
      className={ROOT_CLASS}
      d='M289.39,275.93c-27.25,0-43.98-22.41-43.98-49.93s16.74-49.93,43.98-49.93,44.26,22.27,44.26,49.93-17.29,49.93-44.26,49.93ZM289.39,195.15c-12.59,0-20.06,13.56-20.06,30.84s7.47,30.84,20.06,30.84,20.33-13.69,20.33-30.84-7.88-30.84-20.33-30.84Z'
    />
    <path
      className={ROOT_CLASS}
      d='M117.55,293.91h.41c4.59-5.95,13.65-10,21.89-10,17.3,0,34.06,11.62,34.06,31.62s-15.14,32.97-38.25,32.97c-25.68,0-41.08-18.92-41.08-46.89s13.92-50.68,43.92-50.68c12.43,0,25,4.19,33.65,13.38l-12.57,12.97c-5.41-5.14-12.97-7.03-19.73-7.03-9.19,0-21.49,5.81-22.3,23.65ZM118.77,314.99c0,7.3,5.41,14.73,15.95,14.73,8.78,0,15.95-6.08,15.95-14.19,0-8.51-6.62-14.33-15.81-14.33-10.95,0-16.08,6.89-16.08,13.78Z'
    />
    <path
      className={ROOT_CLASS}
      d='M445.29,333.66c0-8.49-7.28-13.75-15.64-13.75-2.83,0-7.68.4-12.4,2.43l-8.22-11.73c8.49-6.74,16.72-13.48,25.07-20.09h-36.53v-18.87h67.81v13.35c-8.49,7.01-16.99,13.88-25.34,20.9,14.96.94,28.44,12.4,28.44,28.04,0,18.33-14.83,33.57-41.12,33.57-13.35,0-26.29-3.24-35.45-11.73l10.78-14.42c11.05,6.47,18.6,7.28,23.73,7.28,11.59,0,18.87-7.28,18.87-14.96Z'
    />
  </svg>
)
