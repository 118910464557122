import {
  InstantGame,
  PrizesRemaining
} from '@interaction-gaming/game-data-service-browser-client'
import { InputMultiSelectOption } from '../input-multi-select/input-multi-select-ui'
import { getObjectKeys, SerializedDates } from '@Util/object-utilities'
import { InstantGamesByDataServiceId } from '@Client/reducers/instant-games/instant-game-reducer-types'
import {
  PrizesRemainingFormData,
  PrizesRemainingFormField
} from '../prizes-remaining-form/constants'
import { PrizesByGameId } from './prizes-remaining-ui'

export const getInstantGameOptions = (
  instantGames: SerializedDates<InstantGame[]>,
  prizesByGameId: PrizesByGameId
): InputMultiSelectOption[] => {
  const filteredGames =
    getObjectKeys(prizesByGameId).length > 0
      ? instantGames.filter((game) => prizesByGameId[game.id] !== undefined)
      : instantGames

  return filteredGames
    .map((game) => ({
      value: game.id,
      label: game.name
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

const sortByGameOrder = (a: PrizesRemaining, b: PrizesRemaining) =>
  a.sortOrder > b.sortOrder ? 1 : -1

export const getValidPrizes = (
  instantGamesByDataServiceId: InstantGamesByDataServiceId,
  prizesByGameId: PrizesByGameId,
  formPayload: PrizesRemainingFormData
): PrizesByGameId => {
  const prizeAmountsInDollars = formPayload[
    PrizesRemainingFormField.prizeAmounts
  ]
    ? formPayload[PrizesRemainingFormField.prizeAmounts].map((value) =>
        JSON.parse(value)
      )
    : []
  const ticketPricesInCents =
    formPayload[PrizesRemainingFormField.ticketCosts]?.map(Number) ?? []
  const selectedGames = formPayload[PrizesRemainingFormField.games] ?? []
  const validPrizesByGameId: PrizesByGameId = {}
  getObjectKeys(prizesByGameId).forEach((gameId) => {
    // check if game is valid
    const gamePrizes = prizesByGameId[gameId].sort(sortByGameOrder)
    const topPrize = gamePrizes[0]
    const gamePricesInCents =
      instantGamesByDataServiceId[gameId]?.ticketCostOptionsInCents ?? []
    const matchesGames = !selectedGames.length || selectedGames.includes(gameId)
    const matchesTicketPrice =
      !ticketPricesInCents.length ||
      ticketPricesInCents.some((price) => gamePricesInCents.includes(price))
    if (!matchesGames || !matchesTicketPrice) {
      return
    }

    // get valid prizes for game
    const validPrizes = gamePrizes.filter((prize) => {
      const matchesPrizeAmount =
        !prizeAmountsInDollars.length ||
        prizeAmountsInDollars.some(
          (prizeAmountGroup) =>
            prize.prizeAmountInDollars >= prizeAmountGroup.min &&
            prize.prizeAmountInDollars <= prizeAmountGroup.max
        )

      const matchesTopPrize =
        !formPayload[PrizesRemainingFormField.topPrizeOnly] ||
        (topPrize.remainingCount > 0 && topPrize.id === prize.id)

      return matchesPrizeAmount && matchesTopPrize
    })
    if (validPrizes.length) {
      validPrizesByGameId[gameId] = validPrizes.sort(sortByGameOrder)
    }
  })
  return validPrizesByGameId
}
