import * as React from 'react'
import { ImageBanner } from '../banner-image/banner-image-ui'
import { TypedBannerModel } from '@Cms/model-types/banner-carousel-model'
import { CmsModel } from '@Cms/model-types/cms-models'
import { CompositeBanner } from '../banner-composite/composite-banner-ui'
import { SpotlightCarousel } from '../carousel-spotlight/spotlight-carousel-ui'

export type BannerCarouselProps = {
  name: string
  isLoading: boolean
  banners?: TypedBannerModel[]
  autoRotateInterval?: number
}

export const BannerCarousel = (props: BannerCarouselProps) => {
  if (props.banners && !props.banners?.length) {
    return null
  }

  return (
    <SpotlightCarousel
      name={props.name}
      isLoading={props.isLoading}
      autoRotateInterval={props.autoRotateInterval}
      items={props.banners?.map((banner, i) =>
        banner.contentType === CmsModel.imageBanner ? (
          <ImageBanner
            imageBanner={banner.data}
            key={`${banner.data.name}-image-${i}`}
          />
        ) : (
          <CompositeBanner
            banner={banner.data}
            key={`${banner.data.name}-composite-${i}`}
          />
        )
      )}
    />
  )
}
