import * as React from 'react'
import Lottie from 'lottie-react'
import coinsAnimation from './coins.json'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import './styles.scss'

export type PendingAnimationProps = {
  useAsterisk: boolean
  alignment: 'center' | 'left' | 'right'
}

const ROOT_CLASS = 'pending-animation'
const TEXT_CLASS = getBlockClass(ROOT_CLASS, 'text')

export const PendingAnimation = (props: PendingAnimationProps) => (
  <div
    className={joinClasses([
      ROOT_CLASS,
      getModifierClass(ROOT_CLASS, props.alignment, true)
    ])}
  >
    <div className={getBlockClass(ROOT_CLASS, 'animation')}>
      <Lottie animationData={coinsAnimation} loop />
    </div>
    <span
      className={joinClasses([
        TEXT_CLASS,
        getModifierClass(TEXT_CLASS, 'with-asterisk', props.useAsterisk)
      ])}
    >
      Results Pending
    </span>
  </div>
)
