import * as React from 'react'
import { useAppDispatch, useAppSelector } from '@Client/hooks/redux-hooks'
import { ClientConfig } from '@Client/config'
import { StagingEnvironment } from '@Server/constants'
import { CmsPreview } from './cms-preview-ui'
import { operationsActions } from '@Client/reducers/operations/operations-reducer'

export const CmsPreviewContainer = () => {
  const dispatch = useAppDispatch()
  const { cmsPreviewActive } = useAppSelector((state) => state.operations)
  const onChange = (isActive: boolean) => {
    dispatch(operationsActions.setCmsPreviewActive(isActive))
  }

  return ClientConfig.STAGING_ENV === StagingEnvironment.prod ? null : (
    <CmsPreview onChange={onChange} isActive={cmsPreviewActive} />
  )
}
