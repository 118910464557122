export type YourNumbersPayoutInDollars =
  | number
  | 'JACKPOT!'
  | '$25,000.00/year'
  | '$1,000/day for life'

export type NumberMatchPayoutInDollars = {
  base: YourNumbersPayoutInDollars
  withExtra: YourNumbersPayoutInDollars
}

export type KenoWinnerBySpot = Record<number, Array<number>>

export type PrizePayoutsByGameIdentifier = Record<
  string,
  Record<number, NumberMatchPayoutInDollars>
>

export const KenoWinnerBySpot = {
  1: [1],
  2: [1, 2],
  3: [2, 3],
  4: [2, 3, 4],
  5: [3, 4, 5],
  6: [3, 4, 5, 6],
  7: [3, 4, 5, 6, 7],
  8: [4, 5, 6, 7, 8],
  9: [4, 5, 6, 7, 8, 9],
  10: [0, 5, 6, 7, 8, 9, 10],
  11: [0, 5, 6, 7, 8, 9, 10, 11],
  12: [0, 6, 7, 8, 9, 10, 11, 12]
}

export const PrizePayoutsByIdentifier: PrizePayoutsByGameIdentifier = {
  powerball: {
    0: {
      base: 0,
      withExtra: 4
    },
    1: {
      base: 0,
      withExtra: 4
    },
    2: {
      base: 0,
      withExtra: 7
    },
    3: {
      base: 7,
      withExtra: 100
    },
    4: {
      base: 100,
      withExtra: 50000
    },
    5: {
      base: 1000000,
      withExtra: 'JACKPOT!'
    }
  },
  megabucks: {
    0: {
      base: 0,
      withExtra: 0
    },
    1: {
      base: 0,
      withExtra: 2
    },
    2: {
      base: 2,
      withExtra: 5
    },
    3: {
      base: 7,
      withExtra: 25
    },
    4: {
      base: 150,
      withExtra: 1300
    },
    5: {
      base: 30000,
      withExtra: 'JACKPOT!'
    }
  },
  'mega-millions': {
    0: {
      base: 0,
      withExtra: 2
    },
    1: {
      base: 0,
      withExtra: 4
    },
    2: {
      base: 0,
      withExtra: 10
    },
    3: {
      base: 10,
      withExtra: 200
    },
    4: {
      base: 500,
      withExtra: 10000
    },
    5: {
      base: 1000000,
      withExtra: 'JACKPOT!'
    }
  },
  'lucky-for-life': {
    0: {
      base: 0,
      withExtra: 4
    },
    1: {
      base: 0,
      withExtra: 6
    },
    2: {
      base: 3,
      withExtra: 25
    },
    3: {
      base: 20,
      withExtra: 150
    },
    4: {
      base: 200,
      withExtra: 5000
    },
    5: {
      base: '$25,000.00/year',
      withExtra: '$1,000/day for life'
    }
  },
  'gimme-five': {
    0: {
      base: 0,
      withExtra: 0
    },
    1: {
      base: 0,
      withExtra: 0
    },
    2: {
      base: 2,
      withExtra: 2
    },
    3: {
      base: 7,
      withExtra: 7
    },
    4: {
      base: 250,
      withExtra: 250
    },
    5: {
      base: 100000,
      withExtra: 100000
    }
  }
}
