import { isServerRender } from '@Util/ssr-util'
import { Optional } from '@Util/utility-types'

const write = <T>(key: string, value: T): void => {
  if (isServerRender()) {
    return
  }
  const serializedData = JSON.stringify(value)
  window.localStorage.setItem(key, serializedData)
}

const read = <T>(key: string): Optional<T> => {
  if (isServerRender()) {
    throw new Error('Cannot read from local storage on the server')
  }
  const item = window.localStorage.getItem(key)
  if (!item) {
    return undefined
  }
  try {
    return JSON.parse(item) as T
  } catch (e) {
    return undefined
  }
}

const clear = (key: string): void => {
  if (isServerRender()) {
    return
  }
  window.localStorage.removeItem(key)
}

export const LocalStorageAdapter = {
  write,
  read,
  clear
}
