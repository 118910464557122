import {
  ProgressiveEInstantsApi,
  ProgressiveEInstantsResponse
} from '@Client/api/progressive-e-instants'
import { RootState } from '@Client/reducers'
import { iLotteryActions } from '@Client/reducers/ilottery/ilottery-reducer'
import {
  ILotteryActiveGame,
  LaunchGameAction,
  UpdateILotterySessionAction
} from '@Client/reducers/ilottery/ilottery-reducer-types'
import { NeoGamesService } from '@Client/services/pam/ng-service'
import { NeoGamesHasActiveBonus } from '@Client/services/pam/ng-types'
import { Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { put, select, takeEvery } from 'redux-saga/effects'

export function* setupILotterySaga(dispatch: Dispatch) {
  yield takeEvery(iLotteryActions.logout.type, handleLogout)
  yield takeEvery(
    iLotteryActions.requestUpdatedGames.type,
    handleRequestUpdatedGames
  )
  yield takeEvery(
    iLotteryActions.requestSessionDetails.type,
    handleRequestSessionDetails
  )
  yield takeEvery(
    iLotteryActions.setSession.type,
    handleSessionUpdate,
    dispatch
  )
  yield takeEvery(
    iLotteryActions.requestLoadInstantGame.type,
    handleLaunchInstantGame
  )
  yield takeEvery(
    iLotteryActions.openDrawGameWidget.type,
    handleOpenDrawGameWidget
  )
  yield takeEvery(
    iLotteryActions.openSubscriptionGameWidget.type,
    handleOpenSubscriptionGameWidget
  )
  yield takeEvery(iLotteryActions.closeGame.type, handleCloseGame)
  yield takeEvery(
    iLotteryActions.requestProgressiveJackpots.type,
    handleRequestProgressiveJackpots
  )
  yield takeEvery(iLotteryActions.requestBalance.type, handleRequestBalance)
  yield takeEvery(
    iLotteryActions.requestShoppingCartStatus.type,
    handleRequestShoppingCartStatus
  )
  yield takeEvery(iLotteryActions.showChat.type, handleShowChat)
  yield takeEvery(iLotteryActions.showContactUs.type, handleShowContactUs)
  yield takeEvery(iLotteryActions.registrationAborted.type, handleAuthAborted)
  yield takeEvery(iLotteryActions.loginAborted.type, handleAuthAborted)
}

function handleCloseGame() {
  NeoGamesService.closeAllGames()
}

function handleLaunchInstantGame(action: PayloadAction<LaunchGameAction>) {
  NeoGamesService.loadGame({
    ...action.payload
  })
}

function handleLogout() {
  NeoGamesService.logout()
}

function handleOpenDrawGameWidget(action: PayloadAction<number>) {
  NeoGamesService.loadGame({ gameId: action.payload, playMode: 'M' })
}

function handleOpenSubscriptionGameWidget(action: PayloadAction<number>) {
  NeoGamesService.loadGame({
    gameId: action.payload,
    subscription: true,
    playMode: 'M'
  })
}

function handleRequestIsBonusAvailable(dispatch: Dispatch) {
  NeoGamesService.getHasActiveBonus((params: NeoGamesHasActiveBonus) => {
    const isBonusAvailable = !params.IsError && params.BonusAvailable > 0
    dispatch(iLotteryActions.setHasUnviewedBonuses(isBonusAvailable))
  })
}

function* handleSessionUpdate(
  dispatch: Dispatch,
  action: PayloadAction<UpdateILotterySessionAction>
) {
  if (action.payload) {
    yield put(iLotteryActions.requestUpdatedGames())
  }
  handleRequestIsBonusAvailable(dispatch)
}

function* handleRequestProgressiveJackpots() {
  try {
    const progressiveJackpots: ProgressiveEInstantsResponse =
      yield ProgressiveEInstantsApi.getAllGroups()
    yield put(
      iLotteryActions.setProgressiveJackpots({
        progressiveJackpots: progressiveJackpots.ProgressiveJackpots
      })
    )
  } catch (error) {
    console.error('Error fetching progressive jackpots', error)
  }
}

function* handleRequestBalance() {
  const balance = NeoGamesService.getBalance()
  if (balance) {
    yield put(iLotteryActions.setBalance(balance))
  }
}

function* handleRequestShoppingCartStatus() {
  const shoppingCartStatus = NeoGamesService.getShoppingCartStatus()
  const count =
    shoppingCartStatus && !shoppingCartStatus.IsError
      ? shoppingCartStatus.NumberOfItems
      : 0
  yield put(iLotteryActions.setShoppingCartCount(count))
}

function handleRequestUpdatedGames() {
  NeoGamesService.updateGamesList()
}

function* handleRequestSessionDetails() {
  const session = NeoGamesService.getSessionDetails()
  yield put(iLotteryActions.setSession(session))
}

function handleShowChat() {
  NeoGamesService.showChat()
}

function handleShowContactUs() {
  NeoGamesService.showContactUs()
}

function* handleAuthAborted() {
  const activeGame: ILotteryActiveGame = yield select(
    (state: RootState) => state.iLottery.activeGame
  )
  const isPlayerAuthenticated: boolean = yield select(
    (state: RootState) => state.iLottery.isPlayerAuthenticated
  )
  if (
    activeGame.gameId &&
    activeGame.playMode === 'M' &&
    !isPlayerAuthenticated
  ) {
    yield put(iLotteryActions.closeGame())
  }
}
