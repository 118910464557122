import { useState } from 'react'
import { Api } from '@Client/api'

export const useFetchFunction = <ResponseData>() => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<ResponseData>()

  const fetchData = async (fetcher: () => Promise<ResponseData>) => {
    try {
      setIsLoading(true)
      setError(undefined)
      const result = await fetcher()
      setData(result)
      return result
    } catch (error) {
      setError(typeof error === 'string' ? error : Api.DEFAULT_ERROR)
    } finally {
      setIsLoading(false)
    }
  }

  const resetData = () => {
    setData(undefined)
    setIsLoading(false)
    setError(undefined)
  }

  return { isLoading, error, data, fetchData, resetData, setError }
}
