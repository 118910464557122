import * as React from 'react'
import { Button } from '../button/button'
import { getModifierClass, joinClasses } from '../component-helpers'
import './styles.scss'

export type FilterButtonProps = {
  isActive: boolean
  identifier: string
  categoryIdentifier: string
  onToggle: (
    categoryIdentifier: string,
    identifier: string,
    isActive: boolean
  ) => any
  className?: string
  ariaLabel: string
  isTransparent?: boolean
}

const ROOT_CLASS = 'filter-button'

export const FilterButton = React.forwardRef(
  (
    props: React.PropsWithChildren<FilterButtonProps>,
    ref?: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <Button
        ariaLabel={props.ariaLabel}
        onClick={() =>
          props.onToggle(
            props.categoryIdentifier,
            props.identifier,
            !props.isActive
          )
        }
        className={joinClasses([
          ROOT_CLASS,
          getModifierClass(ROOT_CLASS, 'is-active', props.isActive === true),
          getModifierClass(
            ROOT_CLASS,
            'is-transparent',
            props.isTransparent === true
          ),
          props.className
        ])}
        ref={ref}
      >
        {props.children}
      </Button>
    )
  }
)
