import { AssetLink, EntryFieldTypes, EntryLink } from 'contentful'
import { CmsModel } from './cms-models'
import { LinkModel, LinkSkeleton } from './link-model'

export enum ResponsiveImageField {
  name = 'name',
  description = 'description',
  mobileImage = 'mobileImage',
  desktopImage = 'desktopImage',
  link = 'link'
}

export type ResponsiveImageSkeleton = {
  contentTypeId: CmsModel.responsiveImage
  fields: {
    [ResponsiveImageField.name]: EntryFieldTypes.Symbol
    [ResponsiveImageField.description]: EntryFieldTypes.Symbol
    [ResponsiveImageField.mobileImage]: EntryFieldTypes.AssetLink
    [ResponsiveImageField.desktopImage]: EntryFieldTypes.AssetLink
    [ResponsiveImageField.link]: EntryFieldTypes.EntryLink<LinkSkeleton>
  }
}

export type ResponsiveImageAsset = {
  imageUrl: string
}

export type ResponsiveImageModelPublish = {
  name: string
  description: string
  mobileImage: {
    sys: AssetLink
  }
  desktopImage: {
    sys: AssetLink
  }
  link?: {
    sys: EntryLink
  }
}

export type ResponsiveImageModel = {
  description: string
  mobileImage: ResponsiveImageAsset
  desktopImage: ResponsiveImageAsset
  link?: Omit<LinkModel, 'page'>
}

export const ResponsiveImageFields = Object.values(ResponsiveImageField)
