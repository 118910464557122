import * as React from 'react'
import './styles.scss'

export type LoadingSpinnerProps = {
  color?: string
  id?: string
}

const ROOT_CLASS = 'loading-spinner'

export const LoadingSpinner = (props: LoadingSpinnerProps) => (
  <span
    id={props.id}
    className={ROOT_CLASS}
    data-testid={ROOT_CLASS}
    style={props.color ? { borderColor: props.color } : undefined}
  />
)
