import * as React from 'react'
import { Button } from '../button/button'
import { DateFormat, dateUtil } from '@Util/date-util'
import { getModifierClass, joinClasses } from '../component-helpers'
import './styles.scss'

export type CalendarDayProps = {
  isSelected: boolean
  isDisabled: boolean
  isInRange: boolean
  value: Date
  onSelect: (date: Date) => void
  onHover?: (date: Date) => void
}

const ROOT_CLASS = 'calendar-day'

export const CalendarDay = (props: CalendarDayProps) => {
  const dateUtilObj = dateUtil(props.value)
  return (
    <Button
      onClick={() => props.onSelect(props.value)}
      ariaLabel={`Select ${dateUtilObj.format(DateFormat.monthDayYear)} date`}
      disabled={props.isDisabled}
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, 'is-selected', props.isSelected),
        getModifierClass(ROOT_CLASS, 'is-disabled', props.isDisabled),
        getModifierClass(ROOT_CLASS, 'is-in-range', props.isInRange)
      ])}
      htmlAttributes={{ onMouseEnter: () => props.onHover?.(props.value) }}
    >
      {dateUtilObj.date()}
    </Button>
  )
}
