import * as React from 'react'
import { useFetchServer } from '@Client/hooks/use-fetch/use-fetch'
import { useAppDispatch, useAppSelector } from '@Client/hooks/redux-hooks'
import { NewEInstantsResponseData } from '@Server/controllers/game/game-controller'
import { NewEInstants } from './new-e-instants-ui'
import { getObjectKeys } from '@Util/object-utilities'
import { iwgJackpotsActions } from '@Client/reducers/iwg-jackpots/iwg-jackpots-reducer'

const GAME_LIMIT = 4

export const NewEInstantsContainer = () => {
  const dispatch = useAppDispatch()
  const { cmsPreviewActive } = useAppSelector((state) => state.operations)
  const { isLoading, data, fetchData } =
    useFetchServer<NewEInstantsResponseData>('/api/v1/game/e-instants/new')
  const iLotteryInstantGames = useAppSelector(
    (state) => state.iLottery.instantGamesById
  )
  const { mqttByIwgIdentifier, isLoading: mqttIsLoading } = useAppSelector(
    (state) => state.iwgJackpots
  )

  React.useEffect(() => {
    fetchData()
  }, [cmsPreviewActive])

  const games =
    data && Array.isArray(data.newEInstants)
      ? data.newEInstants
          .filter((item) =>
            Object.keys(iLotteryInstantGames).includes(
              item?.configuration?.iLottery?.iLotteryId ?? ''
            )
          )
          .slice(0, GAME_LIMIT)
      : []

  const hasIwgGames = games.some(
    (game) => game.configuration.iLottery?.iwgIdentifier !== undefined
  )

  React.useEffect(() => {
    const existingMqttKeys = getObjectKeys(mqttByIwgIdentifier)
    if (hasIwgGames && existingMqttKeys.length === 0 && !mqttIsLoading) {
      dispatch(iwgJackpotsActions.fetchAllIwgJackpotsRequest())
    }
  }, [hasIwgGames, mqttByIwgIdentifier, mqttIsLoading])

  return <NewEInstants isLoading={isLoading} newInstants={games} />
}
