export const getGameSpecificDisclaimers = (gameIdentifier: string) => {
  switch (gameIdentifier) {
    case 'gimme-five':
      return ['*The jackpot will be divided equally among all winning tickets.']
    case 'lucky-for-life':
      return [
        '*Subject to published Lucky For Life rules; these prize amounts may be split if there are multiple winners. Split prizes will be lower than the published prize amounts. The GRAND PRIZE and/or the 2nd tier prize of $25,000 a year shall be paid after the player becomes entitled to the prize with a per winner annuity. Annuity for both prize levels will be paid for life, but for no less than 20 years.',
        'In some cases, these prizes may be paid on a pari-mutuel basis and will be lower than these published prize levels. Refer to the official game rules for a detailed explanation of the pari-mutuel prize payment.'
      ]
    case 'megabucks':
    case 'powerball':
    case 'mega-millions':
      return [
        '*The jackpot will be divided equally among all winning tickets. In very rare instances, the Lottery may not be able to pay the stated set prize levels. In that case, the prize pools will go pari-mutuel (like the jackpot) and we will divide the prize pool among all of the winners.'
      ]
    case 'pick-three':
    case 'pick-four':
      return ['Prizes based upon a $1 bet']
    default:
      return null
  }
}

export const getYouTubeLink = (gameIdentifier: string) => {
  switch (gameIdentifier) {
    case 'powerball':
      return 'https://www.powerball.com/watch-drawing'
    case 'mega-millions':
      return 'https://www.youtube.com/user/megamillions46/videos'
    case 'lucky-for-life':
      return 'https://www.youtube.com/playlist?list=PL4LxcxwjjsDQJZUBdXzFIyzDATyWT1HIC'
    default:
      return null
  }
}
