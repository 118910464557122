import * as React from 'react'
import { getArrayOfLength } from '@Util/array-utilities'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { DateFormat, dateUtil } from '@Util/date-util'
import { Nullable } from '@Util/utility-types'
import { CalendarDay } from './calendar-day'
import './styles.scss'

export type CalendarMonthProps = {
  selectedDate?: Date
  selectedEndDate?: Date
  onDateChange: (date?: Date) => void
  isDateDisabled?: (date: Date) => boolean
  isDateInRange?: (date: Date) => boolean
  onDateHover?: (date?: Date) => void
  onMouseLeave?: () => void
  month: number
  year: number
  monthPosition: 'prev' | 'next' | 'current'
}

const ROOT_CLASS = 'calendar-month'
const DAYS_OF_WEEK = getArrayOfLength(7).map((_, i) => i)

export const CalendarMonth = (props: CalendarMonthProps) => {
  const monthObj = dateUtil()
    .month(props.month)
    .year(props.year)
    .startOf('month')
  const daysInMonth = monthObj.daysInMonth()
  const firstDayOfWeek = monthObj.day()
  const days: Nullable<number>[] = getArrayOfLength(daysInMonth).map(
    (_, i) => i + 1
  )
  days.unshift(...getArrayOfLength(firstDayOfWeek))

  return (
    <div
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, props.monthPosition, true)
      ])}
      onMouseLeave={props.onMouseLeave}
    >
      {DAYS_OF_WEEK.map((day) => (
        <span
          className={getBlockClass(ROOT_CLASS, 'day-header')}
          key={`day-header-${day}`}
        >
          {dateUtil().day(day).format(DateFormat.minDayOfWeek)}
        </span>
      ))}
      {days.map((day, i) => {
        if (day === null) {
          return (
            <span
              key={`${monthObj.format(DateFormat.fullMonthAndYear)}-null-day-${i}`}
            />
          )
        }
        const date = monthObj.date(day)
        const nativeDate = date.toDate()
        const isSelectedEndDate =
          !!props.selectedEndDate &&
          dateUtil(props.selectedEndDate).isSame(date, 'day')

        const isSelected =
          (!!props.selectedDate &&
            dateUtil(props.selectedDate).isSame(date, 'day')) ||
          isSelectedEndDate

        return (
          <CalendarDay
            key={date.format(DateFormat.monthDayYear)}
            isSelected={isSelected}
            isDisabled={
              props.monthPosition !== 'current' ||
              (props.isDateDisabled?.(nativeDate) ?? false)
            }
            isInRange={props.isDateInRange?.(nativeDate) ?? false}
            value={nativeDate}
            onSelect={props.onDateChange}
            onHover={props.onDateHover}
          />
        )
      })}
    </div>
  )
}
