import {
  DrawGameJackpotResponse,
  FastPlayJackpotGroupResponse,
  JackpotsApi,
  JackpotSummary,
  RapidJackpotResponse
} from '@interaction-gaming/game-data-service-browser-client'
import { Optional } from '@Util/utility-types'
import { ApiConfig, gameDataConfig } from './config'
import { JackpotGroupsResponse } from '@Server/controllers/jackpot/jackpot-controller'
import { Api } from '.'

let JACKPOTS_API: Optional<JackpotsApi> = undefined
export type CurrentJackpot =
  | FastPlayJackpotGroupResponse
  | RapidJackpotResponse
  | DrawGameJackpotResponse

const configureGameDataJackpotsApi = (): JackpotsApi => {
  if (!JACKPOTS_API) {
    JACKPOTS_API = new JackpotsApi(gameDataConfig)
  }
  if (!JACKPOTS_API) {
    throw new Error('Error configuring Jackpots API')
  }
  return JACKPOTS_API
}

const getAllCurrentJackpots = async (): Promise<JackpotSummary> => {
  try {
    const api = configureGameDataJackpotsApi()
    if (!api) {
      throw new Error('Error configuring Jackpots API')
    }
    return await api.getAllCurrentJackpots({
      xClientID: ApiConfig.CLIENT_ID
    })
  } catch (e) {
    console.error('Error fetching jackpots', e)
    throw e
  }
}

const getJackpotGroupsById = async (): Promise<JackpotGroupsResponse> => {
  try {
    const response: JackpotGroupsResponse = await Api.callServer(
      '/api/v1/jackpot/groups'
    )
    return response
  } catch (e) {
    console.error('Error fetching jackpot groups', e)
    throw e
  }
}

export const JackpotApi = {
  getAllCurrentJackpots,
  getJackpotGroupsById
}
