import * as React from 'react'
import './styles.scss'

const ROOT_CLASS = 'fast-play-icon'

export const FastPlayIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='_ÎÓÈ_2'
    data-name='—ÎÓÈ_2'
    viewBox='0 0 578.01 578.01'
  >
    <defs></defs>
    <polygon
      className={ROOT_CLASS}
      points='382.83 125.43 283.05 125.43 194.52 302.17 258.51 302.17 199.62 452.58 383.49 232.88 314.12 232.53 382.83 125.43'
    />
  </svg>
)
