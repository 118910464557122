import * as React from 'react'
import { Link } from 'react-router-dom'
import { UrlUtil } from '@Util/url-utilities'
import { getModifierClass, joinClasses } from '@Components/component-helpers'
import { NgFrameworkDeepLinkParams } from '@Client/services/pam/ng-query-params'
import './styles.scss'

export type LinkProps = {
  dest: string
  className?: string
  onClick?: () => void
  reloadDocument?: boolean
  ariaLabel?: string
}

const ROOT_CLASS = 'link'

export const GeneralLink = (props: React.PropsWithChildren<LinkProps>) => {
  return UrlUtil.isValidUrl(props.dest) ? (
    <a
      href={props.dest}
      aria-label={props.ariaLabel}
      onClick={props.onClick}
      rel='noopener noreferrer'
      target='_blank'
      className={joinClasses([
        ROOT_CLASS,
        getModifierClass(ROOT_CLASS, 'external', true),
        props.className
      ])}
    >
      {props.children}
    </a>
  ) : (
    <Link
      to={props.dest}
      aria-label={props.ariaLabel}
      reloadDocument={
        props.dest
          .toLowerCase()
          .includes(NgFrameworkDeepLinkParams.deepLinkName)
          ? true
          : props.reloadDocument
      }
      onClick={props.onClick}
      className={joinClasses([ROOT_CLASS, props.className])}
    >
      {props.children}
    </Link>
  )
}
