import * as React from 'react'
import logoSrc from '@Client/assets/nhlottery-logo-white-letters.png'
import { AsyncImage } from '../async-image/async-image-ui'
import { getBlockClass } from '../component-helpers'
import { GeneralLink } from '../link/link-ui'
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  LINKEDIN_LINK,
  YOUTUBE_CHANNEL_LINK
} from './constants'
import { YoutubeIcon } from '@Client/icons/youtube/youtube-icon'
import { FacebookIcon } from '@Client/icons/facebook/facebook-icon'
import { InstagramIcon } from '@Client/icons/instagram/instagram-icon'
import { LinkedinIcon } from '@Client/icons/linkedin/linkedin-icon'
import { RoutePath } from '@Client/route-paths'
import './styles.scss'

const ROOT_CLASS = 'footer-icon-group'

export const FooterIconGroup = () => (
  <div className={ROOT_CLASS}>
    <GeneralLink dest={RoutePath.home} ariaLabel='Go to Home Page'>
      <AsyncImage src={logoSrc} alt={'New Hampshire Lottery Logo'} />
    </GeneralLink>
    <div className={getBlockClass(ROOT_CLASS, 'icons')}>
      <GeneralLink dest={FACEBOOK_LINK} ariaLabel='Lottery Facebook Page'>
        <FacebookIcon />
      </GeneralLink>
      <GeneralLink dest={INSTAGRAM_LINK} ariaLabel='Lottery Instagram Page'>
        <InstagramIcon />
      </GeneralLink>
      <GeneralLink
        dest={YOUTUBE_CHANNEL_LINK}
        ariaLabel='Lottery Youtube Channel'
      >
        <YoutubeIcon />
      </GeneralLink>
      <GeneralLink dest={LINKEDIN_LINK} ariaLabel='Lottery LinkedIn Page'>
        <LinkedinIcon />
      </GeneralLink>
    </div>
  </div>
)
