import {
  DrawingNumberRangeResponse,
  RapidDrawingResponse,
  RapidGame,
  RapidGamesApi
} from '@interaction-gaming/game-data-service-browser-client'
import { Optional } from '@Util/utility-types'
import { ApiConfig, gameDataConfig } from './config'

let RAPID_GAME_API: Optional<RapidGamesApi> = undefined

export type RapidGameDateRangeRequest = {
  gameId: string
  startDate: Date
  endDate: Date
}

export type RapidGameDrawRangeRequest = {
  gameId: string
  startDrawNumber: number
  endDrawNumber: number
}

export type GetAllRapidGamesResponse = RapidGame[]
export type RapidGameDateRangeResponse = DrawingNumberRangeResponse
export type RapidDrawRangeResponse = RapidDrawingResponse[]

const configureGameDataRapidGameApi = (): RapidGamesApi => {
  if (!RAPID_GAME_API) {
    RAPID_GAME_API = new RapidGamesApi(gameDataConfig)
  }
  if (!RAPID_GAME_API) {
    throw new Error('Error configuring Draw Games API')
  }
  return RAPID_GAME_API
}

const getRapidGames = async (): Promise<GetAllRapidGamesResponse> => {
  try {
    const api = configureGameDataRapidGameApi()
    if (!api) {
      throw new Error('Error configuring Rapid Games API')
    }
    return await api.getAllRapidGames({
      xClientID: ApiConfig.CLIENT_ID
    })
  } catch (e) {
    console.error('Error fetching rapid games', e)
    throw e
  }
}

const getDrawingNumbersByDateRange = async (
  request: RapidGameDateRangeRequest
) => {
  try {
    const api = configureGameDataRapidGameApi()
    if (!api) {
      throw new Error('Error configuring Rapid Games API')
    }
    return await api.getRapidGameDrawingNumbersByDateRange({
      xClientID: ApiConfig.CLIENT_ID,
      rapidGameId: request.gameId,
      startDate: request.startDate,
      endDate: request.endDate
    })
  } catch (e) {
    console.error('Error fetching rapid games', e)
    throw e
  }
}

const getDrawingsByDrawNumberRange = async (
  request: RapidGameDrawRangeRequest
): Promise<RapidDrawRangeResponse> => {
  try {
    const api = configureGameDataRapidGameApi()
    if (!api) {
      throw new Error('Error configuring Rapid Games API')
    }
    return await api.getRapidGameDrawingsInRange({
      xClientID: ApiConfig.CLIENT_ID,
      rapidGameId: request.gameId,
      start: request.startDrawNumber,
      end: request.endDrawNumber
    })
  } catch (e) {
    console.error('Error fetching rapid games', e)
    throw e
  }
}

export const RapidGameApi = {
  getRapidGames,
  getDrawingNumbersByDateRange,
  getDrawingsByDrawNumberRange
}
