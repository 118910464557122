import * as React from 'react'
import { MarketingModalModel } from '@Cms/model-types/marketing-modal-model'
import { ModalRoot, Modal } from '../modal/modal'
import { getBlockClass, getCustomCssProperties } from '../component-helpers'
import { CmsText } from '../cms-text/cms-text-ui'
import { CTA } from '../cms-cta/cta'
import './styles.scss'

export type MarketingModalProps = {
  isOpen: boolean
  onClose: () => void
  modal: MarketingModalModel
}

const ROOT_CLASS = 'marketing-modal'
const FIFTEEN_SECONDS_MS = 15000

export const MarketingModal = (props: MarketingModalProps) => {
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      props.onClose()
    }, FIFTEEN_SECONDS_MS)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <ModalRoot
      isOpen={props.isOpen}
      onOpenChange={(isOpen) => (isOpen ? null : props.onClose())}
    >
      <Modal
        ariaDescription=''
        ariaTitle={props.modal.title}
        mobileFullScreen={false}
        useCloseButton={true}
      >
        <div
          className={ROOT_CLASS}
          style={getCustomCssProperties({
            '--modal-bkg-color': props.modal.backgroundColor
          })}
        >
          <h2
            className={getBlockClass(ROOT_CLASS, 'title')}
            style={getCustomCssProperties({
              '--modal-title-color': props.modal.titleColor
            })}
          >
            {props.modal.title}
          </h2>
          <CmsText textModel={props.modal.text} />
          {props.modal.cta && (
            <CTA cta={props.modal.cta} onClick={props.onClose} />
          )}
        </div>
      </Modal>
    </ModalRoot>
  )
}
