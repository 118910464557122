import * as React from 'react'
import { FooterLink } from './constants'
import { getBlockClass } from '../component-helpers'
import { GeneralLink } from '../link/link-ui'
import { RoutePath } from '@Client/route-paths'

type FooterLinkGroupProps = {
  title: string
  links: FooterLink[]
}

const ROOT_CLASS = 'footer-link-group'
const LINK_CLASS = getBlockClass(ROOT_CLASS, 'link')

export const FooterLinkGroup = (props: FooterLinkGroupProps) => (
  <div className={ROOT_CLASS}>
    <h2 className={getBlockClass(ROOT_CLASS, 'title')}>{props.title}</h2>
    <ul className={getBlockClass(ROOT_CLASS, 'list')}>
      {props.links.map((link) => (
        <li key={link.route}>
          <GeneralLink className={LINK_CLASS} dest={link.route}>
            {link.text}
          </GeneralLink>
        </li>
      ))}
    </ul>
  </div>
)

export const FooterStandaloneLinks = () => (
  <div className={getBlockClass(ROOT_CLASS, 'standalone-links')}>
    <GeneralLink className={LINK_CLASS} dest={RoutePath.about}>
      About the NH Lottery
    </GeneralLink>
    <GeneralLink className={LINK_CLASS} dest={RoutePath.mobileApp}>
      NH Lottery Mobile App
    </GeneralLink>
    <GeneralLink className={LINK_CLASS} dest={RoutePath.otherWebsites}>
      Other Websites
    </GeneralLink>
  </div>
)
