import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DrawDateState,
  DrawTimesState,
  FetchDrawTimesFailurePayload,
  FetchDrawTimesPayload,
  FetchDrawTimesSuccessPayload
} from './draw-times-reducer-types'
import { dateUtil } from '@Util/date-util'

export const initialDrawTimesState: DrawTimesState = {
  byGameId: {}
}

const drawTimesSlice = createSlice({
  name: 'drawTimes',
  initialState: initialDrawTimesState,
  reducers: {
    fetchAllDrawTimes: () => {},
    fetchDrawTimes: (state, action: PayloadAction<FetchDrawTimesPayload>) => {
      state.byGameId[action.payload.gameId] = {
        isLoading: true,
        error: null,
        drawDates: state.byGameId[action.payload.gameId]?.drawDates ?? []
      }
    },
    fetchDrawTimesSuccess: (
      state,
      action: PayloadAction<FetchDrawTimesSuccessPayload>
    ) => {
      const existingDrawDates =
        state.byGameId[action.payload.gameId]?.drawDates ?? []
      const allDrawDates = [...existingDrawDates, ...action.payload.drawDates]
      const uniqueDrawDates = allDrawDates.reduce((acc, drawDate) => {
        if (!acc.find((d) => d.drawDate === drawDate.drawDate)) {
          acc.push(drawDate)
        }
        return acc
      }, [] as DrawDateState[])
      const mostRecentDatesFirst = uniqueDrawDates.sort(
        (a, b) => dateUtil(b.drawDate).unix() - dateUtil(a.drawDate).unix()
      )
      state.byGameId[action.payload.gameId] = {
        isLoading: false,
        error: null,
        drawDates: mostRecentDatesFirst
      }
    },
    fetchDrawTimesFailure: (
      state,
      action: PayloadAction<FetchDrawTimesFailurePayload>
    ) => {
      state.byGameId[action.payload.gameId] = {
        ...state.byGameId[action.payload.gameId],
        isLoading: false,
        error: action.payload.error
      }
    }
  }
})

export const drawTimesActions = drawTimesSlice.actions
export const drawTimesReducer = drawTimesSlice.reducer
