import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '@Client/hooks/redux-hooks'
import { GameDetailsPage } from './game-details-page-ui'
import { PageNotFound } from '../page-not-found/page-not-found-ui'

export const GameDetailsPageContainer = () => {
  const { identifier } = useParams()
  const cmsGame = useAppSelector((state) => {
    if (!identifier) {
      return null
    }
    const gameDataServiceId =
      state.cmsGames.dataServiceIdByGameIdentifier[identifier]
    return state.cmsGames.gamesByDataServiceId[gameDataServiceId]
  })
  return cmsGame ? (
    <GameDetailsPage game={cmsGame} />
  ) : (
    <PageNotFound message={`We couldn't find that game /:`} />
  )
}
