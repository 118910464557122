import { EntryFieldTypes } from 'contentful'
import { CmsModel } from './cms-models'
import { GameOrderModel, GameOrderSkeleton } from './game-order-model'

export enum SortOptionIdentifier {
  recommended = 'recommended',
  newest = 'newest',
  hottest = 'hottest',
  gameName = 'gameName',
  priceLowToHigh = 'priceLowToHigh',
  priceHighToLow = 'priceHighToLow'
}

export enum SortOptionField {
  name = 'name',
  identifier = 'identifier',
  gameOrder = 'gameOrder'
}

export type SortOptionSkeleton = {
  contentTypeId: CmsModel.sortOption
  fields: {
    [SortOptionField.name]: EntryFieldTypes.Symbol
    [SortOptionField.identifier]: EntryFieldTypes.Symbol
    [SortOptionField.gameOrder]: EntryFieldTypes.EntryLink<GameOrderSkeleton>
  }
}

export type SortOptionModel = {
  name: string
  identifier: string
  gameOrder?: GameOrderModel
}

export const sortOptionIdentifierValues = Object.values(SortOptionIdentifier)
export const SortOptionFields = Object.values(SortOptionField)
