import * as React from 'react'
import { useAppSelector } from '@Client/hooks/redux-hooks'
import { ClientConfig } from '@Client/config'
import { StagingEnvironment } from '@Server/constants'

const ILOTTERY_SCRIPT_ID = 'ilottery-library'

const getILotteryEnv = () => {
  switch (ClientConfig.STAGING_ENV) {
    case StagingEnvironment.develop:
      return 'qa-npi'
    case StagingEnvironment.qa:
      return 'rc-npi'
    case StagingEnvironment.uat:
      return 'uat'
    case StagingEnvironment.uatAi:
      return 'ng.uat'
    case StagingEnvironment.prod:
    default:
      return ''
  }
}

export const useILotteryProvider = () => {
  const { isReadyToLoadLibrary } = useAppSelector((state) => state.iLottery)

  React.useEffect(() => {
    if (isReadyToLoadLibrary) {
      const existingScript = document.getElementById(ILOTTERY_SCRIPT_ID)
      if (existingScript) {
        return
      }
      const iLotteryEnv = getILotteryEnv()
      const scriptUrl = `https://gamesrv1.${iLotteryEnv.length ? `${iLotteryEnv}.` : ''}nhlottery.com/MWC/mwc-app.js`
      const script = document.createElement('script')
      script.id = ILOTTERY_SCRIPT_ID
      script.src = scriptUrl
      script.async = true
      script.defer = true
      document.head.appendChild(script)
    }
  }, [isReadyToLoadLibrary])
}
