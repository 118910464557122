import * as React from 'react'
import {
  getBlockClass,
  getModifierClass,
  joinClasses
} from '../component-helpers'
import { Button } from '../button/button'
import { DateFormat, dateUtil } from '@Util/date-util'
import { CalendarIcon } from '@Client/icons/calendar'
import { ModalRoot, Modal } from '../modal/modal'
import { ModalTriggerButton } from '../modal/modal-trigger-button'
import { DateRangeCalendar } from './date-range-calendar-ui'
import { CloseIcon } from '@Client/icons/close-icon'
import './styles.scss'

export type DateRangeSelectorProps = {
  isDateDisabled: (date: Date) => boolean
  startDate?: Date
  endDate?: Date
  onDateChange: (startDate?: Date, endDate?: Date) => void
  onNextMonth?: (date: Date) => void
  onPreviousMonth?: (date: Date) => void
  inputName: string
  label?: string
  className?: string
}

const ROOT_CLASS = 'date-range-selector'
const TEXT_CLASS = getBlockClass(ROOT_CLASS, 'text')
const ARIA_LABEL_OPEN = 'Open Date Range Selector'

export type DateInputButtonProps = {
  startDate?: Date
  endDate?: Date
  clearDates: () => void
  label?: string
  className?: string
}

const DateInput = (props: DateInputButtonProps) => {
  const hasBothDates = !!props.startDate && !!props.endDate
  return (
    <span
      id={props.label ? `date-range-select-${props.label}` : undefined}
      className={joinClasses([
        getBlockClass(ROOT_CLASS, 'input'),
        props.className ? getBlockClass(props.className, 'input') : ''
      ])}
    >
      <ModalTriggerButton
        ariaLabel={ARIA_LABEL_OPEN}
        className={joinClasses([
          TEXT_CLASS,
          getModifierClass(TEXT_CLASS, 'placeholder', !hasBothDates)
        ])}
      >
        {hasBothDates
          ? `${dateUtil(props.startDate).format(DateFormat.monthDayYear)} - ${dateUtil(props.endDate).format(DateFormat.monthDayYear)}`
          : 'Select a date range...'}
      </ModalTriggerButton>
      {hasBothDates && (
        <Button
          onClick={props.clearDates}
          ariaLabel='Clear dates'
          className={getBlockClass(ROOT_CLASS, 'close-button')}
        >
          <CloseIcon />
        </Button>
      )}
      <ModalTriggerButton
        ariaLabel={ARIA_LABEL_OPEN}
        className={getBlockClass(ROOT_CLASS, 'calendar-icon')}
      >
        <CalendarIcon />
      </ModalTriggerButton>
    </span>
  )
}

export const DateRangeSelector = (props: DateRangeSelectorProps) => {
  const [calendarOpen, setCalendarOpen] = React.useState<boolean>(false)
  const clearDates = () => props.onDateChange()

  React.useEffect(() => {
    if (!calendarOpen && !props.endDate && props.startDate) {
      clearDates()
    }
  }, [calendarOpen, props.endDate, props.onDateChange])

  const selectDates = (startDate?: Date, endDate?: Date) => {
    const startDateObj = dateUtil(props.startDate)
    if (props.startDate && startDateObj.isBefore(props.startDate)) {
      props.onDateChange(startDate, undefined)
    }
    if (props.startDate && dateUtil(endDate).isSame(startDateObj, 'day')) {
      clearDates()
      return
    }
    props.onDateChange(startDate, endDate)
    if (startDate && endDate) {
      setTimeout(() => {
        setCalendarOpen(false)
      }, 300)
    }
  }

  const headerText = !props.startDate
    ? 'Select a start date'
    : !props.endDate
      ? 'Select an end date'
      : 'Select a date range...'

  return (
    <div className={joinClasses([ROOT_CLASS, props.className])}>
      {props.label && (
        <label
          className={joinClasses([
            getBlockClass(ROOT_CLASS, 'label'),
            props.className ? getBlockClass(props.className, 'label') : ''
          ])}
          htmlFor={`date-range-select-${props.label}`}
        >
          {props.label}
        </label>
      )}
      <ModalRoot isOpen={calendarOpen} onOpenChange={setCalendarOpen}>
        <DateInput
          label={props.label}
          className={props.className}
          startDate={props.startDate}
          endDate={props.endDate}
          clearDates={clearDates}
        />
        <Modal
          className={getBlockClass(ROOT_CLASS, 'modal')}
          ariaTitle={'Calendar Picker'}
          ariaDescription={'Select a date range'}
          mobileFullScreen={false}
        >
          <DateRangeCalendar
            startDate={props.startDate}
            endDate={props.endDate}
            onDateChange={selectDates}
            headerText={headerText}
            inputName={props.inputName}
            isDateDisabled={props.isDateDisabled}
            onNextMonth={props.onNextMonth}
            onPreviousMonth={props.onPreviousMonth}
          />
        </Modal>
      </ModalRoot>
    </div>
  )
}
