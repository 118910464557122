import * as React from 'react'
import { getBlockClass } from '../component-helpers'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-ui'
import { ErrorMessage } from '../error-message/error-message-ui'
import { PastResultsTable } from '../past-results-table/past-results-table-ui'
import { PastResult } from '../past-results-table/constants'
import { PastResultsForm } from '../past-results-form/past-results-form-ui'
import {
  GameModel,
  GameType,
  RapidOrDrawGame
} from '@Cms/model-types/game-model'
import { InputSelectOption } from '../input-select/input-select-ui'
import { Nullable } from '@Util/utility-types'
import { deepCopy, getObjectKeys } from '@Util/object-utilities'
import { PrizePayoutsByIdentifier } from '../past-results-your-winnings/your-winnings-prize-map'
import { dateUtil } from '@Util/date-util'
import {
  DrawGameFormPayload,
  RapidGameFormPayload
} from '../past-results-form/constants'
import { useDeepLink } from '@Client/hooks/use-deep-link/use-deep-link'
import { TextButton } from '../button-text/text-button'
import { Button } from '../button/button'
import {
  getInitialBonusNumber,
  getInitialYourNumbers,
  handleDownloadClick
} from './helpers'
import './styles.scss'

export type PastResultsProps = {
  games: GameModel[]
  activeGame?: RapidOrDrawGame
  setActiveGameId: (gameId: string) => void
  results?: PastResult[]
  onDrawGameSubmit: (payload: DrawGameFormPayload) => Promise<void>
  onRapidGameSubmit: (payload: RapidGameFormPayload) => Promise<void>
  isLoading: boolean
  error?: string
  hasMoreResults: boolean
  loadMoreResults: () => Promise<void>
}

const ROOT_CLASS = 'past-results'

export const PastResults = (props: PastResultsProps) => {
  const { getParam } = useDeepLink()
  const [yourNumbers, setYourNumbers] = React.useState<Nullable<number>[]>(() =>
    getInitialYourNumbers(getParam)
  )
  const [yourNumbersSpecialBall, setYourNumbersSpecialBall] = React.useState<
    Nullable<number>
  >(() => getInitialBonusNumber(getParam))

  const gameOptions: InputSelectOption[] = React.useMemo(
    () =>
      props.games.map((game) => ({
        displayText: game.name,
        value: game.identifier
      })),
    [props.games]
  )

  const updateYourNumbers = (newNumber: Nullable<number>, index: number) => {
    const newNumbers = deepCopy(yourNumbers)
    while (index >= newNumbers.length) {
      newNumbers.push(null)
    }
    newNumbers[index] = newNumber
    setYourNumbers(newNumbers)
  }

  const clearYourNumbers = () => {
    setYourNumbers([])
  }

  const getValidResults = () => {
    const validNumbers = yourNumbers.filter(
      (number): number is number => number !== null
    )
    if (!validNumbers.length && yourNumbersSpecialBall === null) {
      return props.results
    }
    const results = props.results?.filter((result) => {
      const baseMatch = validNumbers.some((number) =>
        result.winningNumbers.numbers.includes(number)
      )
      if (!yourNumbersSpecialBall) {
        return baseMatch
      }
      return (
        baseMatch ||
        result.winningNumbers.specialBalls?.some(
          (ball) => ball.value === yourNumbersSpecialBall
        )
      )
    })
    return results
  }

  const dateRanges = React.useMemo(() => {
    if (props.activeGame?.type !== GameType.rapid) {
      return {}
    }
    const dateOne = props.results?.[0]?.drawDate
    const dateTwo = props.results?.[props.results.length - 1]?.drawDate
    const minDate = dateUtil(dateOne).isBefore(dateTwo, 'date')
      ? dateOne
      : dateTwo
    const maxDate = dateUtil(dateOne).isAfter(dateTwo, 'date')
      ? dateOne
      : dateTwo
    return { minDate, maxDate }
  }, [props.results, props.activeGame])

  const results = getValidResults()
  const hasYourNumbers = Boolean(
    !!yourNumbers.find((number) => number !== null) &&
      props.activeGame?.identifier &&
      getObjectKeys(PrizePayoutsByIdentifier).includes(
        props.activeGame?.identifier
      )
  )

  return (
    <div className={ROOT_CLASS}>
      <PastResultsForm
        gameOptions={gameOptions}
        activeGame={props.activeGame}
        setActiveGameId={props.setActiveGameId}
        onDrawGameSubmit={props.onDrawGameSubmit}
        onRapidGameSubmit={props.onRapidGameSubmit}
        yourNumbers={yourNumbers}
        onYourNumbersChange={updateYourNumbers}
        clearYourNumbers={clearYourNumbers}
        yourNumbersSpecialBall={yourNumbersSpecialBall}
        onBonusNumberChange={setYourNumbersSpecialBall}
        specialBallColorHex={
          props.activeGame?.branding?.bonusNumbers?.backgroundColorHex
        }
      />
      {results?.length ? (
        <TextButton
          onClick={() => handleDownloadClick(results, props.activeGame)}
          ariaLabel='Download results as CSV'
          className={getBlockClass(ROOT_CLASS, 'download-button')}
        >
          Download results as CSV
        </TextButton>
      ) : null}
      {props.activeGame ? (
        <PastResultsTable
          results={results}
          activeGame={props.activeGame}
          maxDate={dateRanges.maxDate}
          minDate={dateRanges.minDate}
          hasYourNumbers={hasYourNumbers}
          yourNumbers={yourNumbers}
          yourNumbersSpecialBall={yourNumbersSpecialBall}
        />
      ) : null}
      {props.hasMoreResults && !props.isLoading && (
        <div className={getBlockClass(ROOT_CLASS, 'load-more-container')}>
          <Button
            ariaLabel='Load more results'
            onClick={props.loadMoreResults}
            className={getBlockClass(ROOT_CLASS, 'load-more-button')}
          >
            Load More
          </Button>
        </div>
      )}
      {props.isLoading || props.error ? (
        <div className={getBlockClass(ROOT_CLASS, 'state-container')}>
          {props.isLoading ? (
            <LoadingSpinner />
          ) : (
            <ErrorMessage message={props.error} />
          )}
        </div>
      ) : null}
    </div>
  )
}
