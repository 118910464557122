import * as React from 'react'
import { getBlockClass } from '@Client/components/component-helpers'
import './styles.scss'

const ROOT_CLASS = 'e-instants-icon'

export const EInstantsIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='_ÎÓÈ_2'
    data-name='—ÎÓÈ_2'
    viewBox='0 0 584.03 578.01'
  >
    <defs></defs>
    <circle
      className={getBlockClass(ROOT_CLASS, 'cls-3')}
      cx='289'
      cy='289'
      r='289'
    />
    <g>
      <g>
        <path
          className={getBlockClass(ROOT_CLASS, 'cls-1')}
          d='M426.84,507.42v-24.81l13.49-13.49c7.07-7.07,11.03-16.65,11.03-26.64v-113.15c0-10.55-4.19-20.66-11.65-28.12l-86.24-86.24h0c-25.7,25.7-25.7,67.37,0,93.07l51.35,51.35s-59,19.13-65.37,143.1'
        />
        <path
          className={getBlockClass(ROOT_CLASS, 'cls-1')}
          d='M341.44,478.77h-137.64c-16.48,0-29.84-13.36-29.84-29.84V100.43c0-16.48,13.36-29.84,29.84-29.84h174.3c16.48,0,29.84,13.36,29.84,29.84v169'
        />
        <line
          className={getBlockClass(ROOT_CLASS, 'cls-1')}
          x1='208.97'
          y1='478.77'
          x2='223.29'
          y2='507.42'
        />
        <line
          className={getBlockClass(ROOT_CLASS, 'cls-1')}
          x1='173.96'
          y1='404.19'
          x2='362.18'
          y2='404.19'
        />
        <path
          className={getBlockClass(ROOT_CLASS, 'cls-1')}
          d='M223.33,70.59v21.88c0,11.65,9.44,21.09,21.09,21.09h93.05c11.65,0,21.09-9.44,21.09-21.09v-21.88'
        />
      </g>
      <text
        className={getBlockClass(ROOT_CLASS, 'cls-2')}
        transform='translate(227.72 341.41)'
      >
        <tspan className={getBlockClass(ROOT_CLASS, 'cls-6')} x='0' y='0'>
          P
        </tspan>
        <tspan className={getBlockClass(ROOT_CLASS, 'cls-4')} x='35.43' y='0'>
          L
        </tspan>
        <tspan className={getBlockClass(ROOT_CLASS, 'cls-5')} x='62.95' y='0'>
          A
        </tspan>
        <tspan x='97.07' y='0'>
          Y
        </tspan>
      </text>
      <path
        className={getBlockClass(ROOT_CLASS, 'cls-1')}
        d='M337.41,281.87h-100.71c-20.66,0-37.41,16.75-37.41,37.41v8.29c0,20.66,16.75,37.41,37.41,37.41h105.1c18.57,0,33.99-13.53,36.91-31.28'
      />
    </g>
  </svg>
)
