import { EntryFieldTypes } from 'contentful'
import { CmsModel } from './cms-models'
import { SortOptionSkeleton } from './sort-option-model'
import { FilterTagSkeleton } from './filter-tag-model'
import { GameModel } from './game-model'

export enum GameCarouselField {
  name = 'name',
  title = 'title',
  type = 'type',
  sortOrder = 'sortOrder',
  filterTags = 'filterTags',
  limit = 'limit'
}

export enum GameCarouselType {
  filterTag = 'Filter Tag'
}

export type GameCarouselSkeleton = {
  contentTypeId: CmsModel.gameCarousel
  fields: {
    [GameCarouselField.name]: EntryFieldTypes.Symbol
    [GameCarouselField.title]: EntryFieldTypes.Symbol
    [GameCarouselField.type]: EntryFieldTypes.Symbol<GameCarouselType>
    [GameCarouselField.sortOrder]: EntryFieldTypes.EntryLink<SortOptionSkeleton>
    [GameCarouselField.filterTags]: EntryFieldTypes.Array<
      EntryFieldTypes.EntryLink<FilterTagSkeleton>
    >
    [GameCarouselField.limit]: EntryFieldTypes.Integer
  }
}

export type GameCarouselModel = {
  [GameCarouselField.title]: string
  [GameCarouselField.type]: GameCarouselType
  games: GameModel[]
}

export const GameCarouselTypeValues = Object.values(GameCarouselType)
export const GameCarouselFields = Object.values(GameCarouselField)
