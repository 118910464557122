import * as React from 'react'
import { getBlockClass } from '@Client/components/component-helpers'
import './styles.scss'

const ROOT_CLASS = 'draft-kings-icon'

export const DraftKingsIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' id='Layer_1' viewBox='0 0 600 600'>
    <defs></defs>
    <path
      className={getBlockClass(ROOT_CLASS, 'orange')}
      d='M468.35,149.26c-12.6,5.66-20.18,17.87-20.96,30.73h0c-9.64-6.81-22.24-8.61-33.81-4.11.51,1.41,1.03,2.96,1.67,4.37,4.37,9.77,12.6,16.46,22.11,19.41-5.27,8.23-12.34,15.43-20.83,20.83-3.6,2.44-7.46,3.47-12.08,3.99-11.44,1.41-21.98-4.5-27.13-14.14-4.11,7.33-11.57,12.73-20.57,13.76-4.89.64-9.64-.13-13.88-1.93-1.67-.9-3.21-1.93-4.89-3.09-9.26-6.43-16.84-15.04-22.24-25.33-2.06-4.11-3.86-8.36-5.14-12.86.26,0,.39,0,.64-.13,10.8-1.16,20.83-7.2,26.74-17.23.64-1.03,1.16-2.06,1.67-3.21-.9-.64-1.93-1.41-2.96-1.93-9-5.27-19.28-6.3-28.54-3.6-1.16.39-2.19.64-3.21,1.16.39-12.6-9-31.5-20.57-38.31-1.29-.77-3.21-1.67-4.5-2.31l-1.03-.39-.64.9c-.9,1.16-1.8,2.44-2.44,3.73-6.81,11.57-4.89,32.4,1.41,43.32-1.16.13-2.31.39-3.34.51-9.38,2.06-18,7.84-23.27,16.84-.64,1.03-1.16,2.06-1.67,3.21.9.64,1.93,1.41,2.96,1.93,10.03,5.91,21.73,6.3,31.75,2.19.26-.13.39-.13.51-.26,1.03,4.63,1.67,9.26,1.8,13.76.26,10.28-1.67,20.31-5.66,29.44v-.26c-.13.39-.26.64-.26,1.03-.39.9-.77,1.8-1.29,2.57-1.03,2.06-2.19,4.11-3.47,6.17-2.83,3.6-6.69,6.56-11.31,8.36-8.48,3.34-17.48,2.19-24.55-2.44,0,10.8-6.56,21.08-17.23,25.33-2.44,1.03-5.01,1.54-7.58,1.8-11.44-.13-22.5-3.21-32.27-8.48,6.94-7.07,10.8-16.97,10.03-27.64-.13-1.54-.39-4.5-.64-6.04-12.34,1.54-22.63,12.6-27.64,18.51h0c-6.81-5.91-19.41-16.46-33.04-15.43-3.21.26-6.3,1.67-9.13,2.7,15.3,11.31,29.95,24.04,43.71,37.28,9.64,9.26,19.03,19.16,27.9,29.31,12.34,14.14,33.43,42.55,33.43,42.55,2.57,3.6,13.11,8.36,20.31,7.58,1.29-.13,1.67-1.29,1.67-1.93.13-.77-1.16-1.16-1.8-1.54-6.17-2.7-8.48-6.04-10.16-11.31-3.99-12.47,33.17-34.84,87.55-48.6,54.38-13.76,97.96-12.08,100.53.77,1.03,5.4.64,9.51-3.47,14.78-.39.64-1.41,1.54-.9,2.19.39.51,1.29,1.29,2.44.9,6.56-2.83,13.63-11.96,14.14-16.46,0,0,5.79-35.35,9.9-53.61,2.83-13.11,6.3-26.23,10.41-38.95,5.79-18.26,12.6-36.13,20.57-53.48-3.73,1.03-6.81,1.8-9.77,3.09'
    />
    <path
      className={getBlockClass(ROOT_CLASS, 'green')}
      d='M276.41,318.06s43.2-19.54,91.92-25.33c0,0,61.71,6.04,61.71,78.55s-51.55,113.77-121.62,113.77-107.09-30.85-107.09-63.89,23.65-38.18,34.71-38.18,30.85,9.9,30.85,35.74c0,0-19.8-4.89-19.8,12.21s23.4,27.77,55.54,27.77,77.52-14.01,77.52-76.75c0-66.85-30.98-69.94-52.45-69.94v114.8s.51,10.03,11.31,10.03c0,0-11.31,9.51-39.34,9.51s-36-9-36-9c0,0,13.24,0,13.24-11.7-.13-11.44-.51-107.6-.51-107.6'
    />
  </svg>
)
