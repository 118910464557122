import * as React from 'react'
import { ConstantContentName } from '@Cms/model-types/constant-content-model'
import { useFetchFunction } from '@Client/hooks/use-fetch/use-fetch-function'
import { ContentApi } from '@Client/api/content'
import { BannerCarousel } from './banner-carousel-ui'
import { ConstantContentResponse } from '@Server/controllers/content/content-controller'
import { CmsModel } from '@Cms/model-types/cms-models'

const AUTO_ROTATE_INTERVAL = 6000

export type BannerCarouselContainerProps = {
  name: ConstantContentName
}

export const BannerCarouselContainer = (
  props: BannerCarouselContainerProps
) => {
  const { fetchData, data, isLoading } =
    useFetchFunction<ConstantContentResponse>()

  React.useEffect(() => {
    fetchData(async () => await ContentApi.fetchConstantContent(props.name))
  }, [])

  const images =
    data?.status === 'success' &&
    data.data.contentType === CmsModel.bannerCarousel
      ? data.data.content.banners
      : undefined

  return (
    <BannerCarousel
      isLoading={isLoading}
      banners={images}
      name={props.name}
      autoRotateInterval={AUTO_ROTATE_INTERVAL}
    />
  )
}
